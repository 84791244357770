import React, { useState } from 'react'
import BidCard from '../../../Common/Layout/Card/BidCard'
import DefaultProfileicon from '../../../Images/Vendor/DefaultProfileicon.svg'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import DeclinePopup from '../../../Common/FormFields/DeclinePopup'
import TextFieldInput from '../../../Common/FormFields/TextFieldInput'
import { CircularProgress } from '@mui/material'
import NoContentPage from '../../../Common/Layout/NoContentPage/NoContentPage'
import PaginationComponent from '../../../Common/UiComponents/paginationComponent/PaginationComponent'

const InterestedBid = (props) => {

    const [open, setOpen] = useState(false)
    const [reqId, setReqId] = useState()
    const [priceData, setPriceData] = useState()
    const businessId = parseInt(localStorage.getItem('businessId'))

    const { handleSubmit, reset, control } = useForm()
    const handleclickForBid = (id) => {
        setOpen(!open)
        setReqId(id)
    }

    const onSubmit = (data) => {
        setPriceData(data)
        props.postBid(data, reqId, businessId)
        setOpen(!open)
        reset(priceData)
    }
    // console.log(props?.interestedBidsData, "interestedBidsData ##")

    return (
        <div className='overflow-y-scroll h-[78vh]'>
            {props.loading === true ?
                <div className='flex h-full justify-center items-center'>
                    <CircularProgress size={20} color='success' />
                    <p className='pl-3'>Loading...</p>
                </div>
                :
                <div>
                    {props.interestedBidsData && props.interestedBidsData?.length > 0 ?
                        <div className='min-w-[60rem] overflow-x-auto'>
                            {props.interestedBidsData?.map((intBid, idx) =>
                                <div key={idx} >
                                    {intBid?.requirements?.status === 'live' &&
                                        <BidCard
                                            DefaultProfileicon={DefaultProfileicon}
                                            name={intBid?.requirements?.user?.name}
                                            email={intBid?.requirements?.user?.email}
                                            LiveBid={intBid?.requirements?.status === 'live' && true}
                                            question={intBid?.requirements?.title}
                                            content={intBid?.requirements?.description}
                                            flex={true}
                                            showdate={true}
                                            date={moment(intBid?.requirements?.startDate).format('LL')}
                                            // peoplereplied={true}
                                            numberofpeoplereplied={intBid?.numberofpeoplereplied}
                                            uploadDocument={false}
                                            // attachment={intBid?.document?.length > 0 ? true : false}
                                            expiredtime={true}
                                            time={moment(intBid?.requirements?.endDate).format('LL')}
                                            onClick={() => props.goToAllReqrLiveDetails(intBid?.requirements, false)}
                                            // declineBid={props.singleVendorDetailsData?.userId === intBid?.requirements?.createdBy ? false : true}
                                            // doBidClick={() => handleclickForBid(intBid?.requirements?.id)}
                                            item={intBid?.requirements}
                                        />
                                    }
                                </div>
                            )}
                        </div>
                        :
                        <NoContentPage
                            NoContentMainDiv={`h-[78vh]`}
                            text2={`There is no data`}
                        />
                    }
                </div>}
            <PaginationComponent
                totalItems={props?.totalInterestedRequirements}
                itemsPerPage={props?.itemsPerPage}
                currentPage={props?.currentInterestRequirementPage}
                onPageChange={props?.setCurrentInterestRequirementPage}
                siblingCount={1}
                boundaryCount={2}
                variant="outlined"
                color="primary"
            />
            {open &&
                <form onSubmit={handleSubmit(onSubmit, reset)}>
                    <DeclinePopup
                        dialogTitlecls={`!text-black`}
                        submitBtnCls={`!bg-skyblue-6`}
                        open={open}
                        title={`Bid Amount`}
                        handleClose={handleclickForBid}
                        DilogContenttext={"Price"}
                        DialogContent={
                            <Controller
                                name={"price"}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextFieldInput
                                        textinputname={"price"}
                                        typeNumber={true}
                                        onlyValue
                                        textnewclass={`w-full text-sm`}
                                        placeholder='*Enter Price'
                                        value={(value)}
                                        onChange={(e)=>{
                                            const newValue = e.target.value;
                                            if (newValue >= 0) {
                                                onChange(newValue);
                                            }
                                        }}
                                        onWheel={(e) => e.target.blur()}
                                    />
                                )}
                            />
                        }
                        DeleteBtn={"Bid"}
                        onClick={handleSubmit(onSubmit)}
                    />
                </form>}
        </div>)
}

export default InterestedBid