import React from 'react'

const CommonTermsAndConditions = (props) => {
    return (
        <div className='px-[3%] py-[2%]'>
            <p className={`text-deepgray-4 font-[Roboto-Bold] text-3xl py-5`}>{`Terms and Conditions`}</p>
            <div className={`flex flex-col-reverse md:flex-row gap-4 md:gap-0 justify-between`}>
                <div>
                    <div className={`text-deepgray-4 font-[Roboto-medium] text-[20px] `}>
                        1. Introduction
                    </div>
                    <div className='text-deepgray-4 font-[Roboto-regular] text-[15px] px-2 pt-1'>
                        Welcome to Explore the Markets, a digital platform provided by Explore Advertising & Marketing Co. WLL,
                        aimed at facilitating classified advertisements and connecting users for various transactions. These Terms
                        and Conditions govern your access to and use of the Explore the Markets website and mobile application.
                        By accessing or using our platform, you acknowledge that you have read, understood, and agree to be
                        bound by these terms. If you do not agree with any part of these terms, please refrain from accessing or
                        utilizing our services.
                    </div>

                    <div className={`text-deepgray-4 font-[Roboto-medium] text-[20px] pt-3`}>
                        2. Platform Ownership and Liability
                    </div>
                    <div className='text-deepgray-4 font-[Roboto-regular] text-[15px] px-2 pt-1'>
                        Explore the Markets is owned and operated by Explore Advertising & Marketing Co. WLL, a registered
                        company under the laws of Kuwait. While our platform serves as a medium for users to engage in
                        transactions, we do not endorse, guarantee, or assume any responsibility for the accuracy, quality, or
                        legality of the products or services advertised by users. Explore Advertising & Marketing Co. WLL holds no
                        liability for any disputes, losses, or damages arising from transactions conducted through our platform.

                    </div>

                    <div className={`text-deepgray-4 font-[Roboto-medium] text-[20px] pt-3`}>
                        3. Ad Placement Rules
                    </div>
                    <div className='text-deepgray-4 font-[Roboto-regular] text-[15px] px-2 pt-1'>
                        To ensure the integrity of our platform, users are required to register with a verified mobile phone number
                        and email before posting ads. Advertisers must accurately categorize their ads and provide truthful
                        information including title, description, price, and images. Advertisements must not contain illegal,
                        immoral, or obscene content, nor should they defame individuals or institutions. We strictly prohibit the
                        advertising or display of any weapons, endangered plants and animals, or any items in violation of Kuwaiti
                        laws. Placing ads in violation of the laws of advertiser's state is prohibited. Placing ads in violation of the
                        laws in the State of Kuwait, especially Law No. 63 of 2015 on Combating Cyber Crimes and Law No. 38 of
                        2002 regarding the regulation of commercials of health-related products, is prohibited. Placing ads for
                        medications and aphrodisiacs is prohibited. According to Law No. 9 of 2001 on the misuse of telephone
                        communications and bugging devices, the display and sale of bugging devices and the means of inciting
                        the misuse of telephone devices is prohibited. Advertising for payments of debts and cashing is prohibited.
                        Advertising for furnished apartments to be rented per hour or day, except by the companies permitted to
                        do so, is prohibited. All ads for attracting investment, calling for investment and commercial partnership
                        are prohibited. Placing false labor ads or ads for hiring house labor to work in houses per hour is
                        prohibited. The customer confirms acknowledgment of accessing all special and general conditions using
                        the services of the site and with their full approval and acceptance of it, and decides to bear legal,
                        criminal, and civil responsibility for any damages to the site or those in charge of it or others in case of
                        violating the laws, regulations, and decisions regulating the advertisement and all other laws and decisions
                        applicable in the country. Entering the site or using the services available acknowledges and pledges
                        thereof.

                    </div>

                    <div className={`text-deepgray-4 font-[Roboto-medium] text-[20px] pt-3`}>
                        4. User Responsibilities and Prohibited Activities
                    </div>
                    <div className='text-deepgray-4 font-[Roboto-regular] text-[15px]'> pt-1
                        <div>Users of the Explore the Markets platform are expected to comply with all applicable laws and regulations
                            of Kuwait. Prohibited activities include but are not limited to: <br/>
                        </div>
                        <div className='px-2'>
                        • Advertising illegal substances or engaging in misleading practices. <br/>
                        • Infringing upon the rights of others or engaging in activities harmful to individuals or
                        institutions.<br/>
                        • Violating the terms outlined in our Ad Placement Rules. Explore Advertising & Marketing
                        Co. WLL reserves the right to block or suspend any user account found to be in violation of
                        these terms or engaging in illegal activities on our platform.
                        </div>

                    </div>

                    <div className={`text-deepgray-4 font-[Roboto-medium] text-[20px] pt-3`}>
                        5. Financial Rights
                    </div>
                    <div className='text-deepgray-4 font-[Roboto-regular] text-[15px] px-2 pt-1'>
                        • Ad pricing and duration are determined based on the category of the advertisement. <br/>
                        • Additional charges may apply for optional add-ons. <br/>
                        • Refunds will only be issued in cases of technical failures resulting in the loss of funds, while
                        non-compliant ad costs will not be refunded. <br/>
                        • When using KNET to pay for an ad, you must retain a record of payment, in case you are not
                        credited. Explore the Markets will only refund in the case that the user has evidence that the
                        transaction was deducted from their bank account.

                    </div>

                    <div className={`text-deepgray-4 font-[Roboto-medium] text-[20px] pt-3`}>
                        6. Privacy and Data Sharing
                    </div>
                    <div className='text-deepgray-4 font-[Roboto-regular] text-[15px] px-2 pt-1'>
                        • Explore the Markets prioritizes the confidentiality of users' data. We are committed to ensuring
                        that your information is protected and not shared except within the permissible limits defined by
                        sellers when placing each ad. Sellers are required to input contact information, such as phone
                        number and email, in the designated fields. It's crucial to note that ads violating Kuwaiti laws will
                        not be accepted. <br/>
                        • By using Explore the Markets, users agree to provide their data to the concerned authorities if
                        requested by Explore the Markets in the event of placing an ad that violates Kuwaiti laws or
                        engaging in illegal activities through instant messages or live chat support. <br/>
                        • Explore the Markets maintains a cooperative relationship with the Kuwait Electronic Crimes
                        Control Department to ensure compliance with Kuwaiti laws. Any ad found to violate these laws
                        will be reported accordingly. Users must refrain from violating the intellectual property rights of
                        others and from engaging in offensive behavior through messages, comments, or postings. If a
                        user is found to have violated these terms and conditions, Explore the Markets reserves the right
                        to provide the relevant authorities with the user's data upon request. <br/>
                        • In the event that a user no longer has access to their registered phone number, Explore the
                        Markets cannot be held responsible for maintaining user privacy, including archived or active ads
                        and chats, or unused credits. It is the user's responsibility to update their contact information
                        accordingly.

                    </div>

                    <div className={`text-deepgray-4 font-[Roboto-medium] text-[20px] pt-3`}>
                        7. Intellectual Property Rights
                    </div>
                    <div className='text-deepgray-4 font-[Roboto-regular] text-[15px] px-2 pt-1'>
                        • Explore Advertising & Marketing Co. WLL owns all intellectual property rights related to the
                        Explore the Markets platform, including but not limited to data, images, and content in all forms
                        available on the platform and its website. <br/>
                        • Users are strictly prohibited from attempting to extract, reproduce, or distribute any part of the
                        platform's content or data without prior authorization. The content of Explore the Markets is not
                        to be used in any other intellectual work or included in any electronic database. Reselling,
                        distributing, modifying, translating, or re-engineering the platform's content in any form is strictly
                        prohibited. Explore Advertising & Marketing Co. WLL reserves the right to impose necessary
                        restrictions to prevent any unauthorized use or extraction of the platform's content. <br/>
                        • Users are also prohibited from attempting to re-engineer or disassemble the program used in the
                        platform in order to access the original program source code or structure of the platform or its
                        website. <br/>
                        • Any violation of these terms will be considered copyright and intellectual property infringement
                        and may result in legal action.

                    </div>

                    <div className={`text-deepgray-4 font-[Roboto-medium] text-[20px] pt-3`}>
                        8. Assignment of Rights for Placed Ads and Images
                    </div>
                    <div className='text-deepgray-4 font-[Roboto-regular] text-[15px] px-2 pt-1'>
                        By uploading advertisements and images to the platform, users grant Explore Advertising & Marketing Co.
                        WLL the right to reuse and display this content as deemed appropriate for promotional or marketing
                        purposes.

                    </div>

                    <div className={`text-deepgray-4 font-[Roboto-medium] text-[20px] pt-3`}>
                        9. Modification of Terms and Conditions
                    </div>
                    <div className='text-deepgray-4 font-[Roboto-regular] text-[15px] px-2 pt-1'>
                        • Explore Advertising & Marketing Co. WLL reserves the right to modify these Terms and Conditions
                        at any time without prior notice. Any changes to these terms will be effective immediately upon
                        posting on the Explore the Markets platform. Continued use of the platform following the posting
                        of changes constitutes acceptance of the modified terms. <br />

                        • By continuing to use Explore the Markets, you acknowledge that you have read, understood, and
                        agree to abide by these Terms and Conditions. If you have any questions or concerns regarding
                        these terms, please contact us at info@explorethemarkets.com


                    </div>
                </div>
                {/* <div className={`border border-gray-3 rounded-md px-[2%] pt-[2%] pb-[4%] w-[100%] md:w-[33%] lg:w-[25%] max-h-[50vh] overflow-y-scroll`}>
                    <p className={`text-deepgrey-3 font-[Roboto-Medium] text-lg pb-[5%]`}>{`Table of Contents`}</p>
                    <p className={`text-skyblue-5 font-[Roboto-Regular] text-base pb-[5%]`}>{`User Agreement`}</p>
                    <p className={`text-skyblue-5 font-[Roboto-Regular] text-base pb-[5%]`}>{`How We Use Your Data`}</p>
                    <p className={`text-skyblue-5 font-[Roboto-Regular] text-base pb-[5%]`}>{`How We Share Information`}</p>
                    <p className={`text-skyblue-5 font-[Roboto-Regular] text-base pb-[5%]`}>{`Your Choices & Obligations`}</p>
                    <p className={`text-skyblue-5 font-[Roboto-Regular] text-base pb-[5%]`}>{`Other Important Information`}</p>

                </div> */}
            </div>
        </div>
    )
}

export default CommonTermsAndConditions