import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import TextFieldInput from '../../../Common/FormFields/TextFieldInput';
import RadioButton from '../../../Common/UiComponents/RadioButton';
import ButtonField from '../../../Common/FormFields/ButtonField';
import text from '../../../Common/Languages/en_US.json'
// import MuiDatePicker from '../../../Common/FormFields/MuiDatePicker';
import { useTranslation } from 'react-i18next';
import eventIcon from '../../../Images/adminContent/eventIcon.svg'
import ReactDateRange from '../../../Common/FormFields/DateRangePicker';
// import { ClickAwayListener } from '@mui/material';
// import UploadButton from '../../../Common/UiComponents/UploadButton'
import fileUploadIcon from '../../../Images/SubAdmin/fileUploadIcon.svg'
import UploadMultipleImage from '../../../Common/Layout/UploadMultipleImage/UploadMultipleImage';
import moment from 'moment';
// import { addDays } from 'date-fns';
import './index.css'
import CloseIcon from '@mui/icons-material/Close';
import { ForUploadMediaHooks } from '../../../Containers/UploadMedia/Hooks';
import { useDispatch, useSelector } from 'react-redux';
import { setUploadMultipleImg } from '../../../Containers/UploadMedia/reducer/UploadMediaReducer'
import DateRangePickerComponent from '../../../Common/FormFields/DateRangePickerComponent';


const OfferForm = (props) => {
  const { cleanupMedia, multipleUpload } = ForUploadMediaHooks()
  const loading = useSelector((state) => state.upload.loading)
  const multipleUploadState = [...multipleUpload]
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const [radioValue, setRadioValue] = useState()
  const [dateOpen, setDateOpen] = useState(false)
  // const [dateState, setDateState] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: addDays(new Date(), 7),
  //     key: 'selection'
  //   }
  // ]);
  const [dateClicker, setDateClicker] = useState(false)

  const handleOpenDate = () => {
    setDateOpen(!dateOpen)
    setDateClicker(!dateClicker)
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      productName: props?.editProduct?.product?.name ? props?.editProduct?.product?.name : '',
      productDetails: props?.editProduct?.product?.description ? props?.editProduct?.product?.description : '',
      productActualPrice: props?.editProduct?.product?.price ? props?.editProduct?.product?.price : '',
      offerType: props?.editProduct?.type ? props?.editProduct?.type : '',
      percentage: props?.editProduct?.percentage ? props?.editProduct?.percentage : '',
      amount: props?.editProduct?.price ? props?.editProduct?.price : ''
    }
  });

  const onSubmit = (data) => {
    props.createNewProductOffers(data)
    cleanupMedia()
    // resetting after submission 
    props?.productSubmitError?.error === false && reset({
      productName: '',
      productActualPrice: '',
      productDetails: '',
      type: '',
      offerType: '',
      percentage: '',
      amount: '',
      dateRanger: '',
      offerTiming: '',
      // productImage: '',
    })
  };


  const RadioLabel = [
    {
      value: "percentage",
      label: "Percentage"
    },
    {
      value: "amount",
      label: "Amount"
    },
  ]

  useEffect(() => {
    if (props.editProduct) {
      dispatch(setUploadMultipleImg(props.editProduct?.product?.picture))
    }
  }, [])


  const removeParticularThumbnail = (idx) => {
    if (idx > -1) {
      let arr;
      arr = [...multipleUpload]
      arr.splice(idx, 1);
      dispatch(setUploadMultipleImg(arr))
    }
  }

  return (
    <div className='relative'>
      <p className={`p-4`}>{t("addOffer")}</p>
      <div className={`border border-b`}></div>
      <div className={`px-[4%] py-[3%]`}>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-col-reverse lg:flex-row justify-between gap-3'>
            {/* left part   */}
            <div className='lg:w-1/2 relative w-full'>

              {/* product name  */}
              <div className='w-full flex justify-between items-center gap-6'>
                <div className='w-1/2'>
                  <Controller name={"productName"}
                    control={control}
                    render={({ field: { onChange, value }, formState: { error } }) => {
                      return (
                        (<TextFieldInput
                          onlyValue
                          textinputname="productName"
                          textnewclass={` text-sm h-9 w-full`}
                          floatingLabel='productName'
                          value={(value)}
                          onChange={onChange}
                        />)
                      )
                    }}
                    rules={{
                      required: true, pattern: /^[a-zA-Z][a-zA-Z ]*/i, maxLength: 50, minLength: 5
                    }}
                  />
                  {/* product name error messages */}
                  <div className='mt-4'>
                    {errors.productName && errors.productName.type === "required" && (
                      <span className="error-message text-red-400 text-xs">Required</span>
                    )}
                    {errors.productName && errors.productName.type === "pattern" && (
                      <span className="error-message text-red-400 text-xs">Not Valid</span>
                    )}
                    {errors.productName && errors.productName.type === "maxLength" && (
                      <span className="error-message text-red-400 text-xs">Too Long</span>
                    )}
                    {errors.productName && errors.productName.type === "minLength" && (
                      <span className="error-message text-red-400 text-xs">Too Short</span>
                    )}
                  </div>
                </div>

                {/* product actual price  */}
                <div className={`w-1/2 py-[4%]`}>
                  <Controller name={"productActualPrice"}
                    control={control}
                    render={({ field: { onChange, value }, formState: { error } }) => {
                      return (
                        (<TextFieldInput
                          onlyValue
                          textinputname="productActualPrice"
                          textnewclass={`w-full text-sm h-9`}
                          floatingLabel='productActualPrice'
                          value={(value)}
                          typeNumber={true}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            if (newValue >= 0) {
                              onChange(newValue);
                            }
                          }}
                          onWheel={(e) => e.target.blur()}
                        />)
                      )
                    }}
                    rules={{
                      required: true, pattern: /^[0-9]+$/, minLength: 1
                    }}
                  />
                  {/* product actual price error messages */}
                  <div className='mt-4'>
                    {errors.productActualPrice && errors.productActualPrice.type === "required" && (
                      <span className="error-message text-red-400 text-xs">Required</span>
                    )}
                    {errors.productActualPrice && errors.productActualPrice.type === "pattern" && (
                      <span className="error-message text-red-400 text-xs">Not Valid</span>
                    )}
                    {errors.productActualPrice && errors.productActualPrice.type === "minLength" && (
                      <span className="error-message text-red-400 text-xs">Required</span>
                    )}
                  </div>
                </div>
              </div>

              {/* product details  */}
              <div className='my-5 w-full'>
                <Controller
                  name={"productDetails"}
                  control={control}
                  render={({ field: { onChange, value }, formState: { error } }) => {
                    return (
                      (<TextFieldInput
                        onlyValue
                        textinputname="productDetails"
                        textnewclass={`w-full text-sm h-9`}
                        floatingLabel='productDetails'
                        value={(value)}
                        onChange={onChange}
                        inputProps={{ maxLength: 500 }}
                      />)
                    )
                  }}
                  rules={{
                    required: true, minLength: 5, maxLength: 100
                  }}
                />
                {/* product details error messages */}
                <div className='mt-4'>
                  {errors.productDetails && errors.productDetails.type === "required" && (
                    <span className="error-message text-red-400 text-xs">Required</span>
                  )}
                  {errors.productDetails && errors.productDetails.type === "maxLength" && (
                    <span className="error-message text-red-400 text-xs">Too Long</span>
                  )}
                  {errors.productDetails && errors.productDetails.type === "minLength" && (
                    <span className="error-message text-red-400 text-xs">Too Short</span>
                  )}
                </div>
              </div>

              {/* offer type  */}
              <div className={`flex flex-col sm:flex-row py-[4%] justify-between w-full md:items-center`}>
                <div className={`pl-[2%]`}>
                  <Controller name={'offerType'}
                    control={control}
                    rules={{
                      required: true
                    }}
                    render={({ field: { onChange, value } }) => (
                      <RadioButton
                        name='offerType'
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                          setRadioValue(e.target.value)
                        }}
                        Radiolabel={RadioLabel}
                      />
                    )}
                  />
                  <div>
                    {errors.offerType && errors.offerType.type === "required" && (
                      <span className="error-message text-red-400 text-xs">Required</span>
                    )}
                  </div>
                </div>

                {/* percentage or amount  */}
                <div>
                  <div className='flex sm:justify-center items-center gap-4'>
                    <Controller
                      name={radioValue === "percentage" ? "percentage" : "amount"}
                      control={control}
                      render={({ field: { onChange, value }, formState: { error } }) => {
                        return (
                          (<TextFieldInput
                            onlyValue
                            typeNumber={true}
                            textinputname='totaloffer'
                            textnewclass={`w-full text-sm h-9`}
                            floatingLabel={radioValue === "percentage" ? 'totalOfferPercentage' : radioValue === "amount" ? "Amount" : ""}
                            value={(value)}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              if (newValue >= 0) {
                                onChange(newValue);
                              }
                            }}
                            onWheel={(e) => e.target.blur()}
                          />)
                        )
                      }}
                      rules={{
                        required: true, pattern: /^[0-9]+$/, maxLength: radioValue === "percentage" ? 2 : 4
                      }}
                    />
                    {radioValue === 'percentage' && <p className={`mt-2 flex`}>%</p>}
                  </div>
                  <div className='mt-4'>
                    {radioValue === "percentage" ?
                      errors.percentage && errors.percentage.type === "required" && (
                        <span className="error-message text-red-400 text-xs">Required</span>
                      ) : errors.amount && errors.amount.type === "required" && (
                        <span className="error-message text-red-400 text-xs">Required</span>
                      )
                    }
                    {radioValue === "percentage" ?
                      errors.percentage && errors.percentage.type === "maxLength" && (
                        <span className="error-message text-red-400 text-xs">Too Long</span>
                      ) : errors.amount && errors.amount.type === "maxLength" && (
                        <span className="error-message text-red-400 text-xs">Too Long</span>
                      )
                    }
                    {radioValue === "percentage" ?
                      errors.percentage && errors.percentage.type === "pattern" && (
                        <span className="error-message text-red-400 text-xs">Not Valid</span>
                      ) : errors.amount && errors.amount.type === "pattern" && (
                        <span className="error-message text-red-400 text-xs">Not Valid</span>
                      )
                    }
                  </div>
                </div>
              </div>

              {/* date picker  */}
              <div className={`py-[4%] flex flex-col w-full datepickerfield`} >
                <Controller name={"offerTiming"}
                  control={control}
                  defaultValue={null}
                  render={({ field: { onChange, value }, formState: { error } }) => {
                    return (
                      (
                        <DateRangePickerComponent
                        value={props.dateState}
                        onChange={(item) => {
                          if (!item.selection) return;
                          props.setDateState([item.selection]);
                        }}
                      />

                      )
                    )
                  }}
                  rules={{ required: false }}
                />
                {errors.offerTiming && errors.offerTiming.type === "required" && (
                  <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                )}

                {/* react date picker  */}
                {
                // dateOpen ? (

                //   <Controller name={"dateRanger"}
                //     control={control}
                //     render={({ field: { onChange, value }, formState: { error } }) => {
                //       return (
                //         (
                //           <>
                //             {/* <ClickAwayListener onClickAway={() => setDateOpen(false)}> */}
                //             <div>
                //               {/* <ReactDateRange
                //                 onChange={(item) => props.setDateState([item.selection])}
                //                 showSelectionPreview={true}
                //                 moveRangeOnFirstSelection={false}
                //                 ranges={props.dateState}
                //                 direction="horizontal"
                //                 value={value}
                //                 editableDateInputs={true}
                //                 customStyles={{ position: 'absolute', top: '3rem', right: 0, 'z-index': '10', }}
                //                 wrapperRef={props.wrapperRef}
                //               /> */}
                //               <DateRangePickerComponent
                //                 value={props.dateState}
                //                 onChange={(item) => {
                //                   if (!item.selection) return;
                //                   props.setDateState([item.selection]);
                //                 }}
                //               />
                //             </div>
                //             {/* </ClickAwayListener> */}
                //           </>
                //         )
                //       )
                //     }}
                //     rules={{
                //       required: true, pattern: /^[a-zA-Z][a-zA-Z ]*/i
                //     }}
                //   />
                // ) : null
                }
                {/* dateClicker  */}
                {
                  // dateClicker ?
                  //   <div className={`${dateClicker ? `block` : `hidden`} absolute top-8 z-20 right-0 bg-[#3D91FF] p-1 rounded-full text-white cursor-pointer`} onClick={handleOpenDate}>
                  //     {/* <p>OK</p> */}
                  //     <div>
                  //       <CloseIcon />
                  //       {/* <CheckIcon /> */}
                  //     </div>
                  //   </div> : null
                }
              </div>
              <div className={`flex w-[96%] xl:w-[80%] pt-[5%]`}>
                <ButtonField
                  buttonName={`cancel`}
                  type='reset'
                  buttonextracls={` !px-5 !py-2 !text-skyblue-5 bg-gray-0.5 mr-7 font-[Roboto-Medium]`}
                  onClick={() => { props.gobackToProductOffer(); cleanupMedia() }}
                />
                <ButtonField
                  buttonName={`submitOffer`}
                  type='submit'
                  buttonextracls={`${loading === true ? `bg-gray-400` : ` bg-skyblue-6`} !px-6 !py-2 !text-white font-[Roboto-Medium]`}
                  onClick={handleSubmit(onSubmit)}
                  loading={props.loading}
                  disabled={props.loading === true ? true : false}
                />
              </div>
              {props.productSubmitError?.error === true && <p className={`text-red-5 text-sm text-center font-[Roboto-Regular]`}>{props.productSubmitError?.message}</p>}
            </div>

            {/* right part  */}
            <div className='lg:w-[45%] w-full'>
              <UploadMultipleImage
                sendData={(data) => {
                  props.multipleMediaApiCall(data);
                }}
                disabled={multipleUploadState && multipleUploadState?.length === 4 ? true : false}
                // disabled={thumbnailMulti && thumbnailMulti?.length === 4 ? true : false}
                text1="selectFile"
                text2="dragAndDropItHereText"
                text3={loading === true ? 'uploading' : "uploadImg"}
                multiple={true}
                fileLimit={4}
                accept={{
                  "image/jpeg": [".jpeg", ".jpg"],
                  "image/png": [".png"]
                }}
              />
              <div className={`flex gap-6 pt-4 w-full relative`}>
                {multipleUploadState && (multipleUploadState?.length > 0 && multipleUploadState?.length <= 4) ?
                  multipleUploadState?.map((multipleImg, idx) =>
                    <div key={idx} className={`flex w-fit justify-between`}>
                      <img
                        // src={window.URL.createObjectURL(multipleImg)}
                        crossOrigin="anonymous"
                        src={multipleImg?.key && `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${multipleImg?.key}&height=75&width=100`}
                        alt='thumbnail'
                      />
                      <div className={`cursor-pointer `}>
                        <CloseIcon
                          onClick={() => removeParticularThumbnail(idx)}
                          sx={{ fontSize: "12px", position: 'absolute', top: '0px', border: '1px solid red', borderRadius: '9999px' }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className={`flex w-full gap-2`}>
                      <img src={fileUploadIcon} alt='file upload icon' className='w-[23%]' />
                      <img src={fileUploadIcon} alt='file upload icon' className='w-[23%]' />
                      <img src={fileUploadIcon} alt='file upload icon' className='w-[23%]' />
                      <img src={fileUploadIcon} alt='file upload icon' className='w-[23%]' />
                    </div>
                  )
                }
              </div>
            </div>

          </div>
        </form>

      </div>
    </div>

  )
}

export default OfferForm