import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import CommonCard from '../../Common/Layout/Card/CommonCard'
import img1 from '../../Images/Vendor/offerimg1.png'
import img2 from '../../Images/Vendor/offerimg2.png'
import img3 from '../../Images/Vendor/offerimg3.png'
import contactvendorimg from '../../Images/Vendor/contactvendorimage.svg'
import trackicon from '../../Images/Vendor/trackicon.svg'
import text from "../../Common/Languages/en_US.json"
import MainHeader from '../../Common/Layout/MainHheader/MainHeader'
import LandingHeader from '../../Common/Layout/LandingHeader/LandingHeader'
import Footer from '../../Common/Layout/Footer/Footer'
import { ForVendor } from '../../Containers/VendorDetailPage/Hooks'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ForNotification } from '../../Containers/Notification/Hooks'
import { CircularProgress } from '@mui/material'

const SeeAllServiceOffer = (props) => {
    const { t, i18n } = useTranslation()
    const totalServiceOffer = useSelector((state) => state.vendor.serviceOffers)
    const { handleshow, showNotification, allnotification, detailsPage, getNotification, readNotificationApi } = ForNotification()
    const {
        hasMore,
        increaseServicePageNumber,
        backtoPage,
        getCreatedServiceOffers,
        loading
    } = ForVendor()

    useEffect(() => {
        getCreatedServiceOffers()
    }, [])

    useEffect(() => {
        getNotification()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const itemContents = [
        {
            image: img1
        },
        {
            image: img2
        },
        {
            image: img3
        },
        {
            image: img3
        }
    ]
    return (
        <div>
            <div className='sticky top-0 z-50'>
                <MainHeader />
                <LandingHeader
                    searchBar={true}
                    goTomessage={true}
                    goToNotification={true}
                    border={true}
                    profile={true}
                    detailsPage={detailsPage}
                    allnotification={allnotification}
                    showNotification={showNotification}
                    handleclickNotification={handleshow}
                    readNotificationApi={readNotificationApi}
                />
            </div>

            <div className={`p-[4%] bg-white-5`}>
                <p onClick={backtoPage} className={`bg-primeryColour-5 font-[Roboto-Regular] rounded text-white w-20 py-1 text-center cursor-pointer`}>
                    {"<-"} {t("back")}
                </p>
                <div className={`pb-[3%]`}>
                    <p className={`text-center text-3xl font-[Roboto-Regular] font-normal text-deepgray-5 pb-2 lg:pb-0 cursor-pointer`}>{t("allNewServiceOffers")}</p>
                </div>

                {loading === true ?
                    <div className='flex h-[65vh] justify-center items-center'>
                        <CircularProgress size={20} color='success' />
                        <p className='pl-3'>Loading...</p>
                    </div>
                    :
                    <InfiniteScroll
                        dataLength={totalServiceOffer?.length} //This is important field to render the next data
                        next={increaseServicePageNumber}
                        hasMore={hasMore}
                        loader={<div className={`flex justify-center items-center`}>
                            {/* <CircularProgress /> */}
                            <p>Loading...</p>
                        </div>}
                    // endMessage={
                    //     <p style={{ textAlign: 'center' }}>
                    //         <p className='text-red-5'>No data left</p>
                    //     </p>
                    // }
                    >
                        <div className={`grid gap-2 md:gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 grid-rows-1`}>
                            {totalServiceOffer?.map((item, idx) =>
                                <div key={idx}>
                                    <CommonCard
                                        carouselimg={item?.services?.thumbnail && item?.services?.thumbnail?.length > 0 ? item?.services?.thumbnail : itemContents}
                                        carousel={item?.services?.thumbnail && item?.services?.thumbnail?.length > 0 ? true : false}
                                        noContentImg={item?.services?.thumbnail && item?.services?.thumbnail?.length === 0 && true}
                                        onHandelOpeModal={() => props.onHandelOpeModal(item?.user?.id)}
                                        itemname={item?.services?.business?.name}
                                        Shareicon={true}
                                        carouselHeight="200px"
                                        noContentCls={`h-[200px]`}
                                        rating={true}
                                        percentage={item?.percentage && true}
                                        offerpercentage={item?.percentage}
                                        price={item?.price && true}
                                        offerprice={item?.price}
                                        phone={true}
                                        phonetext={item?.user?.phoneNo ? item?.user?.phoneNo : '--'}
                                        location={true}
                                        locationtext={(item?.services?.business?.lat !== null && item?.services?.business?.long !== null) && item.services?.business?.location ? item.services?.business?.location : "--"}
                                        // distance={true}
                                        // distancetext={item.distance ? item.distance : "--"}
                                        hoverbutton={item?.user?.id !== parseInt(localStorage.getItem('userId')) ? true : false}
                                        // hoverbuttonTrack={(item?.services?.business?.lat === null && item?.services?.business?.long === null) ? false : true}
                                        hoverbuttonTrack={false}
                                        buttonName2={"Contact Vendor"}
                                        buttonimg={contactvendorimg}
                                        buttonName3={"Track"}
                                        buttonimg2={trackicon}
                                        showlocationamount={true}
                                        amount={item?.services?.amount}
                                        itemtext={true}
                                        text1={item?.services?.name}
                                        text2={item?.services?.description}
                                        itememail={true}
                                        email={item?.user?.email}
                                        item={item}
                                        extracls={`min-h-[30rem]`}
                                        height={'h-[535px]'}
                                    />
                                </div>
                            )}
                        </div>
                    </InfiniteScroll>
                }
            </div>
            <Footer />

        </div>
    )
}

export default SeeAllServiceOffer