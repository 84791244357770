import React from 'react'
import pdficon from '../../Images/CommonImages/pdficon.svg'
import uploadicon from '../../Images/CommonImages/uploadicon.svg'
import ButtonField from '../FormFields/ButtonField'
import { Tooltip } from '@mui/material'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
const UploadDocument = (props) => {
  return (
    <div className={`flex w-full justify-between bg-skyblue-0.5 py-5 rounded ${props.extracls}`}>
      <div className={`flex pl-2 w-[100%]`}>
        <div className='w-[10%]'>
          <img src={pdficon} alt="pdficon" height={25} width={25} />
        </div>

        <div className={`flex flex-col pl-2 w-[70%]`}>
          <Tooltip title={props.pdfname}>
            <p className={`text-deepgray-7 font-[Roboto-Regular] text-xs truncate`}>{props.pdfname}</p>
          </Tooltip>
          {props?.view ? (
            <p className={`text-skyblue-5 font-[Roboto-Medium] text-[13px] cursor-pointer`}>{props.viewid}</p>
          ) : (
            <p className={` text-deepgray-1 font-[Roboto-Regular] text-xs`}>{props.kb}</p>
          )}
        </div>

        <div className='w-[25%] lg:ml-6'>
        {props?.uploadbutton ? (
          <FileDownloadIcon className='text-skyblue-5 cursor-pointer' disabled={props?.disabled} onClick={props?.onDownloadClick && props?.onDownloadClick} />
        ) : (
          <p className={` text-deepgray-1 font-[Roboto-Regular] text-xs pr-5`}>{props.kb}</p>
        )
        }
      </div>
      </div>
    </div>
  )
}

export default UploadDocument