import React, { useState } from 'react'
import greentick from '../../../Images/accountcreate/greentick.svg'
import { Delete, Edit } from '@mui/icons-material'
import DeclinePopup from '../../../Common/FormFields/DeclinePopup'

const SubscriptionPlanCards = (props) => {

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [planData, setPlanData] = useState(null)
  const handleclickForDeletePlanModal = (data) => {
    setOpenDeleteModal(!openDeleteModal)
    setPlanData(data)
    setIsHover(false)
  }

  const deleteSubscriptionPlan = () => {
    props.deleteClickApiCall(planData?.id)
    setOpenDeleteModal(!openDeleteModal)
  }

  const [isHover, setIsHover] = useState(false)

  return (
    <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} className={`relative bg-gradient-to-br from-primeryColour-5 to-seconderyColour-5 text-white border min-w-[22rem] max-w-[22rem] min-h-[25rem] overflow-y-scroll px-10 py-6 rounded-lg ${props.mainCls} shadow shadow-gray-500/20 group/item duration-500 cursor-pointer overflow-hidden`}>
      <div className={`absolute w-10 h-10 rounded-xl bg-[#fff]/[0.2] flex justify-center items-center top-3 right-3 duration-500 ${isHover ? 'translate-x-0' : 'translate-x-[100px]'}`}>
        {/* <Edit fontSize='small' onClick={props.editClickHandler} /> */}
        <Delete fontSize='medium' onClick={() => handleclickForDeletePlanModal(props.data)} />
      </div>

      <div className={`flex flex-col justify-center items-center`}>
        <div className={`py-1 w-[30%] bg-[#fff]/[0.2] mb-2 rounded-full`}></div>
        <p className={`text-center text-2xl font-[Poppins-Bold] mb-3`}>{`${props?.data?.price}`}&nbsp;{`${props?.data?.currency}`}</p>
        <p className={`text-center text-xl font-[Roboto-Bold] bg-[#fff]/[0.2] rounded-full px-8 py-2.5 w-fit mb-5`}>{props?.data?.name}</p>
      </div>

      <div className={`flex flex-col gap-2 text-xs`}>

        {props?.data?.planType !== "addOns" && props?.data?.photoLimit ? <div className={`flex gap-2 items-start`}>
          <img src={greentick} alt="greentick" />
          <p className={``}>{`Can upload upto ${props?.data?.photoLimit} photo(s)`}</p>
        </div> : null}
        {props?.data?.planType !== "addOns" && props?.data?.videoLimit ? <div className={`flex gap-2 items-start`}>
          <img src={greentick} alt="greentick" />
          <p className={``}>{`Can upload upto ${props?.data?.videoLimit} video(s)`}</p>
        </div> : null}

        {props?.data?.monthlyPostRequirements ? <div className={`flex gap-2 items-start`}>
          <img src={greentick} alt="greentick" />
          <p className={``}>{`Can post upto ${props?.data?.monthlyPostRequirements?.count} requirement(s) with ${props?.data?.monthlyPostRequirements?.photoLimit} photo(s) per post`}</p>
        </div> : null}
        {props?.data?.monthlyPostOffers ? <div className={`flex gap-2 items-start`}>
          <img src={greentick} alt="greentick" />
          <p className={``}>{`Can post upto ${props?.data?.monthlyPostOffers?.count} product offers with ${props?.data?.monthlyPostOffers?.photoLimit} photo(s) per post`}</p>
        </div> : null}
        {props?.data?.serviceOffered ? <div className={`flex gap-2 items-start`}>
          <img src={greentick} alt="greentick" />
          <p className={``}>{`Can post upto ${props?.data?.serviceOffered?.count} service(s) with ${props?.data?.serviceOffered?.photoLimit} photo(s) per post`}</p>
        </div> : null}
        {props?.data?.planType !== "addOns" && props?.data?.durationInDays ? <div className={`flex gap-2 items-start`}>
          <img src={greentick} alt="greentick" />
          <p className={``}>{`Duration ${props?.data?.durationInDays}`}&nbsp;{`days`}</p>
        </div> : null}
        {props?.data?.planType !== "addOns" && (
          <div className="flex gap-2 items-start">
            {props?.data?.freeDuration ? (
              <>
                <img src={greentick} alt="greentick" />
                <p>{`Free duration ${props?.data?.freeDuration} days`}</p>
              </>
            ) : (
              <div className="flex gap-2 items-start">
                <img src={greentick} alt="greentick" />
                <p>No free duration days</p>
              </div>
            )}
          </div>
        )}

      </div>

      {openDeleteModal &&
        <DeclinePopup
          dialogTitlecls={`!text-red-500`}
          submitBtnCls={`!bg-red-600`}
          open={openDeleteModal}
          title={`Delete`}
          handleClose={handleclickForDeletePlanModal}
          DilogContenttext={"Price"}
          DialogContent={
            <p>{`Delete the ${planData?.name} Plan of price ${planData?.price} ${planData?.currency}?`}</p>
          }
          DeleteBtn={"Delete"}
          onClick={deleteSubscriptionPlan}
        />
      }
    </div>
  )
}

export default SubscriptionPlanCards