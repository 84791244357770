import React, { useState } from 'react'
import text from '../../../Common/Languages/en_US.json'
import DigidialsLogoBlue from '../../../Images/CommonImages/DigidialsLogoBlue.svg'
import { ClickAwayListener } from '@mui/material'
import TextFieldInput from '../../../Common/FormFields/TextFieldInput'
import ButtonField from '../../../Common/FormFields/ButtonField'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import InputField from '../../../Common/FormFields/InputField'
import { ForLogin } from '../../../Containers/Authentication/Login/Hooks'
import OTPFieldInput from '../../../Common/FormFields/OTPFieldInput'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import passwordResetSuccessIcon from '../../../Images/accountcreate/passwordResetSuccessIcon.svg'
import greentick from "../../../Images/accountcreate/greentick.svg"
import SnackBarComponent from '../../../Common/Layout/Snackbar/SnackBar'
import { useTranslation } from "react-i18next";

const ForgotPassword = (props) => {
    const [mailFocused, setMailFocused] = useState(false)
    const [passwordFocused, setPasswordFocused] = useState(false)
    const [showPasswoard, setShowPasswoard] = useState(true)
    const [showConfirmPasswoard, setShowConfirmPasswoard] = useState(true)
    const [loginRoute, setLoginRoute] = useState(false)
    const navigate = useNavigate()
    const [OTPForEmail, setOTPForEmail] = useState();
    const { t } = useTranslation()

    const {
        forgetPasswordFormik, showOtp, autoFillOtp, resetPasswordFormik, showResetPasswordSuccess, loader, forgotPasswordMsg, mail,
        setAutoFillOtp, forgetPasswordApiCall, showVerifyEmailDetails, notification, messageClose
    } = ForLogin()
    const handleLoginRoute = () => {
        setLoginRoute(true);
        navigate('/login')
    }

    return (
        <>
        <div className={`bg-white flex items-center justify-center`}>
            <div className={`w-[70%] md:w-[50%] lg:w-[30%] my-auto  py-[8%] lg:py-[7%]`}>
                {/* login Welcome msg  */}
                <div className={`flex items-center flex-col justify-between gap-10`}>

                    <img src={DigidialsLogoBlue} alt='blue logo' className={`cursor-pointer h-14 w-28`} onClick={() => navigate('/')} />
                    {!showOtp && !showResetPasswordSuccess && <div className='text-center flex flex-col gap-2 mb-10'>
                        <p className='text-primeryColour-4 font-[Roboto-Medium] text-[22px]'>{text.forgotPassword}</p>
                        <p className='text-deepgrey-2 font-[Roboto-Regular] text-[15px]'>{text.forgotPasswordText}</p>
                    </div>}
                    {showOtp && !showResetPasswordSuccess &&
                        <div className='text-center flex flex-col gap-6'>
                            <p className='text-deepgrey-2 font-[Roboto-Regular] text-[15px]'>{text.recieveEmail}</p>
                            <div className='text-center flex flex-col gap-1'>
                                <p className='text-primeryColour-4 font-[Roboto-Medium] text-[22px]'>{text.codeVerification}</p>
                                <div className={``}>
                                    <p className='text-deepgrey-2 font-[Roboto-Regular] text-[15px]'>{text.enterOtp}</p>
                                    {showVerifyEmailDetails?.tick === true ?
                                        <div className={`flex justify-center items-center gap-2`}>
                                            <p className='text-[15px] text-primeryColour-4 font-[Roboto-Regular]'>{mail}</p>
                                            <img src={greentick} alt="greentick" height={15} width={15} className={``} />
                                        </div>
                                        :
                                        <p className='text-[15px] text-primeryColour-4 font-[Roboto-Regular ]'>{mail}&nbsp;{text.notVerified}</p>
                                    }
                                </div>
                            </div>
                        </div>}
                    </div>
                    {/* input fields */}
                <div className={`w-full flex flex-col`}>
                    {!showOtp && !showResetPasswordSuccess && <form onSubmit={forgetPasswordFormik?.handleSubmit} >
                        <div className='flex flex-col gap-4 textFieldWidth'>
                            <TextFieldInput
                                onlyValue
                                extracls={`w-[100%] h-11 text-sm !p-0 signUpEmailExtracls`}
                                placeholder="*Enter your email"
                                textinputname="email"
                                variant="outlined"
                                type="email"
                                id="email"
                                handelChange={forgetPasswordFormik?.handleChange}
                                onBlur={forgetPasswordFormik?.handleBlur}
                                value={forgetPasswordFormik?.values.email}
                                error={
                                    forgetPasswordFormik?.touched.email &&
                                    Boolean(forgetPasswordFormik?.errors.email)
                                }
                                errorText={
                                    forgetPasswordFormik?.touched.email &&
                                    forgetPasswordFormik?.errors.email
                                }
                                onKeyPress={forgetPasswordFormik?.handleSubmit}
                                floatingLabel={'*Enter your email'}
                            />

                            <ButtonField
                                buttonName={text.submit}
                                buttonextracls={`!px-2 !py-2 !text-white bg-seconderyColour-5 text-[15px] font-[Roboto-Medium] w-full hover:bg-skyblue-6 mt-1`}
                                type={'submit'}
                                loading={loader}
                                // onClick={}
                                disabled={loader === true && true}
                            />
                            {forgotPasswordMsg?.status === 404 ? <p className='text-red-600 text-sm'>{t("UserNotFound")}</p> : null}
                        </div>

                    </form>}
                    {showOtp && !showResetPasswordSuccess &&
                        <div className='flex flex-col gap-6 mt-6'>
                            <div className=''>
                                <OTPFieldInput
                                    title={t("*EnterOtpSentToYourEmailId")}
                                    value={autoFillOtp}
                                    onChange={setAutoFillOtp}
                                    placeholder={[0, 0, 0, 0, 0, 0]}
                                    OTPLength={6}
                                    otpType="number"
                                    onResendClick={() => forgetPasswordApiCall(forgetPasswordFormik?.values)}
                                    showVerifyEmailDetails={showVerifyEmailDetails}
                                />
                                {showVerifyEmailDetails?.error === true && <p className={`text-red-600 text-sm`}>{showVerifyEmailDetails?.message}</p>}
                            </div>
                            <form onSubmit={resetPasswordFormik?.handleSubmit}>
                                {/* password  */}
                                <div className='flex flex-col gap-4 textFieldWidth mb-6'>
                                    <TextFieldInput
                                        onlyValue
                                        extracls={`w-[100%] h-11 text-sm !p-0 signUpEmailExtracls`}
                                        placeholder="*Enter Password"
                                        textinputname="password"
                                        variant="outlined"
                                        type={"text"}
                                        id="password"
                                        typePassword={showPasswoard}
                                        endAdornment={
                                            <div className={`cursor-pointer`} onClick={() => setShowPasswoard(!showPasswoard)}>{showPasswoard ? <VisibilityOffIcon sx={{ color: '#747774' }} /> : <RemoveRedEyeIcon sx={{ color: '#747774' }} />}
                                            </div>}
                                        handelChange={resetPasswordFormik?.handleChange}
                                        onBlur={resetPasswordFormik?.handleBlur}
                                        value={resetPasswordFormik?.values.password}
                                        error={
                                            resetPasswordFormik?.touched.password &&
                                            Boolean(resetPasswordFormik?.errors.password)
                                        }
                                        errorText={
                                            resetPasswordFormik?.touched.password &&
                                            resetPasswordFormik?.errors.password
                                        }
                                        onKeyPress={resetPasswordFormik?.handleSubmit}
                                        floatingLabel={'*Enter Password'}
                                    />

                                    {/* confirm password  */}
                                    <TextFieldInput
                                        onlyValue
                                        extracls={`w-[100%] h-11 text-sm !p-0 signUpEmailExtracls`}
                                        placeholder="*Re-type password"
                                        textinputname="rePassword"
                                        variant="outlined"
                                        type={"text"}
                                        id="rePassword"
                                        handelChange={resetPasswordFormik?.handleChange}
                                        onBlur={resetPasswordFormik?.handleBlur}
                                        value={resetPasswordFormik?.values.rePassword}
                                        onKeyPress={resetPasswordFormik?.handleSubmit}
                                        typePassword={showConfirmPasswoard}
                                        endAdornment={
                                            <div className={`cursor-pointer`} onClick={() => setShowConfirmPasswoard(!showConfirmPasswoard)}>{showConfirmPasswoard ? <VisibilityOffIcon sx={{ color: '#747774' }} /> : <RemoveRedEyeIcon sx={{ color: '#747774' }} />}
                                            </div>}
                                        error={
                                            resetPasswordFormik?.touched.rePassword &&
                                            Boolean(resetPasswordFormik?.errors.rePassword)
                                        }
                                        errorText={
                                            resetPasswordFormik?.touched.rePassword &&
                                            resetPasswordFormik?.errors.rePassword
                                        }
                                        floatingLabel={'*Re-type password'}

                                    />

                                </div>

                                <ButtonField
                                    buttonName={text.submit}
                                    buttonextracls={`!px-2 !py-2 !text-white  bg-seconderyColour-5 text-[15px] font-[Roboto-Medium] w-full hover:bg-skyblue-6`}
                                    type={'submit'}
                                    loading={loader}
                                    disabled={loader === true && true}
                                />
                            </form>

                        </div>
                    }
                    {/* password success message */}
                    {showResetPasswordSuccess && !loginRoute &&
                        <div className='flex flex-col gap-8 mt-12'>
                            <img src={passwordResetSuccessIcon} alt='passwordResetSuccessIcon' style={{ margin: "0 auto" }} />
                            <p className='text-deepgrey-2 font-[Roboto-Regular] text-[15px] text-center'>{text.passwordReset}</p>
                            <p className='text-deepgrey-2 font-[Roboto-Regular] text-[15px] text-center'>{text.passwordResetSuccess}</p>
                            <ButtonField
                                buttonName={text.Login}
                                buttonextracls={`!px-2 !py-2 !text-white  bg-seconderyColour-5 text-[15px] font-[Roboto-Medium] w-full hover:bg-skyblue-6`}
                                type={'submit'}
                                onClick={handleLoginRoute}
                            />
                        </div>}
                </div>
                </div>
            </div>
            <SnackBarComponent
                messageOpen={notification.open}
                messageClose={messageClose}
                notificationText={notification.message}
                subText={notification.subText}
                alertType={notification.alertType}
                borderClass={notification.borderClass}
            />
        </>
    )
}

export default ForgotPassword