import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { doGetApiCall, doPostApiCall } from '../../../Utils/ApiConfig';
import { SUB_ADMIN_API } from '../../../Constant';
import getUserId from '../../../Utils/gnUniqueId';
import { getuserDetails } from '../reducer/UserReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ForSignup } from '../../Authentication/Signup/Hook';
import { ForUploadMediaHooks } from '../../UploadMedia/Hooks';
import { setUploadedLogo } from '../../UploadMedia/reducer/UploadMediaReducer';

export const UserAuthHooks = () => {

    const [uniqueid, setUniqueid] = useState("")
    const [loading, setLoading] = useState(false)
    const [typecheck, setTypecheck] = useState("user")
    const [showOTP, setShowOTP] = useState()
    const [showOTPMobile, setShowOTPMobile] = useState()
    const [autoFillOtp, setAutoFillOtp] = useState()
    const [emailVerified, setEmailVerified] = useState(false)
    const [phoneNoVerified, setPhoneNoVerified] = useState(false)
    const [otpForEmail, setOtpForEmail] = useState()
    const [oTPForMobile, setOTPForMobile] = useState();
    const [phoneNo, setPhoneNo] = useState('us');
    const [phoneNoError, setPhoneNoError] = useState(false);
    const { logoUpload } = ForUploadMediaHooks()
    let userId = localStorage.getItem('userId')

    const [showEmailOtpButtonUser, setShowEmailOtpButtonUser] = useState(false)
    const [showPhoneOtpButtonUser, setShowPhoneOtpButtonUser] = useState(false)

    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        if (typecheck) {
            setUniqueid(getUserId(typecheck))
        }
    }, [typecheck])

    // For showing errors while sending email for getting otp, used in the func *EmailOTP*
    const [emailErrTexts, setEmailErrTexts] = useState({
        code: "",
        error: false,
        message: "",
    })

    // This below three functionalities are only for snackbar during user signup 
    const [notification, setNotification] = useState({
        open: false,
        message: "",
        subText: "",
        alertType: "",
        borderClass: "",
    });
    const messageClose = () => {
        setNotification({
            open: false,
            message: "",
            subText: "",
            alertType: "",
            borderClass: "",
        });
    };
    const openMessageLogin = (alertType, message, subText, borderClass) => {
        if (alertType) {
            setNotification({
                open: true,
                message: message,
                subText: subText,
                alertType: alertType,
                borderClass: borderClass,
            });
            setTimeout(messageClose, 5000);
        }
    };

    const locationSelector = useSelector((state) => state.vendorAccount.location)

    const createAccountUserForm = useFormik({
        initialValues: {
            email: '',
            // phoneNo: '',
            password: '',
            cpassword: '',
            name: '',
            // location: ''
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email('Not a valid email! please enter a valid email!')
                .required('Please enter your email'),
            // phoneNo: Yup.string()
            //     .required('Please enter Phone Number'),
            password: Yup.string()
                .required('Please enter your password'),
            cpassword: Yup.string()
                .required('Please confirm your password')
                .oneOf(
                    [Yup.ref("password"), null],
                    "Password and Confirm Password must match"
                ),
            name: Yup.string()
                .required('Please enter your name'),
            // location: Yup.string().required('Please Enter your location')

        }),
        onSubmit: (values) => {
            const phoneRegex = /^\+?\d{10,14}$/;
            let data = {
                ...values,
                "uniqueid": uniqueid,
                "phoneNo" : phoneNo
            }
            if(data.phoneNo.length > 4 && phoneRegex.test(data.phoneNo)){
                CreateAccountUser(data)
            } else {
                setPhoneNoError(true);
            }
        }
    })

    const handleClickForOTP = () => {
        // const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
        const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (regexEmail.test(createAccountUserForm?.values?.email)) {
            EmailOTP(createAccountUserForm?.values)
            // setShowOTP(true)
        } else {
            // setShowOTP(false)
        }
    }

    
    // for sent otp of email
    const EmailOTP = async (item) => {
        let data = {
            url: `${SUB_ADMIN_API}/users/sent/otp`,
            bodyData: {
                email: item.email,
            }
        }
        let res = await doPostApiCall(data)
        if (!res?.error) {
            setShowOTP(true)
            setEmailErrTexts({
                code: "",
                error: false,
                message: "",
            })
        } else {
            setShowOTP(false)
            setEmailErrTexts({
                code: res?.code,
                error: res?.error,
                message: res.message,
            })
        }
        // setAutoFillOtp(res.result.otp)
        // EmailOTPVerify(res.result.otp, item)
    }

    // for email verify otp --> New code for manual verification
    useEffect(() => {
        if (otpForEmail && otpForEmail?.length === 6) {
            EmailOTPVerify(otpForEmail, createAccountUserForm?.values)
        }
    }, [otpForEmail])

    // For verifyotp for email
    const EmailOTPVerify = async (otp, formdata) => {
        let data = {
            url: `${SUB_ADMIN_API}/users/verify`,
            bodyData: {
                email: formdata.email,
                phoneNo: formdata.phoneNo,
                otp: otp,
            }
        }
        let res = await doPostApiCall(data)
        if (!res?.error) {
            setShowOTP(false)
            setShowEmailOtpButtonUser(false)
            // setEmailVerified(true)
            setShowOTP(false)
            setEmailErrTexts({
                code: "",
                error: false,
                message: "",
            })
            openMessageLogin("success", "Success", "OTP verified successfully", "success")
        } else {
            setEmailErrTexts({
                code: res?.code,
                error: res?.error,
                message: res.message,
            })
            openMessageLogin("error", "Error", "Invalid OTP", "error")
        }
        setEmailVerified(res.result.emailVerified)
        setPhoneNoVerified(res.result.phoneNoVerified)

    }


    const handleClickForOTPMobile = () => {
        setShowOTP(false);
        const regexPhone = /^\+?\d{10,14}$/;
        if (regexPhone.test(phoneNo)) {
            setShowOTPMobile(true)
            PhoneOTP(createAccountUserForm?.values, phoneNo)
        } else {
            setShowOTPMobile(false)
            setPhoneNoError(true);
        }
    }


    // For send otp for phone no 
    const PhoneOTP = async (item, phoneNo) => {
        let data = {
            url: `${SUB_ADMIN_API}/users/sent/otp`,
            bodyData: {
                email: item.email,
                phoneNo: phoneNo
            }
        }
        let res = await doPostApiCall(data)

    }

    //for phone verify otp
    useEffect(() => {
        if (oTPForMobile && oTPForMobile?.length === 6) {
            PhoneOTPVerify(oTPForMobile, createAccountUserForm?.values, phoneNo)
        }
    }, [oTPForMobile])

    // For verify otp for phone no 
    const PhoneOTPVerify = async (otp, formdata,phoneNo) => {
        let data = {
            url: `${SUB_ADMIN_API}/users/verify`,
            bodyData: {
                email: formdata.email,
                phoneNo: phoneNo,
                otp: otp,
            }
        }
        let res = await doPostApiCall(data)
        if (!res?.error) {
            setShowOTPMobile(false)
            setShowPhoneOtpButtonUser(false)
            // setEmailVerified(true)
            // setPhoneNoVerified(true)
            openMessageLogin("success", "Success", "OTP verified successfully", "success")
        } else {
            openMessageLogin("error", "Error", "Invalid OTP", "error")
        }
        setEmailVerified(res.result.emailVerified)
        setPhoneNoVerified(res.result.phoneNoVerified)
    }

    const CreateAccountUser = async (item) => {
        setLoading(true);
        let data = {
            url: `${SUB_ADMIN_API}/users`,
            bodyData: {
                email: item.email,
                password: item.password,
                name: item.name,
                type: 'user',
                uniqueId: item.uniqueid,
                // phoneNo: item.phoneNo
                // lat: parseFloat(sessionStorage.getItem('latitude')),
                // long: parseFloat(sessionStorage.getItem('longitude')),
                profilePicture: {
                    name: logoUpload?.originalname,
                    key: logoUpload?.key,
                    mimetype: logoUpload?.mimetype,
                    location: logoUpload?.location,
                    size: logoUpload?.size
                },
            }
        }
        // if (locationSelector?.length > 0) {
        //     data.bodyData.location = locationSelector
        //     data.bodyData.address = locationSelector
        // } else {
        //     data.bodyData.location = item?.location
        //     data.bodyData.address = item?.location
        // }
        let res = await doPostApiCall(data)
        if (!res.error) {
            dispatch(setUploadedLogo({}))
            navigate(`/login`)
        } else {
            console.log(res.error, '## error')
        }

    }

    const getUser = async () => {
        let data = {
            url: `${SUB_ADMIN_API}/users/${userId}`
        }
        if (userId !== '') {
            let res = await doGetApiCall(data)
            if (!res.error) {
                dispatch(getuserDetails(res?.result))
            }
        }
    }

    //phoneNochange handler function

    const phoneChangeHandler = (value) => {
        let valueWithSuffix = '+'.concat('', value)
        setPhoneNo(valueWithSuffix);
        if (value.length < 4) {
            setPhoneNoError(true);
        } else {
            setPhoneNoError(false)
        }
    }

    return {
        createAccountUserForm,
        EmailOTP,
        EmailOTPVerify,
        PhoneOTP,
        PhoneOTPVerify,
        loading,
        showOTP,
        showOTPMobile,
        handleClickForOTP,
        handleClickForOTPMobile,
        autoFillOtp,
        emailVerified,
        phoneNoVerified,
        emailErrTexts,
        setOTPForMobile,
        oTPForMobile,
        getUser,
        otpForEmail,
        setOtpForEmail,
        phoneChangeHandler,
        phoneNo,
        phoneNoError,
        showEmailOtpButtonUser,
        setShowEmailOtpButtonUser,
        showPhoneOtpButtonUser,
        setShowPhoneOtpButtonUser,
        notification
    }
}