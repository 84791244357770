import { CircularProgress, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ButtonField from '../../../Common/FormFields/ButtonField'
import BidCard from '../../../Common/Layout/Card/BidCard'
import CommonCard from '../../../Common/Layout/Card/CommonCard'
import DefaultProfileicon from '../../../Images/Vendor/DefaultProfileicon.svg'
import contactvendorimg from '../../../Images/Vendor/contactvendorimage.svg'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import DeclinePopup from '../../../Common/FormFields/DeclinePopup'
import TextFieldInput from '../../../Common/FormFields/TextFieldInput'
import { useNavigate } from 'react-router-dom'
import { ForLanding } from '../../../Containers/LandingPage/Hooks'


const BidAmountDetailPage = (props) => {
    const { getChatDetailsWithUserApiCAll } = ForLanding()
    const navigate = useNavigate()

    const token = localStorage.getItem('token')
    const role = localStorage.getItem('role')
    const userId = localStorage.getItem('userId')
    const businessId = parseInt(localStorage.getItem('businessId'))

    const { handleSubmit, reset, control } = useForm()

    const biddata = useSelector((state) => state.vendor.bidsByReqId)
    const sortedBidData = biddata?.slice()?.sort((a, b) => a?.price - b?.price)
    const biddataByUserId = sortedBidData?.filter((item) => (item?.createdBy === parseInt(userId)))
    const biddataOthers = sortedBidData?.filter((item) => (item?.createdBy !== parseInt(userId)))

    const [bidId, setBidId] = useState()
    const [reqId, setReqId] = useState()
    const [priceData, setPriceData] = useState()

    // These below useState and two functions are only for editing own bids 
    const [openEdit, setOpenEdit] = useState(false)
    const onEditBidSubmit = (data) => {
        props.editBidPriceApiCall(bidId, data)
        setOpenEdit(!openEdit)
        reset()
    }
    const handleclickForEditBid = (id) => {
        setOpenEdit(!openEdit)
        setBidId(id)
    }

    // These below useState and two functions are only for creating new bids 
    const [open, setOpen] = useState(false)
    const handleclickForBid = (id) => {
        setOpen(!open)
        setReqId(id)
    }
    const onCreateBidSubmit = (data) => {
        setPriceData(data)
        props.postBid(data, reqId, businessId)
        setOpen(!open)
        reset(priceData)
    }

    const onMessageClick = (item) => {
        if (token !== null) {
            getChatDetailsWithUserApiCAll(item?.user?.id).then((res) => {
                if (res.id) {
                    role === 'user' ? navigate('/user/message', { state: res }) : navigate('/vendor/message', { state: res })
                } else {
                    role === 'user' ? navigate('/user/message', { state: item }) : navigate('/vendor/message', { state: item })
                }
            }).catch((err) => { console.log(err) });
        } else {
            navigate('/login')
        }
    }

    useEffect(() => {
        props.getBidApiCAllByReqId(props.saveAllReqData?.id)
    }, [props.saveAllReqData])

    return (
        <div>
            <div onClick={props.goBackFromAllReqrLiveDetails} className={`pt-[2%] pb-[1%] font-[Roboto-Bold] text-base cursor-pointer w-fit`}>
                <p> {"<"} Back</p>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} md={7}>
                    <BidCard
                        DefaultProfileicon={DefaultProfileicon}
                        name={props.saveAllReqData?.user?.name}
                        email={props.saveAllReqData?.user?.email}
                        LiveBid={props.saveAllReqData?.status === 'live' && true}
                        question={props.saveAllReqData?.title}
                        content={props.saveAllReqData?.description}
                        flex={true}
                        showdate={true}
                        date={moment(props.saveAllReqData?.startDate).format('LL')}
                        // attachment={true}
                        validdate={props.saveAllReqData?.status === 'live' ? true : false}
                        time={moment(props.saveAllReqData?.endDate).format('LL')}
                        expiredtime={false}
                        uploadDocument={props.saveAllReqData?.document?.length > 0 && true}
                        downloadPdfAttachments={() => props.downloadPdfAttachments(props.saveAllReqData?.document[0])}
                        saveReqData={props.saveAllReqData}
                        doBidClick={() => handleclickForBid(props.saveAllReqData?.id)}
                        Bid={(props.saveAllReqData?.status === 'expire' || props.saveAllReqData?.user?.id === parseInt(userId)) ? false : true}
                        bidDisabled={biddata?.some((item) => item?.createdBy === parseInt(userId)) ? true : false}
                        item={props.saveAllReqData}
                        isLiveTab={props.isLiveTab}
                    />
                    <ButtonField
                        buttonextracls={`bg-skyblue-6 text-white p-3 mr-4 w-full`}
                        buttonName={`Contact Vendor`}
                        img={contactvendorimg}
                        buttonnamecls={`pl-2  text-[10px] font-[Roboto-Medium]`}
                        onClick={() => onMessageClick(props.saveAllReqData)}
                    />
                </Grid>
                <Grid item xs={12} md={5}>
                    {props.loading === true ?
                        <div className='flex h-full justify-center items-center'>
                            <CircularProgress size={20} color='success' />
                            <p className='pl-3'>Loading...</p>
                        </div>
                        :
                        <div className='overflow-y-scroll h-[78vh]'>

                            {/* Here only the logged in user can see its own bid on the particular requirement  */}
                            {biddataByUserId && biddataByUserId?.length > 0 && biddataByUserId?.map((bidData, idx) =>
                                <div key={idx} className={`w-full relative `}>
                                    <CommonCard
                                        itememail={true}
                                        profile={true}
                                        profileicon={props.singleVendorDetailsData?.logo?.key !== null && props.singleVendorDetailsData?.logo?.key}
                                        email={props.singleVendorDetailsData?.user?.email}
                                        ratingforname={true}
                                        ratingvalue="4"
                                        date={moment(bidData?.createdAt).format('LLL')}
                                        location={true}
                                        showlocationamount={true}
                                        amount={bidData?.price}
                                        phone={true}
                                        phonetext={bidData?.user?.phoneNo}
                                        itemname={bidData?.business?.name}
                                        locationtext={bidData?.business?.location}
                                        editPrice={props.isLiveTab === true ? false : true}
                                        editPriceOnClick={() => handleclickForEditBid(bidData?.id)}
                                    />
                                </div>
                            )}

                            {/* Here other people's bids will be shown partially  */}
                            {biddataOthers && biddataOthers?.length > 0 && biddataOthers?.map((bidData, idx) =>
                                <div key={idx} className={`w-full relative `}>
                                    <CommonCard
                                        itememail={true}
                                        profile={true}
                                        profileicon={bidData.profileicon}
                                        email={bidData.email}
                                        ratingforname={true}
                                        ratingvalue={bidData.ratingvalue}
                                        date={bidData.date}
                                        location={true}
                                        showlocationamount={true}
                                        amount={bidData?.price}
                                        phone={true}
                                        phonetext={bidData.phonetext}
                                        itemname={bidData.itemname}
                                        offerpercentage={bidData.offerpercentage}
                                        locationtext={bidData?.requirements?.location}
                                    />
                                    <div className={`absolute bg-white/[0.06] h-full w-full top-0 backdrop-blur-[7.5px]	`}></div>
                                </div>
                            )}
                        </div>
                    }
                </Grid>
            </Grid>

            {/* For opening edit bid modal */}
            {openEdit &&
                <form onSubmit={handleSubmit(onEditBidSubmit, reset)}>
                    <DeclinePopup
                        dialogTitlecls={`!text-black`}
                        submitBtnCls={`!bg-skyblue-6`}
                        open={openEdit}
                        title={`Bid Amount`}
                        handleClose={handleclickForEditBid}
                        DilogContenttext={"Price"}
                        DialogContent={
                            <Controller
                                name={"price"}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextFieldInput
                                        textinputname={"price"}
                                        typeNumber={true}
                                        onlyValue
                                        textnewclass={`w-full text-sm`}
                                        placeholder='Edit Price'
                                        value={(value)}
                                        onChange={(e) => {
                                            const newValue = e.target.value;
                                            if (newValue >= 0) {
                                                onChange(newValue);
                                            }
                                        }}
                                        onWheel={(e) => e.target.blur()}
                                    />
                                )}
                            />
                        }
                        DeleteBtn={"Bid"}
                        onClick={handleSubmit(onEditBidSubmit)}
                    />
                </form>}

            {/* For opening post bid modal */}
            {open &&
                <form onSubmit={handleSubmit(onCreateBidSubmit, reset)}>
                    <DeclinePopup
                        dialogTitlecls={`!text-black`}
                        submitBtnCls={`!bg-skyblue-6`}
                        open={open}
                        title={`Bid Amount`}
                        handleClose={handleclickForBid}
                        DilogContenttext={"Price"}
                        DialogContent={
                            <Controller
                                name={"price"}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextFieldInput
                                        textinputname={"price"}
                                        typeNumber={true}
                                        onlyValue
                                        textnewclass={`w-full text-sm`}
                                        placeholder='*Enter Price'
                                        value={(value)}
                                        onChange={(e) => {
                                            const newValue = e.target.value;
                                            if (newValue >= 0) {
                                                onChange(newValue);
                                            }
                                        }}
                                        onWheel={(e) => e.target.blur()}
                                    />
                                )}
                            />
                        }
                        DeleteBtn={"Bid"}
                        onClick={handleSubmit(onCreateBidSubmit)}
                    />
                </form>}
        </div>
    )
}

export default BidAmountDetailPage