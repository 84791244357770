import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Stack, TextField } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/en-gb"; 
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("en-gb");

const DateRangePickerComponent = ({ value, onChange, startLabel = "Start Date", endLabel = "End Date" }) => {
  const { startDate, endDate } = value?.[0];

  const handleStartDateChange = (newStart) => {
    const updatedStartDate = newStart ? dayjs(newStart).startOf("day").toDate() : null;
    onChange({
      selection: {
        startDate: updatedStartDate,
        endDate: endDate && updatedStartDate && updatedStartDate > endDate ? updatedStartDate : endDate,
        key: "selection",
      },
    });
  };

  const handleEndDateChange = (newEnd) => {
    const updatedEndDate = newEnd ? dayjs(newEnd).endOf("day").toDate() : null;
    onChange({
      selection: {
        startDate,
        endDate: updatedEndDate,
        key: "selection",
      },
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack direction="row" spacing={2}>
        <DatePicker
          label={startLabel}
          value={startDate ? dayjs(startDate) : null}
          format="DD/MM/YYYY"
          onChange={handleStartDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
        <DatePicker
          label={endLabel}
          value={endDate ? dayjs(endDate) : null}
          format="DD/MM/YYYY"
          minDate={startDate ? dayjs(startDate) : undefined}
          onChange={handleEndDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </Stack>
    </LocalizationProvider>
  );
};

export default DateRangePickerComponent;
