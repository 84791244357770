import { ClickAwayListener } from '@mui/base'
import React, { useState } from 'react'
import ButtonField from '../../../Common/FormFields/ButtonField'
import CheckBox from '../../../Common/FormFields/CheckBox'
import TextFieldInput from '../../../Common/FormFields/TextFieldInput'
import text from '../../../Common/Languages/en_US.json'
import DigidialsLogoBlue from '../../../Images/CommonImages/DigidialsLogoBlue.svg'
import './LoginPage.css'
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next'
import createUser from '../../../Images/CommonImages/userCreate.svg'
import createVendor from '../../../Images/CommonImages/createVendor.svg'

/**
 * @component LoginPage
 * @description LoginPage created
 * @param {*} props 
 */

const LoginPage = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation()

    const [mailFocused, setMailFocused] = useState(false)
    const [passwordFocused, setPasswordFocused] = useState(false)
    const [showPasswoard, setShowPasswoard] = useState(true)

    return (
        <div className={`bg-white flex items-center justify-center`}>
            <div className={`w-[70%] md:w-[50%] lg:w-[35%] 2xl:w-[30%] my-auto mt-[5%]`}>
                {/* login Welcome msg  */}
                <div className={`flex items-center flex-col justify-between gap-5`}>

                    <img src={DigidialsLogoBlue} alt='blue logo' onClick={() => navigate('/')} className={`cursor-pointer h-14 w-28`} />
                    <div className={`flex flex-col gap-1`}>
                        <p className='text-primeryColour-4 font-[Roboto-Medium] pl-2 text-[22px]'>{t("welcomeBack")}</p>
                        <p className='text-deepgrey-2 font-[Roboto-Regular] text-[15px]'>{t("enterDetails")}</p>
                    </div>
                </div>
                {/* input fields */}
                <div className={`w-full flex flex-col ${props.emailErrorMessage || props.passwordErrorMessage || props.message ? 'gap-4' : 'gap-6'} mt-6 textMain`}>
                    <ClickAwayListener onClickAway={() => setMailFocused(false)}>
                        <div onClick={() => setMailFocused(true)} className='textField1'>
                            <TextFieldInput
                                handelChange={(e) => props.setEmail(e)}
                                floatingLabel={mailFocused === true ? '*Email ID' : null}
                                focused={mailFocused}
                                onKeyPress={props.Login}
                                textnewclass={`w-full h-12`}
                                placeholder='enterEmailPass'
                            />
                            {props.emailErrorMessage && <p className={`error-message text-red-600 text-xs p-0 m-0`}>*{props.emailErrorMessage}</p>}
                        </div>
                    </ClickAwayListener>
                    <ClickAwayListener onClickAway={() => setPasswordFocused(false)}>
                        <div onClick={() => setPasswordFocused(true)} className='textField1'>
                            <TextFieldInput
                                typePassword={showPasswoard}
                                handelChange={(e) => props.setPassword(e)}
                                floatingLabel={passwordFocused === true ? '*Password' : null}
                                focused={passwordFocused}
                                onKeyPress={props.Login}
                                textnewclass={`w-full h-12`}
                                placeholder='enterPass'
                                endAdornment={<div className={`cursor-pointer ml-3`} onClick={() => setShowPasswoard(!showPasswoard)}>{showPasswoard ? <VisibilityOffIcon sx={{ color: '#747774' }} /> : <RemoveRedEyeIcon sx={{ color: '#747774' }} />}</div>}
                            />
                            {props.passwordErrorMessage && <p className={`error-message text-red-600 text-xs p-0 m-0`}>*{props.passwordErrorMessage}</p>}
                        </div>
                    </ClickAwayListener>
                    {/* remember me  */}
                    <div className={`flex justify-between items-center paddingLessCheckbox`}>
                        <div className='flex gap-2 items-center '>
                            <CheckBox
                                handleBoxChange={props.handleCheck}
                                checked={props.checked}
                            />
                            <p className='text-deepgray-5 text-sm font-[Roboto-Medium]'>{t("rememberMe")}</p>
                        </div>
                        <div>
                            <p className='text-skyblue-6 font-[Roboto-Medium] text-sm cursor-pointer' onClick={() => navigate('/forgotPassword')}>{t("forgotPassword")}</p>
                        </div>
                    </div>
                    <ButtonField
                        onClick={props.Login}
                        buttonName={text.Login}
                        buttonextracls={`!px-2 !py-2 !text-white ${props.loading === true && 'bg-deepgray-4'} bg-seconderyColour-5 text-[15px] font-[Roboto-Medium] w-full hover:bg-skyblue-6`}
                        loading={props.loading}
                        disabled={props.loading === true ? true : false}
                    />
                    {/* For showing the error message if login isnt't successful  */}
                    {props?.loginError?.error === true && <p className={`text-red-5 text-sm text-center font-[Roboto-Regular]`}>{props?.loginError?.message}</p>}
                    <p className={`w-full flex flex-col gap-4`}>
                        <p className={`w-full flex justify-center text-[#1F2533] text-[14px] font-semibold`}>
                            {t("notRegistered")}&nbsp;&nbsp;{t("createAcc")}
                        </p>
                        <div className={`w-full flex gap-3`}>
                            <div className={`w-1/2 flex justify-center items-center border rounded-full h-[3em] gap-3 cursor-pointer`} onClick={() => navigate("/usersignup")}>
                                <div className={`bg-[#0099CC] ml-1 rounded-full`}>
                                    <img src={createUser} alt={t("asuser")} height={18} width={18} />
                                </div>
                                <p className={`text-[14px] font-semibold hover:text-[#0099CC]`}>{t("asuser")}</p>
                            </div>
                            <p className={`text-[#3B4252] text-[14px] h-[3.5em] flex items-center`}>OR</p>
                            <div className={`w-1/2 flex justify-center items-center border rounded-full h-[3em] gap-3 cursor-pointer`} onClick={() => navigate("/signup")}>
                                <img src={createVendor} alt={t("asvendor")} height={18} width={18} className={`ml-1`} />
                                <p className={`text-[14px] font-semibold hover:text-[#0099CC]`}>{t("asvendor")}</p>
                            </div>
                        </div>
                    </p>
                </div>
            </div>

        </div >
    )
}

export default LoginPage