import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import ProfileUserDescriptionCard from '../../../Common/Layout/ProfileCard/ProfileUserDescriptionCard'
import ProfileUserImagesCard from '../../../Common/Layout/ProfileCard/ProfileUserImagesCard'
import ProfileUserInfoCard from '../../../Common/Layout/ProfileCard/ProfileUserInfoCard'
import TabComponent from '../../../Common/UiComponents/TabComponent'
import TotalUsersHeader from '../TotalUsers/TotalUsersHeader'
import AllReviews from './AllReviews'
import CustomerReview from './CustomerReview'
import text from '../../../Common/Languages/en_US.json'
import { useTranslation } from 'react-i18next'
import { ForAdmin } from '../../../Containers/Admin/Hooks'
import SingleVendorServiceOfferedTab from './SingleVendorServiceOfferedTab'
import SingleVendorOfferTab from './SingleVendorOfferTab'
import { useParams } from 'react-router-dom'
import { ForLanding } from '../../../Containers/LandingPage/Hooks'
import SingleVendorRequirementTab from './SingleVendorRequirementTab'
import { useLocation } from 'react-router-dom'

const VendorDetailPageAdmin = () => {
    const [value, setValue] = React.useState("0");


    const { getSingleVendorDetailsApi,
        checkEachVendorProductAdmin,
        getServiceByBusinessInAdmin,
        getVendorReqByVendorIdAdmin,
        valueOffer,
        loading,
        buttonLoading,
        downloadPdfAttachments
    } = ForAdmin()

    const { getPostedReviews } = ForLanding()
    const { t } = useTranslation()
    const userId = useParams()

    useEffect(() => {
        getSingleVendorDetailsApi(userId?.id)
        // checkEachVendorProductAdmin(userId?.id);
        // getVendorReqByVendorIdAdmin(userId?.id)
    }, [userId])

    // From reducer in admin, for business related data
    const singleVendorsInAdminPage = useSelector((state) => state.totalVendorsInAdmin.singleVendor)

    useEffect(() => {
        // getServiceByBusinessInAdmin(singleVendorsInAdminPage?.id)
        getPostedReviews(singleVendorsInAdminPage?.id)
    }, [singleVendorsInAdminPage])

    const reviewData = useSelector((state) => state.landingData.reviews)

    const categories = [
        {
            label: "Services",
            value: "0",
            backgroundcolor: "bg-[#F7F7F7]",
            content: <SingleVendorServiceOfferedTab
                // checkSingleVendorServicesOffered={checkSingleVendorServicesOffered}
                getServiceByBusinessInAdmin={getServiceByBusinessInAdmin}
                singleVendorsInAdminPage={singleVendorsInAdminPage}
                buttonLoading={buttonLoading}
                loading={loading}
            />
        },
        {
            label: "Product Offers",
            value: "1",
            backgroundcolor: "bg-white",
            content: <SingleVendorOfferTab
                // vendorProductOffersByProductId={vendorProductOffersByProductId}
                checkEachVendorProductAdmin={checkEachVendorProductAdmin}
                userId={userId}
                buttonLoading={buttonLoading}
                loading={loading}
            />
        },
        {
            label: "Requirements",
            value: "2",
            backgroundcolor: "bg-white",
            content: <SingleVendorRequirementTab
                getVendorReqByVendorIdAdmin={getVendorReqByVendorIdAdmin}
                userId={userId}
                buttonLoading={buttonLoading}
                loading={loading}
                downloadPdfAttachments={downloadPdfAttachments}
            />
        }
    ]

    const router = useLocation()
    useEffect(() => {
        if (router?.state?.length > 0) {
            switch (router?.state) {
                case "services":
                    setValue('0');
                    break;
                case "offers":
                    setValue('1');
                    break;
                case "requirements":
                    setValue('2');
                    break;
                default:
                    setValue('0');
            }
        }
    }, [router])

    return (
        <div className={`h-full overflow-y-scroll`}>
            <TotalUsersHeader userName={`Profile`} />
            <Grid container spacing={2} className={`p-4`}>
                <Grid item xs={12} md={3}>
                    <div className={`w-full flex flex-col justify-start gap-4 `}>
                        <ProfileUserInfoCard
                            dataObject={singleVendorsInAdminPage}
                        />
                        <div className={`flex justify-between flex-col gap-4`}>
                            <div className={`w-full`}>
                                <ProfileUserImagesCard
                                    imgArr={singleVendorsInAdminPage?.thumbnail}
                                    dataObject={singleVendorsInAdminPage}
                                    downloadPdfAttachments={downloadPdfAttachments}
                                />
                            </div>
                            <div className={`w-full`}>
                                <ProfileUserDescriptionCard
                                    description={text.des}
                                    profileUserCardDescText={text.profileUserCardDescText}
                                    dataObject={singleVendorsInAdminPage}
                                />
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={9}>
                    <TabComponent
                        mappingname={categories}
                        button={false}
                        value={value}
                        setValue={setValue}
                    />

                    {/* Customer Review  */}
                    <div className={`m-1`}>
                        <p className={`py-4 font-[Roboto-Regular]`}>{t("customerreview")}</p>
                        <CustomerReview BusinessDetails={singleVendorsInAdminPage} />
                    </div>

                    {/* Reviews  */}
                    <div className={`m-1`}>
                        <p className={`py-4 font-[Roboto-Regular]`}>{t("reviews")}</p>
                        <div className='h-[60vh] overflow-y-scroll'>
                            <AllReviews
                                reviewData={reviewData}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default VendorDetailPageAdmin