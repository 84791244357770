import React from 'react'
import dashBoardOne from '../../../Images/profileDashboardIcons/dashBoardOne.svg'
import allReq from '../../../Images/profileDashboardIcons/allReq.svg'
import service from '../../../Images/profileDashboardIcons/service.svg'
import myReq from '../../../Images/profileDashboardIcons/myReq.svg'
import message from '../../../Images/profileDashboardIcons/message.svg'
import subscription from '../../../Images/profileDashboardIcons/subscription.svg'
import dashBoardwhiteicon from '../../../Images/profileDashboardIcons/dashboardwhiteicon.svg'
import servicewhiteicon from '../../../Images/profileDashboardIcons/servicewhiteicon.svg'
import allReqwhiteicon from '../../../Images/profileDashboardIcons/allreqwhitelogo.svg'
import myReqwhiteicon from '../../../Images/profileDashboardIcons/myreqwhiteicon.svg'
import messagewhiteicon from '../../../Images/profileDashboardIcons/messagewhiteicon.svg'
import subscriptionwhiteicon from '../../../Images/profileDashboardIcons/subscriptionwhiteicon.svg'
import "./index.css"
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { Tabs, Tooltip, tabsClasses } from '@mui/material'

const DashboardTags = (props) => {
  const { t } = useTranslation()
  const location = useLocation()

  const dashboardTagsArr = [
    { id: "0", icon: dashBoardOne, activeicon: dashBoardwhiteicon, title: "dashboard", link: '/vendor' },
    { id: "1", icon: service, activeicon: servicewhiteicon, title: "serviceOffers", link: '/vendor/services' },
    { id: "2", icon: allReq, activeicon: allReqwhiteicon, title: "allRequiments", link: '/vendor/allrequirements' },
    { id: "3", icon: myReq, activeicon: myReqwhiteicon, title: "myRequirements", link: '/vendor/myrequirements' },
    { id: "4", icon: message, activeicon: messagewhiteicon, title: "message", link: '/vendor/message' },
    { id: "5", icon: subscription, activeicon: subscriptionwhiteicon, title: "subscription", link: '/vendor/subscription' },
    { id: "6", icon: subscription, activeicon: subscriptionwhiteicon, title: "productOffers", link: '/vendor/products' },
  ]

  return (
    <div className={`grid grid-rows-1 grid-flow-col gap-2`}>
      <Tabs
        variant="scrollable"
        scrollButtons
        aria-label="visible arrows tabs example"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
      >
        {dashboardTagsArr?.map((item, id) => {
          return (
            <Link key={id} to={item?.link} onClick={() => props.closeOtherTabItems()} className={location.pathname === item?.link ? "active" : "buttonactive"}>
              <img src={location.pathname === item?.link ? item.activeicon : item?.icon} alt="icon" />
              <Tooltip title={t(item?.title)}>
                <p className={`pr-4`}>{t(item?.title)}</p>
              </Tooltip>
            </Link>
          )
        })}
      </Tabs>
    </div>

  )
}

export default DashboardTags