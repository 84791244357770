import React from 'react'
import ButtonField from '../../../Common/FormFields/ButtonField'
import ProfileIcon from '../../../Images/UserImages/userDummyImage.png'
import locationIcon from '../../../Images/vendors/location.svg'
import { Tooltip } from '@mui/material'

const UserCard = (props) => {

    const userDetailsPF = `text-deepgray-4 font-[Roboto-Medium] w-1/3 text-sm`
    const userDetailsPS = `text-[#5B5B5B] font-[Roboto-Regular] w-2/3 text-sm`

    return (
        <div className='w-full h-full flex flex-col items-center bg-white border-[0.7px] border-solid border-[#b9c1ce80] rounded-[5px] px-2'>
            <img crossOrigin='anonymous' src={props.userData?.picture?.key !== null ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${props.userData?.picture?.key}&height=60&width=60` : ProfileIcon} alt="profileIcon" className={`w-20 h-20 rounded-full border mt-8`} />
            <div className={`flex flex-col items-center mb-10 mt-4`}>
                <p className={`mb-2 text-xl text-deepgray-5 font-[Roboto-Regular]`}>{props?.userData?.name ?? '--'}</p>
                {/* <p className={`flex gap-1 items-center`}>
                    <img src={locationIcon} alt='locationIcon' />
                    <span className={`text-sm text-grey-600 font-[Roboto-Regular]`}>{props?.userData?.location ?? '--'}</span>
                </p> */}
            </div>
            <div className={`w-full border-b border-solid border-[#b9c1ce80]`}></div>
            <div className={`w-full px-4 mt-4`}>
                <ButtonField
                    buttonextracls={`bg-seconderyColour-5 w-full rounded-full`}
                    buttonnamecls={` text-white text-sm py-[0.2rem] font-[Roboto-Medium] capitalize`}
                    buttonName="editprofile"
                    onClick={() => props.handleUserProfileEdit()}
                />
            </div>
            <div className={`w-full px-4 mt-4 flex flex-col gap-3`}>
                <div className="flex my-2">
                    <p className={userDetailsPF}>Phone:</p>
                    <p className={userDetailsPS}>{props?.userData?.phoneNo ?? '--'}</p>
                </div>
                <div className="flex my-2">
                    <p className={userDetailsPF}>Email:</p>
                    <Tooltip title={`${props?.userData?.email ?? '--'}`}>
                    <p className={`${userDetailsPS} truncate cursor-default`}>{props?.userData?.email ?? '--'}</p>
                    </Tooltip>
                </div>
                <p className="flex my-2">
                    <p className={userDetailsPF}>Unique ID:</p>
                    <p className={userDetailsPS}>{props?.userData?.uniqueId ?? '--'}</p>
                </p>
            </div>
        </div>
    )
}

export default UserCard