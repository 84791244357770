import React, { useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form"
// import DropDownFieldCategory from '../../../Common/FormFields/DropDownFieldCategory'
import TextFieldInput from '../../../Common/FormFields/TextFieldInput'
import text from '../../../Common/Languages/en_US.json'
import UploadButton from '../../../Common/UiComponents/UploadButton'
import fileUploadIcon from '../../../Images/SubAdmin/fileUploadIcon.svg'
import ButtonField from '../../../Common/FormFields/ButtonField'
import backArrow from '../../../Images/CommonImages/backArrow.svg'
import rightArrow from '../../../Images/CommonImages/rightArrow.svg'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
// import MuiDatePicker from '../../../Common/FormFields/MuiDatePicker'
import UploadMultipleImage from '../../../Common/Layout/UploadMultipleImage/UploadMultipleImage'
import setingIcon from '../../../Images/Vendor/setingIcon.svg'
import { Dialog } from '@mui/material'
import CategoryPopUp from '../../../Common/Layout/CategoryPopUp'
import { ForUploadMediaHooks } from '../../../Containers/UploadMedia/Hooks'
import CloseIcon from '@mui/icons-material/Close';
import pdficon from '../../../Images/CommonImages/pdficon.svg'
import MultipleSelectCategory from '../../../Common/FormFields/MultipleSelectCategory'
import { setUploadMultipleImg, setUploadedData } from '../../../Containers/UploadMedia/reducer/UploadMediaReducer'
import MultipleSelect from '../../../Common/FormFields/MultipleSelect'
import DropDownField from '../../../Common/FormFields/DropDownField'

const NewServiceFormForVendor = (props) => {
    const { multipleUpload, deleteUploadedMediaApiCall, cleanupMedia, documentUpload } = ForUploadMediaHooks()
    const loading = useSelector((state) => state.upload.loading)

    // const multipleUploadVar = [...multipleUpload, ...editMultiUpload]
    const multipleUploadVar = [...multipleUpload]

    const dispatch = useDispatch()
    const { t } = useTranslation()

    const editCategory = props.editServiceData?.category?.map((item) => item?.name)
    const editSubCategory = props.editServiceData?.subCategory?.map((item) => item?.name)

    const [addCategory, setAddCategory] = useState([])
    const [addsubCategory, setAddSubCategory] = useState([])
    const [selectCategory, setSelectCategory] = useState([])
    const [selectSubCategory, setSelectSubCategory] = useState([])

    const [categoryVal, setCategoryVal] = useState()
    const [subCategoryVal, setSubCategoryVal] = useState()

    const categoryData = useSelector((state) => state.totalCategoryInAdmin.totalCategories);

    // This is to pass as value in the multiple select 
    // const [multipleCategory, setMultipleCategory] = useState(props?.editServiceData?.category ? editCategoryMulti : [])    

    // form handling using react-hook-form 
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm({
        defaultValues: {
            serviceName: props?.editServiceData?.name ? props?.editServiceData?.name : '',
            serviceDesc: props?.editServiceData?.description ? props?.editServiceData?.description : '',
            amount: props?.editServiceData?.amount ? props?.editServiceData?.amount : '',
            // timeFrame: props?.editServiceData?.timeFrame ? props?.editServiceData?.timeFrame : '',
            categoryId: props?.editServiceData?.categoryId ? props?.editServiceData?.categoryId : '',
            subCategoryId: props?.editServiceData?.subCategoryId ? props?.editServiceData?.subCategoryId : '',
        }
    });

    const onSubmit = (data) => {
        // props?.editServiceData ?
        //     props.editServices(data, selectCategory, selectSubCategory) :
        //     props.createVendorPostService(data, selectCategory, selectSubCategory)

        props?.editServiceData ?
            props.editServices(data, categoryVal, subCategoryVal) :
            props.createVendorPostService(data, categoryVal, subCategoryVal)

        cleanupMedia()
        props?.serviceSubmitError?.error === false && props.serviceSubmitErrorMsg?.error === false && reset({
            serviceName: '',
            serviceDesc: '',
            amount: '',
            // timeFrame: '',
            categoryId: '',
            subCategoryId: ''
        })
    };

    useEffect(() => {
        if (props.editServiceData) {
            dispatch(setUploadedData(props.editServiceData?.document))
            dispatch(setUploadMultipleImg(props.editServiceData?.thumbnail))
        }
    }, [])

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getCategoryForUse = (id, name, subCategory) => {
        let index = addCategory.findIndex((item) => { return item.id === id })
        if (index > -1) {
            addCategory.splice(index, 1)
            setAddCategory([...addCategory])
        } else {
            setAddCategory([{ id, name, subCategory }, ...addCategory])
        }
    }

    const onHandelClickData = (subCategory) => {
        let value;
        let haveCommonEl = subCategory.every((item) => addsubCategory?.includes(item))
        if (haveCommonEl === true) {
            value = addsubCategory?.filter((index) => !subCategory?.includes(index));
            setAddSubCategory(value)
        } else {
            value = addsubCategory?.concat(subCategory)
            setAddSubCategory(value)
        }
    }

    // Select Categ done
    const addCategoryValue = (category) => {
        let id = selectCategory.indexOf(category)
        if (id > -1) {
            selectCategory?.splice(id, 1)
            setSelectCategory([...selectCategory])
        } else {
            setSelectCategory([category, ...selectCategory])
        }
    }

    // Select Sub-categ done 
    const addSubCategoryValue = (subCategory) => {
        let id = selectSubCategory.indexOf(subCategory)
        if (id > -1) {
            selectSubCategory?.splice(id, 1)
            setSelectSubCategory([...selectSubCategory])
        } else {
            setSelectSubCategory([subCategory, ...selectSubCategory])
        }
    }

    const selectCategoryArr = categoryData?.map((item) => {
        return { name: item?.name, value: item?.id }
    })

    const subCategoryFilter = categoryData?.filter((item) => item?.id === categoryVal)

    const selectSubCategoryArr = subCategoryFilter && subCategoryFilter?.length > 0 &&
        subCategoryFilter[0]?.subCategory?.map((item) => {
            return { name: item?.name, value: item?.id }
        })

    const selectSubCategoryArrConverted = Array.from(selectSubCategoryArr);

    const removeParticularThumbnail = (idx, id, key) => {
        if (idx > -1) {
            let arr;
            if (props.editServiceData) {
                arr = [...multipleUploadVar]
                arr.splice(idx, 1);
                dispatch(setUploadMultipleImg(arr))
                deleteUploadedMediaApiCall(id, key, "picture")
            }
            else {
                arr = [...multipleUpload]
                arr.splice(idx, 1);
                dispatch(setUploadMultipleImg(arr))
            }
        }
    }

    return (
        <div className={`p-6 xl:p-10`}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='flex justify-between lg:flex-row flex-col-reverse items-center'>
                    {/* Left part  */}
                    <div className={`textFieldText lg:w-[53%] 2xl:w-1/2 w-full`}>
                        <p className={`text-gray-900 font-[Roboto-Medium] text-base capitalize`}>{t("addNew")}</p>
                        <p className={`text-gray-900 font-[Roboto-Regular] text-sm`}>{t("addNewServiceFormDetails")}</p>

                        {/* Service name  */}
                        <div className='my-2'>
                            <Controller name={"serviceName"}
                                control={control}
                                render={({ field: { onChange, value }, formState: { error } }) => {
                                    return (
                                        (<TextFieldInput
                                            onlyValue
                                            textnewclass={`w-full text-sm `}
                                            floatingLabel='serviceName'
                                            value={(value)}
                                            onChange={onChange}
                                        />)
                                    )
                                }}
                                rules={{
                                    required: true, pattern: /^[a-zA-Z][a-zA-Z ]*/i, minLength: 2, maxLength: 30
                                }}
                            />
                            {/* Service name error messages */}
                            {errors.serviceName && errors.serviceName.type === "required" && (
                                <span className="error-message text-red-400 text-xs">Required</span>
                            )}
                            {errors.serviceName && errors.serviceName.type === "minLength" && (
                                <span className="error-message text-red-400 text-xs">Too Short</span>
                            )}
                            {errors.serviceName && errors.serviceName.type === "maxLength" && (
                                <span className="error-message text-red-400 text-xs">Too Long</span>
                            )}
                            {errors.serviceName && errors.serviceName.type === "pattern" && (
                                <span className="error-message text-red-400 text-xs">Not Valid</span>
                            )}
                        </div>

                        {/* Enter the service description  */}
                        <div>
                            <Controller name={"serviceDesc"}
                                control={control}
                                render={({ field: { onChange, value }, formState: { error } }) => {
                                    return (
                                        (<TextFieldInput
                                            multiline={true}
                                            minRows={4}
                                            maxRows={4}
                                            onlyValue
                                            textnewclass={`w-full text-sm`}
                                            floatingLabel='serviceDesc'
                                            value={(value)}
                                            onChange={onChange}
                                            inputProps={{ maxLength: 500 }}
                                        />)
                                    )
                                }}
                                rules={{
                                    required: true, minLength: 3, maxLength: 100
                                }}
                            />

                            {/* Service description error messages */}
                            {errors.serviceDesc && errors.serviceDesc.type === "required" && (
                                <span className="error-message text-red-400 text-xs">Required</span>
                            )}
                            {errors.serviceDesc && errors.serviceDesc.type === "minLength" && (
                                <span className="error-message text-red-400 text-xs">Too Short</span>
                            )}
                            {errors.serviceDesc && errors.serviceDesc.type === "maxLength" && (
                                <span className="error-message text-red-400 text-xs">Too Long</span>
                            )}
                        </div>

                        <div className={`grid grid-cols-2 gap-6 my-4 w-full`}>
                            {/* Select category  */}
                            <div className='flex flex-col dropdownText'>
                                {/* Old Code  */}
                                {/* <MultipleSelectCategory
                                    placeholder="*selectCateg"
                                    addCategory={addCategory}
                                    onHandelClickData={onHandelClickData}
                                    itemsId={props.addCategoryId}
                                    addCategoryValue={addCategoryValue}
                                    editServiceData={props.editServiceData}
                                    editCategory={editCategory}
                                    extraItem={
                                        <div className={`flex gap-2 items-center`} onClick={handleClickOpen}>
                                            <img src={setingIcon} alt={'setingIcon'} height={15} width={15} property />
                                            <p className={`text-sm font-[Roboto-Regular] text-skyblue-6`}>{t('manageCategories')}</p>
                                        </div>
                                    }
                                /> */}

                                {/* New Code  */}
                                {/* <MultipleSelect
                                    autocompleteExtraCls={` text-sm text-gray-60 font-[Roboto-Regular]`}
                                    multiple={false}
                                    value={categoryVal}
                                    onChange={(e, v) => { setCategoryVal(v.value); setSubCategoryVal(undefined) }}
                                    label="*selectCateg"
                                    isOptionEqualToValue={(option, value) => { return option?.name === value?.name; }}
                                    // autocompleteExtraCls={`selectLocationCls`}
                                    autocompleteArray={selectCategoryArr}
                                /> */}


                                <Controller
                                    name={"categoryId"}
                                    control={control}
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            (
                                            
                                            // <DropDownField
                                            //     name='categoryId'
                                            //     id='categoryId'
                                            //     dropDownRootCls={`h-[3.28rem]`}
                                            //     size="small"
                                            //     // selectOption={categoryVal}
                                            //     placeholder={`*Select Plan Type`}
                                            //     option={value}
                                            //     handleChange={(e) => onChange(e.value)}
                                            // />

                                            <MultipleSelect
                                            name='categoryId'
                                             id='categoryId'
                                            autocompleteExtraCls={` text-sm text-gray-60 font-[Roboto-Regular]`}
                                            multiple={false}
                                            value={categoryVal}
                                            onChange={(e, v) => { 
                                                setCategoryVal(v.value); 
                                                onChange(v.value);
                                                setSubCategoryVal(undefined)}}
                                            label="*selectCateg"
                                            isOptionEqualToValue={(option, value) => { return option?.name === value?.name; }}
                                            // autocompleteExtraCls={`selectLocationCls`}
                                            autocompleteArray={selectCategoryArr}
                                                                                       
                                        />

                                        
                                        
                                        )
                                        )
                                    }}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors.categoryId && errors.categoryId.type === "required" && (
                                    <span className="error-message text-red-400 text-xs">{`Required`}</span>
                                )}
                                {errors.categoryId && errors.categoryId.type === "pattern" && (
                                    <span className="error-message text-red-400 text-xs">Select Category</span>
                                )}


                            </div>

                            {/* Select sub-category  */}
                            <div className='flex flex-col dropdownText'>
                                {/* Old code  */}
                                {/* <MultipleSelectCategory
                                    addsubCategory={addsubCategory}
                                    placeholder="*selectSubCateg"
                                    itemsId={props.addSubCategoryId}
                                    addSubCategoryValue={addSubCategoryValue}
                                    editServiceData={props.editServiceData}
                                    editCategory={editSubCategory}
                                /> */}
                                {/* New code  */}
                                {/* <MultipleSelect
                                    autocompleteExtraCls={` text-sm text-gray-60 font-[Roboto-Regular]`}
                                    multiple={false}
                                    value={subCategoryVal}
                                    onChange={(e, v) => { setSubCategoryVal(v.value) }}
                                    // onChange={onChange}
                                    label="*selectSubCateg"
                                    isOptionEqualToValue={(option, value) => { return option?.name === value?.name; }}
                                    autocompleteArray={selectSubCategoryArrConverted}
                                /> */}




                                <Controller
                                    name={"subCategoryId"}
                                    control={control}
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            (

                                            <MultipleSelect
                                                    name='subCategoryId'
                                                    id='subCategoryId'
                                                    autocompleteExtraCls={` text-sm text-gray-60 font-[Roboto-Regular]`}
                                                    multiple={false}
                                                    value={subCategoryVal}
                                                    onChange={(e, v) => { setSubCategoryVal(v.value)
                                                        onChange(v.value);
                                                     }}
                                                    label="*selectSubCateg"
                                                    isOptionEqualToValue={(option, value) => { return option?.name === value?.name; }}
                                                    autocompleteArray={selectSubCategoryArrConverted}

                                                />



                                            )
                                        )
                                    }}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors.subCategoryId && errors.subCategoryId.type === "required" && (
                                    <span className="error-message text-red-400 text-xs">{`Required`}</span>
                                )}
                                {errors.subCategoryId && errors.subCategoryId.type === "pattern" && (
                                    <span className="error-message text-red-400 text-xs">Select Category</span>
                                )}


                               
                            </div>
                        </div>


                        <div className={`grid grid-cols-2 gap-6 w-full mb-2`}>
                            {/* Enter amount  */}
                            <div className={`flex flex-col dropdownText`}>
                                <Controller name={"amount"}
                                    control={control}
                                    render={({ field: { onChange, value }, formState: { error } }) => {
                                        return (
                                            (<TextFieldInput
                                                typeNumber={true}
                                                onlyValue
                                                textnewclass={`w-full text-sm `}
                                                floatingLabel='*enterAmount'
                                                value={(value)}
                                                onChange={(e)=>{
                                                    const newValue = e.target.value;
                                                    if (newValue >= 0) {
                                                        onChange(newValue);
                                                    }
                                                }}
                                                onWheel={(e) => e.target.blur()}
                                            />)
                                        )
                                    }}
                                    rules={{
                                        required: false,
                                        min: {
                                            value: 0,
                                            message: "Amount can not be less than 0.",
                                        },
                                    }}
                                />

                                {/* Service description error messages */}
                                {errors.amount && errors.amount.type === "required" && (
                                    <span className="error-message text-red-400 text-xs">{text.requiredField} & first number can not be 0</span>
                                )}
                                {errors.amount && errors.amount.type === "pattern" && (
                                    <span className="error-message text-red-400 text-xs">you can put maximum 5 number</span>
                                )}
                            </div>

                            {/* <div className={`flex flex-col dropdownText`}>
                                <Controller name={"timeFrame"}
                                    control={control}
                                    defaultValue={null}
                                    rules={{
                                        required: true
                                    }}
                                    render={({ field: { onChange, value }, formState: { error } }) => {
                                        return (
                                            (
                                                <MuiDatePicker
                                                    label='*Select timeframe'
                                                    value={(value)}
                                                    onChange={onChange}
                                                />

                                                <TextFieldInput
                                                    typeNumber={true}
                                                    onlyValue
                                                    textnewclass={`w-full text-sm `}
                                                    floatingLabel={`selectTimeFrame`}
                                                    value={(value)}
                                                    onChange={onChange}
                                                />
                                            )
                                        )

                                    }}
                                />
                                {errors.timeFrame && errors.timeFrame.type === "required" && (
                                    <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                )}
                            </div> */}
                        </div>

                        {/* <div className={`flex gap-4 w-fit my-4 `}>
                            <div>
                                <p className={`text-Grey-900 font-[Roboto-Medium] font-normal text-base `}>{t("uploadPDFtext")}</p>
                                <p className={`text-Grey-900 font-[Roboto-Regular] font-normal text-sm `}>{t("dragAndDropText")}<span> {t("browse")}</span><br />{t("yourFiles")}</p>
                            </div>
                            <div className={`relative`}>
                                <UploadButton
                                    sendData={(data) => {
                                        if (data[0]?.type === 'application/pdf') {
                                            props.doUploadedMedia(data)
                                        }
                                    }}
                                    extracls='flex justify-center items-center bg-white items-center rounded-md'
                                    extratextcls='text-xs w-16'
                                    type='button'
                                    fileUploadIcon={
                                        documentUpload && documentUpload?.mimetype === 'application/pdf' ?
                                            pdficon :
                                            fileUploadIcon
                                    }
                                    accept={'image/jpeg, application/pdf'}
                                    multiple={false}
                                    disabled={documentUpload && documentUpload?.mimetype === 'application/pdf' ? true : false}
                                    fileName={documentUpload?.mimetype === 'application/pdf' ? (documentUpload?.name || documentUpload?.originalname) : null}
                                    fileSize={documentUpload?.mimetype === 'application/pdf' ? documentUpload?.size : null}
                                />
                                {documentUpload && documentUpload?.mimetype === 'application/pdf' &&
                                    <div className={`absolute -top-3 left-20 -right-4 cursor-pointer`}>
                                        <CloseIcon
                                            sx={{ fontSize: "12px", border: '1px solid red', borderRadius: '9999px' }}
                                            onClick={() => dispatch(setUploadedData({}))}
                                        />
                                    </div>
                                }
                            </div>
                        </div> */}
                    </div>


                    {/* Right part  */}
                    <div className={`lg:w-[45%] lg:pb-0 pb-4 w-[100%] sm:w-[80%]`}>
                        <UploadMultipleImage
                            sendData={(data) => {
                                props.multipleMediaApiCall(data);
                            }}
                            disabled={multipleUploadVar && multipleUploadVar?.length === 4 ? true : false}
                            text1="selectFile"
                            text2="dragAndDropItHereText"
                            text3={loading === true ? 'uploading' : "uploadImg"}
                            multiple={true}
                            fileLimit={4}
                            multipleUploadState={multipleUploadVar}
                            accept={{
                                "image/jpeg": [".jpeg", ".jpg"],
                                "image/png": [".png"],
                            }}
                        />
                        <div className={`flex gap-4 pt-2 w-full relative`}>
                            {multipleUploadVar && multipleUploadVar?.length > 0 ? (
                                multipleUploadVar?.map((multipleImg, idx) => {

                                    return (
                                        <div key={idx} className={`flex w-fit justify-between`}>
                                            {multipleImg?.mimetype?.includes('image') ? (
                                                <img
                                                    crossOrigin="anonymous"
                                                    src={multipleImg?.key && `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${multipleImg?.key}&height=75&width=100`}
                                                    alt="thumbnail"
                                                />
                                            ) : multipleImg?.mimetype?.includes('video') ? (
                                                <video
                                                    crossOrigin="anonymous"
                                                    src={multipleImg?.key && `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${multipleImg?.key}&height=75&width=100`}
                                                    width="100"
                                                    height="75"
                                                    controls
                                                    autoPlay
                                                    muted
                                                />
                                            ) : null}
                                            <div className={`cursor-pointer `}>
                                                <CloseIcon
                                                    onClick={() => removeParticularThumbnail(idx, multipleImg?.id, multipleImg?.key)}
                                                    sx={{ fontSize: "12px", position: 'absolute', top: '0px', border: '1px solid red', borderRadius: '9999px' }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className={`flex w-full gap-2 mt-2`}>
                                    <img src={fileUploadIcon} alt='file upload icon' className='w-[23%]'/>
                                    <img src={fileUploadIcon} alt='file upload icon' className='w-[23%]'/>
                                    <img src={fileUploadIcon} alt='file upload icon' className='w-[23%]'/>
                                    <img src={fileUploadIcon} alt='file upload icon' className='w-[23%]'/>
                                </div>
                            )}
                        </div>

                    </div>
                </div>

                <div className={`flex justify-start my-6`}>
                    <div className='flex gap-3 sm:gap-6 flex-col sm:flex-row w-full sm:w-auto'>
                        <ButtonField
                            buttonName={`cancel`}
                            buttonInsidecls={`gap-2`}
                            img={backArrow}
                            alt='backArrow'
                            type='reset'
                            buttonextracls={`w-[100%] sm:w-auto !px-6 !py-2 !text-deepgrey-3 bg-Gray-5 font-[Roboto-Medium]`}
                            onClick={() => { props.goBackToActiveServiceOfferedTab(); }}
                        />
                        <ButtonField
                            buttonName={`postThisService`}
                            img={rightArrow}
                            alt='rightArrow'
                            type='submit'
                            loading={props.loading}
                            buttonInsidecls={`!flex-row-reverse gap-2 `}
                            buttonextracls={`${loading === true ? `bg-gray-400` : ` bg-skyblue-6`} w-[100%] sm: !px-6 !py-2 !text-white`}
                            onClick={handleSubmit(onSubmit)}
                            disabled={loading === true ? true : false}
                        />
                    </div>
                </div>
                {props.serviceSubmitError?.error === true && <p className={`text-red-5 text-sm text-center font-[Roboto-Regular]`}>{props.serviceSubmitError?.message}</p>}
            </form>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='!w-screen'
                maxWidth={true}
            >
                <CategoryPopUp
                    handleClose={handleClose}
                    categoryArr={props.categoryArr}
                    getCategoryForUse={getCategoryForUse}
                    addCategory={addCategory}
                />
            </Dialog>
        </div>
    )
}

export default NewServiceFormForVendor