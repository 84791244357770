import React from 'react'
import AdminSideBar from './AdminSideBar'
import LandingHeader from '../../Common/Layout/LandingHeader/LandingHeader';
import PropTypes from 'prop-types';
import { Outlet, useLocation } from 'react-router-dom'
import { ForNotification } from '../../Containers/Notification/Hooks';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { adminSideMenu } from './AdminSideBar/sideBarData';

function checkPermission(currentPath, adminSideMenu, permittedArray) {
    return new Promise((resolve) => {
      let hasPermission = false;
  
      for (const menuItem of adminSideMenu) {
        if (menuItem.link === currentPath) {
          const isPermitted = permittedArray.some(
            (permItem) => permItem?.name === menuItem?.name
          );
  
          if (isPermitted) {
            hasPermission = true;
          } else {
            hasPermission = false;
          }
          break; 
        }
      }
      resolve(hasPermission);
    });
  }
  

const AdminIndex = (props) => {
    const { backtohomePage, getNotification, readNotificationApi, handleshow, showNotification, allnotification, detailsPage } = ForNotification()
    const [hasPermission , setHasPermission] = React.useState(true)
    const location = useLocation()
      const signedInUserData = useSelector((state) => state.totalUsers.selectedUserDetails)
    useEffect(() => {
        if(signedInUserData?.role === 'subAdmin' && signedInUserData?.sections && signedInUserData.sections?.length > 0){
            const hasPermission = checkPermission(location.pathname, adminSideMenu, signedInUserData.sections)
            setHasPermission(hasPermission)
        }
    },[location?.pathname, signedInUserData])
   
    useEffect(() => { getNotification() }, [])
    return (
        <div>
            <LandingHeader 
                landingRoot={`fixed top-0 z-50`}
                searchBar={true}
                goTomessage={true}
                goToNotification={true}
                border={true}
                profile={true} 
                handleclickNotification={handleshow}
                showNotification={showNotification}
                allnotification={allnotification}
                detailsPage={detailsPage}
                readNotificationApi={readNotificationApi}
                getNotification={getNotification}
            />
            <div className={`flex w-full min-h-screen pt-10`}>
                <div className={`!w-[15%] md:!w-[16%] bg-white`}>
                    <AdminSideBar/>
                </div>
                <div className='!w-[85%] bg-light-gray overflow-y-hidden pt-10'>
                    {hasPermission ? <Outlet /> : 
                    <div className={`flex items-center justify-center w-full h-full`}>
                        <h1 className={`text-3xl text-neutral-600 font-bold`}>Page Not Found</h1>
                    </div>}
                </div>
            </div>
        </div>
    )
}

AdminIndex.propTypes = {
    subAdminCreate: PropTypes.func,
    getAllUsers: PropTypes.func,
    subAdminUser: PropTypes.string,
    showSubAdminForm: PropTypes.bool,
    goBackToTable: PropTypes.func,
    toggleSubAdmin: PropTypes.func,
    setShowSubAdminForm: PropTypes.func,
}

export default AdminIndex