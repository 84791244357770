import React from 'react'
import facebook from "../../../Images/SocialMedia/facebook.svg"
import twitter from "../../../Images/SocialMedia/twitter.svg"
import instagram from "../../../Images/SocialMedia/instagram.svg"
import playstore from "../../../Images/Vendor/playstore.svg"
import appstore from "../../../Images/Vendor/appstore.svg"
import footerLogo from '../../../Images/CommonImages/exploreFooterLogo.svg'
import text from '../../Languages/en_US.json'
import { useTranslation } from 'react-i18next'
import footerStyles from './FooterStyles.module.css'
import { Link } from 'react-router-dom'

const Footer = () => {
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng.target.value);
    };

    return (
        <div className="w-full py-[4.5%] lg:py-[2.5%] px-[4%] bg-[#0D074D] text-white">
            <div className="flex flex-wrap sm:grid sm:grid-cols-2 lg:flex lg:justify-between gap-6">
                {/* Left Side */}
                <div className="flex flex-col w-[100%] sm:w-[100%] lg:w-[40%]">
                    <div className="flex flex-col gap-5">
                        <img src={footerLogo} alt={t("explore")} className="w-[8rem]" />
                        <p className={`text-xs text-deepgrey-2 leading-5 font-normal font-[Roboto-Regular] ${footerStyles.footerResponsive}`}>
                            {t("digidialssubtext")}
                        </p>
                    </div>
                    <p className="text-xs text-deepgrey-2 leading-6 font-normal font-[Roboto-Medium]">{t("allrightsreserved")}</p>
                </div>

                {/* Middle Side - Quick Links */}
                <div className="flex flex-col pl-[1%]">
                    <p className="text-xs text-white font-normal pb-1">{t("quicklinks")}</p>
                    <div className="flex flex-col ">
                        <Link to="/aboutus" className="text-xs text-deepgrey-2 leading-5 font-normal cursor-pointer">{t("aboutus")}</Link>
                        <Link to="/faq" className="text-xs text-deepgrey-2 leading-5 font-normal cursor-pointer">{t("faqs")}</Link>
                        <Link to="/privacypolicy" className="text-xs text-deepgrey-2 leading-5 font-normal cursor-pointer">{t("privacypolicy")}</Link>
                        <Link to="/termsandconditions" className="text-xs text-deepgrey-2 leading-5 font-normal cursor-pointer">{t("termscondition")}</Link>
                    </div>
                </div>

                {/* Middle Side - Social Media */}
                <div className="text-left flex flex-col justify-between h-32 max-sm:w-[60%]">
                    <p className="text-xs text-white font-normal">{t("followuson")}</p>
                    <div className="flex items-center pt-2 md:pt-1 gap-4">
                        <a href="/" target='_blank' className="rounded-full w-8 h-8 flex items-center justify-center bg-blue-6">
                            <img src={facebook} alt="facebook" height={10} width={10} />
                        </a>
                        <a href="/" target='_blank' className="rounded-full w-8 h-8 flex items-center justify-center bg-blue-6">
                            <img src={instagram} alt="linkedin" height={16} width={16} />
                        </a>
                        <a href="/" target='_blank' className="rounded-full w-8 h-8 flex items-center justify-center bg-blue-6">
                            <img src={twitter} alt="linkedin" height={16} width={16} />
                        </a>
                    </div>
                    <div className="flex gap-2">
                        <img src={playstore} alt="playstore" height={100} width={100} />
                        <img src={appstore} alt="appstore" height={100} width={100} />
                    </div>
                </div>

                {/* Right Side - Language Selector */}
                <div className="flex flex-col items-start gap-3 h-16 pr-[2%]">
                    <button className="text-xs text-white font-normal cursor-pointer" onClick={changeLanguage} value="en_US">{text.English}</button>
                    <button className="text-xs text-white font-normal cursor-pointer" onClick={changeLanguage} value="hin">हिंदी</button>
                    <button className="text-xs text-white font-normal cursor-pointer" onClick={changeLanguage} value="ar">عربي</button>
                </div>
            </div>
        </div>
    );
}

export default Footer;
