import React from 'react'
import SignupAsUser from '../../../Components/User/Auth'
import { UserAuthHooks } from './Hooks'
import MainHeader from '../../../Common/Layout/MainHheader/MainHeader'
import { ForSignup } from '../../Authentication/Signup/Hook'

const SignupAsUserIndex = () => {

  const {
    createAccountUserForm,
    showOTP,
    showOTPMobile,
    handleClickForOTP,
    handleClickForOTPMobile,
    autoFillOtp,
    emailVerified,
    phoneNoVerified,
    setOTPForMobile,
    oTPForMobile,
    loading,
    emailErrTexts,
    otpForEmail,
    setOtpForEmail,
    phoneNo,
    phoneChangeHandler,
    phoneNoError,
    EmailOTP,
    PhoneOTP,
    showEmailOtpButtonUser,
    setShowEmailOtpButtonUser,
    showPhoneOtpButtonUser,
    setShowPhoneOtpButtonUser,
    notification
  } = UserAuthHooks()

  const { mapLocationTxt,
    setMapLocationTxt,
    mapLocation,
    handleClickLocation } = ForSignup()

  return (
    <div>
      <div className={`sticky top-0`}>
        <MainHeader />
      </div>
      <SignupAsUser
        createAccountUserForm={createAccountUserForm}
        handleClickForOTP={handleClickForOTP}
        emailVerified={emailVerified}
        showOTP={showOTP}
        autoFillOtp={autoFillOtp}
        handleClickForOTPMobile={handleClickForOTPMobile}
        phoneNoVerified={phoneNoVerified}
        showOTPMobile={showOTPMobile}
        setOTPForMobile={setOTPForMobile}
        OTPForMobile={oTPForMobile}
        loading={loading}
        emailErrTexts={emailErrTexts}
        mapLocationTxt={mapLocationTxt}
        setMapLocationTxt={setMapLocationTxt}
        mapLocation={mapLocation}
        handleClickLocation={handleClickLocation}
        otpForEmail={otpForEmail}
        setOtpForEmail={setOtpForEmail}
        phoneNo={phoneNo}
        phoneChangeHandler={phoneChangeHandler}
        phoneNoError={phoneNoError}
        EmailOTP={EmailOTP}
        PhoneOTP={PhoneOTP}
        showEmailOtpButtonUser={showEmailOtpButtonUser}
        setShowEmailOtpButtonUser={setShowEmailOtpButtonUser}
        showPhoneOtpButtonUser={showPhoneOtpButtonUser}
        setShowPhoneOtpButtonUser={setShowPhoneOtpButtonUser}
        notification={notification}
      />
    </div>
  )
}

export default SignupAsUserIndex