import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ButtonField from '../../../Common/FormFields/ButtonField';
import InputField from '../../../Common/FormFields/InputField';
import Businessbuttonicon from "../../../Images/accountcreate/bussinessbuttonicon.svg";
import Businessbuttonblueicon from "../../../Images/accountcreate/bussinessbuttonblueicon.svg";
import Indivisualbuttonicon from "../../../Images/accountcreate/indivisualbuttonicon.svg";
import Indivisualbuttonblueicon from "../../../Images/accountcreate/indivisualbuttonblueicon.svg";
import CheckBox from '../../../Common/FormFields/CheckBox';
import CreateBusinessAddressByAdmin from './CreateBusinessAddressByAdmin';
import PhoneInputField from '../../../Common/FormFields/PhoneInputField';

// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .matches(/^[a-zA-Z][a-zA-Z ]*$/, 'Please enter a valid name'),
  email: Yup.string()
    .required('Email is required')
    .email('Please enter a valid email'),
  phoneNo: Yup.string()
    .required('Phone number is required')
    .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Please enter a valid phone number')
});





const AddVendorFromForAdmin = (props) => {


  // console.log(props.editVendorFormData,"#editVendorFormData")
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues:{
      name: props?.editVendorFormData?.name? props.editVendorFormData?.name : '',
      email:props?.editVendorFormData?.email? props.editVendorFormData?.email : '',
      phoneNo: props?.editVendorFormData?.phoneNo? props.editVendorFormData?.phoneNo : '',
      typecheck: props?.editVendorFormData?.typecheck? props.editVendorFormData?.typecheck : '',
    },
  });

  const [colorForBusiness, setColorForBusiness] = useState(false);
  const [colorForIndividual, setColorForIndividual] = useState(false);
  const [typecheck, setTypecheck] = useState('');
  const [check, setCheck] = useState(false);

  const onSubmit = (data) => {

    // console.log(data,"#editVendorFormDatasubb")


    if (props.editVendorFormData) {
      props.editclick(data, typecheck);
    } else {
      props.createVendorByAdmin(data, typecheck);
    }
    if (props.totalVendorCreateMsg?.error === false) {
      reset();
    }
  };

  const handleClickForChooseBusiness = () => {
    setColorForBusiness(!colorForBusiness);
    setColorForIndividual(false);
    setTypecheck(colorForBusiness ? '' : 'business');
  };

  const handleClickForChooseIndividual = () => {
    setColorForIndividual(!colorForIndividual);
    setColorForBusiness(false);
    setTypecheck(colorForIndividual ? '' : 'individual');
  };

  useEffect(()=>{
    if(props?.editVendorFormData?.type){
      if(props.editVendorFormData?.type === 'business'){
        setColorForBusiness(true);
        setColorForIndividual(false);
        setTypecheck('business');
      }else{
        setColorForIndividual(true);
        setColorForBusiness(false);
        setTypecheck('individual');
      }
    }
  },[])

  return (
    <>
      {!props.showAddAddress ? (
        <div className={`w-full flex lg:flex-row flex-col justify-between p-[5%]`}>
          <div className={`w-[100%] lg:w-[50%]`}>
            <form onSubmit={handleSubmit(onSubmit)} className={`flex flex-col items-start justify-between h-[28em]`}>
              {/* Name Section */}
              <div className={`w-full my-3`}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      extracls={`text-sm`}
                      placeholder="*Enter your name"
                      textinputname="name"
                      variant="outlined"
                      type="text"
                      id="name"
                      onChange={onChange}
                      value={value}
                      floatingLabel={'*Enter your name'}
                    />
                  )}
                />
                <small className={`text-red-500`}>
                  {errors.name && <p>{errors.name.message}</p>}
                </small>
              </div>

              {/* Email Section */}
              <div className={`flex flex-col w-full`}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      extracls={`w-full text-sm !p-0`}
                      placeholder="*Enter your email"
                      textinputname="email"
                      variant="outlined"
                      type="email"
                      id="email"
                      onChange={onChange}
                      value={value}
                      disabled={!!props.editVendorFormData?.email}
                      floatingLabel={'*Enter your email'}
                    />
                  )}
                />
                <small className={`text-red-500 mt-2`}>
                  {errors.email && <p>{errors.email.message}</p>}
                </small>
              </div>

              {/* Phone Number Section */}
              <div className={`flex flex-col w-full`}>
                <Controller
                  name="phoneNo"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInputField
                      defaultCountry={'kw'}
                      placeholder={'*Enter Phone number'}
                      label={'*Enter Phone Number'}
                      extraCls={`w-full text-sm mt-[0.45rem]`}
                      inputCls={`w-full h-[3.3rem] cursor-default`}
                      onChange={onChange}
                      value={value}
                      enableSearch={true}
                      disabled={!!props.editVendorFormData?.phoneNo}
                    />
                  )}
                />
                <small className={`text-red-500 mt-2`}>
                  {errors.phoneNo && <p>{errors.phoneNo.message}</p>}
                </small>
              </div>

              {/* Choose Vendor Type */}
              <div>
                <p className={`font-[Roboto-Regular] text-base text-[Grey-900] mb-3`}>Choose Vendor Type</p>
                <div className={`flex`}>
                  <ButtonField
                    buttonextracls={`bg-gray-0.5 border-[1.5px] border-solid border-deepgray-0.5 font-medium font-[Roboto-Medium] capitalize w-40 h-16 text-xs mr-6 ${colorForBusiness ? "bg-[#DCF2FA]/80 border-[#0099CC]" : "bg-gray-0.5 border-deepgray-0.5"}`}
                    buttonName="Businesses"
                    imgtext={`justify-between w-28`}
                    buttonnamecls={`font-[Roboto-Regular] font-normal text-sm ${colorForBusiness ? "text-[#0099CC]" : "text-deepgrey-2"}`}
                    img={colorForBusiness ? Businessbuttonblueicon : Businessbuttonicon}
                    onClick={handleClickForChooseBusiness}
                  />
                  <ButtonField
                    buttonextracls={`border-[1.5px] border-solid font-medium font-[Roboto-Medium] capitalize w-40 h-16 text-xs ${colorForIndividual ? "bg-[#DCF2FA]/80 border-[#0099CC]" : "bg-gray-0.5 border-deepgray-0.5"}`}
                    imgtext={`justify-between w-28`}
                    buttonName="Individuals"
                    buttonnamecls={`font-[Roboto-Regular] font-normal text-sm ${colorForIndividual ? "text-[#0099CC]" : "text-deepgrey-2"}`}
                    img={colorForIndividual ? Indivisualbuttonblueicon : Indivisualbuttonicon}
                    onClick={handleClickForChooseIndividual}
                  />
                </div>
              </div>

              {/* Terms & Conditions */}
              <div className={`flex items-center gap-3 h-[2em]`}>
                <CheckBox checked={check} handleBoxChange={(e) => setCheck(e.target.checked)} />
                <p onClick={() => setCheck(!check)} className={`flex h-full items-center cursor-pointer text-Grey-900 font-[Roboto-Regular] font-normal text-sm`}>
                  I agree to the <a href='/termsandconditions' target='_blank' className={`text-Active-100 pl-1 font-bold`}>Terms and Conditions</a>
                </p>
              </div>

              {/* Register & Continue Button */}
              <div className={`flex mt-5`}>
                <ButtonField
                  buttonextracls={`${check ? 'bg-skyblue-6 cursor-pointer' : 'bg-gray-400'} border-[1.5px] border-solid border-deepgray-0.5 capitalize w-56 h-12`}
                  imgtext={`justify-between w-40`}
                  buttonName="Register and continue >"
                  buttonnamecls={`font-[Roboto-Regular] text-white font-medium text-sm`}
                  type="submit"
                  disabled={!check}
                />
                <ButtonField
                  buttonextracls={`cursor-pointer border-[1.5px] border-solid border-deepgray-0.5 capitalize w-24 ml-5 h-12`}
                  buttonName="Cancel"
                  buttonnamecls={`font-[Roboto-Medium] text-deepgray-0.5 font-medium text-sm`}
                  onClick={props.cancelclick}
                />
              </div>

              {props.totalVendorCreateMsg?.error === true && (
                <p className='text-red-600 text-sm'>{props.totalVendorCreateMsg?.message}</p>
              )}
            </form>
          </div>
        </div>
      ) : (
        <CreateBusinessAddressByAdmin
          businessAddAddressByAdmin={props.businessAddAddressByAdmin}
          editVendorFormData={props.editVendorFormData}
          editbusinessAddressDetails={props.editbusinessAddressDetails}
          doUploadedMedia={props.doUploadedMedia}
          doUploadedLogo={props.doUploadedLogo}
          typecheck={typecheck}
          cancelAddressclick={props.cancelAddressclick}
          multipleMediaApiCall={props.multipleMediaApiCall}
        />
      )}
    </>
  );
};

export default AddVendorFromForAdmin;