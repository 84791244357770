import React from 'react'
import ButtonField from '../../../Common/FormFields/ButtonField'
import buttonArrow from '../../../Images/adminContent/buttonArrow.svg'
// import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import eventIcon from '../../../Images/adminContent/eventIcon.svg'
import ReactDateRange from '../../../Common/FormFields/DateRangePicker';
import './index.css'
import text from "../../../Common/Languages/en_US.json"
import moment from 'moment';
import { date } from 'yup';
import DateRangePickerComponent from '../../../Common/FormFields/DateRangePickerComponent';

const DashboardTop = (props) => {
    const name = localStorage.getItem('name')
    return (
        <div className={`flex justify-between relative`}>
            <div>
                <p className='text-[#1C1C1C] text-2xl font-[Roboto-Regular]'>{text.dashboard}</p>
                <p className='text-[#969696] text-lg font-[Roboto-Regular]'>Good to see you again, {name}</p>
            </div>
            <DateRangePickerComponent
                value={props.dateState}
                onChange={(item) => {
                    if (!item.selection) return;
                    props.setDateState([item.selection]);
                }}
            />
        </div>
    )
}

export default DashboardTop