
import { useEffect, useState } from 'react';
import { SUB_ADMIN_API } from '../../../Constant';
import { doGetApiCall, doPostApiCall } from '../../../Utils/ApiConfig';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getVendorBusinessDetails, getLocationReducer } from './reducer/VendorAccountReducer';
import { ForUploadMediaHooks } from '../../UploadMedia/Hooks';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import getUserId from "../../../Utils/gnUniqueId"
import { useLocation } from 'react-router-dom';
import { ForVendor } from '../../VendorDetailPage/Hooks';
import { useTranslation } from 'react-i18next';
import { savePlanData } from '../../Admin/reducer/SubscriptionPlanReducer';


export const ForSignup = () => {
  const dispatch = useDispatch()
  const routeLocation = useLocation()

  const { t } = useTranslation()

  const { documentUpload, logoUpload, multipleUpload, cleanupMedia } = ForUploadMediaHooks()
  const { getBusiness } = ForVendor()
  //   const card = useSelector((state)=> state.vendorAccount.businessDetails)

  //   useEffect(()=> {
  // console.log(card , "card")
  //   },[card])

  const [uniqueid, setUniqueid] = useState("")
  const [colorForBussiness, setColorForBussiness] = useState(false); //to change the bussinessbutton color on onclick
  const [otpValue, setOtpValue] = useState()
  const [OTPForEmail, setOTPForEmail] = useState()  // for Emailotp value
  const [OTPForMobile, setOTPForMobile] = useState(); //for Mobileotp value
  // const [businessInformation, setBusinessInformation] = useState(false)
  const [typecheck, setTypecheck] = useState("")
  const [emailVerified, setEmailVerified] = useState(false)
  const [phoneNoVerified, setPhoneNoVerified] = useState(false)
  const [showAddAddress, setShowAddAddress] = useState(false)
  const [accountEmail, setAccountEmail] = useState('')
  const [gotoApproval, setGotoApproval] = useState(false);
  const [doneApproval, setDoneApproval] = useState(false)
  const [autoFillOtp, setAutoFillOtp] = useState()
  const [showOTP, setShowOTP] = useState(false)
  const [showOTPMobile, setShowOTPMobile] = useState(false) //to show otpField for mobileField
  const [verifyotp, setVerifyOtp] = useState(false)
  const [colorForIndivisual, setColorForIndivisual] = useState(false); //to change the indivisualbutton color on onclick
  const [check, setCheck] = useState(false) // state for signup checkbox
  const [loading, setLoading] = useState(false);
  const [phoneNo, setPhoneNo] = useState('us');
  const [phoneNoError, setPhoneNoError] = useState(false)

  const [showEmailOtpButtonVendor, setShowEmailOtpButtonVendor] = useState(false)
  const [showPhoneOtpButtonVendor, setShowPhoneOtpButtonVendor] = useState(false)

  // These useStates, function are used in the location icons(wherever it is used) to get lat, long and full address/location of the vendor/user
  const [mapLocation, setMapLocation] = useState()
  const [mapLocationTxt, setMapLocationTxt] = useState('')
  const lat = sessionStorage.getItem('latitude')
  const long = sessionStorage.getItem('longitude')
  const handleClickLocation = () => {
    if (lat === null && long === null) {
      navigator.geolocation.getCurrentPosition((position) => {
        getLocationData(position.coords.latitude, position.coords.longitude)
        setMapLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
      })
    } else {
      getLocationData(parseFloat(lat), parseFloat(long))
      setMapLocation({
        lat: parseFloat(lat),
        lng: parseFloat(long)
      });
    }
  }

  const locationTxt = useSelector((state) => state.vendorAccount.location)

  const [hasMore, setHasMore] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const vendorDetails = useSelector((state) => state.vendorAccount.businessDetails)

  useEffect(() => {
    if (emailVerified === true && phoneNoVerified === true) {
      setVerifyOtp(true)
    } else {
      setVerifyOtp(false)
    }
  }, [emailVerified, phoneNoVerified])

  // for email verify otp --> New Code for Email verification
  useEffect(() => {
    if (OTPForEmail && OTPForEmail?.length === 6) {
      EmailOTPVerify(OTPForEmail, createAccountForm?.values)
    }
  }, [OTPForEmail])

  //for phone verify otp
  useEffect(() => {
    if (OTPForMobile && OTPForMobile?.length === 6) {
      PhoneOTPVerify(OTPForMobile, createAccountForm?.values, phoneNo)
    }
  }, [OTPForMobile])

  useEffect(() => {
    if (typecheck) {
      setUniqueid(getUserId(typecheck))
    }
  }, [typecheck])

  const toggleCheck = () => {
    setCheck(!check);
  }

  const handleChecking = (e) => {
    setCheck(e.target.checked)
  }

  // For showing errors while sending email for getting otp, used in the func *EmailOTP*
  const [emailErrorTexts, setEmailErrorTexts] = useState({
    code: "",
    error: false,
    message: "",
  })

  // This below three functionalities are only for snackbar during signup
  const [notificationSignup, setNotificationSignup] = useState({
    open: false,
    message: "",
    subText: "",
    alertType: "",
    borderClass: "",
  });
  const messageClose = () => {
    setNotificationSignup({
      open: false,
      message: "",
      subText: "",
      alertType: "",
      borderClass: "",
    });
  };
  const openMessageSignup = (alertType, message, subText, borderClass) => {
    if (alertType) {
      setNotificationSignup({
        open: true,
        message: message,
        subText: subText,
        alertType: alertType,
        borderClass: borderClass,
      });
      setTimeout(messageClose, 5000);
    }
  };


  // for sent otp of email
  const EmailOTP = async (item) => {
    let data = {
      url: `${SUB_ADMIN_API}/users/sent/otp`,
      bodyData: {
        email: item.email,
        // phoneNo: item.phoneNo
      }
    }
    let res = await doPostApiCall(data)
    if (!res?.error) {
      setShowOTP(true)
      setEmailErrorTexts({
        code: "",
        error: false,
        message: "",
      })
    } else {
      setAccountEmail(item?.email)
      setShowOTP(false)
      setEmailErrorTexts({
        code: res?.code,
        error: res?.error,
        message: res.message,
      })
    }
    // setAutoFillOtp(res.result.otp)
    // EmailOTPVerify(res.result.otp, item)
  }

  // For verifyotp for email
  const EmailOTPVerify = async (otp, formdata) => {
    let data = {
      url: `${SUB_ADMIN_API}/users/verify`,
      bodyData: {
        email: formdata.email,
        phoneNo: formdata.phoneNo,
        otp: otp,
      }
    }
    let res = await doPostApiCall(data)
    if (!res?.error) {
      setShowOTP(false)
      setShowEmailOtpButtonVendor(false)
      // setEmailVerified(true)
      setEmailErrorTexts({
        code: "",
        error: false,
        message: "",
      })
    } else if (res?.code === "NOT_VERIFIED") {
      setEmailErrorTexts({
        code: res?.code,
        error: res?.error,
        message: t("InvalidOtp"),
      })
      openMessageSignup("error", "Error", t("InvalidOtp"), "error")
    } else {
      console.error(res?.message)
    }
    setEmailVerified(res?.result?.emailVerified)
    setPhoneNoVerified(res?.result?.phoneNoVerified)
  }

  const getSubscriptionToken = async (userId, type) => {
    setLoading(true);
    return new Promise(async (resolve, reject) => {
      let token;
      if (typeof window !== 'undefined') {
        token = localStorage.getItem('token')
      }
      const reqstValues = {
        method: "POST",
        body: JSON.stringify({
          userId: userId,
          type: type,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token || "",
        },
      };
      fetch(`${SUB_ADMIN_API}/users/access/token`, reqstValues)
        .then((result) => {
          if (result.status === 401) {
            localStorage.clear()
          } else {
            return result.json();
          }
        })
        .then((result) => {
          if (result.token) {
            setLoading(false)
            resolve(result?.token);
          }
          else {
            openMessageSignup("error", "Error", result?.message, "error")
          }
        })
        .catch((err) => {
          setLoading(false);
          reject(err);
        });
    });

  }

  // For send otp for phone no 
  const PhoneOTP = async (item, phoneNo) => {
    let data = {
      url: `${SUB_ADMIN_API}/users/sent/otp`,
      bodyData: {
        email: item.email,
        phoneNo: phoneNo
      }
    }
    let res = await doPostApiCall(data)
    // setAutoFillOtp(res.result.otp)
    // PhoneOTPVerify(res.result.otp, item)
  }

  // For verify otp for phone no 
  const PhoneOTPVerify = async (otp, formdata, phoneNo) => {
    let data = {
      url: `${SUB_ADMIN_API}/users/verify`,
      bodyData: {
        email: formdata.email,
        phoneNo: phoneNo,
        otp: otp,
      }
    }
    try {
      let res = await doPostApiCall(data)

      if (!res?.error) {
        setShowOTPMobile(false)
        setShowPhoneOtpButtonVendor(false)
        // setEmailVerified(true)
        // setPhoneNoVerified(true)
      } else {
        openMessageSignup("error", "Error", res?.code === "NOT_VERIFIED" ? t("InvalidOtp") : t("Something went wrong"), "error")
      }
      setEmailVerified(res?.result?.emailVerified)
      setPhoneNoVerified(res?.result?.phoneNoVerified)
    } catch (err) {

      console.error("error in phone verification==>", err)
    }
  }

  const CreateAccountVendor = async (item) => {
    setLoading(true);
    let data = {
      url: `${SUB_ADMIN_API}/users`,
      bodyData: {
        email: item.email,
        password: item.password,
        name: item.name,
        type: item.typecheck,
        uniqueId: item.uniqueid
      }
    }
    await doPostApiCall(data)
      .then((res) => {
        if (!res.error) {
          localStorage.removeItem("subscriptionInfo")

          let subscriptionInfo = {
            name: item?.name.replace(/\s/g, ''),
            email: item?.email
          };
          localStorage.setItem("subscriptionInfo", JSON.stringify(subscriptionInfo));
          setShowAddAddress(true)
          setAccountEmail(res?.result?.email)
          openMessageSignup("success", "Success", t("vendorCreationSuccess"), "success")
        }
        else {
          setLoading(false);
          setShowAddAddress(false)
          openMessageSignup("error", "Error", t("vendorCreationUnsuccessful"), "error")
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  // For get all user account
  const GetAllVendorAccount = async () => {
    let data = {
      url: `${SUB_ADMIN_API}/users`,

    }
    let res = await doGetApiCall(data)
    // setAccountEmail(res.result[0].email)
  }

  const businessAddAddress = async (item, latlng) => {
    let subscriptionInfo = JSON.parse(localStorage.getItem("subscriptionInfo"));
    let data = {
      url: `${SUB_ADMIN_API}/business`,
      bodyData: {
        name: item.name,
        governorate: item.governorate,
        displayName: "No displayName",
        description: item.description,
        places: item.places,
        email: accountEmail ? accountEmail : localStorage?.getItem('email'),
        // location: location ? `Latitude: ${location?.lat}, Longitude: ${location?.lng}` : "",
        location: locationTxt && locationTxt?.length > 0 ? locationTxt : item.location,
        lat: latlng?.lat,
        long: latlng?.lng,
        address: item.fullAddress,
        street: item.street,
        block: item.block,
        buildingNo: item.buildingNo,
        floor: item.floor,
        officeNo: item.officeNo,
        startTime: moment(item?.startTime).format('LT'),
        shiftTimes: [{
          startTime: moment(item?.startshiftTime).format('LT'),
          endTime: moment(item?.endshiftTime).format('LT')
        }],
        endTime: moment(item.endTime).format('LT'),
        establishmentYear: moment(item.date).year().toString(),
        civilDocument: {
          name: documentUpload?.originalname,
          key: documentUpload?.key,
          mimetype: documentUpload?.mimetype,
          location: documentUpload?.location,
          size: documentUpload?.size
        },
        logoPicture: {
          name: logoUpload?.originalname,
          key: logoUpload?.key,
          mimetype: logoUpload?.mimetype,
          location: logoUpload?.location,
          size: logoUpload?.size
        },
        businessThumbnail: multipleUpload
      }
    }

    // return 
    await doPostApiCall(data)
      .then(res => {
        if (!res.error) {
          if (subscriptionInfo) {
            subscriptionInfo.email = accountEmail
            subscriptionInfo.userId = res?.result?.userId;
            subscriptionInfo.address = res?.result?.address.replace(/\s/g, '');
          }
          localStorage.setItem('subscriptionInfo', JSON.stringify(subscriptionInfo));
          openMessageSignup("success", "Success", t("businessCreationSuccess"), "success")
          setGotoApproval(true)  // <-- this set-state directs to ApprovalPandingPage
          dispatch(getLocationReducer(''))
          routeLocation?.pathname === '/vendor' && getBusiness()
          cleanupMedia()
        } else {

          setGotoApproval(false)
          switch (res.code) {
            case 'BUSINESS_NOT_FOUND':
              setLoading(false);
              openMessageSignup("error", "Error", res.message, "error");
              break;

            case 'MEDIA_RESERVE_ALREADY':
              setLoading(false);
              openMessageSignup("warning", "Warning", res.message, "warning");
              break;

            case 'BUSINESS_UPDATION_FAILED':
              setLoading(false);
              openMessageSignup("error", "Error", res.message, "error");
              break;

            case 'PERMISSIONS_DISABLED':
              setLoading(false);
              openMessageSignup("info", "Permissions Disabled", res.message, "info");
              break;

            case 'YOUR_PAYMENT_MISSING':
              setLoading(false);
              openMessageSignup("error", "Payment Missing", res.message, "error");
              break;

            case 'SUBSCRIPTION_EXPRIED':
              setLoading(false);
              openMessageSignup("warning", "Subscription Expired", res.message, "warning");
              break;

            case 'THUMBNAIL_LIMIT':
              setLoading(false);
              openMessageSignup("warning", "Thumbnail Limit", res.message, "warning");
              break;

            case 'VIDEO_LIMIT':
              setLoading(false);
              openMessageSignup("warning", "Video Limit", res.message, "warning");
              break;

            default:
              setLoading(false);
              openMessageSignup("error", "Error", "An unknown error occurred.", "error");
              break;
          }
        }
      }).catch(error => {
        console.error("Error occurred: ", error);
      })

    // getbusinessAddressDetails(res.result.userId)
  }

  /**
  *@uplSnehasish
  *@description- This function is used for making api call for getting all the business or vendors in the landing page
  **/
  const GetAllBusinessAddress = () => {
    setLoading(true)
    let data;
    if (sessionStorage.getItem('latitude') && sessionStorage.getItem('longitude')) {
      data = {
        url: `${SUB_ADMIN_API}/business/near/me/vendor/list?latitude=${sessionStorage.getItem('latitude')}&longitude=${sessionStorage.getItem('longitude')}&dataPerPage=20&page=${pageNumber}`,
      }
    } else {
      data = {
        url: `${SUB_ADMIN_API}/business?dataPerPage=20&page=${pageNumber}`,
      }
    }
    doGetApiCall(data).then((res) => {
      if (!res?.error) {
        setLoading(false)
        if (pageNumber > 1) {
          dispatch(getVendorBusinessDetails([...vendorDetails, ...res?.result]))
        } else {
          dispatch(getVendorBusinessDetails(res?.result))
        }
        if (res?.result?.length === 20) {
          setHasMore(true);
        }
        else {
          setHasMore(false)
        }
      } else {
        setLoading(false)
      }
    })
      .catch(err => {
        console.error(err, "error")
      })
  }
  const increasePageNumber = () => {
    setPageNumber(pageNumber + 1)
  }
  useEffect(() => {
    if (pageNumber > 1) {
      GetAllBusinessAddress()
    }
  }, [pageNumber])



  // use for later
  // const getbusinessAddressDetails = async (userID) => {
  //   let data = {
  //     url: `${SUB_ADMIN_API}/business/${userID}`,
  //   }
  //   let res = await doGetApiCall(data)
  // }

  const goToSubscription = () => {
    setDoneApproval(true)
  }

  const createAccountForm = useFormik({
    initialValues: {
      email: '',
      password: '',
      cpassword: '',
      name: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(t("enterValidMail"))
        .required(t("EnterYourName")),
      password: Yup.string().required(t("EnterYourPassword")),
      // .matches(/^(?=.*\d)(?=.*[A-Z]).{6,16}$/, "password should be at least 6 characters one UpperCase one Number"),
      cpassword: Yup.string()
        .required(t("confirmPass"))
        .oneOf(
          [Yup.ref("password"), null],
          t("PasswordMustMatch")
        ),
      name: Yup.string()
        .required(t("EnterYourName"))

    }),
    onSubmit: (values) => {
      const phoneRegex = /^\+?\d{10,14}$/;
      let data = {
        ...values,
        "phoneNo": phoneNo,
        "typecheck": typecheck,
        "uniqueid": uniqueid
      }
      if (phoneRegex.test(data.phoneNo) && data.phoneNo > 4) {
        CreateAccountVendor(data);
      } else {
        setPhoneNoError(true);
      }
    }
  })

  const handleClickForOTP = () => {
    // const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (regex.test(createAccountForm?.values?.email)) {
      EmailOTP(createAccountForm?.values)
      // setShowOTP(true)
    } else {
      // setShowOTP(false)
    }
  }

  const handleClickForOTPMobile = () => {
    setShowOTP(false);
    const regex = /^\+?\d{10,14}$/;
    if (regex.test(phoneNo)) {
      setShowOTPMobile(true)
      PhoneOTP(createAccountForm?.values, phoneNo)
    } else {
      setShowOTPMobile(false)
      setPhoneNoError(true)
    }
  }


  //this function is use  to change the color on Choose Bussinessbutton click

  const handleClickForChooseBussiness = () => {
    if (typecheck === "business") {
      setTypecheck('')
      setColorForBussiness(!colorForBussiness)
      setColorForIndivisual(false)
      setUniqueid('')
    } else {
      setColorForBussiness(!colorForBussiness)
      setColorForIndivisual(false)
      setTypecheck("business")
    }
  }

  //this function is use  to change the color on Choose IndividualButton click

  const handleClickForChooseIndivisual = () => {
    if (typecheck === "individual") {
      setColorForIndivisual(!colorForIndivisual)
      setColorForBussiness(false)
      setTypecheck("")
      setUniqueid('')
    } else {
      setColorForIndivisual(!colorForIndivisual)
      setColorForBussiness(false)
      setTypecheck("individual")
    }
  }

  const getLocationData = async (latitude, longitude) => {
    let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
    await fetch(url)
      .then((res) => { return res?.json() })
      .then((jsonData) => {
        setMapLocationTxt(jsonData?.results[0]?.formatted_address)
        dispatch(getLocationReducer(jsonData?.results[0]?.formatted_address))
      }).catch(error => {
        console.error(error);
      })
  };

  //phoneNumberChangeHandler function
  const phoneChangeHandler = (value) => {
    let valueWithSuffix = '+'.concat('', value)
    setPhoneNo(valueWithSuffix);
    if (value.length < 4) {
      setPhoneNoError(true)
    } else {
      setPhoneNoError(false);
    }
  }

  /**
    * @method GET
    * @author uplAyan
    * @description - This function is used for making api call for getting all Subscription Plans for user,
    **/
  const getSubscriptionPlans = async () => {
    setLoading(true)
    let data = {
      url: `${process.env.REACT_APP_DG_BASE_URL}/plan?dataPerPage=300`,
    }
    doGetApiCall(data).then((res) => {
      if (res?.status === 200) {
        dispatch(savePlanData(res?.result))
        setLoading(false)
      } else {
        openMessageSignup("error", "Error", res?.message, "error")
        setLoading(false)
      }
    }).catch(err => {
      console.error(err, "error")
    })
  }

  return {
    CreateAccountVendor,
    EmailOTP,
    otpValue,
    EmailOTPVerify,
    GetAllVendorAccount,
    emailVerified,
    phoneNoVerified,
    showAddAddress,
    businessAddAddress,
    gotoApproval,
    doneApproval,
    autoFillOtp,
    goToSubscription,
    GetAllBusinessAddress,
    // getbusinessAddressDetails
    emailErrorTexts,
    PhoneOTP,
    PhoneOTPVerify,
    handleClickForOTP,
    showOTP,
    showOTPMobile,
    handleClickForOTPMobile,
    createAccountForm,
    verifyotp,
    colorForBussiness,
    handleClickForChooseBussiness,
    colorForIndivisual,
    handleClickForChooseIndivisual,
    uniqueid,
    check,
    handleChecking,
    toggleCheck,
    OTPForEmail,
    setOTPForEmail,
    OTPForMobile,
    setOTPForMobile,
    typecheck,
    loading,
    messageClose,
    notificationSignup,
    increasePageNumber,
    hasMore,
    mapLocationTxt,
    setMapLocationTxt,
    mapLocation,
    handleClickLocation,
    getLocationData,
    locationTxt,
    phoneChangeHandler,
    phoneNo,
    phoneNoError,
    showEmailOtpButtonVendor,
    setShowEmailOtpButtonVendor,
    showPhoneOtpButtonVendor,
    setShowPhoneOtpButtonVendor,
    getSubscriptionPlans,
    getSubscriptionToken,
    setShowAddAddress,
    openMessageSignup
  }

}
