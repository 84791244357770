export const governorateArray = [
    {
        name: 'Al Farwaniya',
        value: 'Al Farwaniya',
        location: [
            { name: "Abdullah Al Mubarak", value: "Abdullah Al Mubarak" },
            { name: "Al Dajeej", value: "Al Dajeej" },
            { name: "Al Rai", value: "Al Rai" },
            { name: "Andalus", value: "Andalus" },
            { name: "Ardiya", value: "Ardiya" },
            { name: "Farwaniya", value: "Farwaniya" },
            { name: "Ferdous", value: "Ferdous" },
            { name: "Ishbiliya", value: "Ishbiliya" },
            { name: "Jaleeb Al Shuyoukh", value: "Jaleeb Al Shuyoukh" },
            { name: "Khaitan", value: "Khaitan" },
            { name: "Omariya", value: "Omariya" },
            { name: "Rabiya", value: "Rabiya" },
            { name: "Rehab", value: "Rehab" },
            { name: "Riggae", value: "Riggae" },
            { name: "Sabah Al Nasser", value: "Sabah Al Nasser" }
        ]
    },
    {
        name: 'Al Asima',
        value: 'Al Asima',
        location: [
            { name: "Abdullah Al Salem", value: "Abdullah Al Salem" },
            { name: "Adailiya", value: "Adailiya" },
            { name: "Bneid Al-Gar", value: "Bneid Al-Gar" },
            { name: "Dasma", value: "Dasma" },
            { name: "Dasman", value: "Dasman" },
            { name: "Doha", value: "Doha" },
            { name: "Failaka Island", value: "Failaka Island" },
            { name: "Faiha", value: "Faiha" },
            { name: "Ghornata", value: "Ghornata" },
            { name: "Jaber Al-Ahmed", value: "Jaber Al-Ahmed" },
            { name: "Kaifan", value: "Kaifan" },
            { name: "Khaldiya", value: "Khaldiya" },
            { name: "Mansouriya", value: "Mansouriya" },
            { name: "Mirqab", value: "Mirqab" },
            { name: "Nuzha", value: "Nuzha" },
            { name: "Qadsiya", value: "Qadsiya" },
            { name: "Qibla", value: "Qibla" },
            { name: "Qortuba", value: "Qortuba" },
            { name: "Rawda", value: "Rawda" },
            { name: "Shalehat Doha", value: "Shalehat Doha" },
            { name: "Sharq", value: "Sharq" },
            { name: "Shamiya", value: "Shamiya" },
            { name: "Shuwaikh", value: "Shuwaikh" },
            { name: "Shuwaikh Port", value: "Shuwaikh Port" },
            { name: "Sulaibikhat", value: "Sulaibikhat" },
            { name: "Surra", value: "Surra" },
            { name: "West Sulaibikhat", value: "West Sulaibikhat" },
            { name: "Yarmouk", value: "Yarmouk" }
        ]
    },
    {
        name: 'Hawally',
        value: 'Hawally',
        location: [
            { name: 'Hawally', value: 'Hawally' },
            { name: "Al Bida", value: "Al Bida" },
            { name: "Al Siddiq", value: "Al Siddiq" },
            { name: "Anjafa", value: "Anjafa" },
            { name: "Bayan", value: "Bayan" },
            { name: "Hateen", value: "Hateen" },
            { name: "Hawalli", value: "Hawalli" },
            { name: "Jabriya", value: "Jabriya" },
            { name: "Mishrif", value: "Mishrif" },
            { name: "Mubarak Al Abdullah", value: "Mubarak Al Abdullah" },
            { name: "Mubarakiya", value: "Mubarakiya" },
            { name: "Rumaithiya", value: "Rumaithiya" },
            { name: "Salmiya", value: "Salmiya" },
            { name: "Salwa", value: "Salwa" },
            { name: "Shaab", value: "Shaab" },
            { name: "Shuhada", value: "Shuhada" },
            { name: "Salam", value: "Salam" },
            { name: "Zahra", value: "Zahra" }


        ]
    },
    {
        name: 'Mubarak Al Kabeer',
        value: 'Mubarak Al Kabeer',
        location: [
            { name: "Abu Fteira", value: "Abu Fteira" },
            { name: "Abu Hassaniah", value: "Abu Hassaniah" },
            { name: "Al Adan", value: "Al Adan" },
            { name: "Al Fnaitees", value: "Al Fnaitees" },
            { name: "Al Masayel", value: "Al Masayel" },
            { name: "Al Mesiela", value: "Al Mesiela" },
            { name: "Al Qurain", value: "Al Qurain" },
            { name: "Al Qusour", value: "Al Qusour" },
            { name: 'Kuwait City', value: 'Kuwait City' },
            { name: 'Qurain', value: 'Qurain' },
            { name: "Sabah Al Salem", value: "Sabah Al Salem" },
            { name: "Subhan Industrial", value: "Subhan Industrial" },
            { name: "West Abu Fteira", value: "West Abu Fteira" }
        ]
    },
    {
        name: 'Ahmadi',
        value: 'Ahmadi',
        location: [
            { name: "Abu Halifa", value: "Abu Halifa" },
            { name: "Ahmadi", value: "Ahmadi" },
            { name: "Ali Sabah Al Salim", value: "Ali Sabah Al Salim" },
            { name: "Dhaher", value: "Dhaher" },
            { name: "Egaila", value: "Egaila" },
            { name: "Fahad Al Ahmad", value: "Fahad Al Ahmad" },
            { name: "Fahaheel", value: "Fahaheel" },
            { name: "Khairan", value: "Khairan" },
            { name: "Hadiya", value: "Hadiya" },
            { name: "Jaber Al Ali", value: "Jaber Al Ali" },
            { name: "Mahboula", value: "Mahboula" },
            { name: "Mangaf", value: "Mangaf" },
            { name: "Riqqa", value: "Riqqa" },
            { name: "Sabah Al Ahmad", value: "Sabah Al Ahmad" },
            { name: "Sabahiya", value: "Sabahiya" },
            { name: "Wafra", value: "Wafra" },
            { name: "Zoor", value: "Zoor" },
            { name: "Al Nuwaiseeb", value: "Al Nuwaiseeb" }
        ]
    },
    {
        name: 'Al Jahra', value: 'Al Jahra',
        location: [
            { name: "Abdally", value: "Abdally" },
            { name: "Amghara", value: "Amghara" },
            { name: "Jahra", value: "Jahra" },
            { name: "Naseem", value: "Naseem" },
            { name: "Nahda", value: "Nahda" },
            { name: "Oyoun", value: "Oyoun" },
            { name: "Qairawan", value: "Qairawan" },
            { name: "Qasr", value: "Qasr" },
            { name: "Salmy", value: "Salmy" },
            { name: "Sulaibiya", value: "Sulaibiya" },
            { name: "Subiya", value: "Subiya" },
            { name: "Taima", value: "Taima" },
            { name: "Al Mutlaa", value: "Al Mutlaa" }
        ]
    },
]
