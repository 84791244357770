import React, { useState } from 'react'
import OTPInput, { ResendOTP } from "otp-input-react";

const OTPFieldInput = (props) => {

    const [timeLeft, setTimeLeft] = useState(120); // Initial time in seconds
    React.useEffect(() => {
      if (timeLeft === 0) {
        // Time is up, handle the expiration logic here
      } else {
        const timer = setInterval(() => {
          setTimeLeft((prevTime) => {
            if (prevTime === 0) {
              clearInterval(timer);
              // Handle timer expiration here
            }
            return prevTime - 1;
          });
        }, 1000);
        return () => clearInterval(timer);
      }
    }, [timeLeft]);
  
    // Custom renderTime function to display minutes and seconds
    const renderTime = () => {
      const minutes = Math.floor(timeLeft / 60);
      const seconds = timeLeft % 60;
  
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  
      return `Please wait for ${formattedMinutes} min : ${formattedSeconds} s`;
    };

    return (
        <div>
            <p className={`font-[Roboto-Regular] text-Grey-900 text-base mb-1 mt-5`}>{props.title}</p>

            <OTPInput
                value={props.value}
                onChange={props.onChange}
                placeholder={props.placeholder}
                OTPLength={props.OTPLength}
                otpType={props.otpType}
                disabled={false}
                resendOTP={{}}
                autoFocus
                inputStyles={{
                    background: "#FFFFFF",
                    border: "1px solid #DEDEDE",
                    borderRadius: "4px"
                }}
            // secure
            />
      {props.showVerifyEmailDetails?.tick === true ? null : <div className={`flex mt-2 gap-2`}>
        <p className={`text-deepgray-4 font-[Roboto-Regular] text-xs`}>Didn't receive OTP yet? </p>
        <ResendOTP
          maxTime="120"
          onResendClick={props.onResendClick}
          renderTime={() => React.Fragment}
          renderButton={(buttonProps) => {
            console.log(buttonProps, "# buttonprops")
            return (
              <button {...buttonProps} className={`text-Active-100 text-xs`}>
                {buttonProps.remainingTime !== 0 ? renderTime() : "Resend OTP"}
              </button>
            );
          }}
        />
      </div>}
        </div>
    )
}

export default OTPFieldInput