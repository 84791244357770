import React from 'react'
import DigidialsLogoBlue from "../../../Images/CommonImages/DigidialsLogoBlue.svg"
import StepperField from '../../UiComponents/Stepper'
import Bluetick from '../../../Images/accountcreate/Bluetick.svg'
import emtyProgress from '../../../Images/accountcreate/emtyProgress.svg'
import fullProgress from '../../../Images/accountcreate/fullProgress.svg'
import halfProgress from '../../../Images/accountcreate/halfProgress.svg'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import stepperStyles from './StepperLayout.module.css'

const StepperLayout = (props) => {

    const navigate = useNavigate()
    const { t, i18n } = useTranslation()

    return (

        <div className={stepperStyles.stepperWrapper}>
            <div className={`flex flex-col mt-[12%] sm:mt-[20%] lg:mt-[15%] pl-0 sm:pl-[5%]`}>
                <img onClick={() => navigate('/')} src={DigidialsLogoBlue} alt='blue logo' className={`pb-8 lg:pb-12 cursor-pointer w-full sm:w-[60%] px-[20%] sm:px-[5%]`} />

                <div className='w-full sm:flex flex-row sm:flex-col hidden'>
                    {/* 1 */}
                    <div className={`flex items-center`}>
                        <img src={Bluetick} alt='Bluetick' className={``} />
                        <p className={`w-[75%] ${i18n.dir() === 'rtl' ? 'pr-3' : 'pl-3'}`}>{t("personalInfo")}</p>
                    </div>
                    <div className={`mx-[17px]`}>
                        <img src={props.doneSignup ? fullProgress : halfProgress} alt='Progress' className={`w-0.5 h-10`} />
                    </div>

                    {/* 2 */}
                    <div className={`flex items-center`}>
                        {props.doneSignup || props.doneApproval ? <img src={Bluetick} alt='Bluetick' className={``} />
                            : <div className={`text-base text-black-2 font-normal font-[Roboto-Regular] w-9 h-9 min-w-9 min-h-9 border border-solid border-black rounded-full flex items-center justify-center`}>2</div>}
                        <p className={` w-[75%] ${i18n.dir() === 'rtl' ? 'pr-3' : 'pl-3'}`}>{t("businessInfo")}</p>
                    </div>
                    <div className={`mx-[17px]`}>
                        <img src={props.doneSignup || props.doneApproval ? halfProgress : emtyProgress} alt='Progress' className={`w-0.5 h-10`} />
                    </div>

                    {/* 3 */}
                    <div className={`flex items-center`}>
                        <div className={`text-base text-black-2 font-normal font-[Roboto-Regular] w-9 h-9 border border-solid border-black rounded-full flex items-center justify-center`}>3</div>
                        <p className={`w-[75%] ${i18n.dir() === 'rtl' ? 'pr-3' : 'pl-3'}`}>{t("onboardProcess")}</p>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default StepperLayout