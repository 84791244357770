import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { adminSideMenu } from './sideBarData'
import "./index.css"
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash'
 
const AdminSideBar = () => {
  const location = useLocation();
 
  const role = localStorage.getItem('role')
  const subAdminPermissionArr = JSON.parse(localStorage.getItem('sections'))
  const navigate = useNavigate()
 
  const signedInUserData = useSelector((state) => state.totalUsers.selectedUserDetails)
  // console.log(signedInUserData?.sections,subAdminPermissionArr, "signedInUserData $$")
 
  useEffect(() => {
    if ( signedInUserData?.role === "subAdmin" && _.isEqual(subAdminPermissionArr, signedInUserData?.sections)) {
      return;
    }
 
    if ( signedInUserData?.role === "subAdmin"  && Array.isArray(signedInUserData.sections) &&
      signedInUserData.sections.length > 0
    ) {
      localStorage.setItem("sections", JSON.stringify(signedInUserData.sections));
      for (const item of adminSideMenu || []) {
        const firstPermittedItem = signedInUserData?.sections?.find(
          (filMenu) => filMenu?.name === item?.name
        );
        if (firstPermittedItem) {
          navigate(item?.link);
          break;
        }
      }
    }
  }, [signedInUserData, subAdminPermissionArr, adminSideMenu, navigate]);
 
 
  const filteredSideMenu = adminSideMenu?.filter((itemMenu) => subAdminPermissionArr?.some((filMenu) => filMenu?.name === itemMenu?.name))
 
 
 
  const activeStyle = {
    backgroundColor: "#FFE3D4",
    fontFamily: 'Roboto-Regular',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '15px',
    margin: '0px',
    padding: '0px',
    paddingRight: '0px',
    color: '#FE5B00',
    borderRadius: '5px 5px 0px 0px',
    // filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(0deg)'
  };
 
  const style = {
    color: '#585F71',
    fontFamily: 'Roboto-Regular',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '15px',
  }
 
  return (
    <Box className={`sideMenuMain !w-[15%] !h-[90vh] overflow-auto fixed top-[12%] drop-shadow-md bg-white`}>
      <Box className="overflow-auto">
      {role === 'admin' && adminSideMenu && adminSideMenu?.length > 0 ?
        adminSideMenu?.map((item, index) => {
          return (
            <nav key={index}>
              <List className={`navItems`} sx={{ marginBottom: "0.5rem", marginTop: "0.5rem", paddingTop: 0, paddingBottom: "2px" }}>
                <ListItem disablePadding >
                  <NavLink
                    to={item?.link}
                    style={
                      ({ isActive }) => isActive ? activeStyle : style
                    }
                    className={`w-full`}
                  >
                    <ListItemButton className='py-4 flex-col xl:flex-row gap-2'>
                      <ListItemIcon >
                        <img src={location?.pathname === item?.link ? item?.colorIcon : item?.icon} alt="menu" className="menuImg" />
                      </ListItemIcon>
                      <p className={`menuTxt capitalize`}>{item?.name}</p>
                    </ListItemButton>
                  </NavLink>
                </ListItem>
              </List>
            </nav>
          )
        })
        :
        role === 'subAdmin' && filteredSideMenu?.length > 0 &&
        filteredSideMenu?.map((item, index) => {
          return (
            <nav key={index}>
              <List className={`navItems `} sx={{ marginBottom: "0.5rem", marginTop: "0.5rem", paddingTop: 0, paddingBottom: "2px" }}>
                <ListItem disablePadding >
                  <NavLink
                    to={item?.link}
                    style={
                      ({ isActive }) => isActive ? activeStyle : style
                    }
                    className={`w-full`}
                  >
                    <ListItemButton className='py-4'>
                      <ListItemIcon>
                        <img src={location?.pathname === item?.link ? item?.colorIcon : item?.icon} alt="menu" className="menuImg" />
                      </ListItemIcon>
                      <p className={`menuTxt capitalize`}>{item?.name}</p>
                    </ListItemButton>
                  </NavLink>
                </ListItem>
              </List>
            </nav>
          )
        })
      }
      </Box>
    </Box>
  )
}
 
export default AdminSideBar