import React, { useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form"
import TextFieldInput from '../../../../Common/FormFields/TextFieldInput'
import text from '../../../../Common/Languages/en_US.json'
import fileUploadIcon from '../../../../Images/SubAdmin/fileUploadIcon.svg'
import { InputAdornment } from '@mui/material'
import backArrow from '../../../../Images/CommonImages/backArrow.svg'
import rightArrow from '../../../../Images/CommonImages/rightArrow.svg'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import pdficon from '../../../../Images/CommonImages/pdficon.svg'
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment'
import { ForUploadMediaHooks } from '../../../../Containers/UploadMedia/Hooks'
import { setUploadMultipleImg } from '../../../../Containers/UploadMedia/reducer/UploadMediaReducer'
import MuiDatePicker from '../../../../Common/FormFields/MuiDatePicker'
import MuiTimePicker from '../../../../Common/FormFields/MuiTimePicker'
import MultipleSelect from '../../../../Common/FormFields/MultipleSelect'
import RadioButton from '../../../../Common/UiComponents/RadioButton'
import UploadButton from '../../../../Common/UiComponents/UploadButton'
import CheckBox from '../../../../Common/FormFields/CheckBox'
import ButtonField from '../../../../Common/FormFields/ButtonField'
import UploadMultipleImage from '../../../../Common/Layout/UploadMultipleImage/UploadMultipleImage'



const MyReqFormForVendor = (props) => {
    const { cleanupMedia, multipleUpload } = ForUploadMediaHooks()
    const { t } = useTranslation()
    const multipleUploadState = [...multipleUpload]
    const dispatch = useDispatch()

    const categoryData = useSelector((state) => state.totalCategoryInAdmin.totalCategories)
    const loading = useSelector((state) => state.upload.loading)

    const [checked, setChecked] = useState(false)
    const [categoryVal, setCategoryVal] = useState()
    const [subCategoryVal, setSubCategoryVal] = useState()

    // form handling using react-hook-form 
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        watch
    } = useForm();

    const onSubmit = (data) => {
        props.myRequirementCreateUser(data)
        props?.reqError?.error === false && reset({
            title: '',
            startDate: null,
            endDate: null,
            startTime: null,
            endTime: null,
            type: '',
            selectCategory: '',
            selectSubCategory: '',
            description: ''
        })
        cleanupMedia()
    };

    const selectCategoryArr = categoryData?.map((item) => {
        return { name: item?.name, value: item?.id }
    })

    const subCategoryFilter = categoryData?.filter((item) => item?.id === categoryVal)


    const selectSubCategoryArr = subCategoryFilter && subCategoryFilter?.length > 0 &&
        subCategoryFilter[0]?.subCategory?.map((item) => {
            return { name: item?.name, value: item?.id }
        })

    const selectSubCategoryArrConverted = Array.from(selectSubCategoryArr);

    const removeParticularThumbnail = () => {
        let arr;
        arr = [...multipleUpload]
        arr.splice(0, 1);
        dispatch(setUploadMultipleImg(arr))
    }

    const RadioLabel = [
        {
            value: "individual",
            label: "Individual"
        },
        {
            value: "business",
            label: "Business"
        },
        {
            value: "all",
            label: "All"
        },
    ]

    return (
        <>
            <div onClick={props.toggleUserMyRequirementForm} className={`pt-[1%] pb-[1%] font-[Roboto-Bold] text-base cursor-pointer w-fit`}>
                <p> {"<"} {t("back")}</p>
            </div>
            <div className={`bg-white`}>
                <p className={`p-4`}>{t("postRequirements")}</p>
                <div className={`border border-b`}></div>
                {/* Form  */}
                <div className={`p-10 `}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={` flex flex-col lg:flex-row justify-between gap-[5%]`}>
                            {/* Right part  */}
                            <div className='flex flex-col lg:w-[65%] gap-5 textFieldText'>
                                <div>
                                    <Controller name={"title"}
                                        control={control}
                                        render={({ field: { onChange, value }, formState: { error } }) => {
                                            return (
                                                (
                                                    <TextFieldInput
                                                        onlyValue
                                                        textnewclass={`w-full text-sm `}
                                                        floatingLabel="*enterTitle"
                                                        onChange={onChange}
                                                        value={(value)}
                                                    />
                                                )
                                            )
                                        }}
                                        rules={{
                                            required: true, pattern: /^[a-zA-Z][a-zA-Z ]*/i, minLength: 2, maxLength: 30
                                        }}
                                    />
                                    {/* title error msg */}
                                    {errors.title && errors.title.type === "required" && (
                                        <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                    )}
                                    {errors.title && errors.title.type === "minLength" && (
                                        <span className="error-message text-red-400 text-xs">Too short</span>
                                    )}
                                    {errors.title && errors.title.type === "maxLength" && (
                                        <span className="error-message text-red-400 text-xs">Too Long</span>
                                    )}
                                    {errors.title && errors.title.type === "pattern" && (
                                        <span className="error-message text-red-400 text-xs">Not valid</span>
                                    )}
                                </div>

                                {/* Date range picker */}
                                <div className={`grid grid-cols-2 bg-white w-full gap-[5%]`}>
                                    <div className='flex items-center cursor-pointer text-[#7B7B7B] gap-4 '>
                                        <div className={`flex flex-col w-full`}>
                                            <Controller name={"startDate"}
                                                control={control}
                                                defaultValue={new Date()}
                                                render={({ field: { onChange, value }, formState: { error } }) => {
                                                    return (
                                                        (
                                                            <MuiDatePicker
                                                                label='*selectStartDate'
                                                                value={value}
                                                                format={'dd-MM-yyyy'}
                                                                minDate={new Date()}
                                                                onChange={(newStartDate) => {
                                                                    onChange(newStartDate)
                                                                }}
                                                            />
                                                        )
                                                    )

                                                }}
                                                rules={{ required: true }}
                                            />
                                            {errors.startDate && errors.startDate.type === "required" && (
                                                <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                            )}
                                        </div>
                                    </div>
                                    {/* <div className='flex items-center text-deepgray-4 font-[Roboto-Regular] text-sm'>{text.to}</div> */}
                                    <div className='flex items-center cursor-pointer text-[#7B7B7B] gap-4 '>
                                        <div className={`flex flex-col w-full`}>
                                            <Controller name={"endDate"}
                                                defaultValue={null}
                                                control={control}
                                                render={({ field: { onChange, value }, formState: { error } }) => {
                                                    const startDate = watch("startDate");
                                                    const startTime = watch("startTime");
                                                    const endTime = watch("endTime");
                                                    const isError = startDate && value && new Date(value) < new Date(startDate);
                                                    const isTimeError = startDate && value && new Date(value).getTime() === new Date(startDate).getTime() && startTime && endTime && startTime > endTime;

                                                    return (
                                                        <>
                                                            <MuiDatePicker
                                                                label='*selectEndDate'
                                                                value={value}
                                                                format={'dd-MM-yyyy'}
                                                                minDate={startDate || new Date()}
                                                                onChange={(newEndDate) => {
                                                                    onChange(newEndDate)
                                                                }}
                                                            />
                                                            {isError && (
                                                                <span className="error-message text-red-400 text-xs">
                                                                    End date must be after the start date.
                                                                </span>
                                                            )}

                                                            {!isError && isTimeError && (
                                                                <span className="error-message text-red-400 text-xs">
                                                                    End date must be after start date.
                                                                </span>
                                                            )}
                                                        </>

                                                    )

                                                }}
                                                rules={{ required: true }}
                                            />
                                            {errors.endDate && errors.endDate.type === "required" && (
                                                <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className={`grid grid-cols-2 bg-white w-full gap-[5%]`}>
                                    <div className={`w-full my-3 timePickerCls`}>
                                        <Controller name={"startTime"}
                                            control={control}
                                            defaultValue={new Date()}
                                            render={({ field: { onChange, value }, formState: { error } }) => {
                                                return (
                                                    (<MuiTimePicker
                                                        label={"*Select Open Time"}
                                                        onChange={onChange}
                                                        value={value}
                                                    />)
                                                )
                                            }}
                                            rules={{ required: true }}
                                        />
                                        {errors.startTime && errors.startTime.type === "required" && (
                                            <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                        )}
                                    </div>

                                    <div className={`w-full my-3 timePickerCls`}>
                                        <Controller name={"endTime"}
                                            control={control}
                                            defaultValue={null}
                                            render={({ field: { onChange, value }, formState: { error } }) => {
                                                return (
                                                    (<MuiTimePicker
                                                        label={"*Select Close Time"}
                                                        onChange={onChange}
                                                        value={value}
                                                    />)
                                                )
                                            }}
                                            rules={{ required: true }}
                                        />
                                        {errors.endTime && errors.endTime.type === "required" && (
                                            <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                        )}
                                    </div>
                                </div>


                                {/* DropDowns  */}
                                <div className={`grid grid-cols-2 gap-[5%] w-full dropdownText`}>
                                    {/* Select category controller */}
                                    <div className='flex flex-col'>
                                        <Controller name={"selectCategory"}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => {
                                                return (
                                                    (
                                                        <MultipleSelect
                                                            autocompleteExtraCls={` text-sm text-gray-60 font-[Roboto-Regular]`}
                                                            multiple={false}
                                                            value={value ? value : categoryVal}
                                                            onChange={(e, v) => { setCategoryVal(v.value); onChange(v.value) }}
                                                            label="*selectCateg"
                                                            isOptionEqualToValue={(option, value) => { return option?.name === value?.name; }}
                                                            // autocompleteExtraCls={`selectLocationCls`}
                                                            autocompleteArray={selectCategoryArr}
                                                        />
                                                    )
                                                )
                                            }}
                                            rules={{ required: true }}
                                        />
                                        {/* Select category section error msg  */}
                                        {errors.selectCategory && errors.selectCategory.type === "required" && (
                                            <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                        )}
                                    </div>

                                    {/* Select sub-category controller  */}
                                    <div className='flex flex-col'>
                                        <Controller
                                            name={"selectSubCategory"}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => {
                                                return (
                                                    (
                                                        <MultipleSelect
                                                            autocompleteExtraCls={` text-sm text-gray-60 font-[Roboto-Regular]`}
                                                            // multiple={false}
                                                            value={value ? value : subCategoryVal}
                                                            onChange={(e, v) => { setSubCategoryVal(v.value); onChange(v.value) }}
                                                            // onChange={onChange}
                                                            label="*selectSubCateg"
                                                            isOptionEqualToValue={(option, value) => { return option?.name === value?.name; }}
                                                            autocompleteArray={selectSubCategoryArrConverted}
                                                        />
                                                    )
                                                )
                                            }}
                                            rules={{ required: true }}
                                        />
                                        {/* Select sub-category error msg  */}
                                        {errors.selectSubCategory && errors.selectSubCategory.type === "required" && (
                                            <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                        )}
                                    </div>
                                </div>

                                <p>Notify this requirement</p>
                                <div className={`pl-[2%] flex items-center`}>
                                    <Controller name={'type'}
                                        control={control}
                                        rules={{
                                            required: true
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <RadioButton
                                                name='type'
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e.target.value);
                                                    // setRadioValue(e.target.value)
                                                }}
                                                Radiolabel={RadioLabel}
                                            />
                                        )}
                                    />
                                    {errors.type && errors.type.type === "required" && (
                                        <span className="error-message text-red-400 text-xs">Required</span>
                                    )}
                                </div>

                                {/* description controller  */}
                                <div className={`mb-6`}>
                                    <Controller name={"description"}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => {
                                            return (
                                                (
                                                    <TextFieldInput
                                                        multiline={true}
                                                        minRows={4}
                                                        maxRows={4}
                                                        onlyValue
                                                        textnewclass={`w-full text-sm`}
                                                        floatingLabel='description'
                                                        onChange={onChange}
                                                        value={(value)}
                                                        inputProps={{ maxLength: 500 }}
                                                    />
                                                )
                                            )
                                        }}
                                        rules={{ required: true, minLength: 3, maxLength: 100 }}
                                    />
                                    {errors.description && errors.description.type === "maxLength" && (
                                        <span className="error-message text-red-400 text-xs">Not more than 100 words</span>
                                    )}
                                    {errors.description && errors.description.type === "minLength" && (
                                        <span className="error-message text-red-400 text-xs">Too short</span>
                                    )}
                                    {errors.description && errors.description.type === "required" && (
                                        <span className="error-message text-red-400 text-xs">Required</span>
                                    )}
                                </div>
                            </div>


                            {/* Right part  */}
                            <div className='lg:w-[45%] w-full'>
                                <UploadMultipleImage
                                    sendData={(data) => {
                                        if (
                                            data[0]?.type === 'image/png' ||
                                            data[0]?.type === 'image/jpeg' ||
                                            data[0]?.type === 'application/pdf'
                                        ) {
                                            // Handle file upload (e.g., update state or call API)
                                            props.multipleMediaApiCall(data);
                                        }
                                    }}
                                    disabled={multipleUploadState && multipleUploadState?.length === 4}
                                    text1="selectFilePDF"
                                    text2="dragAndDropItHereText"
                                    text3={loading === true ? 'uploading' : "uploadImg"}
                                    multiple={true}
                                    fileLimit={4}
                                    accept={{
                                        "application/pdf": [".pdf"],
                                        "image/jpeg": [".jpeg", ".jpg"],
                                        "image/png": [".png"],
                                    }}
                                />
                                <div className={`flex gap-6 pt-4 w-full relative`}>
                                    {multipleUploadState && (multipleUploadState?.length > 0 && multipleUploadState?.length <= 4) ? (
                                        multipleUploadState?.map((file, idx) => (
                                            <div key={idx} className={`flex w-fit justify-between`}>
                                                {file?.mimetype === 'application/pdf' ? (
                                                    <div className="flex flex-col items-center">
                                                        <img
                                                            src={pdficon}
                                                            alt="PDF file"
                                                            className="w-20 h-20"
                                                        />
                                                    </div>
                                                ) : (
                                                    <img
                                                        crossOrigin="anonymous"
                                                        src={file.key && `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${file.key}&height=75&width=100`}
                                                        alt="thumbnail"
                                                    />
                                                )}
                                                <div className={`cursor-pointer`}>
                                                    <CloseIcon
                                                        onClick={() => removeParticularThumbnail(idx)}
                                                        sx={{
                                                            fontSize: "12px",
                                                            position: 'absolute',
                                                            top: '0px',
                                                            border: '1px solid red',
                                                            borderRadius: '9999px',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className={`flex w-full gap-2`}>
                                            <img src={fileUploadIcon} alt="file upload icon" className='w-[23%]' />
                                            <img src={fileUploadIcon} alt="file upload icon" className='w-[23%]' />
                                            <img src={fileUploadIcon} alt="file upload icon" className='w-[23%]' />
                                            <img src={fileUploadIcon} alt="file upload icon" className='w-[23%]' />
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* <div className={`flex gap-2 mb-4 lg:w-[35%] lg:pb-0 pb-4 w-full`}>
                                <div>
                                    <p className={`text-Grey-900 font-[Roboto-Medium] font-normal text-base `}>{t("uploadPDFtext")}</p>
                                    <p className={`text-Grey-900 font-[Roboto-Regular] font-normal text-sm `}>{t("dragAndDropText")}<span> {t("browse")}</span><br />{t("yourFiles")}</p>
                                </div>
                                <div className={`relative`}>
                                    <UploadButton
                                        extracls='flex justify-center items-center bg-[#ffffff] items-center rounded-md '
                                        sendData={(data) => {
                                            if (data[0]?.type === 'application/pdf') {
                                                // setThumbnailMulti([...thumbnailMulti, data[0]])
                                                props?.multipleMediaApiCall(data);
                                            }
                                        }}
                                        multiple={false}
                                        texExtraClsRoot={`flex items-center text-sm font-normal font-[PlainRegular] w-full`}
                                        textHead={`*Upload PDF attachments`}
                                        texExtraCls={`text-sm text-start font-[Roboto-Medium]`}
                                        texExtraCls1={`!text-xs text-deepgrey-3 font-[Roboto-Regular]`}
                                        text1={'Drag and drop or'}
                                        texExtraCls2={`!text-xs text-skyblue-6 font-[Roboto-Regular] underline`}
                                        text2={`browse \n`}
                                        text3={`your files`}
                                        texExtraCls3={`!text-xs text-deepgrey-3 font-[Roboto-Regular]`}
                                        type='button'
                                        fileUploadIcon={
                                            multipleUploadState[0]?.mimetype === 'application/pdf' ?
                                                pdficon :
                                                fileUploadIcon
                                        }
                                        accept={{"application/pdf": [".pdf"]}}
                                        disabled={multipleUploadState && multipleUploadState?.length === 1 && multipleUploadState[0]?.mimetype === 'application/pdf' ? true : false}
                                        fileName={multipleUploadState[0]?.mimetype === 'application/pdf' ? multipleUploadState[0]?.name : null}
                                        fileSize={multipleUploadState[0]?.mimetype === 'application/pdf' ? multipleUploadState[0]?.size : null}
                                    />
                                    {multipleUploadState && multipleUploadState?.length === 1 &&
                                        <div className={`cursor-pointer `}>
                                            <CloseIcon
                                                onClick={() => removeParticularThumbnail()}
                                                sx={{ fontSize: "12px", position: 'absolute', top: '0px', left: '75px', border: '1px solid red', borderRadius: '9999px' }}
                                            />
                                        </div>
                                    }
                                </div>
                            </div> */}
                        </div>



                        <div className={`flex justify-start items-center mt-2`}>
                            <CheckBox
                                sx={{ '& .css-b29amo-MuiButtonBase-root-MuiCheckbox-root': { padding: 0 } }}
                                required={true}
                                size={`small`}
                                checked={props.value}
                                handleBoxChange={(e) => setChecked(e?.target?.checked)
                                }
                            />
                            <p className={`text-xs`}>{t("iAgreeToThe")}</p>&nbsp;
                            <p className={`text-xs text-skyblue-6`}>{t("termsAndConditions")}</p>
                        </div>


                        <div className={`flex justify-start mt-6`}>
                            <div className='flex gap-6'>
                                <ButtonField
                                    buttonName="cancel"
                                    buttonInsidecls={`gap-2`}
                                    img={backArrow}
                                    alt='backArrow'
                                    type='reset'
                                    buttonextracls={` !px-6 !py-2 !text-deepgrey-3 bg-Gray-5 font-[Roboto-Medium] hover:bg-deepBlue hover:text-white`}
                                    onClick={() => { props.toggleUserMyRequirementForm(); cleanupMedia() }}
                                />
                                <ButtonField
                                    buttonName='post'
                                    img={rightArrow}
                                    alt='rightArrow'
                                    type='submit'
                                    loading={props.loading}
                                    buttonInsidecls={`!flex-row-reverse gap-2 `}
                                    buttonextracls={`${checked ? '!text-white bg-skyblue-6 hover:bg-deepBlue' : 'bg-Gray-5 !text-deepgrey-3'} !px-6 !py-2 `}
                                    onClick={checked ? handleSubmit(onSubmit) : null}
                                    disabled={props.loading === true ? true : false}
                                />
                            </div>
                        </div>
                        {props.reqError?.error === true && <p className={`text-red-5 text-sm text-center font-[Roboto-Regular]`}>{props.reqError?.message}</p>}
                    </form>
                </div>
            </div>
        </>

    )
}

export default MyReqFormForVendor