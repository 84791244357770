import * as React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ButtonField from '../FormFields/ButtonField';
import { useTranslation } from 'react-i18next';
// import { isAccess } from "../../Utils/isAccess"
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom'

export default function TabComponent(props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handleChange = (event, newValue) => {
    props.setValue(newValue);
  };

// console.log(props.subscriptionData, "subscriptionData @@")

  return (
    <div className={`!bg-white ${props?.faqTab ? props?.faqTab : 'tabcomponent'} `}>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={props?.value}>
          <Box sx={{ borderBottom: 4, borderColor: 'divider', position: "relative", display: "flex", justifyContent: "space-between" }}>
            <div className={`overflow-x-auto`}>
            <TabList sx={{
              minWidth: "700px"
            }} onChange={handleChange} aria-label="lab API tabs example">
              {props?.mappingname?.map((item, idx) =>
                <Tab label={t(item?.label)} value={item?.value} className='text-[13px] sm:text-[16px] p-2 min-w-[60px] sm:min-w-[90px] '/>
              )}
            </TabList>
            </div>
           
            {props?.button ? (<>
              <Tooltip title={props?.subscriptionValid ? props?.buttonSubsMessage : props?.buttonTooltip}>
                <ButtonField
                  buttonName={t(props?.buttonname)}
                  onClick={props?.onClick}
                  buttonextracls={props?.buttonextracls}
                  buttonnamecls={props?.buttonnamecls}
                  outerBtnCls="ml-[4%]"
                  type={props?.type}
                  disabled={props?.subscriptionValid}
                />
              </Tooltip>
            </>
            )
              :
              null
            }
          </Box>
          {props?.subscriptionValid ?
          props?.subscriptionData?.status === 'active' ? null :
            <>
              <p
                className={`flex items-center justify-center ${props?.subscriptionData?.expireIn ? "text-[#d21e1edb]" : "text-[#1da434]"
                  }`}
              >
                *{" "}
                {props?.subscriptionData?.status === 'expire' ? (
                  <>
                    {props?.buttonSubsMessage}{" "}
                    <span
                      onClick={() => navigate("/vendor/subscription")}
                      className="text-blue-600 cursor-pointer"
                    >
                      See Plans
                    </span>
                  </>
                ) : (
                  <>
                    Please get a subscription plan.{" "}
                    <span
                      onClick={() => navigate("/vendor/subscription")}
                      className="text-blue-600 cursor-pointer"
                    >
                      Click here
                    </span>
                  </>
                )}
              </p>
            </>


            : null}
          {props?.mappingname.map((item, idx) =>
            <TabPanel value={item?.value} className={item?.backgroundcolor}>{item?.content}</TabPanel>
          )}

        </TabContext>
      </Box >
    </div >
  );
}