import React, { useEffect, useState } from 'react'
import CommonCard from '../../../Common/Layout/Card/CommonCard'
import img1 from '../../../Images/Vendor/offerimg1.png'
import img2 from '../../../Images/Vendor/offerimg2.png'
import img3 from '../../../Images/Vendor/offerimg3.png'
import profileUserDetailsIcon from '../../../Images/profileUserCardImages/profileUserDetailsIcon.png'
import featuretag from "../../../Images/Vendor/Featuretag.svg"
import crownicon from "../../../Images/Vendor/crownicon.svg"
import "./index.css"
import DeclinePopup from '../../../Common/FormFields/DeclinePopup'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@mui/material'
import NoContentPage from '../../../Common/Layout/NoContentPage/NoContentPage'

const VendorServiceOfferActive = (props) => {
    const { t } = useTranslation()
    const itemContents = [
        {
            image: img1
        },
        {
            image: img2
        },
        {
            image: img3
        },
        {
            image: img3
        }
    ]
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [serviceItem, setServiceItem] = useState()
    const handleclickForDeleteService = (data) => {
        setOpenDeleteModal(!openDeleteModal)
        setServiceItem(data)
    }
    const deleteServiceSubmit = () => {
        props.deletePendingServices(serviceItem)
        setOpenDeleteModal(!openDeleteModal)
    }

    return (
        <>
            {props.loading === true ?
                <div className='flex h-[75vh] justify-center items-center'>
                    <CircularProgress size={20} color='success' />
                    <p className='pl-3'>Loading...</p>
                </div>
                :
                (props?.userData && props?.userData?.length > 0) ?
                    <div className="overflow-y-scroll h-[75vh] newcardDesign mt-5">
                        {props?.userData?.filter((item) => item.status === 'active').map((item, idx) =>
                            <div key={idx} className={`w-full`}>
                                {item?.status === 'active' ?
                                    <CommonCard
                                        carousel={item?.thumbnail && item?.thumbnail?.length > 0 ? true : false}
                                        noContentImg={item?.thumbnail && item?.thumbnail?.length === 0 && true}
                                        carouselimg={item?.thumbnail && item?.thumbnail?.length > 0 ? item?.thumbnail : itemContents}
                                        carouselHeight="200px"
                                        noContentCls={`h-[200px]`}
                                        height={false}
                                        featurecrown={false}
                                        crownicon={crownicon}
                                        featuretag={featuretag}
                                        rating={true}
                                        ratingvalue={item.ratingvalue}
                                        itemtext={true}
                                        Shareicon={false}
                                        text1={item?.name}
                                        text2={item?.description}
                                        itememail={true}
                                        // profile={true}
                                        // profileicon={profileUserDetailsIcon}
                                        location={false}
                                        email={item?.user?.email}
                                        distance={false}
                                        showamount={true}
                                        amount={item.amount}
                                        itemname={item?.user?.name}
                                        percentage={item?.offer[0]?.percentage && true}
                                        offerpercentage={item?.offer[0]?.percentage}
                                        price={item?.offer[0]?.price && true}
                                        offerprice={item?.offer[0]?.price}
                                        border={true}
                                        distancetext={item.distancetext}
                                        hoverbutton={false}
                                        buttonName2={item.buttonName2}
                                        buttonimg={item.buttonimg}
                                        buttonName3={item.buttonName3}
                                        buttonimg2={item.buttonimg2}
                                        addOffer={item?.offer?.length === 1 ? false : true}
                                        handleAddOffer={() => props.toggleAddOffer(item)}
                                        deleteIcon={false}
                                        deleteIconFunc={() => handleclickForDeleteService(item)}
                                    /> : null}
                            </div>
                        )}
                        {openDeleteModal &&
                            <DeclinePopup
                                dialogTitlecls={`!text-red-500`}
                                submitBtnCls={`!bg-red-600`}
                                open={openDeleteModal}
                                title={`Decline`}
                                handleClose={handleclickForDeleteService}
                                DilogContenttext={"Price"}
                                DialogContent={
                                    <>
                                        <p>{t('deleteServiceText')}</p>
                                    </>
                                }
                                DeleteBtn={"Decline"}
                                onClick={deleteServiceSubmit}
                            />
                        }
                    </div>
                    :
                    <NoContentPage
                        NoContentMainDiv={`h-[78vh]`}
                        text2={`There is no data`}
                    />
            }
        </>
    )
}

export default VendorServiceOfferActive