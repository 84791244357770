import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import { useSelector } from 'react-redux'
import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import HyperlinkIcon from '../../Images/Setting/HyperlinkIcon.svg'

const RechargeandPaybills = (props) => {
    const { t } = useTranslation()
    const allLinks = useSelector((state) => state.setting.hyperlinks)

    const goToLink = (myLink) => {
        window.open(myLink, '_blank', 'noreferrer')
    }

    const prevRef = useRef(null)
    const nextRef = useRef(null)

    return (
        <div className="relative p-[4%]">
            {/* Header Section */}
            <div className="flex flex-row justify-between items-center pb-[3%]">
                <p className="text-left font-[Roboto-Regular] font-normal text-deepgray-5 pb-2 lg:pb-0 text-lg md:text-3xl">
                    {t("rechargeandpaybills")}
                </p>
                {allLinks?.length > 5 && (
                    <p
                        className="text-left text-base font-[Roboto-Regular] font-normal text-blue-5 cursor-pointer"
                        onClick={props.handleClickForAllHyperlink}
                    >
                        View All
                    </p>
                )}
            </div>

            {/* Swiper Section */}
            {allLinks && allLinks.length > 0 ? (
                <div className="relative px-4 sm:px-10 md:px-20 bg-slate-100 py-5 rounded-md">
                    {/* Left Arrow - Always visible */}
                    <button ref={prevRef} className="absolute left-[20px] sm:left-[40px] top-[50%] transform -translate-y-1/2 z-10">
                        <MdKeyboardArrowLeft size={32} className="text-gray-700" />
                    </button>

                    {/* Swiper Carousel */}
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={10}
                        slidesPerView={1}
                        navigation={{
                            prevEl: prevRef.current,
                            nextEl: nextRef.current,
                        }}
                        onInit={(swiper) => {
                            swiper.params.navigation.prevEl = prevRef.current
                            swiper.params.navigation.nextEl = nextRef.current
                            swiper.navigation.init()
                            swiper.navigation.update()
                        }}
                        breakpoints={{
                            640: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 3,
                            },
                            1024: {
                                slidesPerView: 5,
                            },
                        }}
                    >
                        {allLinks.slice(0, 6).map((item, idx) => (
                            <SwiperSlide key={idx}>
                                <Tooltip title={item?.description}>
                                    <div
                                        className="flex flex-col items-center justify-center py-4 rounded-md shadow-sm cursor-pointer bg-white"
                                        onClick={() => goToLink(item?.url)}
                                    >
                                        <img
                                            src={item?.picture?.key 
                                                ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${item?.picture?.key}&height=20&width=auto` 
                                                : HyperlinkIcon}
                                            alt={item.name}
                                            className="h-20 md:h-15 w-20 md:w-15 rounded-full"
                                            crossOrigin="anonymous"
                                        />
                                        <p className="font-[Roboto-Regular] font-normal text-deepgray-5 text-2xl md:text-[17px] pt-4 truncate text-center">
                                            {item?.name}
                                        </p>
                                    </div>
                                </Tooltip>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    {/* Right Arrow - Always visible */}
                    <button ref={nextRef} className="absolute right-[20px] sm:right-[40px] top-[50%] transform -translate-y-1/2 z-10">
                        <MdKeyboardArrowRight size={32} className="text-gray-700" />
                    </button>
                </div>
            ) : (
                <div className="flex flex-col md:flex-row justify-center md:justify-evenly flex-wrap mt-6">
                    No Data Found
                </div>
            )}
        </div>
    )
}

export default RechargeandPaybills
