import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { SUB_ADMIN_API } from "../../../Constant"
import { doPostApiCall, doPutApiCall, doGetApiCall } from "../../../Utils/ApiConfig"
import encryptDecrypt from "../../../Utils/encryptDecrypt"
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux"
// import { getLoggedInDetails } from '../Login/reducer/LoginReducer'
import { getSubscriptionDetails } from "../Login/reducer/subscriptionReducer"
import { ForAdmin } from "../../Admin/Hooks"
// import { ForVendor } from "../../VendorDetailPage/Hooks"
import { useTranslation } from "react-i18next";

export const ForLogin = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { getTotalUserDetailsApi } = ForAdmin()
    const singleVendorDetailsData = useSelector((state) => state.vendor.singleVendorDetails)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailErrorMessage, setEmailErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [passwordErrorMessage, setPasswordErrorMessage] = useState()
    const [message, setMessage] = useState('')
    const [loader, setLoader] = useState(false)
    const [loginError, setLoginError] = useState({
        code: "",
        error: false,
        message: ""
    })

    const emailAddress = (e) => {
        setEmail(e.target.value)
    }

    const passwordAddress = (e) => {
        setPassword(e.target.value)
    }

    const [showOtp, setShowOtp] = useState(false)
    const [autoFillOtp, setAutoFillOtp] = useState()
    const [mail, setMail] = useState()
    const [showResetPasswordSuccess, setShowResetPasswordSuccess] = useState(false)
    const [forgotPasswordMsg, setForgotPasswordMsg] = useState()
    const [showVerifyEmailDetails, setShowVerifyEmailDetails] = useState({
        tick: false,
        error: false,
        message: ''
    })

    useEffect(() => {
        if (singleVendorDetailsData?.id) {
            const subscriptionInfo = {
                userId: singleVendorDetailsData?.userId,
                name: singleVendorDetailsData?.user?.name,
                email: singleVendorDetailsData?.user?.email,
                address: singleVendorDetailsData?.address?.replace(/\s/g, '')
            }
            localStorage.setItem("subscriptionInfo", JSON.stringify(subscriptionInfo));
        }
    }, [singleVendorDetailsData])

    const [checked, setChecked] = useState(false)
    const handleCheck = (e) => {
        setChecked(e.target.checked)
        // localStorage.setItem()
    }
    // This below three functionalities are only for snackbar during login
    const [notification, setNotification] = useState({
        open: false,
        message: "",
        subText: "",
        alertType: "",
        borderClass: "",
    });
    const messageClose = () => {
        setNotification({
            open: false,
            message: "",
            subText: "",
            alertType: "",
            borderClass: "",
        });
    };
    const openMessageLogin = (alertType, message, subText, borderClass) => {
        if (alertType) {
            setNotification({
                open: true,
                message: message,
                subText: subText,
                alertType: alertType,
                borderClass: borderClass,
            });
            setTimeout(messageClose, 5000);
        }
    };

    // passwordRegex used for password checking
    const Login = async () => {
        let data = {
            url: `${SUB_ADMIN_API}/users/login`,
            bodyData: {
                email: email,
                password: password
            }
        }
        const emalRegex = /^[\w]{1,}[\w.+-]{0,}@[\w-]{2,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$/i;
        // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$/i;
        const passwordRegex = /^.{2,}$/;
        if (!emalRegex.test(email)) {
            setLoading(false)
            setEmailErrorMessage(t("Please enter a valid email"))
        }
        else if (!passwordRegex.test(password)) {
            setEmailErrorMessage('')
            setPasswordErrorMessage(t("Password must contain at least 2 characters"))
        }
        else {
            setPasswordErrorMessage('');
            setLoading(true)
            setLoginError({
                code: "",
                error: false,
                message: ""
            })
            const res = await doPostApiCall(data)
            if (!res?.error && res?.status === 200) {
                setLoading(false)
                openMessageLogin("success", "Success", t("SuccessfullyLoggedIn"), "success")
                const decryptResult1 = await encryptDecrypt(res?.result, 'd')
                localStorage.setItem("name", decryptResult1?.name);
                localStorage.setItem("email", decryptResult1?.email)
                localStorage.setItem("userId", decryptResult1?.id);
                localStorage.setItem("role", decryptResult1?.role);
                localStorage.setItem("businessType", decryptResult1?.type);
                decryptResult1?.role === 'subAdmin' && localStorage.setItem("sections", JSON.stringify(decryptResult1?.sections));
                decryptResult1?.role !== 'vendor' && getTotalUserDetailsApi(decryptResult1?.id);
                decryptResult1?.role === 'vendor' ? navigate(`/vendor`)
                    :
                    (decryptResult1?.role === 'admin' || decryptResult1?.role === 'subAdmin') ? navigate(`/admin/dashboard`)
                        :
                        decryptResult1?.role === 'user' && navigate(`/user`)
                getSubscriptionDetailsForUser()
            } else if (res?.status === 403) {
                setLoading(false)
                openMessageLogin("warning", "Warning", res?.code === "INCORRECT_PASSWORD" && t("IncorrectPassword"), "warning")
                setLoginError({
                    code: res?.code,
                    error: res?.error,
                    message: t("IncorrectPassword")
                })
            } else {
                setLoading(false)
                const errMess = res?.code === "USER_NOT_VERIFIED" ? t("Vendor account awaiting admin approval") : res?.code === "INVALID_CREDENTIALS" ? t("InvalidUser") : res?.code === "USER_DISABLED" ? res?.message : t("Something went wrong");
                openMessageLogin("error", "Error", errMess, "error")
                setLoginError({
                    code: res?.code,
                    error: res?.error,
                    message: errMess
                })
            }
        }
    }

    const getSubscriptionDetailsForUser = () => {
        try {
            doGetApiCall({ url: `${process.env.REACT_APP_DG_BASE_URL}/payment/subscription/details` })
                .then((res) => {
                    if (!res?.error) {
                        dispatch(getSubscriptionDetails({ subscription: res?.result, userDataCount: res?.userDataCount }))
                    } else {
                    }
                })
                .catch((err) => {
                    console.error(err)
                })

        } catch (error) {
            console.error(error, "error in getSubscriptionDetails")
        }
    }

    // forgot password 
    const forgetPasswordFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',

        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email(t("InvalidEmailAddress"))
                .required(t("EmailFieldCannotBeEmpty")),
        }),
        onSubmit: (values) => {
            forgetPasswordApiCall(values)
        }
    });

    // reset password 
    const resetPasswordFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            password: "",
            rePassword: ""
        },
        validationSchema: Yup.object({
            password: Yup.string().required(t("EnterNewPassword")).matches(
                /\w/,
                t("PleaseEnterValidPassword")
            ),
            rePassword: Yup.string().oneOf([Yup.ref("password"), null], t("PasswordMustMatch")).required(t("ReEnterPassword")).matches(
                /\w/,
                t("PleaseEnterValidPassword")
            ),
        }),
        onSubmit: (values) => {
            setLoader(true)
            let data = {
                url: `${SUB_ADMIN_API}/users/reset/password`,

                bodyData: {
                    email: mail,
                    otp: parseInt(autoFillOtp),
                    password: values.rePassword
                }
            }
            doPutApiCall(data)
                .then((res) => {
                    setLoader(false)
                    res?.code === "PASSWORD_RESET_SUCCESS" && openMessageLogin("success", "Success", t("ResetPasswordSuccessfully"), "success")
                    navigate(`/login`)
                })
                .catch((err) => {
                    setLoader(false)
                    openMessageLogin("warning", "Warning", err?.message || t("samePassword"), "warning")
                    console.error(err)
                })
        }

    })

    /**
    * @method PUT
    * @author uplRanabir
    * @description api call function for forget password
    **/
    const forgetPasswordApiCall = async (values) => {
        setLoader(true)
        setMail(values.email)
        let data = {
            url: `${SUB_ADMIN_API}/users/forget/password`,
            bodyData: {
                email: values.email
            }
        }
        doPutApiCall(data)
            .then((res) => {
                if (!res?.error) {
                    setLoader(false)
                    setShowOtp(true)
                    // setAutoFillOtp(res?.result.otp)
                }
                else {
                    setLoader(false)
                    setForgotPasswordMsg(res)
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }


    /**
    * @method PUT
    * @author uplSnehasish
    * @description api call function for checking if the email is verified via OTP verification or not
    **/
    const verifyEmailForForgetPasswordApiCall = async (values, otp) => {
        let data = {
            url: `${SUB_ADMIN_API}/users/forget/password/otp/verify`,
            bodyData: {
                email: values.email,
                otp: otp
            }
        }
        try {
            let res = await doPutApiCall(data)
            if (!res?.error) {
                setShowVerifyEmailDetails({
                    tick: true,
                    error: false,
                    message: ''
                })
                openMessageLogin("success", "Success", t("OtpVerifiedSuccessfully"), "success")
            }
            else {
                setShowVerifyEmailDetails({
                    tick: false,
                    error: true,
                    message: res?.message
                })
                openMessageLogin("error", "Error", res?.code === "INVALID_OTP" ? t("InvalidOtp") : t("Something went wrong"), "error")
            }
        } catch (err) {
            openMessageLogin("error", "Error", t("InvalidOtp"), "error")
        }
    }

    // useEffect for checking is email is being verified via otp verification or not 
    useEffect(() => {
        if (autoFillOtp && autoFillOtp?.length === 6) {
            verifyEmailForForgetPasswordApiCall(forgetPasswordFormik?.values, autoFillOtp)
        }
    }, [autoFillOtp])

    return {
        Login,
        email,
        password,
        emailAddress,
        passwordAddress,
        emailErrorMessage,
        passwordErrorMessage,
        message,
        setLoginError,
        loginError,
        loading,
        forgetPasswordFormik,
        showOtp,
        autoFillOtp,
        resetPasswordFormik,
        showResetPasswordSuccess,
        loader,
        forgotPasswordMsg,
        mail,
        checked,
        handleCheck,
        notification,
        messageClose,
        setAutoFillOtp,
        forgetPasswordApiCall,
        showVerifyEmailDetails,
        getSubscriptionDetailsForUser
    }
}
