import { SUB_ADMIN_API } from "../../Constant"
import { useDispatch, useSelector } from "react-redux";
import { doGetApiCall, doPostApiCall, doPutApiCall, doDeleteApiCall } from "../../Utils/ApiConfig"
import { getHyperlinks, getSubAdmin, getAnnouncement} from "./reducer/SettingReducer";
import { getTotalUsers, getSelectedUserDetails, getSelectedVendorDetails, getSingleUserRequirements } from "./reducer/TotalUsersReducer";
import { useEffect, useState } from "react";
import { getTotalVendors, getSingleVendor, getSingleVendorProducts, getSingleVendorServices, getSingleVendorRequirements, getServicesByServiceId } from "./reducer/TotalVendorsReducer";
import moment from "moment";
import { ForUploadMediaHooks } from '../UploadMedia/Hooks'
import { getTotalCategory, getTotalSubCategory } from "./reducer/CategoryReducer";
import _, { get } from "lodash";
import { getTotalNotification } from "./reducer/NotificationReducer";
import { getDashBoardData, mostViewedReducer } from "./reducer/DashboardReducer";
import { setUploadedLogo } from "../UploadMedia/reducer/UploadMediaReducer";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { faqInAdmin, getFaqDetails } from "./reducer/CMSReducer";
import { useTranslation } from "react-i18next";
import { savePlanData, singlePlanData } from "./reducer/SubscriptionPlanReducer";
import { getSubscriptionList } from "./reducer/SubscriptionList"
import { getTransactionList } from "./reducer/TransactionReducer";
import { useLocation } from 'react-router-dom'
import { getApproveMediaList } from "./reducer/TotalApproveReducer";
import dayjs from "dayjs";
import { getLocationReducer } from "../Authentication/Signup/reducer/VendorAccountReducer";

export const ForAdmin = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation()
    const { documentUpload, logoUpload, multipleUpload, doUploadedLogo, cleanupMedia } = ForUploadMediaHooks()
    const navigate = useNavigate()
    const [value, setValue] = useState("0")
    const [valueOffer, setValueOffer] = useState("")

    // const userData = useSelector((state) => state.setting.users)
    const allLinks = useSelector((state) => state.setting.hyperlinks)
    const [uploadData, setUploadData] = useState(null)
    const [showAddAddress, setShowAddAddress] = useState(false)
    const [accountEmail, setAccountEmail] = useState('')
    const [openVendorTable, setOpenVendorTable] = useState(false)

    const [openModal, setOpenModal] = useState(false)
    const [addCategoryName, setAddCategoryName] = useState('')
    const [addCategoryDescription, setAddCategoryDescription] = useState('')
    const [editVendorFormData, setEditVendorFormData] = useState(null)
    const [newCategoryName, setNewCategoryName] = useState('')
    const [categoryId, setCategoryId] = useState('')
    // const [subCategoryId, setSubCategoryId] = useState('')
    const [newSubCategoryName, setNewSubCategoryName] = useState('')
    const [searchString, setSearchString] = useState('')

    const [specialAnnouncement, setSpecialAnnouncement] = useState(false)
    const [accountExpiry, setAccountExpiry] = useState(false)
    const [newOffer, setNewOffer] = useState(false)
    const [newUser, setNewUser] = useState(false)




    const [subscriptionActivity, setSubscriptionActivity] = useState(false)
    const [bidConfirmation, setBidConfirmation] = useState(false)
    const [notificationType, setNotificationType] = useState('alert')
    const [dropdownName, setDropdownName] = useState();
    const [notificationFound, setNotificationFound] = useState(false);
    const [notificationNotFound, setNotificationNotFound] = useState(false);
    // const notification = useSelector((state) => state.notification.totalNotification)
    const [dataValue, setDataValue] = useState()

    const [subAdminCreateMsg, setSubAdminCreateMsg] = useState()
    const [totalUserCreateMsg, setTotalUserCreateMsg] = useState()
    const [totalVendorCreateMsg, setTotalVendorCreateMsg] = useState()
    const [openSubCategoryModal, setOpenSubCategoryModal] = useState({ id: 0, state: false })
    const [uploadLogoProfile, setUploadLogoProfile] = useState([])
    const [treeUploadLogoProfile, setTreeUploadLogoProfile] = useState([])
    const [loading, setLoading] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [defaultSubcat, setDefaultSubcat] = useState('')

    const [editSubCategoryData, setEditSubCategoryData] = useState()
    const locationTxt = useSelector((state) => state.vendorAccount.location)

    // state for text editor of Privacy Policy
    const [showPrivPolTextEditor, setShowPrivPolTextEditor] = useState(false)
    const [privacyDetails, setPrivacyDetails] = useState()

    // state for text editor of Faq
    const [showFaqTextEditor, setShowFaqTextEditor] = useState(false)
    const [editTabValue, setEditTabValue] = useState("0");

    const [dateForViews, setDateForViews] = useState([{
        startDate: null,
        endDate: null,
        key: 'selection'
    }])

    const [dateOpenViews, setDateOpenViews] = useState(false)

    const [showSubscriptionForm, setShowSubscriptionForm] = useState(false)
    const handleSubscriptionForm = () => {
        setShowSubscriptionForm(prev => !prev)
    }

    setTimeout(() => {
        setNotificationFound(false);
        setNotificationNotFound(false)
    }, 3000);

    const locationSelector = useSelector((state) => state.vendorAccount.location)

     // For opening or closing the edit profile form in vendor 
     const [editProfile, setEditProfile] = useState(false)
     
    const handleClickForOpenVendorTable = () => {
        setEditVendorFormData(null)
        setOpenVendorTable(!openVendorTable)
        // console.log(openVendorTable, "dbhnm")
    }

    const handleeditclick = (data) => {
        setEditVendorFormData(data)
        cleanupMedia()
        setOpenVendorTable(!openVendorTable)
    }

    console.log(openVendorTable, "openVendorTable #")

    const toggleEditProfile = (data) => {
       if(data) setEditVendorFormData(data)
        else setEditVendorFormData(null)
        setEditProfile(!editProfile)
        setOpenVendorTable(false)
        cleanupMedia()
      }

    const closeForm = () =>  {
        setEditVendorFormData(null)
        setEditProfile(false)
        setOpenVendorTable(false)
        cleanupMedia()
        setLoading(false);
        dispatch(getLocationReducer(''));
        getTotalVendorsInAdmin()
        setVendorBusinessData(null)
        setEditTabValue("0")
    }


 
    // For storing data of individual user in the user of admin, when the respective edit is clicked
    const [editUserFormData, setEditUserFormData] = useState(null)

    // for conditionally rendering subAdminForm component 
    const [showSubAdminForm, setShowSubAdminForm] = useState(false);
    const toggleSubAdmin = () => {
        setEditUserFormData(null)
        setShowSubAdminForm(!showSubAdminForm)
    }


     // for conditionally rendering subAdminForm component 
     const [showannouncementForm, setAnnouncementForm] = useState(false);
     const toggleAnnounce = () => {
         setEditUserFormData(null)
         setAnnouncementForm(prev => !prev)
     }

     const goBackToAnnounceTable = () => {
       
        setAnnouncementForm(prev => !prev)
    }

    // to get only subAdmin    
    // const subAdminUser = userData?.filter((item) => (
    //     item?.uniqueId.split('-')[0] === 'SA'
    // ))

    // to go back to showAdminTable 
    const goBackToTable = () => {
        setEditSubAdminFormData(null)
        setShowSubAdminForm(!showSubAdminForm)
    }

    // for conditionally rendering hyperlinkForm
    const [hyperlinkForm, setHyperlinkForm] = useState(false)
    const [hyperlinkFormData, setHyperlinkFormData] = useState(null)

    const showHyperlinkForm = () => {
        setHyperlinkForm(!hyperlinkForm)
    }

    // to go to HyperlinkList 
    const goToLinkTable = () => {
        setHyperlinkFormData(null)
        setHyperlinkForm(!hyperlinkForm)
    }
    // For showing user form in admin 
    const [userForm, setUserForm] = useState(false)
    const toggleUserForm = () => {
        setEditSubAdminFormData(null)
        setUserForm(!userForm)
    }

    // For editing the user form in admin 
    const editUserForm = (data) => {
        setEditUserFormData(data)
        setUserForm(!userForm)
    }


    // For storing data of individual sub-admin in admin, when the respective edit is clicked
    const [editSubAdminFormData, setEditSubAdminFormData] = useState(null)
    // For editing the sub-admin form in admin panel
    const editSubAdminForm = (data) => {
        setEditSubAdminFormData(data)
        setShowSubAdminForm(!showSubAdminForm)
    }

    // For storing data of individual sub-admin in admin, when the respective edit is clicked
    const [editPlan, setEditPlan] = useState(null)
    // For editing the sub-admin form in admin panel
    const editSubscriptionFormHandler = (data) => {
        setEditPlan(data)
        setShowSubscriptionForm(!showSubscriptionForm)
    }

    // These below three functionalities are only for snackbar of all kinds of form fillup's post api calls in vendor
    const [adminSnackbar, setAdminSnackbar] = useState({
        open: false,
        message: "",
        subText: "",
        alertType: "",
        borderClass: "",
    });
    const messageClose = () => {
        setAdminSnackbar({
            open: false,
            message: "",
            subText: "",
            alertType: "",
            borderClass: "",
        });
    };
    const openMessage = (alertType, message, subText, borderClass) => {
        if (alertType) {
            setAdminSnackbar({
                open: true,
                message: message,
                subText: subText,
                alertType: alertType,
                borderClass: borderClass,
            });
            setTimeout(messageClose, 5000);
        }
    };

    /**
    * @author uplRanabir, uplAyan
    * @description- this function is used for making api call to create subAdmin
    **/
    const subAdminCreate = (formData ) => {
        // setLoading(true)
      
        let phoneNum = '+'.concat('', formData.phoneNumber)
        // let sectionsArr = []
        // if (formData?.roles && formData?.roles.length > 0) {
        //     for (let i = 0; i < formData?.roles.length; i++) {
        //         let obj = {}
        //         obj.name = formData?.roles[i]
        //         sectionsArr.push(obj);
        //     }
        // }
        let data = {
            url: `${SUB_ADMIN_API}/users/create`,
            bodyData: {
                email: formData.email,
                name: formData.fullName,
                subRole: formData.subRole,
                phoneNo: phoneNum,
                governorate: formData.governorate,
                place: formData.places,
                sections: formData?.roles,
                type: 'subAdmin',
                profilePicture: {
                    name: logoUpload?.originalname,
                    key: logoUpload?.key,
                    mimetype: logoUpload?.mimetype,
                    location: logoUpload?.location,
                    size: logoUpload?.size
                },
            }
        }

        doPostApiCall(data)
            .then((res) => {
                if (!res.error) {
                    setLoading(false)
                    cleanupMedia()
                    setShowSubAdminForm(!showSubAdminForm)
                    openMessage("success", "Success", res?.message, "success")
                    getAllSubAdmin()
                    setSubAdminCreateMsg()
                } else {
                    setLoading(false)
                    openMessage("error", "Error", res?.message, "error")
                    setSubAdminCreateMsg(res)
                }
            })
            .catch(err => {
                console.log(err, "error")
            })
    }


    const getAllAnnouncement = () => {
        setLoading(true)
        let data = {
            url: `${process.env.REACT_APP_DG_BASE_URL}/announcements`
        }
        doGetApiCall(data).then((res) => {
            if (!res?.error) {
                setLoading(false)
                dispatch(getAnnouncement(res?.result))
            } else {
                setLoading(false)
                dispatch(getAnnouncement([]))
            }
        })
            .catch(err => {
                console.log(err, "error")
            })

    }



    const announcementCreate = (formData) => {

    
        setLoading(true)
       
        let data = {
            url: `${SUB_ADMIN_API}/announcements`,
            bodyData: {
                title: formData.title,
                expireIn:dayjs(formData?.expireIn).format('YYYY-MM-DD'),
                sentTo: formData.sentTo,
                description: formData.description,         
            }
        }

     
        doPostApiCall(data)
            .then((res) => {
                if (!res.error) {
                    setLoading(false)
               
                    setAnnouncementForm(prev => !prev)
                    openMessage("success", "Success", res?.message, "success")
                    getAllAnnouncement()
                    // setSubAdminCreateMsg()
                } else {
                    setLoading(false)
                    openMessage("error", "Error", res?.message, "error")
                    setSubAdminCreateMsg(res)
                }
            })
            .catch(err => {
                console.log(err, "error")
            })
    }




    const deleteAnnouncement = (userId) => {
        
        let data = {
            url: `${SUB_ADMIN_API}/announcements/${userId}`
        }
        doDeleteApiCall(data)
            .then((res) => {
                if (!res.error) {
                    getAllAnnouncement()
                    openMessage("success", "Success", res?.message, "success")
                } else {
                    openMessage("error", "Error", res?.message, "error")
                }
            })
            .catch(err => {
                console.log(err, "error")
            })
    }


    /**
    * @uplRanabir
   * @description- this function is used for making api call to create new hyperlink
    */

    const createHyperlink = (hyperlinkData) => {
        setLoading(true)
        let data = {
            url: `${SUB_ADMIN_API}/hyperlinks`,
            bodyData: {
                name: hyperlinkData.hyperlinkName,
                description: hyperlinkData.description,
                url: hyperlinkData.addUrl,
                picture: {
                    name: logoUpload?.originalname,
                    key: logoUpload?.key,
                    mimetype: logoUpload?.mimetype,
                    location: logoUpload?.location,
                    size: logoUpload?.size
                },
            }
        }
        doPostApiCall(data)
            .then((res) => {
                if (!res.error) {
                    openMessage("success", "Success", res?.message, "success")
                    setLoading(false)
                    setHyperlinkForm(!hyperlinkForm)
                    getAllHyperlinks()
                } else {
                    setLoading(false)
                    openMessage("error", "Error", res?.message, "error")
                }
            })
            .catch(err => {
                console.log(err, "error")
            })
    }

    /**
  * @uplRanabir
 * @description- this function is used for making api call to get all hyperlinks
  */
    const getAllHyperlinks = () => {
        setLoading(true)
        let data = {
            url: `${process.env.REACT_APP_DG_BASE_URL}/hyperlinks?dataPerPage=200`
        }
        doGetApiCall(data).then((res) => {
            if (!res?.error) {
                setLoading(false)
                dispatch(getHyperlinks(res?.result))
            } else {
                setLoading(false)
                dispatch(getHyperlinks([]))
            }
        })
            .catch(err => {
                console.log(err, "error")
            })

    }

    const sendData = (data) => {
        setUploadData(data)
    }


    /**
    *@author uplSnehasish
    *@description- This function is used for making api call for getting all posted service based on business
    **/
    const getServiceByBusinessInAdmin = (businessId, status) => {
        setLoading(true)
        if (businessId) {
            let data = {
                url: `${SUB_ADMIN_API}/services/business/${businessId}?dataPerPage=200&status=${status}`
            }
            doGetApiCall(data).then((res) => {
                if (!res.error) {
                    setLoading(false)
                    dispatch(getSingleVendorServices(res?.result))
                }
                else {
                    setLoading(false)
                    dispatch(getSingleVendorServices([]))
                }
            })
                .catch(err => {
                    dispatch(getSingleVendorServices([]))
                    setLoading(false)
                    console.log(err, "error")
                })
        }
    }

    /**
     * @author uplSnehasish
     * @description This function is used to get the total number of vendors and for sorting vendors.
     **/

    const [govFieldData, setGovFieldData] = useState()

    const getTotalVendorsInAdmin = (sortType, governorate) => {
        setLoading(true);
        let url = `${SUB_ADMIN_API}/users?type=vendor&dataPerPage=1000&profile=true`
        if (sortType && sortType?.length > 0) {
            if (sortType === 'governorate') {
                url = `${url}&governorate=${governorate}`
            }
            else if (sortType === 'places') {
                url = `${url}&places=${governorate}`
            }
            else {
                url = `${url}&sort=${sortType}`
            }
        }
        if (searchString && searchString?.length > 0) {
            url = `${url}&string=${searchString}`
        }

        let data = {
            url: url
        }
        doGetApiCall(data)
            .then((res) => {
                if (!res.error) {
                    setLoading(false)
                    dispatch(getTotalVendors(res?.result))
                }
            })
            .catch(err => {
                console.log(err, "error")
            })
    }
    /**
     * @author uplNupurS
     * @method post
     * @param {*} item 
     * @param {*} typecheck 
     * @description- this function is create for to post the detail of vendor by admin
     */

    const createVendorByAdmin = async (item, typecheck) => {
        setLoading(true)
        let phoneNum = '+'.concat('', item.phoneNo)
        let data = {
            url: `${SUB_ADMIN_API}/users/create`,
            bodyData: {
                email: item.email,
                phoneNo: phoneNum,
                name: item.name,
                type: typecheck,
            }
        }
        let res = await doPostApiCall(data)
        if (!res.error) {
            setLoading(false)
            setAccountEmail(res.result.email)
            setShowAddAddress(true)
            getTotalVendorsInAdmin()
            openMessage("success", "Success", t("vendorCreationSuccess"), "success")

            setTotalVendorCreateMsg()
        } else {
            setLoading(false)
            setShowAddAddress(false)
            openMessage("error", "Error", t("vendorCreationUnsuccessful"), "error")
            setTotalVendorCreateMsg(res)
        }
    }
    const userId = editVendorFormData?.id


    const editclick = async (vendorAdminData, typecheck) => {
        setLoading(true)
        let data = {
            url: `${SUB_ADMIN_API}/users/${userId}`,
            bodyData: {
                // email: vendorAdminData.email,
                // phoneNo: vendorAdminData.phoneNo,
                name: vendorAdminData.name,
                type: typecheck,
            }
        }
        let res = await doPutApiCall(data)
        if (!res.error) {
            setLoading(false)
            setShowAddAddress(true)
            setAccountEmail(res.result.email)
            getTotalVendorsInAdmin()
            // editbusinessAddressDetails()
            // getbusinessAddressDetails()

            // localStorage.setItem("accountEmail", item.email);
        } else {
            setLoading(false)
            setShowAddAddress(false)
        }
    }

    // const deleteclick = async (item, typecheck) => {
    //     console.log(item, "#")
    //     let data = {
    //         url: `${SUB_ADMIN_API}/users/${userId}`,
    //         bodyData: {
    //             // email: item.email,
    //             // phoneNo: item.phoneNo,
    //             name: item.name,
    //             type: typecheck,
    //         }
    //     }
    //     let res = await doDeleteApiCall(data)
    //     console.log(res, 'resresres #');
    //     if (!res.error) {
    //         setAccountEmail(res.result.email)
    //         getTotalVendorsInAdmin()
    //         setShowAddAddress(true)

    //         // localStorage.setItem("accountEmail", item.email);
    //     } else {
    //         setShowAddAddress(false)
    //     }
    // }

    /**
     * @author uplNupurS
     * @method post
     * @param {*} item 
     * @description this function is created for post the business details for vendor by admin
     */


    const businessAddAddressByAdmin = (item, latlng) => {
        // console.log( moment(item.date).format('YYYY'), item, "#")
        setLoading(true)
        let data = {
            url: `${SUB_ADMIN_API}/business`,
            bodyData: {
                name: item.name,
                governorate: item.governorate,
                displayName: "No displayName",
                places: item.places,
                email: accountEmail,
                location: locationTxt && locationTxt?.length > 0 ? locationTxt : item.location,
                lat: latlng?.lat,
                long: latlng?.lng,
                address: item.fullAddress,
                street: item.street,
                block: item.block,
                buildingNo: item.buildingNo,
                floor: item.floor,
                officeNo: item.officeNo,
                startTime: moment(item.startTime).format('LT'),
                shiftTime: moment(item.shiftTime).format('LT'),
                endTime: moment(item.endTime).format('LT'),
                establishmentYear: moment(item.date).year().toString(),
                civilDocument: {
                    name: documentUpload?.originalname,
                    key: documentUpload?.key,
                    mimetype: documentUpload?.mimetype,
                    location: documentUpload?.location,
                    size: documentUpload?.size
                },
                logoPicture: {
                    name: logoUpload?.originalname,
                    key: logoUpload?.key,
                    mimetype: logoUpload?.mimetype,
                    location: logoUpload?.location,
                    size: logoUpload?.size
                },
                businessThumbnail: multipleUpload
            }
        }
        let res = doPostApiCall(data)
        if (!res.error) {
            setLoading(false)
            setOpenVendorTable(!openVendorTable)
            getTotalVendorsInAdmin()
            setShowAddAddress(false)
            openMessage("success", "Success", t("businessCreationSuccess"), "success")
        } else {
            setLoading(false)
            openMessage("error", "Error", t("businessCreationUnsuccessful"), "error")
        }
    }


    const businessId = editVendorFormData?.business && editVendorFormData?.business?.length > 0 && editVendorFormData?.business[0]?.id

    const editbusinessAddressDetails = (item) => {
        setLoading(true)
        let data = {
            url: `${SUB_ADMIN_API}/business/${businessId}`,
            bodyData: {
                name: item.name,
                governorate: item.governorate,
                places: item.places,
                email: accountEmail,
                location: item.location,
                address: item.fullAddress,
                street: item.street,
                block: item.block,
                buildingNo: item.buildingNo,
                floor: item.floor,
                officeNo: item.officeNo,
                startTime: moment(item.startTime).format('LT'),
                shiftTime: moment(item.shiftTime).format('LT'),
                endTime: moment(item.endTime).format('LT'),
                establishmentYear: moment(item.date).format('L')
            }
        }
        doPutApiCall(data)
            .then((res) => {
                if (!res.error) {
                    setLoading(false)
                    setOpenVendorTable(!openVendorTable)
                    getTotalVendorsInAdmin()

                }
            })
            .catch(err => {
                console.log(err, "error")
            })
    }


    /**
     * @author uplVikash
     * @method getAllUsers
     * @description This function is used to get the total number of users and for sorting users.
     */

    const getAllUsers = (sortType, governorate) => {
        setLoading(true)
        let data = ''
        if (sortType === 'governorate') {
            data = {
                url: `${SUB_ADMIN_API}/users?governorate=${governorate}&type=user&dataPerPage=1000`
            }
        }
        else if (sortType === 'places') {
            data = {
                url: `${SUB_ADMIN_API}/users?places=${governorate}&type=user&dataPerPage=1000`
            }
        }
        else if (sortType) {
            data = {
                url: `${SUB_ADMIN_API}/users?sort=${sortType}&type=user&dataPerPage=1000`
            }
        }
        else if (searchString) {
            data = {
                url: `${SUB_ADMIN_API}/users?string=${searchString}&type=user&dataPerPage=1000`
            }
        }
        else {
            data = {
                url: `${SUB_ADMIN_API}/users?type=user&dataPerPage=1000`
            }
        }

        doGetApiCall(data)
            .then((res) => {
                if (!res?.error) {
                    setLoading(false)
                    dispatch(getTotalUsers(res?.result))
                }
            })
            .catch(err => {
                console.log(err, "error")
            })
    }

    /**
    * @author uplSnehasish
    * @description This function is used to create a new user, which can only be done by admin
    **/
    const createNewUserByAdmin = (userAdminData) => {
        setLoading(true)
        let phoneNum = '+'.concat('', userAdminData.phoneNo)
        let data = {
            url: `${SUB_ADMIN_API}/users/create`,
            bodyData: {
                name: userAdminData.name,
                email: userAdminData.email,
                phoneNo: phoneNum,
                location: userAdminData.location,
                type: "user",
                // place: userAdminData.place,
                // fullAddress: userAdminData.fullAddress
            }
        }
        doPostApiCall(data)
            .then((res) => {
                if (!res.error) {
                    setLoading(false)
                    setUserForm(!userForm)
                    getAllUsers()
                    openMessage("success", "Success", res?.message, "success")
                    setTotalUserCreateMsg()
                } else {
                    setLoading(false)
                    openMessage("error", "Error", res?.message, "error")
                    setTotalUserCreateMsg(res)
                }
            })
            .catch(err => {
                console.log(err, "error")
            })
    }

    const userid = editUserFormData?.id
    /**
    * @author uplSnehasish
    * @description This function is used to edit a particular user
    **/
    const editUserApi = (userAdminData) => {
        setLoading(true)
        let data = {
            url: `${SUB_ADMIN_API}/users/${userid}`,
            bodyData: {
                name: userAdminData.name,
                location: userAdminData.location
                // gender: userAdminData.gender,
                // bio: userAdminData.bio,
            }
        }
        doPutApiCall(data)
            .then((res) => {
                if (!res.error) {
                    setLoading(false)
                    setUserForm(!userForm)
                    getAllUsers()
                    openMessage("success", "Success", res?.message, "success")
                } else {
                    setLoading(false)
                    openMessage("error", "Error", res?.message, "error")
                }
            })
            .catch(err => {
                console.log(err, "error")
            })
    }

    /**
    * @author uplRanabir
    * @method getTotalUserDetailsApi
    * @description This function is used to get details of every selected user.
    */
    const getTotalUserDetailsApi = (userId) => {
        let data = {
            url: `${SUB_ADMIN_API}/users/${userId}`
        }
        doGetApiCall(data)
            .then((res) => {
                if (!res?.error) {
                    dispatch(getSelectedUserDetails(res?.result))
                }
            })
            .catch(err => {
                console.log(err, "error")
            })
    }
    const getTotalvendorDetailsApi = (userId) => {
        let data = {
            url: `${SUB_ADMIN_API}/users/${userId}`
        }
        doGetApiCall(data)
            .then((res) => {
                if (!res?.error) {
                    dispatch(getSelectedVendorDetails(res?.result))
                }
            })
            .catch(err => {
                console.log(err, "error")
            })
    }

    /**
    * @author uplRanabir
    * @method approveUserApi
    * @description This function is used to approve/Decline user.
    */
    const approveUserApi = (userId, value, description) => {
        setButtonLoading(true)
        let data = {
            url: `${SUB_ADMIN_API}/users/approve/${userId}?status=${value}`,
            bodyData: description
        }
        doPutApiCall(data)
            .then((res) => {
                if (!res.error) {
                    setButtonLoading(false)
                    getAllUsers()
                    getTotalVendorsInAdmin()
                    getTotalvendorDetailsApi(userId)
                    openMessage("success", "Success", res?.message, "success")
                    // getTotalUserDetailsApi(userId);
                } else {
                    setButtonLoading(false)
                    openMessage("error", "Error", "Something went wrong", "error")
                }
            })
            .catch(err => {
                console.log(err, "error")
            })
    }

    /**
     * @author uplAyan
     * @method downloadExcelReport
     * @param {String} type: user / vendor
     * @description This function downloads excel file for users or vendors
     */
    const downloadExcelReport = async (type) => {
        try {
            let token;
            if (typeof window !== 'undefined') {
                token = localStorage.getItem('token')
            }
            let url = `${SUB_ADMIN_API}/users/file/download?type=${type}`
            const requestValues = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: token || "",
                },
            };
            const res = await fetch(url, requestValues)
            const blob = await res.blob();
            const newBlob = new Blob([blob]);
            const blobUrl = window.URL.createObjectURL(newBlob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `${type}-exported.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            // clean up Url
            window.URL.revokeObjectURL(blobUrl);
        }
        catch (err) {
            console.log(err);
        }
    }

    /**
        * @author uplRanabir
        * @method userEnableDisableApi
        * @description This function is used to approve/Decline user.
        */
    const userEnableDisableApi = (userId, queryVal, type) => {
        let checkVal = queryVal === 1 ? 'enable' : 'disable'
        let data = {
            url: type ? `${SUB_ADMIN_API}/users/${userId}?type=${checkVal}` : `${SUB_ADMIN_API}/users/${userId}`
        }
        doDeleteApiCall(data)
            .then((res) => {
                if (!res.error) {
                    getAllUsers()
                    getTotalVendorsInAdmin()
                    if(res?.code === 'USER_DISABLED'){
                        openMessage("success", "Success", t("Disabled"), "success")
                    }
                    else if(res?.code === 'USER_ENABLE_SUCCESSS'){
                        openMessage("success", "Success", t("Enabled"), "success")
                    }
                    else if(res?.code === 'USER_DELETE_SUCCESS'){
                        openMessage("success", "Success", t("vendorUserDelete"), "success")
                    }
                    else{
                        openMessage("success", "Success", res?.message, "success")
                    }
                    
                } else {
                    openMessage("error", "Error", res?.message, "error")
                }
            })
            .catch(err => {
                console.log(err, "error")
            })
    }

    const token = localStorage.getItem('token')
    /**
    * @author uplSnehasish  
    * @url /business/${userId}
   * @description This function is used to get vendor details of every selected vendor in the admin.
   **/
    const getSingleVendorDetailsApi = (userId) => {
        setLoading(true)
        let data;
        if (token) {
            data = {
                url: `${SUB_ADMIN_API}/business/${userId}`
                // url: `${SUB_ADMIN_API}/users/${userId}`
            }
        } else {
            data = {
                url: `${SUB_ADMIN_API}/business/noauth/${userId}`
            }
        }

        doGetApiCall(data)
            .then((res) => {
                if (!res?.error) {
                    setLoading(false)
                    dispatch(getSingleVendor(res?.result))
                    dispatch(getSelectedUserDetails(res?.result))
                }
                else {
                    setLoading(false)
                    dispatch(getSingleVendor({}))
                    dispatch(getSelectedUserDetails(''))
                }
            })
            .catch(err => {
                console.log(err, "error")
            })
    }



    /**
   * @author uplSnehasish
  * @description This function is used to give a badge to the selected user.
  **/
    const giveBadgetoUser = (userId) => {
        setButtonLoading(true)
        let data = {
            url: `${SUB_ADMIN_API}/users/batch/approve/${userId}`,
            bodyData: {
                batch: true
            }
        }
        doPutApiCall(data)
            .then((res) => {
                if (!res.error) {
                    setButtonLoading(false)
                    getSingleVendorDetailsApi(userId)
                    getTotalUserDetailsApi(userId)
                    openMessage("success", "Success", "Badge approved successfully", "success")
                } else {
                    setButtonLoading(false)
                    openMessage("error", "Error", "Some problem occured", "error")
                }
            })
            .catch(err => {
                console.log(err, "error")
            })
    }

    // create category functions

    const categoryOpenModal = () => {
        setOpenModal(true)
    }


    // useEffect(() => {
    //  console.log(dataValue , "data")
    // }, [dataValue])

    const CategoryDescription = (e) => {
        setAddCategoryDescription(e.target.value)
    }

    const cancleModal = () => {
        setOpenModal(false)
        setDataValue(null)

    }




    const subCategoryModal = (item) => {
        setOpenSubCategoryModal({ id: item?.id, state: true })
    }

    const subCategoryModalClose = () => {
        setOpenSubCategoryModal({ id: 0, state: false })
        setEditSubCategoryData(null)

    }


    /* this formik is use for create category*/
    const CategoryForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: dataValue?.name ? dataValue?.name : "",
            description: dataValue?.description ? dataValue?.description : "",

        },

        validationSchema: Yup.object().shape({
            name: Yup.string()
                .min(2, "Too short!")
                .required("name field cannot be empty"),
            description: Yup.string().required("description field cannot be empty")

        }),
        onSubmit: (values, { resetForm }) => {

            dataValue ? editCategory(values, resetForm) : createCategory(values, resetForm)
        },
    });

    /** this formik is use for create subcategory */

    const subCategoryForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: editSubCategoryData?.name ? editSubCategoryData?.name : "",
            description: editSubCategoryData?.description ? editSubCategoryData?.description : "",

        },

        validationSchema: Yup.object().shape({
            name: Yup.string()
                .min(2, "Too short!")
                .required("name field cannot be empty"),
            description: Yup.string(),
            // .required("description field cannot be empty")

        }),
        onSubmit: (values, { resetForm }) => {

            editSubCategoryData ? editSubCategory(values, resetForm) : createCategory(values, resetForm)
        },
    });


    /**
     * @author uplNupurS
     * @param {*} item
     * @method POST
     * @description- this function is created for to create the category
     */

    const createCategory = async (item, resetForm) => {
        setLoading(true)
        doUploadedLogo()
        let data = {
            url: `${SUB_ADMIN_API}/categories`,
            bodyData: {
                name: item.name,
                description: item.description,
                // categoryId: openSubCategoryModal.id && openSubCategoryModal.id
            }
        }
        if (openSubCategoryModal?.id !== 0) {
            data.bodyData.categoryId = openSubCategoryModal.id
            data.bodyData.subCategoryPicture = {
                name: logoUpload?.originalname,
                key: logoUpload?.key,
                mimetype: logoUpload?.mimetype,
                location: logoUpload?.location,
                size: logoUpload?.size
            }
        } else {
            data.bodyData.categoryPicture = {
                name: logoUpload?.originalname,
                key: logoUpload?.key,
                mimetype: logoUpload?.mimetype,
                location: logoUpload?.location,
                size: logoUpload?.size
            }
        }

        let res = await doPostApiCall(data)
        if (!res.error) {
            setLoading(false)
            resetForm()
            setUploadLogoProfile([])
            dispatch(setUploadedLogo([]))
            setOpenModal(false)
            setOpenSubCategoryModal({ id: 0, state: false })
            getCategory()
            openMessage("success", "Success", res?.message, "success")
        } else {
            setLoading(false)

            openMessage("error", "Error", res?.message, "error")
        }

    }

    /**
     * @author uplNupurS
     * @param {*} item
     * @method GET
     * @description this function is created for to get the all categories
     */

    const [categorySearchString, setCategorySearchString] = useState()

    const handleCategorySearch = (data) => {
        setCategorySearchString(data)
    }
    const handleSearchCategory = (e) => {
        debounceCategorySearch(e.target.value)
    }

    const debounceCategorySearch = _.debounce(handleCategorySearch, 500)



    const getCategory = async (item) => {
        setLoading(true)
        let data = ''

        if (categorySearchString) {
            data = {
                url: `${SUB_ADMIN_API}/categories?string=${categorySearchString}`
            }
        }
        else {
            data = {
                url: `${SUB_ADMIN_API}/categories?dataPerPage=200`,
            }
        }

        let res = await doGetApiCall(data)
        if (!res.error) {
            setLoading(false)
            dispatch(getTotalCategory(res?.result))
        } else {
            setLoading(false)
            dispatch(getTotalCategory([]))
        }
    }

    /**
     * @author uplRanabir
     * @method GET
     * @description this function is created for to get the all sub-categories and search those sub-categories
     */
    const [subCatSearchString, setSubCatSearchString] = useState()

    const subCatStateHandler = (data) => {
        setSubCatSearchString(data)
    }

    const subCatSearchHandler = (e) => {
        subCatDebounceHandler(e.target.value)
    }
    const subCatDebounceHandler = _.debounce(subCatStateHandler, 500)

    const searchSubCategoryApi = async (catId) => {
        if (catId) {
            setLoading(true)
            let data = '';
            if (subCatSearchString) {
                data = {
                    url: `${SUB_ADMIN_API}/categories/subcategory/${catId}?string=${subCatSearchString}`
                }
            } else {
                data = {
                    url: `${SUB_ADMIN_API}/categories/subcategory/${catId}`
                }
            }
            let res = await doGetApiCall(data)
            if (!res.error) {
                setLoading(false)
                dispatch(getTotalSubCategory(res?.result))
                if (res?.result?.length > 0) {
                    setDefaultSubcat(res?.result[0]?.id)
                }
                else {
                    setDefaultSubcat('')
                }
            } else {
                setLoading(false)
                dispatch(getTotalSubCategory([]))
            }
        }
    }

    // const [saveId, setSaveId] = useState()
    const addNewCategoryName = (e) => {
        setNewCategoryName(e)
    }
    /** this function is used for edit category */
    const openEditName = (item) => {
        setDataValue(item)
        setOpenModal(true)

    }
    // const handelChange = (data, id) => {
    //     setSaveId(id)
    //     // console.log(data ,id , "id #")
    //     doUploadedLogo(data)

    // }
    // useEffect(() => {
    // console.log(saveId , "saveId")
    // }, [saveId ])
    /**
        * @author uplNupurS
        * @param {*} item
        * @param {*} categoryId
        * @method PUT
        * @description this function is created for
        */

    const CategoryId = dataValue?.id

    const editCategory = async (item, resetForm) => {
        setLoading(true)
        // doUploadedLogo()
        let data = {
            url: `${SUB_ADMIN_API}/categories/${CategoryId}`,
            bodyData: {
                // pictureId: item.pictureId,
                categoryPicture: {
                    name: logoUpload?.originalname,
                    key: logoUpload?.key,
                    mimetype: logoUpload?.mimetype,
                    location: logoUpload?.location,
                    size: logoUpload?.size
                },
                name: item.name,
                description: item.description,
            }
        }
        let res = await doPutApiCall(data)
        if (!res.error) {
            setLoading(false)
            resetForm()
            getCategory()
            setOpenModal(false)
            setAddCategoryName('')
            setAddCategoryDescription('')
            setDataValue(null)
            // setCategoryId('')
            dispatch(setUploadedLogo([]))
            openMessage("success", "Success", res?.message, "success")

        } else {
            setLoading(false)
            openMessage("error", "Error", res?.message, "error")


        }
    }

    // for delete category 
    /**
     * @author uplNupurS
     * @param {*} item
     * @param {*} categoryId
     * @method DELETE
     * @description this function is used for delete category
     */


    const deleteCategory = async (categoryId) => {
        let data = {
            url: `${SUB_ADMIN_API}/categories/${categoryId}`,

        }
        let res = await doDeleteApiCall(data)
        if (!res.error) {
            getCategory()
            openMessage("success", "Success", res?.message, "success")

        } else {
            openMessage("error", "Error", res?.message, "error")

        }
    }


    const addNewSubCategoryName = (e) => {
        setNewSubCategoryName(e)
    }

    const openEditSubCategoryName = (item) => {
        setEditSubCategoryData(item)
        // if (subCategoryId === item?.id) {
        //     setSubCategoryId('')
        // } else {
        //     setSubCategoryId(item?.id)
        // }
    }
    const subCategoryId = editSubCategoryData?.id
    /**
     * @author uplNupurS
     * @param {*} item 
     * @param {*} resetForm 
     */
    const editSubCategory = async (item, resetForm) => {
        setLoading(true)
        let data = {
            url: `${SUB_ADMIN_API}/categories/subcategory/${subCategoryId}`,
            bodyData: {
                subCategoryPicture: {
                    name: logoUpload?.originalname,
                    key: logoUpload?.key,
                    mimetype: logoUpload?.mimetype,
                    location: logoUpload?.location,
                    size: logoUpload?.size
                },
                name: item?.name,
                description: item.description,
                categoryId: item.categoryId
            }
        }
        let res = await doPutApiCall(data)
        if (!res.error) {
            setLoading(false)
            resetForm()
            setEditSubCategoryData(null)
            setOpenSubCategoryModal({ id: 0, state: false })
            getCategory()
            // setSubCategoryId('')
        } else {
        }
    }


    /**
     * @author uplRanabir
     * @method PUT
     * @description- this function is used to edit hyperlink list
     */

    const editHyperlinkForm = (data) => {
        setHyperlinkFormData(data)
        setHyperlinkForm(!hyperlinkForm)
    }


    const editHyperlinkApi = (hyperlinkdata) => {
        setLoading(true)
        let data = {
            url: `${SUB_ADMIN_API}/hyperlinks/${hyperlinkFormData?.id}`,
            bodyData: {
                name: hyperlinkdata.hyperlinkName,
                description: hyperlinkdata.description,
                url: hyperlinkdata.addUrl,
                picture: {
                    name: logoUpload?.originalname,
                    key: logoUpload?.key,
                    mimetype: logoUpload?.mimetype,
                    location: logoUpload?.location,
                    size: logoUpload?.size
                },
            }
        }
        doPutApiCall(data)
            .then((res) => {
                if (!res.error) {
                    setLoading(false)
                    setHyperlinkForm(!hyperlinkForm)
                    getAllHyperlinks()
                    setHyperlinkFormData(null)
                    openMessage("success", "Success", res?.message, "success")
                }
            })
            .catch(err => {
                console.log(err, "error")
            })

    }

    /**
    * @author uplRanabir
    * @method DELETE
    * @description- this function is used to delete hyperlink list
    */
    const deleteHyperlinkApi = (hyperlinkData) => {
        let data = {
            url: `${SUB_ADMIN_API}/hyperlinks`,
            bodyData: {
                "hyperlinkIds": [
                    hyperlinkData?.id
                ]
            }
        }
        doDeleteApiCall(data)
            .then((res) => {
                if (!res.error) {
                    getAllHyperlinks()
                    openMessage("success", "Success", res?.message, "success")
                } else {
                    openMessage("error", "Error", res?.message, "error")
                }
            })
            .catch(err => {
                console.log(err, "error")
            })
    }

    /**
  * @author uplSnehasish   
 * @description This function is used to approve or deny the service of the selected vendor.
 **/
    const approveVendorService = async (serviceId, busId) => {
        setButtonLoading(true)
        let data = {
            url: `${SUB_ADMIN_API}/services/approve/${serviceId}`,
            bodyData: {
                status: "approved"
            }
        }
        let res = await doPutApiCall(data)
        if (!res.error) {
            setButtonLoading(false)
            // getSingleVendorDetailsApi(userId)
            openMessage("success", "Success", res?.message, "success")
            getServiceByBusinessInAdmin(busId)
            setValue('0')
        } else {
            setButtonLoading(false)
            openMessage("error", "Error", res?.message, "error")
        }
    }

    const declineVendorService = async (serviceId, busId, declineReason) => {
        setButtonLoading(true)
        let data = {
            url: `${SUB_ADMIN_API}/services/approve/${serviceId}`,
            bodyData: {
                status: "denied",
                declineReason
            }
        }
        let res = await doPutApiCall(data)
        if (!res.error) {
            setButtonLoading(false)
            // getSingleVendorDetailsApi(userId)
            openMessage("success", "Success", res?.message, "success")
            getServiceByBusinessInAdmin(busId)
            setValue('0')
        } else {
            setButtonLoading(false)
            openMessage("error", "Error", res?.message, "error")
        }
    }


    /**
    * @author uplSnehasish  
    * @url offers/list/user/${userId}?dataPerPage=200
    * @description This function is used to get product offers of every selected vendor, in the admin page.
    **/
    const checkEachVendorProductAdmin = async (userId, status) => {
        setLoading(true)
        let data = {
            url: `${SUB_ADMIN_API}/offers/list/user/${userId}?dataPerPage=200&status=${status}`
        }
        let res = await doGetApiCall(data)
        if (!res?.error) {
            setLoading(false)
            dispatch(getSingleVendorProducts(res?.result))
        }
        else {
            setLoading(false)
            dispatch(getSingleVendorProducts([]))
        }
    }

    /**
    * @author uplSnehasish   
    * @description This function is used to approve the product of the selected vendor.
    **/
    const approveVendorProducts = async (offerId, userId) => {
        setButtonLoading(true)
        let data = {
            url: `${SUB_ADMIN_API}/offers/approve/${offerId}`,
            bodyData: {
                status: "active"
            }
        }
        let res = await doPutApiCall(data)
        if (!res.error) {
            setButtonLoading(false)
            checkEachVendorProductAdmin(userId)
            setValue('0')
            setValueOffer('succeeded')
            openMessage("success", "Success", res?.message, "success")
        } else {
            setButtonLoading(false)
            openMessage("error", "Error", res?.message, "error")
        }
    }

    // useEffect(() => {
    //     console.log(value, valueOffer, "***")
    // }, [value, valueOffer])

    /**
    * @author uplSnehasish   
    * @description This function is used to get all the sub-admins that are being created by admin
    **/
    const getAllSubAdmin = async () => {
        setLoading(true)
        let data = {
            url: `${SUB_ADMIN_API}/users?type=subAdmin&dataPerPage=200`,
        }
        let res = await doGetApiCall(data)
        if (!res?.error) {
            setLoading(false)
            dispatch(getSubAdmin(res?.result))
        } else {
            setLoading(false)
            dispatch(getSubAdmin([]))
        }
    }


    const subAdminId = editSubAdminFormData?.id
    /**
    * @author uplSnehasish
    * @url api/user/userId   
    * @description This function is used to edit a particular sub-admin that was created
    **/
    const editSubAdminApiCall = async (formData) => {
        setLoading(true)
        let arrayApi = []
        if (formData?.roles && formData.roles?.length > 0) {
            for (let i = 0; i < formData.roles.length; i++) {
                let obj = {}
                obj.name = formData.roles[i]
                arrayApi.push(obj);
            }
        }
        let phoneNum = '+'.concat('', formData.phoneNumber)
        let data = {
            url: `${SUB_ADMIN_API}/users/${subAdminId}`,
            bodyData: {
                email: formData.email,
                name: formData.fullName,
                subRole: formData.subRole,
                phoneNo: phoneNum,
                governorate: formData.governorate,
                place: formData.places,
                sections: arrayApi,
                type: 'subAdmin',
                profilePicture: {
                    name: logoUpload?.originalname || logoUpload?.name,
                    key: logoUpload?.key,
                    mimetype: logoUpload?.mimetype,
                    location: logoUpload?.location,
                    size: logoUpload?.size
                },
            }
        }
   
        let res = await doPutApiCall(data)
        if (!res?.error) {
            cleanupMedia()
            setLoading(false)
            openMessage("success", "Success", res?.message, "success")
            setShowSubAdminForm(!showSubAdminForm)
            goBackToTable()
        } else {
            setLoading(false)
            openMessage("error", "Error", res?.message, "error")
        }
    }

    /**
    * @author uplSnehasish
    * @url api/user/userId   
    * @description This function is used to get all the sub-admins that are being created by admin
    **/
    const deleteSubAdminApiCall = async (userId) => {
        let data = {
            url: `${SUB_ADMIN_API}/users/${userId}`,
        }
        let res = await doDeleteApiCall(data)
        if (!res?.error) {
            // setShowSubAdminForm(!showSubAdminForm)
            openMessage("success", "Success", res?.message, "success")
            getAllSubAdmin()
        } else {
            openMessage("error", "Error", res?.message, "error")
        }
    }

    /**
    * @author uplSnehasish
    * @description this function is used to debounce search results for users/vendors.
    */
    const handleSearch = (data) => {
        setSearchString(data)
    }
    // For users 
    const handleSearchUser = (e) => {
        debounceSearch(e.target.value)
    }
    // For Vendors
    const handleSearchVendor = (e) => {
        debounceSearch(e.target.value)
    }
    const debounceSearch = _.debounce(handleSearch, 500)



    // start notification seting function
    const [updateNotifications, setUpdateNotifications] = useState(false)


    const checkSpecialAnnouncement = () => {
        setSpecialAnnouncement(!specialAnnouncement)
    }
    const checkAccountExpiry = () => {
        setAccountExpiry(!accountExpiry)
    }
    const checkNewOffer = () => {
        setNewOffer(!newOffer)
    }

    const checkNewUser = () => {
        setNewUser(!newUser)
    }
    const checkSubscriptionActivity = () => {
        setSubscriptionActivity(!subscriptionActivity)
    }
    const checkBidConfirmation = () => {
        setBidConfirmation(!bidConfirmation)
    }

    const selectTypeMsg = (e) => {
        setDropdownName(e)
    }

    /**
     * @author uplRajdwip
     * @method POST
     * @description- this function is used to create setting notification
     */
    const postNotificationSeting = async () => {
        let data = {
            url: `${SUB_ADMIN_API}/settings`,
            bodyData: {
                specialAnnouncement: specialAnnouncement,
                accountExpiry: accountExpiry,
                newOffer: newOffer,
                type: notificationType,
                alert: dropdownName ? dropdownName : null,
                emailNotification: dropdownName ? dropdownName : null,
                newUser: newUser,
                subscriptionActivity: subscriptionActivity,
                bidConfirmation: bidConfirmation
            }
        };
        let res = await doPostApiCall(data)
        if (!res.error) {
            // setSpecialAnnouncement(false)
            // setAccountExpiry(false)
            // setNewOffer(false)
            // setNewUser(false)
            // setSubscriptionActivity(false)
            // setBidConfirmation(false)
            // setDropdownName()
            // setNotificationFound(true)
            getNotificationSetingData()
            // setUpdateNotifications(true)
            openMessage("success", "Success", res?.message, "success")
        } else {
            notificationNotFound(true);
            openMessage("error", "Error", res?.message, "error")
        }
    }

    /**
     * @author uplRajdwip
     * @method GET
     * @description- this function is used to get setting notification
     */

    const getNotificationSetingData = async () => {
        let data = {
            url: `${SUB_ADMIN_API}/settings`
        };
        let res = await doGetApiCall(data)
        if (!res.error) {
            dispatch(getTotalNotification(res?.result))
            setAccountExpiry(res?.result[0]?.accountExpiry)

            setSpecialAnnouncement(res?.result[0]?.specialAnnouncement)

            setNewUser(res?.result[0]?.newUser)

            setNewOffer(res?.result[0]?.newOffer)

            setBidConfirmation(res?.result[0]?.bidConfirmation)

            setSubscriptionActivity(res?.result[0]?.subscriptionActivity)
            


        } else {
            console.log('error found');
        }
    }

    /**
 * @author uplRanabir
 * @method PUT
 * @description- this function is used to update setting notifications
 */
    const settingNotificationUpdate = async (settingId) => {
        let data = {
            url: `${SUB_ADMIN_API}/settings/${settingId}`,
            bodyData: {
                specialAnnouncement: specialAnnouncement,
                accountExpiry: accountExpiry,
                newOffer: newOffer,
                type: notificationType,
                alert: dropdownName ? dropdownName : null,
                emailNotification: dropdownName ? dropdownName : null,
                newUser: newUser,
                subscriptionActivity: subscriptionActivity,
                bidConfirmation: bidConfirmation
            }
        };
        let res = await doPutApiCall(data)
        if (!res.error) {
            setSpecialAnnouncement(false)
            setAccountExpiry(false)
            setNewOffer(false)
            setNewUser(false)
            setSubscriptionActivity(false)
            setBidConfirmation(false)
            setDropdownName()
            setNotificationFound(true)
            getNotificationSetingData()
            openMessage("success", "Success", res?.message, "success")
        } else {
            notificationNotFound(true);
            openMessage("error", "Error", res?.message, "error")
        }
    }

    // end notification seting function

    /**
    *@uplSnehasish
    *@description- This function is used for making api call for getting all the requirements of one vendor in admin 
    **/
    const getVendorReqByVendorIdAdmin = (userId, status) => {
        setLoading(true)
        let data = {
            url: `${process.env.REACT_APP_DG_BASE_URL}/requirements/user?userId=${userId}&dataPerPage=200&status=${status}`
        }
        doGetApiCall(data).then((res) => {
            if (!res.error && res.status === 200) {
                setLoading(false)
                dispatch(getSingleVendorRequirements(res.result))
                dispatch(getSingleUserRequirements(res.result))
            }
            else {
                setLoading(false)
                dispatch(getSingleVendorRequirements([]))
                dispatch(getSingleUserRequirements([]))
            }
        })
            .catch(err => {
                console.log(err, "error")
            })
    }


    /**
    * @author uplSnehasish   
    * @description This function is used to approve the requirements of the vendors.
    **/
    const approveVendorRequirements = async (reqId, statusValue, userId, reason) => {
        setButtonLoading(true)

        let data = {
            url: `${SUB_ADMIN_API}/requirements/approve/${reqId}`,
            bodyData: {
                status: statusValue,
            },
        };

        if (reason) {
            data = { ...data, bodyData: { ...data.bodyData, declineReason: reason } };
        }
        let res = await doPutApiCall(data)
        if (!res.error) {
            setButtonLoading(false)
            getVendorReqByVendorIdAdmin(userId)
            openMessage("success", "Success", res?.message, "success")
        } else {
            setButtonLoading(false)
            openMessage("error", "Error", res?.message, "error")
        }
    }

    /**
         * @author uplRanabir
         * @method downloadExcelReport
         * @description This function download excel file in admin dashboard
         */
    const downloadExcelReportDashboard = async (type) => {
        try {
            let token;
            if (typeof window !== 'undefined') {
                token = localStorage.getItem('token')
            }
            let url = `${SUB_ADMIN_API}/users/file/download?type=${type}`
            const requestValues = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: token || "",
                },
            };
            const res = await fetch(url, requestValues)
            const blob = await res.blob();
            const newBlob = new Blob([blob]);
            const blobUrl = window.URL.createObjectURL(newBlob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `${type}-exported.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            // clean up Url
            window.URL.revokeObjectURL(blobUrl);
        }
        catch (err) {
            console.log(err);
        }
    }

    const [dateOpen, setDateOpen] = useState(false)
    const handleOpenDate = () => {
        setDateOpen(!dateOpen)
    }
    const [dateState, setDateState] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);

    const formatStartDate = dateState[0]?.startDate?.toISOString()
    const formatEndDate = dateState[0]?.endDate?.toISOString()

    /**
  * @uplRanabir
 * @description- this function is used for making api call to get dashboard data
  */
    const getDashboardData = () => {
        let data;
        if (dateState[0]?.startDate && dateState[0]?.endDate) {
            data = {
                url: `${process.env.REACT_APP_DG_BASE_URL}/users/dashboard?startDate=${formatStartDate}&endDate=${formatEndDate}`
            }
        } else {
            data = {
                url: `${process.env.REACT_APP_DG_BASE_URL}/users/dashboard`
            }
        }

        doGetApiCall(data).then((res) => {
            if (!res?.error) {
                dispatch(getDashBoardData(res?.result))
                dateOpen === true && setDateOpen(!dateOpen)
                // setDateState(dateState[0]?.startDate === new Date(), dateState[0]?.endDate === new Date())
            } else {
                dispatch(getDashBoardData(res?.result))
                setDateOpen(!dateOpen)

            }
        })
            .catch(err => {
                console.log(err, "error")
            })

    }

    /**
  * @uplSoumyadeepNandi
 * @description- this function is used for making api call to get vendor services by their id
  */

    const getServicesbyServiceIdApiCall = (serviceId) => {
        setLoading(true)
        let data = {
            url: `${process.env.REACT_APP_DG_BASE_URL}/services/${serviceId}`
        }
        doGetApiCall(data).then((res) => {
            if (!res.error && res.status === 200) {
                setLoading(false)
                dispatch(getServicesByServiceId(res.result))
            }
            else {
                setLoading(false)
                dispatch(getServicesByServiceId([]))
            }
        })
            .catch(err => {
                console.log(err, "error")
            })
    }

    // function to handle click in offers page

    const handleServices = (id) => {
        if (id !== undefined) {
            navigate(`/servicedetails/${id}`)
        } else {
            console.log(id, 'not switchable');
        }
    }


    /**
    * @author uplSnehasish
    * @method DELETE
    * @description this function is used for deleting sub-category
    */
    const deleteSubCategoryApiCall = async (subCatId) => {
        if (subCatId) {
            let data = {
                url: `${SUB_ADMIN_API}/categories/sub/categories`,
                bodyData: {
                    "subCatIds": [
                        subCatId
                    ]
                }
            }
            let res = await doDeleteApiCall(data)
            if (!res.error) {
                getCategory()
                openMessage("success", "Success", res?.message, "success")

            } else {
                openMessage("error", "Error", res?.message, "error")
            }
        }
    }

    const handlePrivPolTextEditor = () => {
        setShowPrivPolTextEditor(!showPrivPolTextEditor)
    }

    const handleFaqTextEditor = () => {
        setShowFaqTextEditor(!showFaqTextEditor)
    }

    /**
    * @author uplSnehasish
    * @description- This function is used for making api call during creating FAQs
    */
    const createFaqApiCall = (headingValue, questionValue, contentValue, type) => {
        setLoading(true)
        let data = {
            url: `${SUB_ADMIN_API}/faqs`,
            bodyData: {
                type: type,
                // heading: headingValue,
                // questions: questionValue,
                answers: contentValue
            }
        }
        if (type === 'privacyPolicy') {
            data.bodyData.heading = headingValue
        } else if (type === 'faq') {
            data.bodyData.questions = questionValue
        }
        doPostApiCall(data).then((res) => {
            if (!res.error) {
                openMessage("success", "Success", res?.message, "success")
                setLoading(false)
                setShowPrivPolTextEditor(false)
                setShowFaqTextEditor(false)
                getFaqInAdminApiCall()
            } else {
                setLoading(false)
                openMessage("error", "Error", res?.message, "error")
            }
        }).catch(err => {
            console.log(err, "error")
        })
    }

    /**
    *@author uplSnehasish
    *@description- This function is used for making api call for getting all posted FAQs in admin panel
    **/
    const getFaqInAdminApiCall = () => {
        setLoading(true)
        let data = {
            url: `${SUB_ADMIN_API}/faqs?dataPerPage=2000`
        }

        doGetApiCall(data).then((res) => {
            if (!res.error && res?.status === 200) {
                setLoading(false)
                dispatch(faqInAdmin(res?.result))
            } else {
                setLoading(false)
                dispatch(faqInAdmin([]))
            }
        }).catch(err => {
            console.log(err, "error")
        })
    }

    /**
    *@author uplRanabir
    *@description- This function is used for making api call for getting details of a clicked  FAQs in admin panel
    **/
    const getFaqDetailsApi = (faqId) => {
        setLoading(true)
        let data = {
            url: `${SUB_ADMIN_API}/faqs/${faqId}`
        }

        doGetApiCall(data).then((res) => {
            if (!res.error && res?.status === 200) {
                setLoading(false)
                setPrivacyDetails(res.result)
                dispatch(getFaqDetails(res?.result))
            } else {
                setLoading(false)
                setPrivacyDetails({})
                dispatch(getFaqDetails({}))
            }
        }).catch(err => {
            console.log(err, "error")
        })
    }

    /**
    *@author uplSnehasish
    *@description- This function is used for making api call for getting details of a clicked  FAQs in admin panel
    **/
    const getMostViewedVendorsApiCall = () => {
        setLoading(true)
        let data;
        if (dateForViews[0]?.startDate && dateForViews[0]?.endDate) {
            data = {
                url: `${process.env.REACT_APP_DG_BASE_URL}/users/top/views?startDate=${dateForViews[0]?.startDate?.toISOString()}&endDate=${dateForViews[0]?.endDate?.toISOString()}`
            }
        } else {
            data = {
                url: `${SUB_ADMIN_API}/users/top/views`
            }
        }
        doGetApiCall(data).then((res) => {
            if (!res.error && res?.status === 200) {
                setLoading(false)
                setDateOpenViews(false)
                setPrivacyDetails(res.result)
                dispatch(mostViewedReducer(res?.result))
            } else {
                setDateOpenViews(false)
                setLoading(false)
                setPrivacyDetails({})
                dispatch(mostViewedReducer([]))
            }
        }).catch(err => {
            console.log(err, "error")
        })
    }


    // API calls for Subscription Plans 
    const subscriptionBody = (planData) => {
        let bodyData = {};
        if (planData?.type === 'addOns') {
            if (planData?.addonsType === 'service') {
                bodyData.serviceOffered = {
                    'count': planData?.serviceOfferedCount,
                    'photoLimit': planData?.serviceOfferedPhotoLimit
                }
            } else if (planData?.addonsType === 'postOffer') {
                bodyData.monthlyPostOffers = {
                    'count': planData?.postOffersCount,
                    'photoLimit': planData?.postOffersPhotoLimit
                }
            } else {
                bodyData.monthlyPostRequirements = {
                    'count': planData?.postRequirementCount,
                    'photoLimit': planData?.postRequirementPhotoLimit
                }
            }

        } else {
            bodyData.durationInDays = planData?.durationInDays
            bodyData.freeDuration = planData?.freeDuration
            bodyData.photoLimit = planData?.photoLimit
            bodyData.videoLimit = planData?.videoLimit

            bodyData.serviceOffered = {
                'count': planData?.serviceOfferedCount,
                'monthlyChangeLimit': planData?.serviceOfferedChangeLimit,
                'photoLimit': planData?.serviceOfferedPhotoLimit
            }
            bodyData.monthlyPostOffers = {
                'count': planData?.postOffersCount,
                'monthlyChangeLimit': planData?.postOffersChangeLimit,
                'photoLimit': planData?.postOffersPhotoLimit
            }
            bodyData.monthlyPostRequirements = {
                'count': planData?.postRequirementCount,
                'monthlyChangeLimit': planData?.postRequirementChangeLimit,
                'photoLimit': planData?.postRequirementPhotoLimit
            }
        }
        bodyData.name = planData?.name
        bodyData.price = planData?.price
        bodyData.planType = planData?.type
        bodyData.priority = planData?.priority

        // bodyData.accountView = planData?.listing
        // bodyData.videoDuration = planData?.videoDuration

        return bodyData
    }

    /**
    * @method POST
    * @author uplSnehasish
    * @description - This function is used for making api call for creating Subscription Plan
    **/
    const createSubscriptionPlansApiCall = (planData) => {
        // setLoading(true)
        let body = subscriptionBody(planData)
        let data = {
            url: `${process.env.REACT_APP_DG_BASE_URL}/plan`,
            bodyData: body
        }
        doPostApiCall(data).then((res) => {
            if (res?.status === 201) {
                openMessage("success", "Success", res?.message, "success")
                getSubscriptionPlansApiCall()
                setShowSubscriptionForm(false)
                setLoading(false)
            } else {
                openMessage("error", "Error", res?.message, "error")
                setLoading(false)
            }
        }).catch(err => {
            console.log(err, "error")
        })
    }

    /**
    * @method GET
    * @author uplSnehasish
    * @description - This function is used for making api call for getting all Subscription Plans
    **/
    const getSubscriptionPlansApiCall = () => {
        setLoading(true)
        let data = {
            url: `${process.env.REACT_APP_DG_BASE_URL}/plan?dataPerPage=300`,
        }
        doGetApiCall(data).then((res) => {
            if (res?.status === 200) {
                // openMessage("success", "Success", res?.message, "success")
                dispatch(savePlanData(res?.result))
                setLoading(false)
            } else {
                openMessage("error", "Error", res?.message, "error")
                setLoading(false)
            }
        }).catch(err => {
            console.log(err, "error")
        })
    }

    /**
    * @method PUT
    * @author uplSnehasish
    * @description - This function is used for making api call for updating Subscription Plan
    **/
    const updateSubscriptionPlansApiCall = async (planId, planData) => {
        setLoading(true)
        let bodyData = subscriptionBody(planData)
        let data = {
            url: `${process.env.REACT_APP_DG_BASE_URL}/plan/${planId}`,
            bodyData: bodyData
        }
        let res = await doPutApiCall(data)
        if (res?.code === 'PLAN_UPDATED') {
            getSubscriptionPlansByIdApiCall(planId)
            setShowSubscriptionForm(false)
            openMessage("success", "Success", res?.message, "success")
            setLoading(false)
        } else {
            setLoading(false)
            openMessage("error", "Error", res?.message, "error")
        }
    }

    /**
    * @method GET
    * @author uplSnehasish
    * @description - This function is used for making api call for getting all Subscription Plans by planId
    **/
    const getSubscriptionPlansByIdApiCall = (planId) => {
        setLoading(true)
        let data = {
            url: `${process.env.REACT_APP_DG_BASE_URL}/plan/${planId}`,
        }
        doGetApiCall(data).then((res) => {
            if (!res.error) {
                dispatch(singlePlanData(res?.result))
                openMessage("success", "Success", res?.message, "success")
                setLoading(false)
            } else {
                openMessage("error", "Error", res?.message, "error")
                setLoading(false)
            }
        }).catch(err => {
            console.log(err, "error")
        })
    }

    /**
    * @author uplSnehasish
    * @description This function is used to delete a subscription plan
    **/
    const deleteSubscriptionPlanApiCall = async (planId) => {
        let data = {
            url: `${SUB_ADMIN_API}/plan/${planId}`,
        }
        let res = await doDeleteApiCall(data)
        if (!res?.error) {
            getSubscriptionPlansApiCall()
            openMessage("success", "Success", res?.message, "success")
        } else {
            openMessage("error", "Error", res?.message, "error")
        }
    }

    /**
    * @method POST
    * @author uplAyan
    * @description - This function is used for making api call for creating corporate Subscription for user 
    **/
    const createCorporateSubscription = (planData, userId) => {
        setLoading(true)
        let body = {
            "amount": planData?.price,
            "currency": planData?.currency,
            // "authId": "string",
            "type": "subscription",
            "planId": planData?.id,
            "userId": userId
        }
        let data = {
            url: `${process.env.REACT_APP_DG_BASE_URL}/payment`,
            bodyData: body
        }
        doPostApiCall(data).then((res) => {
            if (res?.status === 201) {
                openMessage("success", "Success", res?.message, "success")
                getTotalVendorsInAdmin();
                setLoading(false)
            } else {
                openMessage("error", "Error", res?.message, "error")
                setLoading(false)
            }
        }).catch(err => {
            console.log(err, "error")
        })
    }

    /**
    * @method GET
    * @author uplBhaskar
    * @description - This function is used for get subscription list 
    **/
    const getAllSubscription = () => {
        try {
            setLoading(true)
            let data = {
                url: `${process.env.REACT_APP_DG_BASE_URL}/payment/users/subscriptions/list`,
            }
            doGetApiCall(data).then((res) => {
                if (!res.error) {
                    dispatch(getSubscriptionList(res?.result))
                    openMessage("success", "Success", res?.message, "success")
                    setLoading(false)
                } else {
                    openMessage("error", "Error", res?.message, "error")
                    setLoading(false)
                }
            }).catch(err => {
                console.log(err, "error")
                setLoading(false)

            })

        } catch (error) {
            setLoading(false)
            console.log(error, "Error in all subscription list get")
        }
    }

    const getAllApproveMedia = () => {
        try {
            setLoading(true)
            let data = {
                url: `${process.env.REACT_APP_DG_BASE_URL}/business?request=media`,
            }
            doGetApiCall(data).then((res) => {
                if (!res.error) {
                    dispatch(getApproveMediaList(res?.result))
                    // openMessage("success", "Success", res?.message, "success")
                    setLoading(false)
                } else {
                    // openMessage("error", "Error", res?.message, "error")
                    setLoading(false)
                    dispatch(getApproveMediaList([]))
                }
            }).catch(err => {
                console.log(err, "error")
                setLoading(false)

            })

        } catch (error) {
            setLoading(false)
            console.log(error, "Error in all approve list get")
        }
    }

    const approveUser = async (id, action) =>{
        const data = {
            url:`${process.env.REACT_APP_DG_BASE_URL}/business/media/approve/${id}`,
            bodyData: {status:action}
        }
        try {
            const res = await doPutApiCall(data);
            if (!res.error) {
                // openMessageSignup("success", "Success", t("vendorCreationSuccess"), "success")
                openMessage("success", "Success", res?.message, "success")
                dispatch(getAllApproveMedia((res?.result)))
                setLoading(false)
            }  else {
                openMessage("error", "Error", res?.message, "error")
                dispatch(getAllApproveMedia([]))
                setLoading(false)
            }
        } catch (err) {
            setLoading(false)
            console.error('API Error:', err);
        }
    }

    const getTranstactionData = () => {
        try {
            setLoading(true)
            let data = {
                url: `${process.env.REACT_APP_DG_BASE_URL}/payment`,
            }
            doGetApiCall(data).then((res) => {
                if (!res.error) {
                    dispatch(getTransactionList(res?.result))
                    openMessage("success", "Success", res?.message, "success")
                    setLoading(false)
                } else {
                    openMessage("error", "Error", res?.message, "error")
                    setLoading(false)
                }
            }).catch(err => {
                console.log(err, "error")
                setLoading(false)

            })
        } catch (error) {
            setLoading(false)
            console.log(error, "Error in all subscription list get")
        }
    }

    const downloadPdfAttachments = async (docObject) => {
        console.log(docObject, "docObject")
        try {
            let token;
            if (typeof window !== 'undefined') {
                token = localStorage.getItem('token')
            }
            let url = `${SUB_ADMIN_API}/uploads/download/file?key=${docObject?.key}`
            const requestValues = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: token || "",
                },
            };
            const res = await fetch(url, requestValues)
            if (res.status !== 200) {
                throw new Error(`Failed to download PDF: ${res.status}`);
            }
            const blob = await res.blob();
            const newBlob = new Blob([blob], { type: "application/pdf" });
            const blobUrl = window.URL.createObjectURL(newBlob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `${docObject?.name}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            // clean up Url
            window.URL.revokeObjectURL(blobUrl);
        }
        catch (err) {
            console.error(err);
        }
    }

    const deletePrivacy = async (id) => {
        let data = {
            url: `${process.env.REACT_APP_DG_BASE_URL}/faqs`,
            bodyData: {
                "faqIds": [id]
            }
        }
        doDeleteApiCall(data)
            .then((res) => {
                if (!res.error) {
                    openMessage("success", "Success", "Deleted successfully", "success");
                    getFaqInAdminApiCall();
                }
                else {
                    openMessage("error", "Error", res?.message, "error")
                }
            })
            .catch(err => {
                console.log(err, "error")
                openMessage("error", "Error", err?.message, "error")
            })
    }
    const router = useLocation();
    
    useEffect(() => {
        if (router?.state?.length > 0) {
            setValue('1');
        }
    }, [router])


     // edit vendor 
   

    const [vendorBusinessData, setVendorBusinessData] = useState(null)
    const [businessLoader, setBusinessLoader] = useState(false)


     const getBusiness = async () => {
        setBusinessLoader(true)
        let data = {
          url: `${SUB_ADMIN_API}/business/${editVendorFormData?.id}`
        }
        let res = await doGetApiCall(data)
        if (!res?.error) {
          setVendorBusinessData(res?.result)
          setBusinessLoader(false)
        } else {
          setBusinessLoader(false)
        }
      }

      useEffect(()=>{
        if(editVendorFormData?.id){
            getBusiness()
        }
      },[editVendorFormData])


        const editVendorProfileDetailsApi = async (vendorAdminData, latlng) => {
          setLoading(true)
          if (vendorBusinessData?.user?.name !== vendorAdminData?.name) {
            let data = {
              url: `${SUB_ADMIN_API}/users/${userId}`,
              bodyData: {
                name: vendorAdminData.name,
                phoneNo: vendorAdminData.phoneNo,
              }
            }
              data.bodyData.lat = latlng?.lat
              data.bodyData.long = latlng?.lng
              data.bodyData.location = locationSelector?.length > 0 ? locationSelector : vendorAdminData.location
           
            let res = await doPutApiCall(data)
            if (!res.error) {
              setLoading(false)
              setEditProfile(false)
                setLoading(false)
                getTotalVendorsInAdmin()
                setVendorBusinessData(null)
                dispatch(getLocationReducer(''));
                setEditTabValue("0")
                if (res.code) {
                      openMessage("success", "Success", res.message , "success")
                  }
            }
            else {
                setLoading(false);
                openMessage("error", "Error", res.message , "error")
            }
          }
          else {
            setLoading(false)
            // setVendorBusinessData(null)
            openMessage("warning", "Warning", 'Nothing to update' , "success")
          }
        }
      
      
        const editVendorBusinessDetailsApi = async (data, location) => {
           const item = {...data, shiftTimes :[{
            startTime: moment(data?.shiftTimes).format('LT'),
            endTime: moment(data?.shiftTimes).format('LT')
       }]} 
          let obj = {
            name: vendorBusinessData.name,
            governorate: vendorBusinessData.governorate,
            description: vendorBusinessData.description,
            places: vendorBusinessData.places,
            location: vendorBusinessData.location,
            lat: vendorBusinessData?.lat,
            lng: vendorBusinessData?.long,
            fullAddress: vendorBusinessData.address,
            street: vendorBusinessData.street,
            block: vendorBusinessData.block,
            buildingNo: vendorBusinessData.buildingNo,
            floor: vendorBusinessData.floor,
            officeNo: vendorBusinessData.officeNo,
            startTime: (new Date(["1999-04-06", vendorBusinessData?.startTime])),
            endTime: (new Date(["1999-04-06", vendorBusinessData?.endTime])),
            date: vendorBusinessData.establishmentYear,
            shiftTimes: [{
                               startTime: moment(vendorBusinessData?.shiftTime).format('LT'),
                               endTime: moment(vendorBusinessData?.shiftTime).format('LT')
                          }],
          }
      
          const isKeySameInBothArr = (arr1, arr2, key) => {
            if (arr1.length !== arr2.length) {
              return false; // Arrays have different lengths, keys cannot be equal
            }
            for (let i = 0; i < arr1.length; i++) {
              if (arr1[i][key] !== arr2[i][key]) {
                return false; // Key values are not equal in at least one pair of objects
              }
            }
            return true; // All key values are equal in corresponding objects
          }
          const resultForArr = isKeySameInBothArr(multipleUpload, vendorBusinessData?.thumbnail, "key");
        //   console.log(obj, item, "vendorBusinessData $$") 
      
          if (_.isEqual(obj, item) === false || vendorBusinessData?.civilDocument?.key !== documentUpload?.key || vendorBusinessData?.logo?.key !== logoUpload?.key || resultForArr === false) {
            setLoading(true)
            let data = {
              url: `${SUB_ADMIN_API}/business/${businessId}`,
              bodyData: {
                name: item.name,
                governorate: item.governorate,
                description: item.description,
                places: item.places,
                location: locationSelector?.length > 0 ? locationSelector : item.location,
                lat: location?.lat,
                long: location?.lng,
                address: item.fullAddress,
                street: item.street,
                block: item.block,
                buildingNo: item.buildingNo,
                floor: item.floor,
                officeNo: item.officeNo,
                startTime: moment(item.startTime).format('LT'),
                endTime: moment(item.endTime).format('LT'),
                establishmentYear: moment(item.date).year().toString(),
                shiftTimes: item?.shiftTimes,
              }
            }
            
            if (vendorBusinessData?.civilDocument?.key !== documentUpload?.key) {
              data = {
                ...data, bodyData: {
                  ...data?.bodyData, civilDocument: {
                    name: documentUpload?.originalname ?? documentUpload?.name,
                    key: documentUpload?.key,
                    mimetype: documentUpload?.mimetype,
                    location: documentUpload?.location,
                    size: documentUpload?.size
                  }
                }
              }
            }
      
            if (vendorBusinessData?.logo?.key !== logoUpload?.key) {
              data = {
                ...data, bodyData: {
                  ...data?.bodyData, logoPicture: {
                    name: logoUpload?.originalname ?? logoUpload?.name,
                    key: logoUpload?.key,
                    mimetype: logoUpload?.mimetype,
                    location: logoUpload?.location,
                    size: logoUpload?.size
                  },
                }
              }
            }
      
            if (!resultForArr) {
              data = {
                ...data, bodyData: {
                  ...data?.bodyData,  businessThumbnail: multipleUpload,
                }
              }
            }
      
            
            let res = await doPutApiCall(data)
            if (!res.error) {
              switch (res.code) {
                case 'BUSINESS_UPDATED':
                  setLoading(false);
                  setEditProfile(false);
                  dispatch(getLocationReducer(''));
                  getTotalVendorsInAdmin()
                  setVendorBusinessData(null)
                  setEditTabValue("0")
                  openMessage("success", "Success", res.message , "success")
                  break;
              
                default:
                  setLoading(false);
                  setEditProfile(false);
                  setEditTabValue("0");
                  openMessage("success", "Success", res.message , "success")
                  break;
              }
      
            } else {
                setLoading(false);
                openMessage("error", "Error", res.message , "error")
            }
          }
          else {
            setLoading(false)
            setVendorBusinessData(null)
            openMessage("warning", "Warning", 'Nothing to update' , "success")
          }
        }
      

    return {

        //edit vendor
        toggleEditProfile,
        editProfile,
        vendorBusinessData, 
        businessLoader,
        editVendorProfileDetailsApi,
        editVendorBusinessDetailsApi,
        closeForm,

        subAdminCreate,
        showSubAdminForm,
        setShowSubAdminForm,
        toggleSubAdmin,
        // subAdminUser,
        goBackToTable,
        hyperlinkForm,
        setHyperlinkForm,
        showHyperlinkForm,
        createHyperlink,
        allLinks,
        getAllHyperlinks,
        goToLinkTable,
        uploadData,
        sendData,
        getTotalVendorsInAdmin,
        getAllUsers,
        userForm,
        toggleUserForm,
        createNewUserByAdmin,
        handleClickForOpenVendorTable,
        createVendorByAdmin,
        businessAddAddressByAdmin,
        openVendorTable,
        showAddAddress,
        editclick,
        editbusinessAddressDetails,
        handleeditclick,
        editVendorFormData,
        // deleteclick,
        editUserApi,
        editUserForm,
        editUserFormData,
        getTotalUserDetailsApi,
        approveUserApi,
        approveUser,
        downloadExcelReport,
        userEnableDisableApi,
        getSingleVendorDetailsApi,
        giveBadgetoUser,
        createCategory,
        getCategory,
        editCategory,
        deleteCategory,
        editSubCategory,
        categoryOpenModal,
        CategoryDescription,
        openModal,
        cancleModal,
        addNewCategoryName,
        newCategoryName,
        openEditName,
        categoryId,
        subCategoryModal,
        openSubCategoryModal,
        subCategoryModalClose,
        subCategoryId,
        addNewSubCategoryName,
        openEditSubCategoryName,
        newSubCategoryName,
        editHyperlinkApi,
        editHyperlinkForm,
        hyperlinkFormData,
        deleteHyperlinkApi,
        addCategoryName,
        addCategoryDescription,
        approveVendorService,
        declineVendorService,
        setGovFieldData,
        checkEachVendorProductAdmin,
        approveVendorProducts,
        getAllSubAdmin,
        editSubAdminForm,
        editSubAdminApiCall,
        editSubAdminFormData,
        deleteSubAdminApiCall,
        handleSearchUser,
        handleSearchVendor,
        searchString,
        getServiceByBusinessInAdmin,
        checkSpecialAnnouncement,
        checkAccountExpiry,
        checkNewOffer,
        postNotificationSeting,
        checkNewUser,
        checkSubscriptionActivity,
        checkBidConfirmation,
        specialAnnouncement,
        accountExpiry,
        newOffer,
        newUser,
        subscriptionActivity,
        bidConfirmation,
        selectTypeMsg,
        dropdownName,
        getNotificationSetingData,
        notificationFound,
        notificationNotFound,
        updateNotifications,
        settingNotificationUpdate,
        getVendorReqByVendorIdAdmin,
        approveVendorRequirements,
        downloadExcelReportDashboard,
        dateState,
        setDateState,
        getDashboardData,
        handleOpenDate,
        dateOpen,
        value,
        setValue,
        valueOffer,
        subAdminCreateMsg,
        totalUserCreateMsg,
        totalVendorCreateMsg,
        setValueOffer,
        adminSnackbar,
        messageClose,
        uploadLogoProfile,
        setUploadLogoProfile,
        // handelChange,
        treeUploadLogoProfile,
        setTreeUploadLogoProfile,
        // saveId,
        dataValue,
        loading,
        CategoryForm,
        getServicesbyServiceIdApiCall,
        handleServices,
        subCategoryForm,
        editSubCategoryData,
        deleteSubCategoryApiCall,
        createFaqApiCall,
        handlePrivPolTextEditor,
        showPrivPolTextEditor,
        getFaqInAdminApiCall,
        showFaqTextEditor,
        handleFaqTextEditor,
        getFaqDetailsApi,
        privacyDetails,
        handleSearchCategory,
        categorySearchString,
        searchSubCategoryApi,
        subCatSearchHandler,
        subCatSearchString,
        buttonLoading,
        getMostViewedVendorsApiCall,
        dateForViews,
        setDateForViews,
        dateOpenViews,
        setDateOpenViews,

        getSubscriptionPlansApiCall,
        showSubscriptionForm,
        handleSubscriptionForm,
        createSubscriptionPlansApiCall,
        editPlan,
        editSubscriptionFormHandler,
        updateSubscriptionPlansApiCall,
        deleteSubscriptionPlanApiCall,
        createCorporateSubscription,
        getAllSubscription,
        getAllApproveMedia,
        getTranstactionData,
        downloadPdfAttachments,
        deletePrivacy,
        defaultSubcat,
        setDefaultSubcat,
        showannouncementForm,
        announcementCreate,
        toggleAnnounce,
        getAllAnnouncement,
        deleteAnnouncement,
        goBackToAnnounceTable,
        editTabValue, 
        setEditTabValue
    }
}