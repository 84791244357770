import React from 'react'
import { useSelector } from 'react-redux'
import AdminLineChart from '../AdminLineChart'
import AdminBarChart from '../AdminBarChart'
import AdminPieChart from '../AdminPieChart'
import TotalUsersHeader from '../TotalUsers/TotalUsersHeader'
import DashboardCard from './DashboardCard'
import DashboardTop from './DashboardTop'
// import HighestRatedVendorTable from './HighestRatedVendorTable'
import MostViewedVendorTable from './MostViewedVendorTable'
// import TotalUsersHomeTable from './TotalUsersHomeTable'
// import { useEffect } from 'react'
 
const DashboardCompo = (props) => {
  // const totalUsersData = useSelector((state) => state.totalUsers.totalUsers)
  // const totalVendorsData = useSelector((state) => state.totalVendorsInAdmin.totalVendors)
  const dashboardData = useSelector((state) => state.dashboard.dashboardData)
 
  const cardDetails = [
    { cardHeading: 'Total Users', cardTotal: dashboardData?.totalUser, cardSavings: dashboardData?.totalUser, graphColor: '#1DBF73', btnName: '0%', endText: `user joined this year` },
    { cardHeading: 'Total Vendors', cardTotal: dashboardData?.totalVendor, cardSavings: dashboardData?.totalVendor, graphColor: '#FF0033', btnName: '0%', endText: "vendor joined this year" },
    { cardHeading: 'Total Earning', cardTotal: dashboardData?.totalEarnings, cardSavings: dashboardData?.totalEarnings, graphColor: '#FFCD46', btnName: '-- %', endText: "general user joined this year" },
    { cardHeading: 'Expired Subscription', cardTotal: dashboardData?.expiredSubscriptionUsers, cardSavings: dashboardData?.expiredSubscriptionUsers, graphColor: '#186AB2', btnName: '-- %', endText: "is most used subscription plan" },
    { cardHeading: 'Additional Requirements', cardTotal: dashboardData?.userRequirements, cardSavings: dashboardData?.userRequirements, graphColor: '#823AA7', btnName: '-- %', endText: "is Top Category Used By Vendor" }
  ]
  return (
    <div style={{ background: '#F5F5F566', padding: '2%' }} className={`sideCalender overflow-y-auto min-h-[86vh]`}>
      <DashboardTop downloadExcelReportDashboard={props.downloadExcelReportDashboard} dateState={props.dateState} setDateState={props.setDateState} getDashboardData={props.getDashboardData} handleOpenDate={props.handleOpenDate} dateOpen={props.dateOpen} />
 
      {/* Dashboard card  */}
      <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5 gap-4 w-full mt-4`}>
        {cardDetails?.map((item, idx) => (
          <DashboardCard
            cardName={item.cardHeading}
            cardTotal={item.cardTotal}
            cardSavings={item.cardSavings}
            graphColor={item.graphColor}
            btnName={item.btnName}
            endText={item?.endText}
          />
        ))}
      </div>
      <div className='w-full'>
        <AdminLineChart />
 
      </div>
 
      <div className='flex flex-wrap'>
        <div className='w-[50%] pr-4'>
          <AdminBarChart />
        </div>
 
        <div className='w-[50%] pl-4'>
          <AdminPieChart />
        </div>
      </div>
      {/* <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'> */}
      {/* <div>
          <TotalUsersHeader
            userName={`Highest Rated Vendors By Category`}
            nameCls={`text-primeryColour-3`}
            csvBtn={false}
            dropDown={true}
            addBtn={false}
          />
          <HighestRatedVendorTable />
        </div> */}
      <div>
        <TotalUsersHeader
          userName={`Most Viewed Vendors`}
          nameCls={`text-primeryColour-3`}
          csvBtn={false}
          dropDown={false}
          addBtn={false}
          showDateTimePicker={true}
          dateForViews={props.dateForViews}
          setDateForViews={props.setDateForViews}
          dateOpenViews={props.dateOpenViews}
          setDateOpenViews={props.setDateOpenViews}
        />
        <MostViewedVendorTable />
      </div>
      {/* </div> */}
    </div>
  )
}
 
export default DashboardCompo