import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import text from "../../../Common/Languages/en_US.json";
import DropDownField from '../../../Common/FormFields/DropDownField';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const AdminLineChart = () => {
  const selectArr = [
    { name: "Months", value: "months" },
    { name: "Earning", value: "earning" },
  ];

  const dashboardData = useSelector((state) => state.dashboard.dashboardData);

  const data = {
    labels,
    datasets: [
      {
        label: 'Total Vendor',
        data: dashboardData?.monthWiseVendors,
        borderColor: '#00BA34',
        backgroundColor: '#00BA34',
      },
      {
        label: 'Total Earnings',
        data: dashboardData?.monthWiseEarnings,
        borderColor: '#0085FF',
        backgroundColor: '#0085FF',
      },
    ],
  };

  const config = {
    type: 'line',
    data: data,
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
        },
        title: {
          display: false,
          text: 'Chart',
        },
      },
      scales: {
        y: {
          ticks: {
            callback: function (value) {
              return `${value} KWD`;
            },
            color: '#969696',
          },
        },
        x: {
          ticks: {
            color: '#969696',
          },
        },
      },
    },
  };

  return (
    <div className="w-full py-6">
      <div
        style={{
          boxShadow:
            '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 1px rgba(0, 0, 0, 0.08)',
        }}
        className="flex flex-col gap-4 px-5 lg:px-10 py-3 lg:py-6 rounded-lg bg-white"
      >
        <div className="flex justify-between items-center w-full">
          <p className="text-xl text-[#1C1C1C] font-medium">{text.totalearning}</p>
          {/* <DropDownField
            selectOption={selectArr}
            dropDownRootCls="h-10 pl-[25px] text-sm font-[Roboto-Medium]"
            filterImg={true}
            placeholder="Filter"
          /> */}
        </div>
        <Line options={config.options} data={data} updateMode="resize" />
      </div>
    </div>
  )
}

AdminLineChart.propTypes = {
  data: PropTypes.array,
  options: PropTypes.object,
  selectOption: PropTypes.array,
  dropDownRootCls: PropTypes.string,
  filterImg: PropTypes.bool,
  placeholder: PropTypes.string,
}

export default AdminLineChart;
