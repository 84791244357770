import React from 'react'
import img1 from '../../../Images/Vendor/offerimg1.png'
import img2 from '../../../Images/Vendor/offerimg2.png'
import img3 from '../../../Images/Vendor/offerimg3.png'
import contactvendorimg from '../../../Images/Vendor/contactvendorimage.svg'
import trackicon from '../../../Images/Vendor/trackicon.svg'
import CommonCard from '../Card/CommonCard'
import MainHeader from '../MainHheader/MainHeader'
import LandingHeader from '../LandingHeader/LandingHeader'
import Footer from '../Footer/Footer'
import { useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import ndf from '../../../Images/Vendor/ndf.svg'

const FavouriteVendor = (props) => {
  const navigate = useNavigate()
  const userId = localStorage.getItem('userId')

  const itemContents = [
    {
      image: img1
    },
    {
      image: img2
    },
    {
      image: img3
    },
    {
      image: img3
    }
  ]
  return (
    <div className='flex flex-col h-screen'>
      <div className='sticky top-0 z-50'>
        <MainHeader />
        <LandingHeader
          searchBar={true}
          goTomessage={true}
          goToNotification={true}
          border={true}
          profile={true}
        />
      </div>

      <div className={`p-[4%] bg-white-5 flex-1`}>
        <p onClick={() => navigate('/vendor')} className={`bg-primeryColour-5 font-[Roboto-Regular] rounded text-white w-20 py-1 text-center cursor-pointer`}>
          {"<-"} Back
        </p>
        <div className={`pb-[3%]`}>
          <p className={`text-center text-3xl font-[Roboto-Regular] font-normal text-deepgray-5 pb-2 lg:pb-0 cursor-pointer`}>{"Favourite Vendors"}</p>
        </div>
        {props.loading === true ?
          <div className='flex h-[50vh] justify-center items-center'>
            <CircularProgress size={20} color='success' />
            <p className='pl-3'>Loading...</p>
          </div>
          :
          <div>
            {props.favVendorDetails?.length > 0 ?
              <div className={`grid gap-2 md:gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 grid-rows-1`}>
                {props.favVendorDetails?.map((item, idx) =>
                  <div key={idx}>
                    <CommonCard
                      carousel={item?.business?.thumbnail && item?.business?.thumbnail?.length > 0 ? true : false}
                      noContentImg={item?.business?.thumbnail && item?.business?.thumbnail?.length === 0 && true}
                      carouselimg={item?.business?.thumbnail && item?.business?.thumbnail?.length > 0 ? item?.business?.thumbnail : itemContents}
                      onHandelOpeModal={() => props.handleFavouriteVendor(item?.business?.user?.id)}
                      itemname={item.business?.user?.name ? item.business?.user?.name : "--"}
                      carouselHeight="200px"
                      noContentCls={`h-[150px]`}
                      Shareicon={true}
                      height={false}
                      rating={false}
                      profile={true}
                      profileicon={item?.business?.logo?.key !== null && item?.business?.logo?.key}
                      itememail={true}
                      email={item?.business?.user?.email}
                      phone={true}
                      phonetext={item?.business?.user?.phoneNo}
                      location={true}
                      locationtext={(item?.business?.lat !== null && item?.business?.long !== null) && item?.business?.location ? item?.business?.location : "--"}
                      itemtext={true}
                      text1={item?.name}
                      hoverbutton={true}
                      hoverbuttonTrack={(item?.business?.lat === null && item?.business?.long === null) ? false : true}
                      buttonName2={"Contact Vendor"}
                      buttonimg={contactvendorimg}
                      buttonName3={"Track"}
                      buttonimg2={trackicon}
                      showLikeIcon={true}
                      onDelChange={() => {
                        props.deleteFavVendorApiCall(item?.id, userId, 'disable')
                      }}
                      favValue={1}
                      item={item}
                    />
                  </div>
                )}
              </div>
              :
              props.favVendorDetails?.length === 0 &&
              <div className={`flex flex-col gap-4 items-center justify-center`}>
                  <img src={ndf} alt="no data found" height={80} width={80} />
                <p className={`w-full flex items-center justify-center text-md font-[Roboto-Regular] font-normal text-[#555]`}>{"No Favourite Vendors Available"}</p>
              </div>
            }
          </div>
        }
      </div>
      <Footer />
    </div>
  )
}

export default FavouriteVendor