import React from 'react'
import BidCard from '../../../Common/Layout/Card/BidCard'
import moment from 'moment'
import DefaultProfileicon from '../../../Images/Vendor/DefaultProfileicon.svg'
import { ForAdmin } from '../../../Containers/Admin/Hooks'
import SnackBarComponent from '../../../Common/Layout/Snackbar/SnackBar'
import { CircularProgress } from '@mui/material'
import NoContentPage from '../../../Common/Layout/NoContentPage/NoContentPage'
import PaginationComponent from '../../../Common/UiComponents/paginationComponent/PaginationComponent'

const MyReqPending = (props) => {
    const { approveVendorRequirements, adminSnackbar, messageClose } = ForAdmin();

    return (
        <div className={`overflow-y-scroll h-[78vh]`}>
            {props.loading === true ?
                <div className='flex h-full justify-center items-center'>
                    <CircularProgress size={20} color='success' />
                    <p className='pl-3'>Loading...</p>
                </div>
                :
                <div>
                    {props.liveMyRequirementsData && props.liveMyRequirementsData?.length > 0 ?
                        <div className='min-w-[60rem] overflow-x-auto'>
                            {props.liveMyRequirementsData?.map((item, idx) =>
                                <div key={idx}>
                                    {item?.status === "pending" &&
                                        <>
                                            <BidCard
                                                DefaultProfileicon={DefaultProfileicon}
                                                name={item?.user?.name}
                                                email={item?.user?.email}
                                                question={item?.title}
                                                content={item?.description}
                                                flex={true}
                                                showdate={true}
                                                date={moment(item?.startDate).format('LL')}
                                                // peoplereplied={true}
                                                // numberofpeoplereplied={item?.numberofpeoplereplied}
                                                attachment={item?.document?.length > 0 ? true : false}
                                                validdate={false}
                                                time={moment(item?.endDate).format('LL')}
                                                onClick={() => props.goToMyReqrLiveDetails(item)}
                                                item={item}
                                                approveFunc={approveVendorRequirements}
                                                buttonLoading={props.buttonLoading}
                                                downloadPdfAttachments={props?.downloadPdfAttachments}
                                            />
                                        </>
                                    }
                                </div>
                            )}
                        </div>
                        :
                        <NoContentPage
                            NoContentMainDiv={`h-[78vh]`}
                            text2={`There is no data`}
                        />
                    }
                </div>}
                  <PaginationComponent
                                                totalItems={props?.totalCurrentMyReqItems}
                                                itemsPerPage={props?.itemsPerPage}
                                                currentPage={props?.currentMyReqPage}
                                                onPageChange={props?.setCurrentMyReqPage}
                                                siblingCount={1}
                                                boundaryCount={2}
                                                variant="outlined"
                                                color="primary"
                                            />
            <SnackBarComponent
                messageOpen={adminSnackbar.open}
                messageClose={messageClose}
                notificationText={adminSnackbar.message}
                subText={adminSnackbar.subText}
                alertType={adminSnackbar.alertType}
                borderClass={adminSnackbar.borderClass}
            />
        </div>)
}

export default MyReqPending