import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { SUB_ADMIN_API } from '../../Constant';
import { doDeleteApiCall, doGetApiCall, doPostApiCall } from '../../Utils/ApiConfig'
import { getBusinessDetails, getSearchDetails, getReviews, getFavVendors, getGoogleMarker, getSubCategoryVendor } from './LandingReducer';
import { getChatWithbyUserId } from '../VendorDetailPage/reducer/VendorReducer';
import { ForSignup } from '../Authentication/Signup/Hook';
import _ from "lodash";

export const ForLanding = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {GetAllBusinessAddress} = ForSignup()
  const [chatBtnLoading, setChatBtnLoading] = useState(false)
  const element = document.getElementById('landing-tabs')  
  const [loading, setLoading] = useState(false)
  const [loader, setLoader] = useState(false)

  const handleClickForOfferSeeAll = () => {
    navigate("/offerdetails")
  }
  const handleClickForAllHyperlink = () => {
    navigate("/allHyperlinks")
  }
  const backtoPage = () => {
    navigate("/")
  }
  const handleClickForBusinessSeeAll = () => {
    navigate("/vendordetails")
  }
  const handleClickForProductSeeAll = () => {
    navigate("/productdetails")

  }
  const handleAllServiceOffer = () => {
    navigate("/allServiceOffer")

  }

  const onHandelOpeModal = (id) => {
    if (id !== undefined) {
      navigate(`/details/${id}`)
    } else {
      console.log(id, 'not switchable');
    }
  }

  const handleFavouriteVendor = (id) => {
    navigate(`/favouritevendor/${id}`)
  }

  // This below three functionalities are only for snackbar during login
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    subText: "",
    alertType: "",
    borderClass: "",
  });
  const messageClose = () => {
    setNotification({
      open: false,
      message: "",
      subText: "",
      alertType: "",
      borderClass: "",
    });
  };
  const openMessage = (alertType, message, subText, borderClass) => {
    if (alertType) {
      setNotification({
        open: true,
        message: message,
        subText: subText,
        alertType: alertType,
        borderClass: borderClass,
      });
      setTimeout(messageClose, 5000);
    }
  };

  useEffect(() => {
    // console.log("# 1st")
    if (sessionStorage.getItem('latitude') === null && sessionStorage.getItem('longitude') === null) {
      // console.log("# 2nd")
      navigator.geolocation.watchPosition((position) => {
        // console.log("# 3rd")
        sessionStorage.setItem('latitude', position.coords.latitude)
        sessionStorage.setItem('longitude', position.coords.longitude)
        GetAllBusinessAddress()
        // window.location.reload()
      });
    }
  }, [sessionStorage.getItem('latitude'), sessionStorage.getItem('longitude')])

  const getNewLandingOffers = async (userId) => {
    // const userId = localStorage.getItem('userId')
    let data = {
      url: `${SUB_ADMIN_API}/business/noauth/${userId}`,
    }
    let res = await doGetApiCall(data)
    if (!res.error) {
      dispatch(getBusinessDetails(res.result))
    }
  }

  /**
   *@method getTheSearchedQuery 
   *@author uplSnehasish
   *@description This function is used to trigger search api call in the search bar when business name and business type is given in the search bar
  **/

  const getTheSearchedQuery = (type, searchInput) => {
    let data;
    if (type && searchInput !== '') {
      data = {
        url: `${SUB_ADMIN_API}/search?type=${type}&string=${searchInput}`
      }
    }  else {
      data = {
        url: `${SUB_ADMIN_API}/search?string=${searchInput}`
      }
    }
    doGetApiCall(data)
      .then((res) => {
        if (!res.error) {
          dispatch(getSearchDetails(res?.result))
        }
      })
      .catch(err => {
        console.log(err, "error")
      })
  }

  /**
   *@method postReviewsOfVendors 
   *@author uplSnehasish
   *@description This function is used to give rating and reviews to the searched vendor
  **/


  const postReviewsOfVendors = async (formData, businessId) => {
    try {
      // Input validation
      const rating = parseInt(formData.rating);
      if (isNaN(rating) || rating < 1 || rating > 5) {
        openMessage("error", "Invalid Rating", "Please provide a rating between 1 and 5.", "error");
        return;
      }
      if (!businessId) {
        openMessage("error", "Missing Business ID", "Business ID is required.", "error");
        return;
      }
  
     
      const data = {
        url: `${SUB_ADMIN_API}/reviews`,
        bodyData: {
          rate: rating,
          description: formData.writereview,
          businessId: businessId
        }
      };
  
     
      const res = await doPostApiCall(data);
  
      // Response handling
      if (!res.error) {
        getPostedReviews(businessId);
        openMessage("success", "Success", res?.message || "Review posted successfully.", "success");
      } else {
        openMessage("error", "Error", res?.message || "Failed to post review.", "error");
      }
    } catch (err) {
      console.error("API Error:", err);
      openMessage("error", "Error", "An unexpected error occurred. Please try again later.", "error");
    }
  };
  

  const token = localStorage.getItem('token')
  /**
  *@method getPostedReviews
  *@author uplSnehasish
  *@description This function is used to get rating and reviews of the searched vendor
  **/
  const getPostedReviews = (businessId) => {
    if (businessId) {
      let data;
      if (token) {
        data = {
          url: `${process.env.REACT_APP_DG_BASE_URL}/reviews/${businessId}?dataPerpage=200`,
        }
      } else {
        data = {
          url: `${process.env.REACT_APP_DG_BASE_URL}/reviews/noauth/${businessId}?dataPerpage=200`,
        }
      }
      doGetApiCall(data).then((res) => {
        if (!res.error) {
          dispatch(getReviews(res.result))
        } else {
          dispatch(getReviews([]))
        }
      })
        .catch(err => {
          console.log(err, "error")
        })
    }
  }

  /**
  * @author uplSnehasish
  * @description this function is used to delete reviews
  */
  const deleteReviews = (reviewId, businessId) => {
    let data = {
      url: `${SUB_ADMIN_API}/reviews/${reviewId}`,
    }
    doDeleteApiCall(data)
      .then((res) => {
        if (!res.error) {
          getPostedReviews(businessId)
          openMessage("success", "Success", res?.message, "success")
        } else {
          openMessage("error", "Error", res?.message, "error")
        }
      })
      .catch(err => {
        console.log(err, "error")
      })
  }

  /**
  *@author uplSnehasish
  *@description This function is used to get favourite vendor
  **/
  // const favVendorData = useSelector((state) => state.landingData.favVendors)

  const getFavVendorApiCall = (userId) => {
    setLoading(true)
    let data = {
      url: `${process.env.REACT_APP_DG_BASE_URL}/favourite/vendors/users/${userId}?dataPerpage=200`,
    }
    doGetApiCall(data).then((res) => {
      if (!res.error) {
        setLoading(false)
        dispatch(getFavVendors(res.result))
      } else {
        setLoading(false)
        dispatch(getFavVendors([]))
      }
    }).catch(err => {
      console.log(err, "error")
    })
  }

  /**
   *@author uplSnehasish
   *@description This function is used to a choose favourite vendors
  **/
  const createFavVendorApiCall = async (businessId, userId) => {
    let data = {
      url: `${SUB_ADMIN_API}/favourite/vendors`,
      bodyData: {
        businessId: businessId,
        // favouriteVendorId: 
      }
    }
    let res = await doPostApiCall(data)
    if (!res.error) {
      getFavVendorApiCall(userId)
      openMessage("success", "Success", "Favourite Vendor Created Successfully", "success")
    } else {
      openMessage("error", "Error", res?.message, "error")
    }
  }

  // for google map 

  let lat = sessionStorage.getItem('latitude');
  let lng = sessionStorage.getItem('longitude');
  const [searchData, setSearchData] = useState()

  const handleSearchData = (e) =>{
    e.preventDefault()
    setSearchData(e.target.value);
  }

  const [openMap, setOpenMap] = useState(false)
  const [searchResult, setSearchResult] = useState([]);
  const [markers, setMarkers] = useState([]);   // this state is used to store Marker value on Map
  const [selected, setSelected] = useState(null);
  
  const handleSetMarkers = (item) => {
    setMarkers((current) => [
      ...current, item
  ]); 
  }

  const handleSetSelected = (item) => {
    setSelected(item);
  }

  const handleSearchResult = () => {
    console.log(searchData, lat, lng, 'handleSearchResult ##');
    setSearchResult([]);
    setMarkers([]);
    searchData && setOpenMap(true);
    // ${window.location.href} --> This will catch the domain name if given inside the url below(befor the https)
    const URL = `${process.env.REACT_APP_DG_BASE_URL}/nearby/location?latitude=${lat}&longitude=${lng}&radius=1500&type=${searchData}`;
    const requestValues = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    searchData && fetch(URL,requestValues).then(data => {
        return data.json()
    }).then(jsonData => {
        setSearchResult(jsonData?.result?.results)
        dispatch(getGoogleMarker(jsonData?.result?.results))
      }).catch(error => {
        console.log(error);
      })
  }

  
  // const handleSearchResult = () => {
  //   let data = {
  //     url: `https://dd.dev.api.underscoretec.com/api/business/near/me/vendor/list?latitude=22.6212829&longitude=88.4650408&radius=10000`,
  //   }
  //   doGetApiCall(data).then((res) => {
  //     if (!res.error) {
  //       setSearchResult(res.results)
  //       dispatch(getGoogleMarker(res.results))
  //     } else {
  //     }
  //   }).catch(err => {
  //     console.log(err, "error")
  //   })
  // }
  /**
*@author uplSnehasish, utplBhaskar
*@description- This function is used for making api call for getting chats
**/
  const getChatDetailsWithUserApiCAll = async (userId) => {
    return new Promise(async (resolve, reject) => {
      setChatBtnLoading(true)
      let data = {
        url: `${SUB_ADMIN_API}/chats/details/with/${userId}`
      }
      doGetApiCall(data).then((res) => {
        if (!res.error) {
          dispatch(getChatWithbyUserId(res?.result))
          setChatBtnLoading(false)
          resolve(res?.result);
        } else {
          dispatch(getChatWithbyUserId({}))
          setChatBtnLoading(false)
          resolve({});
        }
      })
        .catch(err => {
          console.log(err, "error")
        })
    })
  }

  /** 
  * @author uplSnehasish
  * @description this function is used to delete favourite vendors
  */
  const deleteFavVendorApiCall = (favId, userId) => {
    let data = {
      url: `${SUB_ADMIN_API}/favourite/vendors`,
      bodyData: {
        favouriteVendorId: favId,
      }
    }
    doPostApiCall(data)
      .then((res) => {
        if (!res.error) {
            getFavVendorApiCall(userId)
            openMessage("success", "Success", 'Favourite Vendor Removed Successfully', "success")
          } else {
            openMessage("error", "Error", res?.message, "error")
          }
      })
      .catch(err => {
        console.log(err, "error")
      })
    
  }

  const scrollClickHandler = () => {
    window.scrollTo({top:606, behaviour: "smooth"});
  }

  /**
 *@author uplRanabir
 *@description This function is used to get vendor lists under sub-categories
 **/

  const getVendorsUnderSubCategory = (subCatId) => {
    setLoader(true)
    let data = {
      url: `${process.env.REACT_APP_DG_BASE_URL}/business/listby/sub/category/${subCatId}?latitude=${lat}&longitude=${lng}&dataPerpage=200`,
    }
    doGetApiCall(data).then((res) => {
      if (!res.error) {
        let vendorObj = {
          subCatId: subCatId,
          vendorArr: res?.result
        }
        dispatch(getSubCategoryVendor(vendorObj));
        setLoader(false)
      } else {
        dispatch(getSubCategoryVendor([]))
        setLoader(false)
      }
    }).catch(err => {
      console.log(err, "error");
      setLoader(false)
    })
  }


//=============================================== Contact Us Starts ============================================
  const handleContactUs = async(payload, setIsLoading, handleResetForm) =>  {
    try {
        setIsLoading?.(true);
        const data = {
          url: `${process.env.REACT_APP_DG_BASE_URL}/contactus`,
          bodyData: payload,
        };
      
        const res = await doPostApiCall(data);
        if (!res.error) {
          switch (res.code) {
            case "CONTACTUS_SAVE":
              openMessage("success", res?.message, "success");
              handleResetForm?.()
              break;
      
            default:
              openMessage("error", "Error", "Failed to send message. Please try again.", "error");
              break;
          }
        } else {
          switch (res.code) {
            case "INTERNAL_SERVER_ERROR":
              openMessage("error", "Error", "An internal server error occurred. Please try again later.", "error");
              break;
      
            default:
              openMessage("error", "Error", "Failed to send message. Please try again.", "error");
              break;
          }
        }
      } catch (error) {
        console.error("Error:", error);
        openMessage("error", "Error", "Something went wrong. Please try again.", "error");
      } finally {
        setIsLoading?.(false);
      }
}

const getTrack = async ( businessId) => {
  try {
      const data = {
          url: `${SUB_ADMIN_API}/business/trace`,
          bodyData: {
              // ip: 'string', 
              userId: localStorage.getItem("userId") || null,
              businessId: businessId,
              type: "track"
          }
      };

      const response = await fetch(data.url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data.bodyData)
      });

      const result = await response.json();
  } catch (error) {
      console.error("Error:", error);
      return null;
  }
};


  return {
    getNewLandingOffers,
    handleClickForOfferSeeAll,
    handleClickForBusinessSeeAll,
    handleClickForProductSeeAll,
    backtoPage,
    onHandelOpeModal,
    getTheSearchedQuery,
    getPostedReviews,
    postReviewsOfVendors,
    handleClickForAllHyperlink,
    deleteReviews,
    getFavVendorApiCall,
    createFavVendorApiCall,
    handleFavouriteVendor,
    notification,
    messageClose,
    handleAllServiceOffer,
    handleSearchResult,
    openMap,
    lat,
    lng,
    deleteFavVendorApiCall,
    handleSearchData,
    searchData,
    searchResult,
    getChatDetailsWithUserApiCAll,
    chatBtnLoading,
    scrollClickHandler,
    loading,
    getVendorsUnderSubCategory,
    markers,
    handleSetMarkers,
    selected,
    handleSetSelected,
    loader,
    handleContactUs,
    getTrack
  }
}
