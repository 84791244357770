import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import { IoClose } from "react-icons/io5";
import { Modal } from '@mui/material';
import ButtonField from './ButtonField';
import { useSelector } from 'react-redux';

const CorporatePlanPopup = ({ vendor, open, onClose, planSubmit, selectPlan, setPlan, createCorporateSubscription }) => {
    const [activeTab, setActiveTab] = useState("corporate");

    const subscriptionPlanData = useSelector((state) => state.subscription.plans);
    const corporatePlansData = subscriptionPlanData?.filter(item => item?.planType === 'corporate');
    const generalPlansData = subscriptionPlanData?.filter(item => item?.planType === 'general');

    return (
        <Modal open={open} onClose={onClose} className='flex items-center justify-center'>
            <form onSubmit={planSubmit} className='bg-white w-[33rem] max-h-[80vh] overflow-hidden rounded-xl shadow-xl'>

                {/* Header */}
                <div className="sticky top-0 bg-white z-50 border-b border-slate-200">
                    <div className='flex justify-between items-center px-4 py-4'>
                        <span className='text-xl text-green-600 font-semibold'>Apply Plan</span>
                        <button onClick={onClose} className='text-gray-600 text-[25px] hover:bg-gray-200 rounded-full p-1'>
                            <IoClose />
                        </button>
                    </div>

                    {/* Tabs */}
                    <div className="flex border-b border-gray-300">
                        <button 
                            className={`w-1/2 py-2 text-center ${activeTab === "corporate" ? "border-b-2 border-green-500 font-semibold" : "text-gray-500"}`} 
                            onClick={() => setActiveTab("corporate")}
                            type="button"
                        >
                            Corporate Plan
                        </button>
                        <button 
                            className={`w-1/2 py-2 text-center ${activeTab === "general" ? "border-b-2 border-green-500 font-semibold" : "text-gray-500"}`} 
                            onClick={() => setActiveTab("general")}
                            type="button"
                        >
                            General Plan
                        </button>
                    </div>
                </div>

                {/* Plan Listing (Scrollable) */}
                <div className='p-3 h-[50vh] overflow-y-auto'>
                    {activeTab === "corporate" && corporatePlansData?.length > 0 ? (
                        corporatePlansData.map((plan, index) => (
                            <div key={index} className='mb-4 flex items-center gap-2'>
                                <Radio
                                    checked={selectPlan?.id === plan?.id}
                                    onChange={() => setPlan(plan)}
                                    value={plan?.id}
                                    name="plan"
                                    inputProps={{ 'aria-label': plan?.name }}
                                />
                                <span>{plan?.name} | {plan?.price} {plan?.currency}</span>
                            </div>
                        ))
                    ) : activeTab === "general" && generalPlansData?.length > 0 ? (
                        generalPlansData.map((plan, index) => (
                            <div key={index} className='mb-4 flex items-center gap-2'>
                                <Radio
                                    checked={selectPlan?.id === plan?.id}
                                    onChange={() => setPlan(plan)}
                                    value={plan?.id}
                                    name="plan"
                                    inputProps={{ 'aria-label': plan?.name }}
                                />
                                <span>{plan?.name} | {plan?.price} {plan?.currency}</span>
                            </div>
                        ))
                    ) : (
                        <p className="text-gray-500 text-center">No plans available</p>
                    )}
                </div>

                {/* Apply Button */}
                <div className='flex justify-end bg-white sticky bottom-0 gap-3 border-t border-slate-200 px-3 py-3'>
                    <ButtonField
                        buttonName="Cancel"
                        buttonextracls="text-blue-400 px-4 py-2"
                        type="button"
                        onClick={onClose}
                    />

                    <ButtonField
                        buttonName="Apply"
                        buttonextracls={`text-white px-4 py-2 ${selectPlan ? 'bg-green-600' : 'bg-green-300'}`}
                        type="submit"
                        disabled={!selectPlan}
                        onClick={() => createCorporateSubscription(selectPlan, vendor?.id)}
                    />
                </div>
            </form>
        </Modal>
    );
};

export default CorporatePlanPopup;
