import React, { useState } from 'react'
import ReactDateRange from '../../Common/FormFields/DateRangePicker'
// import LandingHeader from '../../Common/Layout/LandingHeader/LandingHeader'
// import MainHeader from '../../Common/Layout/MainHheader/MainHeader'
// import ProfileUserDescriptionCard from '../../Common/Layout/ProfileCard/ProfileUserDescriptionCard'
// import ProfileUserImagesCard from '../../Common/Layout/ProfileCard/ProfileUserImagesCard'
// import ProfileUserInfoCard from '../../Common/Layout/ProfileCard/ProfileUserInfoCard'
import DashboardCard from '../Admin/Dashboard/DashboardCard'
import AllReviews from '../Admin/TotalVendors/AllReviews'
import CustomerReview from '../Admin/TotalVendors/CustomerReview'
// import Footer from '../../Common/Layout/Footer/Footer'
import text from '../../Common/Languages/en_US.json'
import eventIcon from '../../Images/adminContent/eventIcon.svg'
// import DashboardTags from '../../Common/Layout/ProfileCard/DashboardTags'
import image1 from '../../Images/profileUserCardImages/image1.png'
import image2 from '../../Images/profileUserCardImages/image2.png'
import image3 from '../../Images/profileUserCardImages/image3.png'
import image4 from '../../Images/profileUserCardImages/image4.png'
import image5 from '../../Images/profileUserCardImages/image5.png'
import image6 from '../../Images/profileUserCardImages/image6.png'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { ClickAwayListener } from '@mui/material'
import DateRangePickerComponent from '../../Common/FormFields/DateRangePickerComponent'

const VendorDetailsPageIndex = (props) => {

  const dashboardDataVendor = useSelector((state) => state.dashboard.dashboardDataVendor)

  // Use the useTranslation hook inside your functional components to access the translation function or i18n instance.
  const { t, ready } = useTranslation();

  const handleClickAway = () => {
    if (props?.dateOpen) {
      props?.handleOpenDate(false);
    }
  };

  // const [dateOpen, setDateOpen] = useState(false)
  // const [state, setState] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: new Date(),
  //     key: 'selection'
  //   }
  // ]);

  // const handleOpenDate = () => {
  //   setDateOpen(!dateOpen)
  //   // console.log(dateOpen, "#")
  // }

  const dashBoardArr = [
    {
      cardName: 'Total Views',
      cardTotal: dashboardDataVendor?.views,
      // btnName: '27.4',
      graphColor: '#1DBF73',
      // cardSavings: dashboardDataVendor?.totalUser,
      endText: `total views this year`
    },
    {
      cardName: 'Total Favorites',
      cardTotal: dashboardDataVendor?.totalFavorites,
      // btnName: '--',
      graphColor: '#FF0033',
      // cardSavings: dashboardDataVendor?.totalVendor,
      endText: 'people favours you'
    },
    {
      cardName: 'No. of calls',
      cardTotal: dashboardDataVendor?.contactCount,
      // btnName: '--',
      graphColor: '#FFCD46',
      // cardSavings: dashboardDataVendor?.totalGeneralUsers,
      endText: 'people gave 5 star ratings'
    },
    {
      cardName: 'Directions',
      cardTotal: dashboardDataVendor?.trackCount,
      // btnName: '--',
      graphColor: '#186AB2',
      // cardSavings: dashboardDataVendor?.mostUsedSubsciptionPlan,
      endText: 'people gave 4 star ratings'
    }
  ]

  const imgArr = [image1, image2, image3, image4, image5, image6]

  return (
    <>
      {ready &&
        <div className={`!bg-[#F7F7F7] w-full mt-2 overflow-y-scroll h-[88vh]`}>
          {/* Date range picker dashboard */}
          {/* <ClickAwayListener onClickAway={handleClickAway}>
          <div className={` hidden lg:flex lg:justify-end lg:mb-2 sideCalender`}>
            <div className={`flex justify-between items-end border border-[#DEDEDE] bg-white w-[16rem] px-3 py-2 rounded-md cursor-pointer relative mx-1 mb-1`} onClick={props?.handleOpenDate}>
              <div className='flex items-center text-[#7B7B7B] gap-1'
              // onClick={startDateHandler}
              >
                <img src={eventIcon} alt='event icon' />
                <p className='text-[13px] font-[Roboto-Regular] text-gray-6'>{props?.dateStateVendorDashboard[0]?.startDate !== null ? moment(props?.dateStateVendorDashboard[0]?.startDate)?.format('DD-MM-YYYY') : text.startDate}</p>
              </div>
              <div className=' text-deepgray-4 font-[Roboto-Regular] text-[12px]'>{t("to")}</div>
              <div className='flex items-center basis-3/7 text-[#7B7B7B] gap-1'
              // onClick={endDateHandler}
              >
                <img src={eventIcon} alt='event icon' />
                <p className='text-[13px] font-[Roboto-Regular] text-gray-6'>{props?.dateStateVendorDashboard[0]?.endDate !== null ? moment(props?.dateStateVendorDashboard[0]?.endDate)?.format('DD-MM-YYYY') : text.endDate}</p>
              </div>
              
            </div>

            {props?.dateOpen ? (

              <ReactDateRange
                onChange={(item) => props?.setDateStateVendorDashboard([item?.selection])}
                showSelectionPreview={false}
                moveRangeOnFirstSelection={false}
                ranges={props?.dateStateVendorDashboard}
                direction="horizontal"
                value={props?.dateStateVendorDashboard}
                editableDateInputs={true}
                customStyles={{ position: 'absolute', top: '230px', right: '65px', zIndex: '10', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}
              // wrapperRef={props.wrapperRef}
              />
            ) : null}
          </div>
          </ClickAwayListener> */}
          <div className='flex items-center justify-end w-full px-1 py-3'>
            <DateRangePickerComponent
              value={props.dateStateVendorDashboard}
              onChange={(item) => {
                if (!item.selection) return;
                props.setDateStateVendorDashboard([item.selection]);
              }}
            />
          </div>


          {/* Dashboard  */}
          <div className={`grid grid-rows-4 sm:grid-rows-2 md:grid-rows-2 grid-flow-col xl:grid-rows-1`}>
            {
              dashBoardArr?.map((item, id) => {
                return (
                  <div key={id} className={`m-1`}>
                    <DashboardCard
                      cardName={item?.cardName}
                      cardTotal={item?.cardTotal}
                      btnName={item?.btnName}
                      graphColor={item?.graphColor}
                      cardSavings={item?.cardSavings}
                      startText={item?.startText}
                      endText={item?.endText}
                    />
                  </div>
                )
              })
            }
          </div>

          {/* Customer Review  */}
          <div className={`m-1`}>
            <p className={`py-4 font-[Roboto-Regular]`}>{t("customerreview")}</p>
            <CustomerReview BusinessDetails={props?.BusinessDetails} />
          </div>

          {/* Reviews  */}
          <div className={`m-1`}>
            <p className={`py-4 font-[Roboto-Regular]`}>{t("reviews")}</p>
            <AllReviews
              reviewData={props?.reviewData}
              enableBorder={true}
              showEyeAndDel={false}
              showDateBelowDesc={false}
              mainDivCls={`flex flex-col justify-between items-center bg-white`}
              subDivCls={`px-0 py-6`}
            />
          </div>

        </div>
      }
    </>

  )
}

export default VendorDetailsPageIndex