import React, { useState, useEffect } from 'react'
import textsEn from '../../Languages/en_US.json'
import textsAr from '../../Languages/arabic.json'
import DdGlob from '../../../Images/CommonImages/DdGlob.svg'
import DdPhone from '../../../Images/CommonImages/DdPhone.svg'
import CustomDropDown from '../../FormFields/CustomDropDown'
import textsHi from '../../Languages/hindi.json'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import becomeAVendor from '../../../Images/LandingImages/becomeAVendor.svg'
import becomeAUser from '../../../Images/LandingImages/becomeAUser.svg'
import { Tooltip } from '@mui/material'
import ContactUsModal from '../../UiComponents/ContactUsModal'
import { parseCookies, setCookie } from "nookies";
const MainHeader = (props) => {


    const COOKIE_NAME = "googtrans";
    const navigate = useNavigate()
    const token = localStorage.getItem('token')
    const role = localStorage.getItem('role')
    const [currentLanguage, setCurrentLanguage] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = (e) => {
        setIsModalOpen(false);
    };

    // Use the useTranslation hook inside your functional components to access the translation function or i18n instance.
    const { t, i18n } = useTranslation();

    // func for changing language
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setCookie(null, COOKIE_NAME, "/auto/" + lng)
        setCookie(null, COOKIE_NAME, "/auto/" + lng, { domain: `.${getDomain(window.location.hostname)}`, path: '/' })
        window.location.reload();
    };

    // window.location.reload(true)
    const [select, setSelect] = useState('')

    const languageArray = [
        { name: textsEn.English, value: "en_US" },
        { name: textsAr.Arabic, value: "ar" },
        { name: textsHi.Hindi, value: "hi" }
    ]

    const getDomain = (url, subdomain) => {
        subdomain = subdomain || false;
        url = url.replace(/(https?:\/\/)?(www.)?/i, '');
    
        if (!subdomain) {
          url = url.split('.');
          url = url.slice(url.length - 2).join('.');
        }
        if (url.indexOf('/') !== -1) {
          return url.split('/')[0];
        }
    
        return url;
      }

    useEffect(() => {
        // 1. Read the cookie
        const cookies = parseCookies()
        const existingLanguageCookieValue = cookies[COOKIE_NAME];
        let languageValue;
        if (existingLanguageCookieValue) {
          // 2. If the cookie is defined, extract a language nickname from there.
          const sp = existingLanguageCookieValue.split("/");
          if (sp.length > 2) {
            languageValue = sp[2];
          }
        }
        // 3. If __GOOGLE_TRANSLATION_CONFIG__ is defined and we still not decided about languageValue, let's take a current language from the predefined defaultLanguage below.
        if (global.__GOOGLE_TRANSLATION_CONFIG__ && !languageValue) {
          languageValue = global.__GOOGLE_TRANSLATION_CONFIG__.defaultLanguage;
        }
        if (languageValue) {
          // 4. Set the current language if we have a related decision.
          setCurrentLanguage(languageValue);
        }
        // 5. Set the language config.
        // if (global.__GOOGLE_TRANSLATION_CONFIG__) {
        //   setLanguageConfig(global.__GOOGLE_TRANSLATION_CONFIG__);
        // }
      }, []);

    return (
        <div className={`bg-primeryColour-5 py-3 w-full flex justify-between items-center pl-[4.6%] pr-[5%] ${currentLanguage !=='en_US' ? 'mt-[-50px]': ''}`}>
            {isModalOpen && <ContactUsModal handleContactUs={props?.handleContactUs} handleCloseModal={handleCloseModal} />}
            <div>
                <div onClick={handleOpenModal} className={`flex gap-4 items-center cursor-pointer`}>
                    <img src={DdPhone} alt='DdPhone' className={`h-[12px] w-[12px]`} />
                    <p className={` font-medium text-white text-xs leading-5 capitalize font-[Roboto-Medium] tracking-[0.15px]`}>{t("HelpContact")}</p>
                </div>
            </div>
            <div className={`flex items-center`}>
                <CustomDropDown
                    startIcon={true}
                    src={DdGlob}
                    alt='DdGlob'
                    dropdownBoxExtraCls={`top-8`}
                    dropDownValue={select}
                    dropDownName={select}
                    dropDownArray={languageArray}
                    setValue={setSelect}
                    enableLanguageChanger={true}
                    value={i18n.language}
                    changeLanguage={changeLanguage}
                    extraContain={
                        i18n.language === 'en_US' ? "English"
                            :
                            i18n.language === 'hin' ? "हिंदी"
                                :
                                i18n.language === 'ar' && "عربي"
                    }
                />
                <div className={`border-l mx-5 border-solid border-primeryColour-7 h-6`}></div>
                {!role && !token && <div className='flex gap-4'>
                    <div
                        onClick={() => navigate('/signup')}
                        className={`font-medium text-white text-[12px] leading-5 cursor-pointer font-[Roboto-Medium] tracking-[0.15px] flex gap-2`}
                    >
                        {/* <Tooltip title="Become a vendor" arrow> */}
                            <div className="flex items-center gap-2">
                                <img src={becomeAVendor} alt='become a vendor' />
                                <p className='hidden sm:block'>{t("BecomeAVendor")}</p>
                            </div>
                        {/* </Tooltip> */}
                    </div>
                    <div
                        onClick={() => navigate('/usersignup')}
                        className={`font-medium text-white text-[12px] leading-5 cursor-pointer font-[Roboto-Medium] tracking-[0.15px] flex gap-2`}
                    >
                        {/* <Tooltip title={t("Become a user")} arrow> */}
                            <div className="flex items-center gap-2">
                                <img src={becomeAUser} alt='become a user' />
                                <p className='hidden sm:block'>{t("BecomeAUser")}</p>
                            </div>
                        {/* </Tooltip> */}
                    </div>
                </div>}
            </div>
        </div>

    )
}

export default MainHeader