import React, { useEffect, useState } from 'react'
import usericon from '../../../Images/adminTable/userAvatar.svg'
import ButtonField from '../../../Common/FormFields/ButtonField';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import SubscriptionDetail from './SubscriptionDetail';
import CloseIcon from '@mui/icons-material/Close';
import NoContentPage from '../../../Common/Layout/NoContentPage/NoContentPage';


/**
 * @component SubscriptionTable
 * @description Subscription table
 * @param {*} props 
 */

const SubscriptionTable = () => {
    const subscriptionAlllist = useSelector((state) => state.subscriptionList.subscriptionList);
    const [detailModal, setDetailModal] = useState({ showModal: false, data: {} })
    console.log(subscriptionAlllist, "subscriptionList ==")

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95vw',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleDetailClick = (idx) => {
        setDetailModal({ showModal: true, data: subscriptionAlllist[idx] })
    }

    const handleClose = () => {
        setDetailModal({ showModal: false, data: {} })
    }





    return (
        <div className='w-full border-[#EBEBEB]'>


            {
                subscriptionAlllist && subscriptionAlllist.length > 0 ?
                    <table className="table-auto w-[140vw] lg:w-[110vw] xl:w-full ">

                        <thead className='bg-white sticky top-0 z-10'>
                            <tr className='h-10'>
                                {/* <th className={`w-[5%] text-[#828282] text-left  text-base font-normal font-[SansRegular] px-[1%] `}>
                            <CheckBox />
                        </th> */}
                                <th className={`pl-6 w-[15%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular] `}>Owner Name</th>

                                {/* <th className={`w-[8%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular] `}>Business Name</th> */}
                                <th className={`w-[10%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular] `}>Email</th>
                                <th className={`w-[8%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular] `}>Validity</th>
                                <th className={`w-[8%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular] `}>Plan Name</th>
                                <th className={`w-[10%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular] `}>Price</th>
                                <th className={`w-[10%] text-[#585F71] text-left pr-8 text-base font-normal font-[Roboto-Regular] `}>Status</th>
                                <th className={`w-[10%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular]  pr-6`}>Action</th>

                            </tr>
                        </thead>
                        <tbody>

                            {subscriptionAlllist?.map((item, idx) => {
                                return (
                                    <tr
                                        key={idx}
                                        className={' drop-shadow-md border-t-2 border-b-2  border-[#EBEBEB]'}>
                                        {/* <td className={`text-left text-[14px] font-normal font-[SansRegular] px-[1%]`}>{item.checkbox}</td> */}
                                        <div className='flex pl-6'>
                                            <img src={usericon} alt="usericon" height={42} width={42} />
                                            <td className={`text-left text-[#1F2533] text-sm py-6 pl-2 font-medium  font-[Roboto-Regular]`}>{item?.user?.filter(u => u?.id === item?.userId)[0]?.name}</td>
                                        </div>
                                        {/* <td className={`text-left text-[14px] font-normal font-[Roboto-Regular]`}>{item?.businessName}</td> */}
                                        <td className={`text-left text-[#3677B1] text-[14px] font-normal font-[Roboto-Regular]`}>{item?.user?.filter(u => u?.id === item?.userId)[0]?.email}</td>
                                        <td className={`text-left  pr-8 text-[#1F2533] text-[14px] font-normal font-[Roboto-Regular]`}>{moment(item?.expireIn).format("YYYY-MM-DD")}</td>
                                        <td className={`text-left text-[14px] text-[#1F2533] font-normal font-[Roboto-Regular]`}>{item?.plans?.name}</td>
                                        <td className={` text-left text-base text-[#1F2533] font-medium font-[Roboto-Medium] }`}>
                                            {item?.plans.price} {item?.plans.currency}</td>
                                        <td className={`text-left pr-6 text-[14px] text-[#1F2533] font-normal font-[Roboto-Regular]`}>

                                            <ButtonField
                                                buttonName={item.status}
                                                buttonextracls={item.status === 'expire' ? `!px-3 !py-1 !text-white bg-[#FF6685] text-[12px] font-[Roboto-Regular] w-[6rem] rounded-full` : item.status === 'Expiring' ? `!px-3 !py-1 !text-white bg-[#FBCB00]  text-[12px] font-[Roboto-Regular] w-[6rem] rounded-full` : item.status === 'active' ? `!px-3 !py-1 !text-white bg-[#1DBF73]  text-[12px] font-[Roboto-Regular] w-[6rem] rounded-full` : null}
                                            />

                                        </td>
                                        <td>
                                            <ButtonField buttonName={'Details'} onClick={() => handleDetailClick(idx)} />
                                        </td>

                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    :
                    <div className={`flex justify-center items-center h-[70vh]`}>
                        <NoContentPage
                            text2={`No Data Found`}
                        />
                    </div>
            }

            <Modal
                open={detailModal.showModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                <div className='w-full flex justify-end pb-2'>
                    <CloseIcon onClick={()=>handleClose()} className='cursor-pointer text-red-500' />
                </div>
                    <SubscriptionDetail data = {detailModal.data}/>
                </Box>
            </Modal>


        </div>
    )
}

export default SubscriptionTable

