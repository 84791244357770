import React, { useEffect, useState } from 'react'
import ButtonField from '../../../Common/FormFields/ButtonField'
// import DropDownField from '../../../Common/FormFields/DropDownField'
import TextFieldInput from '../../../Common/FormFields/TextFieldInput'
import './index.css'
import text from "../../../Common/Languages/en_US.json"
import { useForm, Controller } from "react-hook-form"
// import { ForAdmin } from '../../../Containers/Admin/Hooks'
// import { useSelector } from 'react-redux'
import {
    Box,
    Chip,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react'
import UploadButton from '../../../Common/UiComponents/UploadButton'
import { ForUploadMediaHooks } from '../../../Containers/UploadMedia/Hooks'
import { useDispatch } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import fileUploadIcon from '../../../Images/SubAdmin/fileUploadIcon.svg'
import { setUploadedLogo } from '../../../Containers/UploadMedia/reducer/UploadMediaReducer'
import { useJsApiLoader } from '@react-google-maps/api';
import { ForSignup } from '../../../Containers/Authentication/Signup/Hook'
import DropDownField from '../../../Common/FormFields/DropDownField'
import MultipleSelect from '../../../Common/FormFields/MultipleSelect'
import PhoneInputField from '../../../Common/FormFields/PhoneInputField'
import { governorateArray } from '../../../Utils/governorateArray'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



const SubAdminForm = (props) => {

    const { cleanupMedia, logoUpload, doUploadedLogo } = ForUploadMediaHooks()
    const dispatch = useDispatch()
    const [addLocation, setAddLocation] = useState(props.editSubAdminFormData?.governorate ? props.editSubAdminFormData?.governorate : '')
    const [input, setInput] = useState(props.editSubAdminFormData?.place || undefined);

    const editSelectedMulti = props?.editSubAdminFormData?.sections.map((item) => item?.name)

     const  [relatedPlace, setRelatedPlace] = useState([])

     const selectArr1 = [
        { name: "dashboard", value: "Dashboard" },
        { name: "totalUsers", value: "Total Users" },
        { name: "totalVendors", value: "Total Vendors" },
        { name: "categories", value: "Categories" },
        { name: "support", value: "Support" },
        { name: "cmsPage", value: "CMS page" },
        { name: "subscriptionList", value: "Subscription List" },
        { name: "transactionList", value: "Transaction List" },
        { name: "plans", value: "Plans" },
        { name: 'approveMedia', value: 'Approve Media' },
        { name: 'settings', value: 'Settings' },
    ]

    // form handling using react-hook-form 
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        getValues,
        setValue
    } = useForm({
        defaultValues: {
            fullName: props.editSubAdminFormData?.name ? props.editSubAdminFormData?.name : '',
            email: props.editSubAdminFormData?.email ? props.editSubAdminFormData?.email : '',
            phoneNumber: props.editSubAdminFormData?.phoneNo ? props.editSubAdminFormData?.phoneNo : '',
            governorate: props.editSubAdminFormData?.governorate ? props.editSubAdminFormData?.governorate : '',
            places: props.editSubAdminFormData?.place ? props.editSubAdminFormData?.place : '',
            subRole: props?.editSubAdminFormData?.subRole ? props?.editSubAdminFormData?.subRole : '',
            roles: editSelectedMulti ? editSelectedMulti : [],
        }
    });

    const onSubmit = (data) => {

        props.editSubAdminFormData ? props.editSubAdminApiCall(data) : props.subAdminCreate(data)

        // resetting after submission 
        props.subAdminCreateMsg?.error === false &&
            reset({
                fullName: '',
                email: '',
                phoneNumber: '',
                roles: [],
                governorate: [],
                places: [],
                subRole: ''
            })
    };

    useEffect(() => {
        if (props.editSubAdminFormData && Object.keys(props.editSubAdminFormData)?.length > 0) {
            dispatch(setUploadedLogo(props.editSubAdminFormData?.picture))
        }
    }, [])

     useEffect(() =>  {
            if(addLocation ){
                const selectedPlaces = governorateArray.find((item) => item?.name === addLocation)?.location
                setRelatedPlace(selectedPlaces);
                if(selectedPlaces){
                    const isValidPlace = selectedPlaces?.some((item) =>  item?.name === input)
                    if(!isValidPlace){
                        setValue('places', '')
                    }
                }
            }
        }, [addLocation, input, relatedPlace])


    // console.log(props.editSubAdminFormData, multipleSections, addLocation,relatedPlace, getValues(), "props.editSubAdminFormData ##")


    return (
        <div className={`px-[4%] py-[5%] flex flex-col gap-[3rem]`}>
            {/* form content  */}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='flex flex-col gap-5 textFieldText'>
                    <div className='flex justify-between w-full'>
                        <div className='w-[65%]'>
                            <div>
                                <Controller name={"fullName"}
                                    control={control}
                                    render={({ field: { onChange, value }, formState: { error } }) => {
                                        return (
                                            (<TextFieldInput
                                                onlyValue
                                                textnewclass={`w-full text-sm`}
                                                placeholder='*Enter full name'
                                                onChange={onChange}
                                                value={value}
                                                floatingLabel={'*Enter full name'}
                                            />)
                                        )
                                    }}

                                    rules={{
                                        required: true, pattern: /^[a-zA-Z][a-zA-Z ]*/i, minLength: 2
                                    }}
                                />

                                {/* fullName error msg */}
                                {errors.fullName && errors.fullName.type === "required" && (
                                    <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                )}
                                {errors.fullName && errors.fullName.type === "minLength" && (
                                    <span className="error-message text-red-400 text-xs">{text.fullNameValidation}</span>
                                )}
                                {errors.fullName && errors.fullName.type === "pattern" && (
                                    <span className="error-message text-red-400 text-xs">{text.enterValidName}</span>
                                )}
                            </div>

                            {/* Email  */}
                            <div className={`grid grid-cols-2 gap-6 w-full mt-4`}>
                                {/* mail controller  */}
                                <div className='flex flex-col'>
                                    <Controller name={"email"}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => {
                                            return (
                                                (<TextFieldInput
                                                    onlyValue
                                                    textnewclass={`flex-1 w-full text-sm`}
                                                    placeholder='*Enter email ID'
                                                    onChange={onChange}
                                                    value={value}
                                                    disabled={props.editSubAdminFormData?.email ? true : false}
                                                    floatingLabel={'*Enter email ID'}
                                                />)
                                            )
                                        }}
                                        rules={{
                                            required: props.editSubAdminFormData?.email ? false : true,
                                            pattern: /^[\w]{1,}[\w.+-]{0,}@[\w-]{2,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$/i
                                        }}
                                    />
                                    {/* mail error msg  */}
                                    {errors.email && errors.email.type === "required" && (
                                        <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                    )}
                                    {errors.email && errors.email.type === "pattern" && (
                                        <span className="error-message text-red-400 text-xs">{text.enterValidMail}</span>
                                    )}
                                </div>

                                <div className='flex flex-col arrowRemovedMain'>
                                    {/* phone controller  */}
                                    <Controller name={"phoneNumber"}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => {
                                            return (
                                                <PhoneInputField
                                                    // phoneName={'phoneNo'}
                                                    defaultCountry={'kw'}
                                                    placeholder={'*Enter Phone no'}
                                                    label={'*Enter Phone Number'}
                                                    extraCls={`w-full text-sm mt-[0.45rem]`}
                                                    inputCls={`w-full h-[3.3rem] cursor-default ${props.editSubAdminFormData?.phoneNo ? 'text-gray-400' : ''}`}
                                                    onChange={(value) => { onChange(value) }}
                                                    value={value}
                                                    enableSearch={true}
                                                    disabled={props.editSubAdminFormData?.phoneNo ? true : false}
                                                />
                                            )
                                        }}
                                        rules={{
                                            required: props.editSubAdminFormData?.phoneNo ? false : true,
                                            pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i,
                                            // maxLength: 10
                                            minLength: 10
                                        }}
                                    />
                                    {/* phone error msg  */}
                                    {errors.phoneNumber && errors.phoneNumber.type === "required" && (
                                        <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                    )}
                                    {errors.phoneNumber && errors.phoneNumber.type === "pattern" && (
                                        <span className="error-message text-red-400 text-xs">{text.enterValidPhone}</span>
                                    )}
                                    {errors.phoneNumber && errors.phoneNumber.type === "minLength" && (
                                        <span className="error-message text-red-400 text-xs">{text.enterValidPhone}</span>
                                    )}
                                </div>
                            </div>

                            <div className={`grid mb-4`}>
                                {/* governorate  */}
                                <div className={`w-full my-3  flex flex-col governorText`}>
                                    <Controller
                                        name={"governorate"}
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                        // defaultValue={props.editSubAdminFormData?.governorate ? props.editSubAdminFormData?.governorate : ''}
                                        render={({ field: { onChange, value } }) => (
                                            <DropDownField
                                                name='governorate'
                                                id='governorate'
                                                dropDownRootCls={`governorateCls`}
                                                // size="small"
                                                selectOption={governorateArray}
                                                placeholder={`enterGovernorate`}
                                                // option={value}
                                                // handleChange={onChange}
                                                option={value ? value : addLocation}
                                                handleChange={(e) => { setAddLocation(e.target.value); onChange(e.target.value); }}
                                            />
                                        )}
                                    />
                                    <small className={`text-red-400 text-xs`}>
                                        {errors?.governorate && errors?.governorate?.type === "required" && (<p>This field is required</p>)}

                                    </small>

                                </div>

                                {/* section for selecting multiple places */}
                                <div className={`w-full  mt-3  flex flex-col`}>
                                    {/* <Controller
                                        name={"places"}
                                        control={control}
                                        rules={{
                                            required: props.editSubAdminFormData?.place ? false : true,
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <MultipleSelect
                                                size="small"
                                                multiple={false}
                                                name="places"
                                                id='places'
                                                value={value ? value : input}
                                                onChange={(e, v) => { setInput(v.name); onChange(v.name); }}
                                                // isOptionEqualToValue={(option, value) => { return option?.name === value?.name; }}
                                                placeholder={`selectPlace`}
                                                autocompleteExtraCls={`selectLocationCls`}
                                                autocompleteArray={governorateArray.find((item) => item?.name === addLocation)?.location || []}
                                            />
                                        )}
                                    /> */}

                                    <Controller
                                        name={"places"}
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <DropDownField
                                                name='places'
                                                id='places'
                                                dropDownRootCls={`governorateCls`}
                                                // size="small"
                                                selectOption={governorateArray.find((item) => item?.name === addLocation)?.location || []}
                                                placeholder={`selectPlace`}
                                                handleChange={(e) => { setInput(e.target.value); onChange(e.target.value) }}
                                                // defaultValue={(props.editSubAdminFormData?.places ?
                                                //     props.editSubAdminFormData?.places : value ?
                                                //         value : addLocation
                                                // )}
                                                option={value ? value : undefined}
                                            />
                                        )}
                                    />

                                    <small className={`text-red-400 text-xs`}>
                                        {errors?.places && errors?.places?.type === "required" && (<p>This field is required</p>)}
                                    </small>
                                </div>

                            </div>

                            {/* Sub Role Name controller  */}
                            <div className={`mb-5`}>
                                <Controller name={"subRole"}
                                    control={control}
                                    render={({ field: { onChange, value }, formState: { error } }) => {
                                        return (
                                            (<TextFieldInput
                                                onlyValue
                                                textnewclass={`w-full text-sm`}
                                                placeholder='Sub Role Name'
                                                onChange={onChange}
                                                value={value}
                                                floatingLabel={'Sub Role Name'}
                                                inputProps={{ maxLength: 500 }}
                                            />)
                                        )
                                    }}
                                    
                                    rules={{
                                        required: "This field is required",
                                        minLength: {
                                            value: 3,
                                            message: "Minimum 3 characters required",
                                        },
                                        validate: (value) =>
                                            value.trim().length > 0 || "Whitespace is not allowed",
                                    }}
                                />
                                <small className={`text-red-400 text-xs`}>
                                    {errors?.subRole && <p>{errors.subRole.message}</p>}
                                </small>
                            </div>

                            {/* Assigned dropDown  */}
                            <div className={`w-full dropdownText`}>

                                <Controller
                                    name="roles"
                                    control={control}
                                    defaultValue={[]}
                                    rules={{
                                        required: 'At least one role must be selected',
                                    }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <FormControl className="w-full" error={!!error}>
                                            <InputLabel id="demo-multiple-chip-label" className="bg-white">
                                                *Add Role
                                            </InputLabel>
                                            <Select
                                                labelId="demo-multiple-chip-label"
                                                id="demo-multiple-chip"
                                                multiple
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e.target.value);
                                                    // handleChangeSections(e); // Optional: Combine this with your state logic
                                                }}
                                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((val) => (
                                                            <Chip key={val} label={val} />
                                                        ))}
                                                    </Box>
                                                )}
                                                MenuProps={MenuProps}
                                                disabled={localStorage.getItem('role') === 'subAdmin'}
                                            >
                                                {selectArr1?.map((item) => (
                                                    <MenuItem
                                                        key={item.value}
                                                        value={item.value}
                                                    >
                                                        {item.value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {error && <FormHelperText>{error.message}</FormHelperText>}
                                        </FormControl>
                                    )}
                                />

                            </div>


                        </div>

                        <div>
                            <p className={`text-Grey-900 font-[Roboto-Medium] font-normal text-base mb-5`}>Upload Your Profile</p>
                            <div className={`relative`}>
                                {logoUpload &&
                                    (logoUpload?.mimetype === 'image/jpeg' || logoUpload?.mimetype === 'image/png') &&
                                    <div className={`absolute -top-2 left-24 -right-4 cursor-pointer`}>
                                        <CloseIcon
                                            sx={{ fontSize: "12px", border: '1px solid red', borderRadius: '9999px' }}
                                            onClick={() => dispatch(setUploadedLogo({}))}
                                        />
                                    </div>
                                }
                                <UploadButton
                                    sendData={(data) => {
                                        if (data[0]?.type === 'image/png' || data[0]?.type === 'image/jpeg') {
                                            doUploadedLogo(data)
                                        }
                                    }}
                                    extracls='flex justify-center items-center bg-white  items-center rounded-md '
                                    type='button'
                                    fileUploadIcon={
                                        logoUpload &&
                                            (logoUpload?.mimetype === 'image/jpeg' || logoUpload?.mimetype === 'image/png') && logoUpload?.key ?
                                            `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${logoUpload?.key}&height=20&width=auto` :
                                            fileUploadIcon
                                    }
                                    accept={'image/jpg, image/png'}
                                    multiple={false}
                                    disabled={logoUpload &&
                                        (logoUpload?.mimetype === 'image/jpeg' || logoUpload?.mimetype === 'image/png') ?
                                        true : false
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    {/* form cta  */}
                    <div className={`flex justify-start`}>
                        <div className='flex gap-6'>
                            <ButtonField
                                buttonName={text.cancel}
                                type='reset'
                                buttonextracls={` !px-6 !py-2 !text-gray-60 bg-gray-1 font-[Roboto-Medium] hover:bg-deepBlue hover:text-white`}
                                onClick={() => { props.goBackToTable(); cleanupMedia() }}
                            />
                            <ButtonField
                                buttonName={text.save}
                                type='submit'
                                buttonextracls={` !px-6 !py-2 !text-white bg-skyblue-6 hover:bg-deepBlue`}
                                onClick={handleSubmit(onSubmit)}
                                loading={props.loading}
                                disabled={props.loading === true ? true : false}
                            />
                        </div>
                    </div>
                    {props.subAdminCreateMsg?.error === true ? <p className='text-red-600 text-sm'>{props.subAdminCreateMsg?.message}</p> : null}
                </div>



            </form>
        </div>
    );
}

SubAdminForm.propTypes = {
    subAdminCreate: PropTypes.func,
    goBackToTable: PropTypes.func,

}

export default SubAdminForm