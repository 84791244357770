import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonField from '../../../Common/FormFields/ButtonField'
import edit from "../../../Images/profileDashboardIcons/edit.svg"
import deleteicon from "../../../Images/profileDashboardIcons/delete.svg"
import defaultserviceIcon from "../../../Images/profileDashboardIcons/defaultserviceIcon.svg"
import { useLocation } from 'react-router-dom'
import { ForAdmin } from '../../../Containers/Admin/Hooks'
import MuiCarousel from '../../../Common/UiComponents/Carousel'
import SnackBarComponent from '../../../Common/Layout/Snackbar/SnackBar'
import { CircularProgress } from '@mui/material'
import DeclinePopup from '../../../Common/FormFields/DeclinePopup'
import { priceAfterDiscount } from '../../../Utils/CommonFunc'
import NoContentPage from '../../../Common/Layout/NoContentPage/NoContentPage'
import {truncateText} from "../../../Utils/CommonFunc";

const OfferPendingPage = (props) => {
    const { approveVendorProducts, adminSnackbar, messageClose } = ForAdmin()

    const { t } = useTranslation()
    const locatn = useLocation()
    const [productActive, setProductActive] = useState({
        state: false,
        id: ''
    })

    const approveProducts = (offerId, userId) => {
        approveVendorProducts(offerId, userId)
        setProductActive({
            state: true,
            id: offerId
        })
    }

    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [productItem, setProductItem] = useState()
    const handleclickForDeleteProduct = (data) => {
        setOpenDeleteModal(!openDeleteModal)
        setProductItem(data)
    }
    const deleteProductSubmit = () => {
        props.deleteProductOffers(productItem)
        setOpenDeleteModal(!openDeleteModal)
    }

    return (
        <div className={`relative overflow-y-scroll h-[78vh]`}>
            {props.loading === true ?
                <div className='flex h-full justify-center items-center'>
                    <CircularProgress size={20} color='success' />
                    <p className='pl-3'>Loading...</p>
                </div>
                :
                <div>
                    {props?.tabledata && props?.tabledata?.length > 0 ?
                        <table class="table-auto w-full  min-w-[60rem] overflow-auto">
                            <thead>
                                <tr className={`w-full  h-12 sticky top-0 z-[10] bg-white`}>
                                    <th className={`w-[22%] font-[Roboto-Regular] text-base text-text-60 font-normal text-left pl-5`}>{t("preview")}</th>
                                    <th className={`w-[36%] font-[Roboto-Regular] text-base text-text-60 font-normal text-left`}>{`Products`}</th>
                                    <th className={`w-[13%] font-[Roboto-Regular] text-base text-text-60 font-normal text-left`}>{`Start date`}</th>
                                    <th className={`w-[13%] font-[Roboto-Regular] text-base text-text-60 font-normal text-left`}>{`End date`}</th>
                                    {/* <th className={`w-[16%] font-[Roboto-Regular] text-base text-text-60 font-normal text-left`}>{t("addOffer")}</th> */}
                                    <th className={`w-[14%] font-[Roboto-Regular] text-base text-text-60 font-normal text-left`}>{t("adminApproval")}</th>
                                    {locatn.pathname === '/vendor/products' &&
                                        <th className={`w-[12%] font-[Roboto-Regular] text-base text-text-60 font-normal text-left pr-5`}>{t("action")}</th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {props?.tabledata?.map((item, idx) =>
                                    <tr key={idx} className={`border border-b-grey py-5`}>
                                        {item?.status === 'pending' &&
                                            <>
                                                <td className={`pl-4 py-5`}>
                                                    {/* <img src={defaultserviceIcon} alt="defaultserviceIcon" /> */}
                                                    <MuiCarousel
                                                        itemContents={item?.product?.picture} // carouselimg reffers to show the images
                                                        height="100px"
                                                        autoPlay={false}
                                                        navButtonsAlwaysVisible={true}
                                                        stopAutoPlayOnHover={false}
                                                        animation="slide"
                                                        indicators={false}
                                                        navButtonsProps={{
                                                            style: {
                                                                borderRadius: 50,
                                                                margin: 2,
                                                                backgroundColor: '#333',
                                                                fontSize: 20,
                                                            }
                                                        }} navButtonsWrapperProps={{
                                                            style: {
                                                                bottom: '0',
                                                                top: 'unset'
                                                            }
                                                        }}
                                                    />
                                                </td>
                                                <td className='pl-4'>
                                                    <div className={`flex items-center pb-3`} >
                                                        <p className={`font-[Roboto-Regular] text-sm text-gray-60 pr-3`}>{`Product Name`}:</p>
                                                        <p className={`font-[Roboto-Regular] text-base text-[#1F1F1F]`}>{truncateText(item?.product?.name, 30)}</p>
                                                    </div>
                                                    <div className={`flex items-center gap-1`}>
                                                        <p className={`font-[Roboto-Regular] text-sm text-gray-60 pr-1`}>{`Details`}:</p>
                                                        <p className={`text-base font-[Roboto-Regular]  text-[#1F1F1F]`} title={item?.product?.description}> {truncateText(item?.product?.description, 30)}</p>
                                                    </div>
                                                    {/* <div className={`flex pb-3`}>
                                                        <p className={`font-[Roboto-Regular] text-sm text-gray-60 pr-3`}>{t("deliveryTime")}:</p>
                                                        <p className={`font-[Roboto-Regular] text-sm text-[#1F1F1F]`}>{item?.deliverytime}</p>
                                                    </div> */}
                                                    <div className={`flex items-center gap-2`}>
                                                        <p className={`font-[Roboto-Regular] text-sm text-gray-60 pr-1 pt-[6px]`}>{`Product Price`}:</p>
                                                        <p className={`text-lg font-[Roboto-Medium] text-[#1F1F1F] line-through`}>{item?.product?.price}&nbsp;{t(`KD`)}</p>
                                                        {item?.percentage ?
                                                            <p className={`text-lg font-[Roboto-Medium] text-[#1F1F1F]`}>{priceAfterDiscount(item?.product?.price, null, item?.percentage)}&nbsp;{t(`KD`)}</p>
                                                            :
                                                            item?.price &&
                                                            <p className={`text-lg font-[Roboto-Medium] text-[#1F1F1F]`}>{priceAfterDiscount(item?.product?.price, item?.price, null)}&nbsp;{t(`KD`)}</p>
                                                        }
                                                    </div>
                                                    <div className={`flex items-center`}>
                                                        {item?.percentage ?
                                                            <div className={`flex items-center`}>
                                                                <p className={`font-[Roboto-Regular] text-sm text-gray-60 pr-1 pt-[6px]`}>{`Percentage`}:</p>
                                                                <p className={`text-lg font-[Roboto-Medium] text-[#1F1F1F]`}>{item?.percentage}&nbsp;%</p>
                                                            </div>
                                                            :
                                                            item?.price &&
                                                            <div className={`flex items-center`}>
                                                                <p className={`font-[Roboto-Regular] text-sm text-gray-60 pr-1 pt-[6px]`}>{`Amount`}:</p>
                                                                <p className={`text-lg font-[Roboto-Medium] text-[#1F1F1F]`}>{item?.price}&nbsp;{t(`KD`)}</p>
                                                            </div>
                                                        }
                                                    </div>
                                                </td>
                                                <td className={`pl-4`}>
                                                    <p className={`font-[Roboto-Medium] text-[#1F1F1F] text-sm`}>{moment(item?.startTime).format('L')}</p>
                                                </td>
                                                <td className={`pl-4`}>
                                                    <p className={`font-[Roboto-Medium] text-[#1F1F1F] text-sm`}>{moment(item?.endTime).format('L')}</p>
                                                </td>
                                                {/* <td>
                                        <ButtonField
                                            buttonextracls={`bg-skyblue-6 text-white capitalize`}
                                            buttonName='addOffer'
                                        />
                                    </td> */}
                                                <td>
                                                    <p className={`text-seconderycolour-4 font-[Roboto-Medium] text-[15px]`}>{t("pending")}</p>
                                                    {locatn.pathname === `/admin/totalVendors/${item?.user?.id}` &&
                                                        <ButtonField
                                                            buttonextracls={`${(productActive?.state === true && productActive?.id === item?.id) ? 'hidden' : 'cursor-pointer text-xs bg-skyblue-5 p-2 text-white rounded w-fit'} `}
                                                            onClick={() => approveProducts(item?.id, item?.user?.id)}
                                                            buttonName={`Approve`}
                                                            loading={productActive?.id === item?.id && props.buttonLoading}
                                                        />
                                                    }
                                                </td>
                                                {locatn.pathname === '/vendor/products' &&
                                                    <td>
                                                        <div className='flex w-20 pr-8 justify-between'>
                                                            {/* <img src={edit} alt="edit" height={15} width={15} className={`cursor-pointer`} onClick={() => props.editProductForm(item)} /> */}
                                                            <img src={deleteicon} alt="delete" height={15} width={15} className={`cursor-pointer`} onClick={() => handleclickForDeleteProduct(item)} />
                                                        </div>
                                                    </td>
                                                }
                                            </>
                                        }
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        :
                        <NoContentPage
                            NoContentMainDiv={`h-[78vh]`}
                            text2={`There is no data`}
                        />
                    }
                </div>
            }
            {openDeleteModal &&
                <DeclinePopup
                    dialogTitlecls={`!text-red-500`}
                    submitBtnCls={`!bg-red-600`}
                    open={openDeleteModal}
                    title={`Decline`}
                    handleClose={handleclickForDeleteProduct}
                    DilogContenttext={"Price"}
                    DialogContent={
                        <>
                            <p>{t('deleteProductText')}</p>
                        </>
                    }
                    DeleteBtn={"Decline"}
                    onClick={deleteProductSubmit}
                />
            }
            <SnackBarComponent
                messageOpen={adminSnackbar.open}
                messageClose={messageClose}
                notificationText={adminSnackbar.message}
                subText={adminSnackbar.subText}
                alertType={adminSnackbar.alertType}
                borderClass={adminSnackbar.borderClass}
            />
        </div>
    )
}

export default OfferPendingPage