import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form';
import TextFieldInput from '../../../Common/FormFields/TextFieldInput';
import ButtonField from '../../../Common/FormFields/ButtonField';
import DropDownField from '../../../Common/FormFields/DropDownField';
import { IoChevronBackSharp } from "react-icons/io5";

const SubscriptionForm = (props) => {

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    watch
  } = useForm({
    defaultValues: {
      type: props.editPlan?.planType ? props.editPlan?.planType : '',
      addonsType: props.editPlan?.addonsType ? props.editPlan?.addonsType : '',
      name: props.editPlan?.name ? props.editPlan?.name : '',
      durationInDays: props.editPlan?.durationInDays ? props.editPlan?.durationInDays : '',
      photoLimit: props.editPlan?.photoLimit ? props.editPlan?.photoLimit : '',
      videoLimit: props.editPlan?.videoLimit ? props.editPlan?.videoLimit : '',
      videoDuration: props.editPlan?.videoDuration ? props.editPlan?.videoDuration : '',
      freeDuration: props?.editPlan?.freeDuration ? props.editPlan?.freeDuration : '',
      price: props.editPlan?.price ? props.editPlan?.price : '',
      priority: props.editPlan?.priority ? props.editPlan?.priority : null,

      serviceOfferedCount: props?.editPlan?.serviceOfferedCount ? props?.editPlan?.serviceOfferedCount : '',
      // serviceOfferedChangeLimit: props?.editPlan?.serviceOfferedChangeLimit ? props?.editPlan?.serviceOfferedChangeLimit : '',
      serviceOfferedPhotoLimit: props?.editPlan?.serviceOfferedPhotoLimit ? props?.editPlan?.serviceOfferedPhotoLimit : '',

      postRequirementCount: props?.editPlan?.postRequirementCount ? props?.editPlan?.postRequirementCount : '',
      // postRequirementChangeLimit: props?.editPlan?.postRequirementChangeLimit ? props?.editPlan?.postRequirementChangeLimit : '',
      postRequirementPhotoLimit: props?.editPlan?.postRequirementPhotoLimit ? props?.editPlan?.postRequirementPhotoLimit : '',

      postOffersCount: props?.editPlan?.postOffersCount ? props?.editPlan?.postOffersCount : '',
      // postOffersChangeLimit: props?.editPlan?.postOffersChangeLimit ? props?.editPlan?.postOffersChangeLimit : '',
      postOffersPhotoLimit: props?.editPlan?.postOffersPhotoLimit ? props?.editPlan?.postOffersPhotoLimit : '',

      // requirementNum: props.editPlan?.monthlyPostRequirements ? props.editPlan?.monthlyPostRequirements : '',
      // servicesPost: props.editPlan?.serviceOffered ? props.editPlan?.serviceOffered : '',
      // productsPost: props.editPlan?.monthlyPostOffers ? props.editPlan?.monthlyPostOffers : '',
      // listing: props.editPlan?.accountView ? props.editPlan?.accountView : '',

    }
  });

  const watchPlanType = watch('type', false);
  const watchAddonsType = watch('addonsType', false);

  const onSubmit = (data) => {
    props.editPlan ? props.updateSubscriptionPlansApiCall(props.editPlan?.id, data) : props.createSubscriptionPlansApiCall(data)   // <-- API call for creating Subscription

    reset({
      type: '',
      addonsType: '',
      name: '',
      durationInDays: '',
      photoLimit: '',
      videoLimit: '',
      videoDuration: '',
      freeDuration: '',
      price: '',
      priority: 1,

      serviceOfferedCount: '',
      // serviceOfferedChangeLimit: '',
      serviceOfferedPhotoLimit: '',

      postRequirementCount: '',
      // postRequirementChangeLimit: '',
      postRequirementPhotoLimit: '',

      postOffersCount: '',
      // postOffersChangeLimit: '',
      postOffersPhotoLimit: ''


      // productsPost: '',
      // requirementNum: '',
      // servicesPost: '',
      // listing: '',
    })
  };

  const planType = [
    { name: 'General', value: 'general' },
    { name: 'Corporate', value: 'corporate' },
    { name: 'Add-on', value: 'addOns' },
  ]

  const addonsType = [
    { name: 'Posting Additional Service', value: 'service' },
    { name: 'Posting Additional Offer', value: 'postOffer' },
    { name: 'Posting Additional Requirements', value: 'requirement' }
  ]

  const priorityType = [
    { name: 'Platinum', value: 3 },
    { name: 'Gold', value: 2 },
    { name: 'Basic', value: 1 },
  ]


  // Callback version of watch.  It's your responsibility to unsubscribe when done.
  useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      console.log(value, name, type)
    )
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <div className={`p-10`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex justify-between textFieldText'>
          <div className={`w-[65%]`}>
            <div className='flex items-center cursor-pointer font-semibold text-[20px] pb-2' onClick={props.handleSubscriptionForm}>
              <IoChevronBackSharp className='text-[px] pt-[2px]'/>
              Back
            </div>
            <p>Create your plan</p>

            {/* Subscription type and name  */}
            <div className={`grid grid-cols-2 gap-6 w-full my-4`}>

              {/* Subscription type */}
              <div className='flex flex-col'>
                <Controller
                  name={"type"}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      (<DropDownField
                        name='type'
                        id='type'
                        dropDownRootCls={`h-[3.28rem]`}
                        size="small"
                        selectOption={planType}
                        placeholder={`*Select Plan Type`}
                        option={value}
                        handleChange={(e) => onChange(e.target.value)}
                      />)
                    )
                  }}
                  rules={{
                    required: true,
                  }}
                />
                {errors.type && errors.type.type === "required" && (
                  <span className="error-message text-red-400 text-xs">{`Required`}</span>
                )}
                {errors.type && errors.type.type === "pattern" && (
                  <span className="error-message text-red-400 text-xs">Select plan type</span>
                )}
              </div>

              {/* Addons Type */}
              {watchPlanType === 'addOns' && <div className='flex flex-col'>
                <Controller
                  name={"addonsType"}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      (<DropDownField
                        name='addonsType'
                        id='addonsType'
                        dropDownRootCls={`h-[3.28rem]`}
                        size="small"
                        selectOption={addonsType}
                        placeholder={`*Select Addons Type`}
                        option={value}
                        handleChange={(e) => onChange(e.target.value)}
                      />)
                    )
                  }}
                  rules={{
                    required: true,
                  }}
                />
                {errors.addonsType && errors.addonsType.type === "required" && (
                  <span className="error-message text-red-400 text-xs">{`Required`}</span>
                )}
                {errors.addonsType && errors.addonsType.type === "pattern" && (
                  <span className="error-message text-red-400 text-xs">Please select addons type</span>
                )}
              </div>}

              {/*Subscription Name */}
              <div className='flex flex-col'>
                <Controller
                  name={"name"}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      (<TextFieldInput
                        onlyValue
                        textnewclass={`flex-1 w-full text-sm`}
                        onChange={onChange}
                        value={value}
                        // defaultValue={props.editPlan ?  : value}
                        floatingLabel={'*Enter Subscription Name'}
                      />)
                    )
                  }}
                  rules={{
                    required: true, pattern: /^[a-zA-Z]/
                  }}
                />
                {errors.name && errors.name.type === "required" && (
                  <span className="error-message text-red-400 text-xs">{`Required`}</span>
                )}
                {errors.name && errors.name.type === "pattern" && (
                  <span className="error-message text-red-400 text-xs">Invalid Name</span>
                )}
              </div>
            </div>

            {watchPlanType !== 'addOns' && <div className={`grid grid-cols-2 gap-6 w-full my-4`}>
              {/* Duration In days */}
              <div className={`flex flex-col`}>
                <Controller
                  name={"durationInDays"}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      (<TextFieldInput
                        onlyValue
                        textnewclass={`flex-1 w-full text-sm`}
                        typeNumber
                        onChange={onChange}
                        onWheel={(e) => e.target.blur()}
                        value={value}
                        // defaultValue={props.editPlan ? props.editPlan?.durationInDays : value}
                        floatingLabel={'*Enter Duration (in Days)'}
                      />)
                    )
                  }}
                  rules={{
                    required: true, pattern: /^[0-9]+$/
                  }}
                />
                {errors.durationInDays && errors.durationInDays.type === "required" && (
                  <span className="error-message text-red-400 text-xs">{`Required`}</span>
                )}
                {errors.durationInDays && errors.durationInDays.type === "pattern" && (
                  <span className="error-message text-red-400 text-xs">Invalid Number</span>
                )}
              </div>
              {/* Photo posting limit */}
              <div className={`flex flex-col`}>
                <Controller
                  name={"photoLimit"}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      (<TextFieldInput
                        onlyValue
                        textnewclass={`flex-1 w-full text-sm`}
                        typeNumber
                        onChange={onChange}
                        onWheel={(e) => e.target.blur()}
                        value={value}
                        // defaultValue={props.editPlan ? props.editPlan?.photoLimit : value}
                        floatingLabel={'*Enter Photo Limit'}
                      />)
                    )
                  }}
                  rules={{
                    required: true, pattern: /^[0-9]+$/
                  }}
                />
                {errors.photoLimit && errors.photoLimit.type === "required" && (
                  <span className="error-message text-red-400 text-xs">{`Required`}</span>
                )}
                {errors.photoLimit && errors.photoLimit.type === "pattern" && (
                  <span className="error-message text-red-400 text-xs">Invalid Number</span>
                )}
              </div>
            </div>}

            {watchPlanType !== 'addOns' && <div className={`grid grid-cols-2 gap-6 w-full my-4`}>
              {/* Video Limit */}
              <div className={`flex flex-col`}>
                <Controller
                  name={"videoLimit"}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      (<TextFieldInput
                        onlyValue
                        textnewclass={`flex-1 w-full text-sm`}
                        typeNumber
                        onChange={onChange}
                        onWheel={(e) => e.target.blur()}
                        value={value}
                        // defaultValue={props.editPlan ? props.editPlan?.videoLimit : value}
                        floatingLabel={'*Enter Video Limit'}
                      />)
                    )
                  }}
                  rules={{ required: true, pattern: /^[0-9]+$/ }}
                />
                {errors.videoLimit && errors.videoLimit.type === "required" && (
                  <span className="error-message text-red-400 text-xs">{`Required`}</span>
                )}
                {errors.videoLimit && errors.videoLimit.type === "pattern" && (
                  <span className="error-message text-red-400 text-xs">Invalid Number</span>
                )}
              </div>
              {/* Video duration */}
              <div className='flex flex-col'>
                <Controller
                  name={"videoDuration"}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      (<TextFieldInput
                        onlyValue
                        typeNumber
                        textnewclass={`flex-1 w-full text-sm`}
                        onChange={onChange}
                        onWheel={(e) => e.target.blur()}
                        value={value}
                        // defaultValue={props.editPlan ? props.editPlan?.videoDuration : value}
                        floatingLabel={'Enter Video duration'}
                      />)
                    )
                  }}
                // rules={{
                //   required: true, pattern: /^[0-9]+$/
                // }}
                />
                {/* {errors.videoDuration && errors.videoDuration.type === "required" && (
                  <span className="error-message text-red-400 text-xs">{`Required`}</span>
                )}
                {errors.videoDuration && errors.videoDuration.type === "pattern" && (
                  <span className="error-message text-red-400 text-xs">Invalid number</span>
                )} */}
              </div>
            </div>}

            {/* Free duration and Price */}
            <div className={`grid grid-cols-2 gap-6 w-full my-4`}>

              {/* Free duration */}
              {watchPlanType !== 'addOns' && <div className='flex flex-col'>
                <Controller
                  name={"freeDuration"}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      (<TextFieldInput
                        onlyValue
                        typeNumber
                        textnewclass={`flex-1 w-full text-sm`}
                        onChange={onChange}
                        onWheel={(e) => e.target.blur()}
                        value={value}
                        floatingLabel={'Free duration (in Days)'}
                      />)
                    )
                  }}
                  rules={{
                    required: false, pattern: /^[0-9]+$/
                  }}
                />
                {errors.freeDuration && errors.freeDuration.type === "required" && (
                  <span className="error-message text-red-400 text-xs">{`Required`}</span>
                )}
                {errors.freeDuration && errors.freeDuration.type === "pattern" && (
                  <span className="error-message text-red-400 text-xs">Invalid Format</span>
                )}
              </div>}

              {/* Price */}
              <div className='flex flex-col'>
                <Controller
                  name={"price"}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      (<TextFieldInput
                        onlyValue
                        typeNumber
                        textnewclass={`flex-1 w-full text-sm`}
                        onChange={onChange}
                        onWheel={(e) => e.target.blur()}
                        value={value}
                        // defaultValue={props.editPlan ? props.editPlan?.price : value}
                        floatingLabel={'*Price'}
                      />)
                    )
                  }}
                  rules={{
                    required: true, pattern: /^[0-9]+$/
                  }}
                />
                {errors.price && errors.price.type === "required" && (
                  <span className="error-message text-red-400 text-xs">{`Required`}</span>
                )}
                {errors.price && errors.price.type === "pattern" && (
                  <span className="error-message text-red-400 text-xs">Invalid Name</span>
                )}
              </div>
            </div>
            <div className={`grid grid-cols-2 gap-6 w-full my-4`}>
              {watchPlanType !== 'addOns' &&
                <div className='flex flex-col'>
                  <Controller
                    name={"priority"}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        (<DropDownField
                          name='priority'
                          id='priority'
                          dropDownRootCls={`h-[3.28rem]`}
                          size="small"
                          selectOption={priorityType}
                          placeholder={`*Select Priority`}
                          option={value}
                          handleChange={(e) => onChange(e.target.value)}
                        />)
                      )
                    }}
                    rules={{
                      required: true,
                    }}
                  />
                  {errors.type && errors.type.type === "required" && (
                    <span className="error-message text-red-400 text-xs">{`Required`}</span>
                  )}
                  {errors.type && errors.type.type === "pattern" && (
                    <span className="error-message text-red-400 text-xs">Select plan type</span>
                  )}
                </div>}
            </div>

            {/* Service offered */}
            {((watchPlanType === 'addOns' && watchAddonsType === 'service') || watchPlanType !== 'addOns') && <div className={`py-3`}>
              <p>Service Offered</p>
              <div className={`flex gap-4 w-full my-4`}>

                {/* Service offered count */}
                <div className='flex w-[50%] flex-col'>
                  <Controller
                    name={"serviceOfferedCount"}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        (<TextFieldInput
                          onlyValue
                          textnewclass={`flex-1 w-full text-sm`}
                          typeNumber
                          onChange={onChange}
                          onWheel={(e) => e.target.blur()}
                          value={value}
                          // defaultValue={props.editPlan ? props.editPlan?.monthlyPostRequirements : value}
                          floatingLabel={'*Services'}
                        />)
                      )
                    }}
                    rules={{ required: true, pattern: /^[0-9]+$/ }}
                  />
                  {errors.serviceOfferedCount && errors.serviceOfferedCount.type === "required" && (
                    <span className="error-message text-red-400 text-xs">{`Required`}</span>
                  )}
                  {errors.serviceOfferedCount && errors.serviceOfferedCount.type === "pattern" && (
                    <span className="error-message text-red-400 text-xs">Please enter a valid type</span>
                  )}
                </div>

                {/* Service offered monthly change limit */}
                {watchPlanType !== 'addOns' && <div className='flex flex-col'>
                  {/* <Controller
                    name={"serviceOfferedChangeLimit"}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        (<TextFieldInput
                          onlyValue
                          textnewclass={`flex-1 w-full text-sm`}
                          typeNumber
                          onChange={onChange}
                          value={value}
                          // defaultValue={props.editPlan ? props.editPlan?.serviceOffered : value}
                          floatingLabel={'*Change Limit/month'}
                        />)
                      )
                    }}
                    rules={{
                      required: true, pattern: /^[0-9]+$/
                    }} 
                  />*/}
                  {errors.serviceOfferedChangeLimit && errors.serviceOfferedChangeLimit.type === "required" && (
                    <span className="error-message text-red-400 text-xs">{`Required`}</span>
                  )}
                  {errors.serviceOfferedChangeLimit && errors.serviceOfferedChangeLimit.type === "pattern" && (
                    <span className="error-message text-red-400 text-xs">Invalid Number</span>
                  )}
                </div>}

                {/* photo limit per month */}
                <div className='flex w-[50%] flex-col'>
                  <Controller
                    name={"serviceOfferedPhotoLimit"}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        (<TextFieldInput
                          onlyValue
                          textnewclass={`flex-1 w-full text-sm`}
                          typeNumber
                          onChange={onChange}
                          onWheel={(e) => e.target.blur()}
                          value={value}
                          // defaultValue={props.editPlan ? props.editPlan?.monthlyPostOffers : value}
                          floatingLabel={'*Photo Limit / Month'}
                        />)
                      )
                    }}
                    rules={{
                      required: true, pattern: /^[0-9]+$/
                    }}
                  />
                  {errors.serviceOfferedPhotoLimit && errors.serviceOfferedPhotoLimit.type === "required" && (
                    <span className="error-message text-red-400 text-xs">{`Required`}</span>
                  )}
                  {errors.serviceOfferedPhotoLimit && errors.serviceOfferedPhotoLimit.type === "pattern" && (
                    <span className="error-message text-red-400 text-xs">Please enter a valid type</span>
                  )}
                </div>
              </div>
            </div>}



            {/* Monthly post requirement offered */}
            {((watchPlanType === 'addOns' && watchAddonsType === 'requirement') || watchPlanType !== 'addOns') && <div className={`py-3`}>
              <p>Monthly Post Requirement</p>
              <div className={`flex gap-4 w-full my-4`}>

                {/* Monthly Post Requirement count */}
                <div className='flex w-[50%] flex-col'>
                  <Controller
                    name={"postRequirementCount"}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        (<TextFieldInput
                          onlyValue
                          textnewclass={`flex-1 w-full text-sm`}
                          typeNumber
                          onChange={onChange}
                          onWheel={(e) => e.target.blur()}
                          value={value}
                          // defaultValue={props.editPlan ? props.editPlan?.monthlyPostRequirements : value}
                          floatingLabel={'*Post Requirement / Month'}
                        />)
                      )
                    }}
                    rules={{ required: true, pattern: /^[0-9]+$/ }}
                  />
                  {errors.postRequirementCount && errors.postRequirementCount.type === "required" && (
                    <span className="error-message text-red-400 text-xs">{`Required`}</span>
                  )}
                  {errors.postRequirementCount && errors.postRequirementCount.type === "pattern" && (
                    <span className="error-message text-red-400 text-xs">Please enter a valid type</span>
                  )}
                </div>

                {/* Monthly Post Requirement monthly change limit */}
                {watchPlanType !== 'addOns' && <div className='flex flex-col'>
                  {/* <Controller
                    name={"postRequirementChangeLimit"}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        (<TextFieldInput
                          onlyValue
                          textnewclass={`flex-1 w-full text-sm`}
                          typeNumber
                          onChange={onChange}
                          value={value}
                          // defaultValue={props.editPlan ? props.editPlan?.serviceOffered : value}
                          floatingLabel={'*Change Limit/month'}
                        />)
                      )
                    }}
                    rules={{
                      required: true, pattern: /^[0-9]+$/
                    }}
                  /> */}
                  {errors.postRequirementChangeLimit && errors.postRequirementChangeLimit.type === "required" && (
                    <span className="error-message text-red-400 text-xs">{`Required`}</span>
                  )}
                  {errors.postRequirementChangeLimit && errors.postRequirementChangeLimit.type === "pattern" && (
                    <span className="error-message text-red-400 text-xs">Invalid Number</span>
                  )}
                </div>}

                {/* photo limit per month */}
                <div className='flex w-[50%] flex-col'>
                  <Controller
                    name={"postRequirementPhotoLimit"}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        (<TextFieldInput
                          onlyValue
                          textnewclass={`flex-1 w-full text-sm`}
                          typeNumber
                          onChange={onChange}
                          onWheel={(e) => e.target.blur()}
                          value={value}
                          // defaultValue={props.editPlan ? props.editPlan?.monthlyPostOffers : value}
                          floatingLabel={'*Photo Limit / Month'}
                        />)
                      )
                    }}
                    rules={{
                      required: true, pattern: /^[0-9]+$/
                    }}
                  />
                  {errors.postRequirementPhotoLimit && errors.postRequirementPhotoLimit.type === "required" && (
                    <span className="error-message text-red-400 text-xs">{`Required`}</span>
                  )}
                  {errors.postRequirementPhotoLimit && errors.postRequirementPhotoLimit.type === "pattern" && (
                    <span className="error-message text-red-400 text-xs">Please enter a valid type</span>
                  )}
                </div>
              </div>
            </div>}


            {/* Post offers */}
            {((watchPlanType === 'addOns' && watchAddonsType === 'postOffer') || watchPlanType !== 'addOns') && <div className={`py-3`}>
              <p>Product Offers</p>
              <div className={`flex gap-4 w-full my-4`}>

                {/* Post offers count */}
                <div className='flex w-[50%] flex-col'>
                  <Controller
                    name={"postOffersCount"}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        (<TextFieldInput
                          onlyValue
                          textnewclass={`flex-1 w-full text-sm`}
                          typeNumber
                          onChange={onChange}
                          onWheel={(e) => e.target.blur()}
                          value={value}
                          // defaultValue={props.editPlan ? props.editPlan?.monthlyPostRequirements : value}
                          floatingLabel={'*Product offers / Month'}
                        />)
                      )
                    }}
                    rules={{ required: true, pattern: /^[0-9]+$/ }}
                  />
                  {errors.postOffersCount && errors.postOffersCount.type === "required" && (
                    <span className="error-message text-red-400 text-xs">{`Required`}</span>
                  )}
                  {errors.postOffersCount && errors.postOffersCount.type === "pattern" && (
                    <span className="error-message text-red-400 text-xs">Please enter a valid type</span>
                  )}
                </div>

                {/* Post offers monthly change limit */}
                {watchPlanType !== 'addOns' && <div className='flex flex-col'>
                  {/* <Controller
                    name={"postOffersChangeLimit"}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        (<TextFieldInput
                          onlyValue
                          textnewclass={`flex-1 w-full text-sm`}
                          typeNumber
                          onChange={onChange}
                          value={value}
                          // defaultValue={props.editPlan ? props.editPlan?.serviceOffered : value}
                          floatingLabel={'*Change Limit/month'}
                        />)
                      )
                    }}
                    rules={{
                      required: true, pattern: /^[0-9]+$/
                    }}
                  /> */}
                  {errors.postOffersChangeLimit && errors.postOffersChangeLimit.type === "required" && (
                    <span className="error-message text-red-400 text-xs">{`Required`}</span>
                  )}
                  {errors.postOffersChangeLimit && errors.postOffersChangeLimit.type === "pattern" && (
                    <span className="error-message text-red-400 text-xs">Invalid Number</span>
                  )}
                </div>}

                {/* photo limit per month */}
                <div className='flex w-[50%] flex-col'>
                  <Controller
                    name={"postOffersPhotoLimit"}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        (<TextFieldInput
                          onlyValue
                          textnewclass={`flex-1 w-full text-sm`}
                          typeNumber
                          onChange={onChange}
                          onWheel={(e) => e.target.blur()}
                          value={value}
                          // defaultValue={props.editPlan ? props.editPlan?.monthlyPostOffers : value}
                          floatingLabel={'*Photo Limit / Month'}
                        />)
                      )
                    }}
                    rules={{
                      required: true, pattern: /^[0-9]+$/
                    }}
                  />
                  {errors.postOffersPhotoLimit && errors.postOffersPhotoLimit.type === "required" && (
                    <span className="error-message text-red-400 text-xs">{`Required`}</span>
                  )}
                  {errors.postOffersPhotoLimit && errors.postOffersPhotoLimit.type === "pattern" && (
                    <span className="error-message text-red-400 text-xs">Please enter a valid type</span>
                  )}
                </div>

              </div>
            </div>}

          </div>
        </div>

        <div className={`flex justify-start my-4`}>
          <div className='flex gap-6'>
            <ButtonField
              buttonName={`cancel`}
              buttonInsidecls={`gap-2`}
              type='reset'
              buttonextracls={` !px-6 !py-2 !text-deepgrey-3 bg-Gray-5 font-[Roboto-Medium]`}
              onClick={props.handleSubscriptionForm}
            />
            <ButtonField
              buttonName={props.editUserFormData ? `Update` : `Add`}
              type='submit'
              loading={props.loading}
              buttonInsidecls={`!flex-row-reverse gap-2 `}
              buttonextracls={`!text-white bg-skyblue-6 font-[Roboto-Medium] !px-6 !py-2 `}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
        {props.totalUserCreateMsg?.error === true ? <p className='text-red-600 text-sm'>{props.totalUserCreateMsg?.message}</p> : null}
      </form>
    </div>
  )
}

export default SubscriptionForm