import React, { useCallback, useEffect, useRef, useState } from 'react'
import TextFieldInput from '../../Common/FormFields/TextFieldInput'
import browsenearbybackgroundimage from "../../Images/LandingImages/DD_BG.png"
import browsenearbybackgroundimageInverted from "../../Images/LandingImages/DD_BG_inverted.png"
import { useTranslation } from 'react-i18next'
// import RenderGoogleMap from './RenderGoogleMap'
import GoogleMapTwo from '../../Common/UiComponents/GoogleMapTwo/GoogleMapTwo'
import RenderGoogleMap from './RenderGoogleMap'
import GoogleMapView from '../../Common/UiComponents/GoogleMapView'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";

const BrowsNearbyPlace = (props) => {
  const { ready, value, suggestions: { status, data },
    setValue, clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 22.5726, lng: () => 88.3639 },
      radius: 5 * 1000,
    }
  });
  const { t, i18n } = useTranslation()
  const [searchObj, setSearchObj] = useState(null);
  const mapRef = useRef();

  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);

  const myLatLngCenter = {
    lat: parseFloat(sessionStorage.getItem('latitude')),
    lng: parseFloat(sessionStorage.getItem('longitude'))
  }

  return (
    <div className='w-full flex flex-col  h-full p-[4.5%]'>
      <div className={`relative pb-[5%]`}>
        {i18n.dir() === 'ltr' ?
          <img src={browsenearbybackgroundimage} alt="browsenearby" className='w-full' />
          :
          i18n.dir() === 'rtl' && <img src={browsenearbybackgroundimageInverted} alt="browsenearby" />
        }
        <div className={`flex flex-col p-2 sm:p-[7.5%] absolute top-0 w-[100%]`}>
          <p className={`text-deepgray-5 flex flex-col `}>
            <span className={`font-[Poppins-Bold] font-bold text-[12px] md:text-[20px] lg:text-[25px] leading-[10px] lg:leading-[28px] tracking-[0.25px] pb-[1%]`}>{t("explore")}</span>
            <span className={`fonst-[Roboto-Medium] font-medium text-[12px] md:text-[20px] lg:text-[30px] leading-[20px] sm:leading-[40px] lg:leading-[47px] tracking-[0.25px]`}>{t("browseNearby")}</span>
            <span className={`font-[Roboto-Light] font-light text-[12px] md:text-[20px] lg:text-[30px] leading-[20px] sm:leading-[40px] lg:leading-[47px] tracking-[0.25px]`}>{t("browseNearbyExample")}</span>
          </p>


          <div className={`flex pt-1 sm:pt-[3.5%]`}>
            <div
              className={`bg-white ${i18n.dir() === 'rtl' ? 'rounded-r-[10px]' : 'rounded-l-[10px]'
                } w-[100%] sm:w-[30%]`}
            >
              <input
                type="text"
                placeholder="Search"
                className={`w-full h-8 sm:h-12 px-4 text-sm text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-400 ${i18n.dir() === 'rtl' ? 'rounded-r-[10px]' : 'rounded-l-[10px]'
                }`}
                onChange={(e) => props.handleSearchData(e)}
                value={props.searchData}
                onKeyPress={props.handleSearchResult}
                disabled={!myLatLngCenter?.lat && !myLatLngCenter?.lng}
              />
              {/* <Combobox
                style={{width:'500px', height:'46px'}}
                onSelect={async (address) => {
                  setValue(address);
                  clearSuggestions();
                  try {
                    const results = await getGeocode({ address });
                    if (results && results.length > 0) {
                      const { lat, lng } = await getLatLng(results[0]);
                      // console.log(results[0],'results[0] #');
                      panTo({ lat, lng });
                      setSearchObj(results[0]);
                      
                    }
                    
                  } catch (error) {
                    console.error(error);
                  }

                }
                }>
                <ComboboxInput
                  style={{width:'100%', height:'100%', padding: '1em'}}
                  value={value} 
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                  disabled={!ready}
                  placeholder="Search places here"
                />
                <ComboboxPopover>
                  <ComboboxList>
                  {status === 'OK' && data.map(({ id, description }) => (
                    <ComboboxOption key={id} value={description} />
                  ))}
                  </ComboboxList>
                </ComboboxPopover>
              </Combobox> */}
            </div>

            <div
              className={`text-base text-white font-normal font-[Roboto-Regular] w-24 h-8 sm:h-12 flex items-center justify-center ${i18n.dir() === 'rtl' ? 'rounded-l-[10px]' : 'rounded-r-[10px]'
                } ${!myLatLngCenter?.lat && !myLatLngCenter?.lng ? 'bg-[#b8b8b8] text-black' : 'cursor-pointer bg-seconderyColour-5 text-white'}`}
              onClick={props.handleSearchResult}
            >
              {t("search")}
            </div>

          </div>
          {myLatLngCenter?.lat && myLatLngCenter?.lng ?
            null
            : <p className='w-[100%] sm:w-[60%] mt-2 sm:mt-4 text-[#ff0000] text-[10px] sm:text-[15px]'>* Allow location in your system, please. Next, refresh the site.</p>
          }
        </div>
      </div>
      {
        <GoogleMapView
          searchResult={props.searchResult}
          mapRef={mapRef}
          markers={props.markers}
          handleSetMarkers={props.handleSetMarkers}
          selected={props.selected}
          handleSetSelected={props.handleSetSelected}
        />
        // props.openMap &&
        // <GoogleMapTwo searchResult={props.searchResult} lat={props.lat} lng={props.lng} />
        // <RenderGoogleMap lat={props.lat} lng={props.lng} />
      }
    </div>
  )
}

export default BrowsNearbyPlace