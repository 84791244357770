import React from 'react'
import DDlandingBanner from '../../Images/LandingImages/DDlandingBanner.jpg'
import MMlandingBanner from '../../Images/LandingImages/MMlandingBanner.jpg'
import appStore from '../../Images/LandingImages/appStore.svg'
import playStore from '../../Images/LandingImages/playStore.svg'
import './LandingPage.css'
import ScrollTab from '../../Common/Layout/ScrollTab/ScrollTab'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'

const BannerSection = (props) => {
    const { t, i18n } = useTranslation()
    const categories = useSelector(state => state.totalCategoryInAdmin.totalCategories)
    const subCatVendors = useSelector(state => state.landingData.subCatVendor)
    const role = localStorage.getItem('role')
    const token = localStorage.getItem('token')

    const navigate = useNavigate()

    const postRequirementHandler = () => {
        if (role && token) {
            switch (role) {
                case "user": navigate('/user')
                    break;
                case "vendor": navigate('/vendor/myrequirements')
                    break;
                case "admin": navigate('/admin/dashboard')
                    break;
                default: break;
            }
        } else {
            navigate('/login')
        }
    }

    return (
        <>
            <div className={`h-full relative`}>
                <img src={DDlandingBanner} alt='DDlandingBanner' className='w-full h-full' />
                {/* <img src={MMlandingBanner} alt='DDlandingBanner' className='block md:hidden w-full h-full' /> */}
                {/* <div className={`flex flex-col w-4/5 h-full lg:w-3/5 justify-center text-left px-[4.5%] tracking-wide  text-white absolute top-0 ${i18n.dir() === 'rtl' ? 'bannerBackgroundForArabicCls' : 'bannerBackgroundCls'}`}> */}
                <div className={`flex flex-col w-[100%] md:w-[70%] lg:w-[55%] h-full justify-center text-left px-[4.5%] tracking-wide  text-white absolute top-0 ${i18n.dir() === 'rtl' ? 'left-0' : ''}`}>
                    {/* <div className={`flex flex-col`}>
                        <p className={`font-[Roboto-Regular] md:leading-10 font-extrabold text-[15px] sm:text-[25px] md:text-[25px] lg:text-[30px] ${i18n.dir() === 'rtl' ? 'text-right' : ''}`}>{t("lookingForSomething")}</p>
                        <p className={`font-[Roboto-Light] md:leading-10 w-full font-light text-[10px] sm:text-[20px] md:text-[20px] lg:text-[26px] ${i18n.dir() === 'rtl' ? 'text-right' : ''}`}>{t("weGotYou")}</p>
                    </div> */}
                    <div className={`flex items-center gap-4 py-2 sm:py-7`}>
                        <div onClick={postRequirementHandler} className={` w-auto flex justify-center items-center text-black text-[10px] sm:text-[18px] bg-white rounded-full px-2 sm:px-4 md:px-6 py-2 md:py-4 cursor-pointer`}>{t("postRequirement")}</div>
                        <a href='https://play.google.com/store/games?hl=en_IN&pli=1' target='_blank' rel="noreferrer" className={` w-auto text-[10px] sm:text-[18px] flex justify-center items-center text-white bg-skyblue-6 rounded-full px-2 sm:px-4 md:px-6 py-2 md:py-4 gap-4 cursor-pointer `}>
                            {t("downloadApp")}
                            <div className='flex items-center gap-3'>
                                <img src={appStore} alt='appStore' className='w-4 h-[15px]' />
                                <img src={playStore} alt='playStore' className='w-4 h-[15px]' />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            {/* scrolling tab */}
            <div id="landing-tabs">
                <ScrollTab categoriesArray={categories} scroll={true} scrollClickHandler={props.scrollClickHandler} getVendorsUnderSubCategory={props.getVendorsUnderSubCategory} subCatVendors={subCatVendors} />
            </div>
        </>
    )
}

export default BannerSection