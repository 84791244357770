import React, { useState } from 'react'
import trackicon from '../../../Images/Vendor/trackicon.svg'
import message from '../../../Images/CommonImages/message.svg'
import defaultprofile from '../../../Images/vendors/defaultprofile.svg'
import MainHeader from '../MainHheader/MainHeader'
import LandingHeader from '../LandingHeader/LandingHeader'
import { Grid, Tabs, tabsClasses } from '@mui/material'
import BabyCareShowImg from '../BabyCare/BabyCareShowImg'
import ButtonField from '../../FormFields/ButtonField'
import RatingField from '../../UiComponents/Rating'
import CustomerReview from '../../../Components/Admin/TotalVendors/CustomerReview'
import AllReviews from '../../../Components/Admin/TotalVendors/AllReviews'
import text from '../../Languages/en_US.json'
// import Footer from '../Footer/Footer'
import { useNavigate, useParams } from 'react-router-dom'
import OverallRatingReview from './OverallRatingReview'
import { useSelector } from 'react-redux'
import ShareButton from '../../UiComponents/ShareButton'
import reviewStyles from './WriteAReview.module.css'
import CommonCard from '../Card/CommonCard'
import { ForLanding } from '../../../Containers/LandingPage/Hooks'
import { distanceFunc } from '../../../Utils/CommonFunc'
import DeclinePopup from '../../FormFields/DeclinePopup'
import Footer from '../Footer/Footer'
import { Tooltip } from '@mui/material'

const WriteAReview = (props) => {
    const { getChatDetailsWithUserApiCAll, postReviewsOfVendors, getTrack } = ForLanding()

    const reviewData = useSelector((state) => state.landingData.reviews)

    const [writeReview, setWriteReview] = useState(false)
    const handleReview = () => {
        setWriteReview(true)
    }

    let lat = parseFloat(sessionStorage.getItem('latitude'))
    let lng = parseFloat(sessionStorage.getItem('longitude'))

    const navigate = useNavigate()
    // The below variables are used to ensure that the logged in vendor cannot give review and rating to himself/herself
    const userId = useParams()
    const userIdFromStorage = localStorage.getItem('userId')

    // The below variables are used to make sure that review cannot be given to other vendors without logging 
    const token = localStorage.getItem('token')
    const role = localStorage.getItem('role')

    const [openTrackModal, setOpenTrackModal] = useState(false)
    const handleClickForTrack = () => {
        setOpenTrackModal(!openTrackModal)
    }
    const clickedVendorLatlng = React.useMemo(() => ({
        lat: props.BusinessDetails?.lat ? props.BusinessDetails?.lat : 0,
        lng: props.BusinessDetails?.long ? props.BusinessDetails?.long : 0,
        location: props.BusinessDetails?.location
    }), [props.BusinessDetails]);

    const goToOriginalGMap = async () => {
        if (token) {
            try {
                const data = await getTrack(props.BusinessDetails.id);
                window.open(`https://www.google.com/maps/dir/${parseFloat(sessionStorage.getItem('latitude'))},${parseFloat(sessionStorage.getItem('longitude'))}/${clickedVendorLatlng?.location}/@${clickedVendorLatlng?.lat},${clickedVendorLatlng?.lng},${10}z`);
                setOpenTrackModal(!openTrackModal)
            } catch (error) {
                console.error("Error:", error.message);
            }
        } else {
            navigate('/login')
        }
    }

    const onMessageClick = (item) => {
        if (token !== null) {
            getChatDetailsWithUserApiCAll(item?.user?.id).then((res) => {
                if (res.id) {
                    role === 'user' ? navigate('/user/message', { state: res }) : navigate('/vendor/message', { state: res })
                } else {
                    role === 'user' ? navigate('/user/message', { state: item }) : navigate('/vendor/message', { state: item })
                }
            }).catch((err) => { console.log(err) });
        } else {
            navigate('/login')
        }
    }

    const onTrackClick = async (item) => {
        if (token) {
            try {
                const data = await getTrack(item.id);
                navigate('/maps', { state: item });
            } catch (error) {
                console.error("Error:", error.message);
            }
        } else {
            navigate('/login');
        }
    };

    // console.log(props.BusinessDetails, "# props.BusinessDetails")
    // const hostName = window.location.hostname;

    const shareUrl = `${process.env.REACT_APP_SHARE_URL}/details/${userId?.id}`
    return (
        <div className={``}>
            <MainHeader />
            <LandingHeader
                searchBar={true}
                goTomessage={true}
                goToNotification={true}
                border={true}
                profile={true}
            />


            {/* This is the main div of this component  */}
            <div className={` pl-[4.6%] pr-[5%] pb-6`}>
                <div className={`w-full flex justify-between items-center`}>
                    <p className={`py-8 text-deepgray-5 text-2xl font-[Roboto-Bold]`}>{props.BusinessDetails?.name}
                        {/* <span className={`font-[Roboto-Regular]`}>- 500 Items</span> */}
                    </p>
                    {/* {props.goToALlOffers === true &&
                        <div className={`p-2 text-white bg-skyblue-6 rounded text-xl font-[Roboto-Bold] cursor-pointer`} onClick={() => navigate('/offerdetails')}>
                            Go to all offers
                        </div>
                    } */}
                </div>
                <div className={`${reviewStyles.containerResponsive}`}>
                    <div className={`${reviewStyles.BabyCareShowImg}`}>
                        <div>
                            {props.BusinessDetails?.thumbnail?.length > 0 ? <BabyCareShowImg
                                imgArr={props.BusinessDetails?.thumbnail}
                                // likeIcon={true}
                                smallImgsDivCls={`flex flex-col gap-5`}
                                bigImgDivCls={`relative flex justify-center w-[76%] lg:w-[65%] min-h-[300px]`}
                            /> : <div className={`w-full h-[15em] flex justify-center items-center border rounded border-slate-600`}>
                                <p className={`text-[2em]`}>
                                    No Thumbnails Available
                                </p>
                            </div>}
                            {props.BusinessDetails?.user?.id !== parseInt(userIdFromStorage) &&
                                <Grid container spacing={4} style={{ marginTop: "1%" }} className={`gridFix`}>
                                    <Grid item xs={12} sm={8}>
                                        <ButtonField
                                            buttonextracls={`w-[94%] xl:w-[70%] bg-skyblue-6 py-2 lg:py-4 rounded cursor-pointer flex justify-center`}
                                            img={message}
                                            alt="message"
                                            buttonName='Contact Vendor'
                                            buttonnamecls={`text-white font-[Roboto-Medium] pl-1 sm:pl-2 capitalize`}
                                            sx={{
                                                fontSize: '1.25rem',
                                                '@media screen and (min-width:827px) and (max-width:1024px)': {
                                                    fontSize: '1em'
                                                },

                                                '@media screen and (max-width:826px)': {
                                                    fontSize: '0.70em'
                                                },
                                            }}
                                            onClick={() => onMessageClick(props.BusinessDetails)}
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4} className={`buttonFix`}>
                                        <ButtonField
                                            variant='outlined'
                                            buttonextracls={`w-full bg-white ${(props.BusinessDetails?.lat === null && props.BusinessDetails?.long === null) ? `cursor-not-allowed` : `cursor-pointer`}  py-2 lg:py-4 rounded border-deepgray-4 border flex justify-center`}
                                            img={trackicon}
                                            disabled={(props.BusinessDetails?.lat === null && props.BusinessDetails?.long === null) ? true : false}
                                            alt="trackicon"
                                            buttonName='Track'
                                            // onClick={() => onTrackClick(props.BusinessDetails)}
                                            onClick={() => handleClickForTrack()}
                                            buttonnamecls={`text-deepgray-4 font-[Roboto-Medium] pl-1 sm:pl-2 capitalize`}
                                            sx={{
                                                fontSize: '1.25rem',
                                                '@media screen and (min-width:828px) and (max-width:1024px)': {
                                                    fontSize: '1em'
                                                },

                                                '@media screen and (max-width:827px)': {
                                                    fontSize: '0.70em'
                                                },
                                            }}
                                        />
                                    </Grid>
                                    {openTrackModal &&
                                        <DeclinePopup
                                            dialogTitlecls={`!text-yellow-600`}
                                            open={openTrackModal}
                                            handleClose={handleClickForTrack}
                                            title={`Track`}
                                            DialogContent={
                                                <>
                                                    <p>{`Where do you want to go?`}</p>
                                                </>
                                            }
                                            cancelTextCls={`!bg-blue-600 !text-white`}
                                            notCancelText={`Browser's Google Map`}
                                            handleCancelButton={goToOriginalGMap}
                                            submitBtnCls={`!bg-green-600`}
                                            DeleteBtn={"In App Map"}
                                            onClick={() => onTrackClick(props.BusinessDetails)}
                                        />
                                    }
                                </Grid>
                            }
                        </div>
                    </div>
                    <div className={`${reviewStyles.businessDetails}`}>
                        <div>
                            {/* This below div is entirely for the heading along with sharing the details below the heading  */}
                            <div className={`flex justify-between mb-[10%]`}>
                                <div className={`flex`}>
                                    <div className={`bg-[#D9D9D9] rounded-full border border-[#D9D9D9] h-20 w-20 mr-4`}>
                                        {props.BusinessDetails?.logo && props.BusinessDetails?.logo?.key ?
                                            <img
                                                className='h-20 w-20 rounded-full'
                                                crossOrigin="anonymous"
                                                src={`${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${props.BusinessDetails?.logo?.key}&height=auto&width=auto`}
                                                alt="business"
                                            />
                                            :
                                            <img src={defaultprofile} alt="defaultprofile" />
                                        }
                                    </div>
                                    <div className={`flex flex-col gap-2`}>
                                        <p className={`text-[#434343] text-xl font-[Roboto-Medium]`}>{props.BusinessDetails?.user?.name}</p>
                                        <RatingField
                                            // SendRating={true}
                                            size="small"
                                            value={props.BusinessDetails?.avgRating}
                                            readOnly={true}
                                        />
                                        {/* <div className={`flex justify-start items-center gap-2`}>
                                            <div className={`bg-green-primary-100 h-3 w-3 rounded-full`}></div>
                                            <p className={`text-grey-600 font-[Roboto-Regular] text-sm`}>{text.active}</p>
                                        </div> */}
                                    </div>
                                </div>
                                <ShareButton url={shareUrl} fbCaption={'Please visit Explore...'} twitterCaption={'Please visit Explore...'} whatsappCaption={'Please visit Explore...'} />
                            </div>
                            {/* This div contails the details of the above header */}
                            {/* {props.BusinessDetails ? props.BusinessDetails : companyDetails.map((item, idx) => */}
                            {/* <div key={idx} className={`flex flex-col gap-8`}> */}
                            <div className={`flex flex-col gap-8`}>
                                <div className={`flex`}>
                                    <p className={`w-1/3 text-black text-sm font-[Roboto-Regular]`}>{text.location}</p>
                                    <p className={`w-2/3 text-grey-600 text-sm`}>{props.BusinessDetails?.location}</p>
                                </div>
                                {props.BusinessDetails?.lat !== null && props.BusinessDetails?.long !== null &&
                                    <div className={`flex`}>
                                        <p className={`w-1/3 text-black text-sm font-[Roboto-Regular]`}>{`Distance from you`}</p>
                                        <p className={`w-2/3 text-grey-600 text-sm`}>{distanceFunc(lat, props.BusinessDetails?.lat, lng, props.BusinessDetails?.long) + " K.M away"}</p>
                                    </div>
                                }
                                <div className={`flex`}>
                                    <p className={`w-1/3 text-black text-sm font-[Roboto-Regular]`}>{text.contactNumber}</p>
                                    <p className={`w-2/3 text-grey-600 text-sm`}>{props.BusinessDetails?.user?.phoneNo}</p>
                                </div>
                                <div className={`flex`}>
                                    <p className={`w-1/3 text-black text-sm font-[Roboto-Regular]`}>{text.email}</p>
                                    <Tooltip title={props.BusinessDetails?.user?.email}>
                                        <p className={`w-2/3 text-Active100 text-sm truncate`}>{props.BusinessDetails?.user?.email}</p>
                                    </Tooltip>
                                </div>
                                <div className={`flex`}>
                                    <p className={`w-1/3 text-black text-sm font-[Roboto-Regular]`}>{text.businessTimings}</p>
                                    <p className={`w-2/3 text-grey-600 text-sm`}>{props.BusinessDetails?.startTime + ` To ` + props.BusinessDetails?.endTime}</p>
                                </div>
                                <div className={`flex`}>
                                    <p className={`w-1/3 text-black text-sm font-[Roboto-Regular]`}>{text.establishmentYear}</p>
                                    <p className={`w-2/3 text-grey-600 text-sm`}>{props.BusinessDetails?.establishmentYear}</p>
                                </div>
                                <div className={`flex`}>
                                    <p className={`w-1/3 text-black text-sm font-[Roboto-Regular]`}>{text.serviceWeOffer}</p>
                                    <div className={`w-2/3 flex gap-2 flex-wrap whitespace-nowrap`}>
                                        {props.BusinessDetails?.services?.map((item, idx) =>
                                            <p key={idx} className={`text-[10px] bg-skyblue-0.5 px-2 py-0.5 rounded-full text-skyblue-7 break-words truncate`}>{item?.name}</p>)}
                                    </div>
                                </div>
                                <div className={`flex`}>
                                    <p className={`w-1/3 text-black text-sm font-[Roboto-Regular]`}>{text.offerAvailable}</p>
                                    <p className={`w-2/3 text-grey-600 text-sm`}>{props.BusinessDetails?.services?.length}</p>
                                </div>
                                <div className={`flex flex-col gap-4`}>
                                    <div className={`!bg-white p-5 border`}>
                                        <p className={`text-base font-[Roboto-Regular] text-black pb-2 lg:pb-5`}>{text.description}:</p>
                                        <p className={`text-[15px] leading-7 font-[Roboto-Regular] text-grey-600`}>{props.BusinessDetails?.description ? props.BusinessDetails?.description : "No Description Found"}</p>
                                    </div>
                                </div>
                            </div>
                            {/* )} */}

                        </div>
                    </div>
                </div>

                {/* Scrollable tabs where cards are called */}
                <div>
                    <p className={`font-[Roboto-Medium] text-xl my-[2%]`}>{`Services`}</p>
                    {props?.serviceData?.filter((item) => item?.status === 'active')?.length > 0 ?
                        <Tabs
                            className={`scrollButton w-full h-full flex justify-start`}
                            variant="scrollable"
                            scrollButtons
                            aria-label="visible arrows tabs example"
                            sx={{
                                [`& .${tabsClasses.scrollButtons}`]: {
                                    '&.Mui-disabled': { opacity: 0.3 },
                                },
                            }}
                        >
                            <div className={`flex w-full gap-6`}>
                                {props?.serviceData?.filter((item) => item?.status === 'active')?.map((item, idx) =>
                                    <div key={idx} className={`w-auto sm:w-[50%] md:w-[36%] lg:w-[25%]`}>
                                        <CommonCard
                                            carousel={item?.thumbnail && item?.thumbnail?.length > 0 ? true : false}
                                            noContentImg={item?.thumbnail && item?.thumbnail?.length === 0 && true}
                                            carouselimg={item?.thumbnail && item?.thumbnail?.length > 0 && item?.thumbnail}
                                            carouselHeight="200px"
                                            carouselwidth="14em"
                                            noContentCls={`h-[150px]`}
                                            height={false}
                                            featurecrown={false}
                                            onHandelOpeModal={() => props.handleServices(item.id)}
                                            // crownicon={item.crownicon}
                                            // featuretag={item.featuretag}
                                            showLikeIcon={false}
                                            rating={true}
                                            ratingvalue={item.ratingvalue}
                                            itemtext={true}
                                            Shareicon={false}
                                            text1={item.name}
                                            text2={item.description}
                                            itememail={true}
                                            profile={false}
                                            profileicon={item.profileicon}
                                            email={item?.user?.email}
                                            distance={false}
                                            showamount={true}
                                            amount={item.amount}
                                            itemname={item?.user?.name}
                                            percentage={item?.offer[0]?.percentage && true}
                                            offerpercentage={item?.offer[0]?.percentage}
                                            price={item?.offer[0]?.price && true}
                                            offerprice={item?.offer[0]?.price}
                                            border={true}
                                            distancetext={item.distancetext}
                                            hoverbutton={false}
                                            buttonName2={item.buttonName2}
                                            buttonimg={item.buttonimg}
                                            buttonName3={item.buttonName3}
                                            buttonimg2={item.buttonimg2}
                                        />
                                    </div>
                                )}
                            </div>
                        </Tabs>
                        : <div className="text-lg text-[#555] font-semibold ml-6">No service available.</div>}
                </div>



                <div className={`border border-b my-10`}></div>

                <div className={`${reviewStyles.reviewSection}`}>
                    <div className={`${reviewStyles.customerReviewWidth}`}>
                        <p className={`font-[Roboto-Medium] text-black text-xl mb-4`}>{text.customerreview}</p>
                        <CustomerReview BusinessDetails={props.BusinessDetails} />
                        {writeReview === true ?
                            <OverallRatingReview setWriteReview={setWriteReview} BusinessDetails={props.BusinessDetails} postReviewsOfVendors={postReviewsOfVendors} />
                            :
                            userIdFromStorage !== userId?.id &&
                            <div className={`cursor-pointer rounded border-deepgray-4 border flex justify-center my-4 boxShadowButton `}>
                                <ButtonField
                                    buttonName='Write a Review'
                                    buttonextracls={` rounded-md border-deepgrey-3 border bg-white py-3 w-full`}
                                    buttonnamecls={`text-deepgray-4 font-[Roboto-Medium]  text-sm capitalize`}
                                    outerBtnCls={`w-full`}
                                    onClick={() => {
                                        if (token !== null || undefined && role !== 'admin') {
                                            handleReview()
                                        } else {
                                            navigate('/login')
                                        }
                                    }}
                                />
                            </div>
                        }
                    </div>
                    <div className={`${reviewStyles.reviewsWidth}`}>
                        <p className={`font-[Roboto-Medium] text-black text-xl mb-4 break-word`}>{text.reviews}</p>
                        <AllReviews
                            reviewData={reviewData}
                            reviewDate={props.reviewDate}
                        // mainDivCls={`flex flex-col justify-between items-center bg-white`}
                        // subDivCls={`px-0 py-6`}
                        />
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default WriteAReview