import React, { useState } from 'react'
import TotalUsersHeader from '../TotalUsers/TotalUsersHeader'
import text from '../../../Common/Languages/en_US.json'
import TotalVendorsTable from './TotalVendorsTable'
// import VendorDetailsPage from './VendorDetailsPage'
import AddVendorFromForAdmin from './AddVendorFromForAdmin'
import StepperLayout from '../../../Common/Layout/StepperLayout/StepperLayout'
import TabComponent from '../../../Common/UiComponents/TabComponent'
import { ForUploadMediaHooks } from '../../../Containers/UploadMedia/Hooks'
import { ForSignup } from '../../../Containers/Authentication/Signup/Hook'
import EditProfileInfo from '../../VendorDetailsPage/ProfileEdit/EditProfileInfo'
import EditBusinessInfo from '../../VendorDetailsPage/ProfileEdit/EditBusinessInfo'


const VendorComponent = (props) => {

    console.log(props?.editProfile , "props?.editProfile  #")

    const categories = [
        {
            label: "Profile Info",
            value: "0",
            backgroundcolor: "bg-white",
            content: <EditProfileInfo
                toggleEditProfile={props.toggleEditProfile}
                editVendorProfileDetailsApi={props?.editVendorProfileDetailsApi}
                vendorProfileData={props?.vendorBusinessData}
                loading={props?.loading}
                closeForm={props?.closeForm}
                isAdmin={true}
            />
        },
        {
            label: "Business Info",
            value: "1",
            backgroundcolor: "bg-white",
            content: <EditBusinessInfo
                toggleEditProfile={props?.toggleEditProfile}
                closeForm={props.closeForm}
                vendorProfileData={props?.vendorBusinessData}
                editVendorBusinessDetailsApi={props?.editVendorBusinessDetailsApi}
                doUploadedMedia={props?.doUploadedMedia}
                doUploadedLogo={props?.doUploadedLogo}
                multipleMediaApiCall={props?.multipleMediaApiCall}
                documentUpload={props?.documentUpload}
                logoUpload={props?.logoUpload}
                multipleUpload={props?.multipleUpload}
                loading={props?.loading}
                mapLocationTxt={props?.mapLocationTxt}
                setMapLocationTxt={props?.setMapLocationTxt}
                mapLocationArr={props?.mapLocationArr}
                mapLocation={props.mapLocation}
                handleClickLocation={props?.handleClickLocation}
                isAdmin={true}
            />
        },
    ]

    return (
        <div className={`${props?.editProfile ? 'p-5 bg-white' : ""}`}>
            {!props.openVendorTable && !props?.editProfile ?
                <>
                    <TotalUsersHeader userName={text.totalVendors}
                        csvBtn={true}
                        dropDown={true}
                        addBtn={true}
                        downloadExcelReport={props.downloadExcelReport}
                        toggleSubAdmin={props.handleClickForOpenVendorTable}
                        getTotalVendorsInAdmin={props.getTotalVendorsInAdmin}
                        forComponent='totalVendor'
                        setGovFieldData={props.setGovFieldData}
                        onChange={props.handleSearchVendor}
                        search={true}
                    />
                    <div className={`h-auto !overflow-auto `}>
                        <TotalVendorsTable
                            getTotalVendorsInAdmin={props.getTotalVendorsInAdmin}
                            totalVendorsInAdminPage={props.totalVendorsInAdminPage}
                            handleeditclick={props.handleeditclick}
                            deleteclcik={props.deleteclick}
                            approveUserApi={props.approveUserApi}
                            userEnableDisableApi={props.userEnableDisableApi}
                            getSingleVendorDetailsApi={props.getSingleVendorDetailsApi}
                            checkEachVendorProductAdmin={props.checkEachVendorProductAdmin}
                            giveBadgetoUser={props.giveBadgetoUser}
                            approveVendorService={props.approveVendorService}
                            getServiceByBusinessInAdmin={props.getServiceByBusinessInAdmin}
                            loading={props.loading}
                            buttonLoading={props.buttonLoading}
                            createCorporateSubscription={props.createCorporateSubscription}
                            toggleEditProfile={props?.toggleEditProfile}
                        />
                    </div>
                </>
                :
               !props?.editProfile && <div>
                    <AddVendorFromForAdmin
                        cancelclick={props.handleClickForOpenVendorTable}
                        cancelAddressclick={props.handleClickForOpenVendorTable}
                        showAddAddress={props.showAddAddress}
                        createVendorByAdmin={props.createVendorByAdmin}
                        businessAddAddressByAdmin={props.businessAddAddressByAdmin}
                        GetAllVendorByAdmin={props.GetAllVendorByAdmin}
                        doUploadedMedia={props.doUploadedMedia}
                        doUploadedLogo={props.doUploadedLogo}
                        // doUploadedMultipleImages={props.doUploadedMultipleImages}
                        editclick={props.editclick}
                        editVendorFormData={props.editVendorFormData}
                        editbusinessAddressDetails={props.editbusinessAddressDetails}
                        multipleMediaApiCall={props.multipleMediaApiCall}
                        totalVendorCreateMsg={props.totalVendorCreateMsg}
                        loading={props.loading}
                    />


                </div>


            }

            {props?.editProfile && (props?.businessLoader ? <div className='w-full h-screen flex items-center justify-center'>
                <h1>Please wait...</h1>
            </div>
                : <TabComponent
                    mappingname={categories}
                    onClick={() => { }}
                    value={props.editTabValue}
                    setValue={props?.setEditTabValue}
                />)
            }

        </div>
    )
}

export default VendorComponent