import axios from "axios";

export const doGetApiCall = async (data) => {
  return new Promise(async (resolve, reject) => {
    let token;
    if (typeof window !== 'undefined') {
      token = localStorage.getItem('token')
    }
    const reqstValues = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token || "",
      },
    };
    fetch(data.url, reqstValues)
      .then((response) => {
        if (response.status === 401) {
          // localStorage.clear()
        } else {
          return response.json();
        }
      })
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const doPostApiCall = async (data) => {
  return new Promise(async (resolve, reject) => {
    let token;
    if (typeof window !== 'undefined') {
      token = localStorage.getItem('token')
    }
const bodyData = data.bodyData;
    const reqstValues = {
      method: "POST",
      body: JSON.stringify(data.bodyData),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token || "",
      },
    };
    fetch(data.url, reqstValues)
      .then((result) => {
        if (result.status === 401) {
          localStorage.clear()
        } else {
          return result.json();
        }
      })
      .then((result) => {
        if (result.token) {
          localStorage.setItem("token", result.token);
        }
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// export const doUploadMediaApiCall = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     let token;
//     if (typeof window !== 'undefined') {
//       token = localStorage.getItem('token')
//     }
//     const reqstValues = {
//       method: "POST",
//       body: data.bodyData,
//       headers: {
//         Authorization: token || "",
//       },
//     };
//     fetch(data.url, reqstValues)
//       .then((result) => {
//         if (result.status === 401) {
//           localStorage.clear()
//         } else {
//           return result.json();
//         }
//       })
//       .then((result) => {
//         if (result.token) {
//           localStorage.setItem("token", result.token);
//         }
//         resolve(result);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };



export const doUploadMediaApiCall = async (data, onUploadProgress) => {
  return new Promise(async (resolve, reject) => {
    let token;
    if (typeof window !== "undefined") {
      token = localStorage.getItem("token");
    }

    axios
      .post(data.url, data.bodyData, {
        headers: {
          Authorization: token || "",
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            onUploadProgress(progress); // Update progress in UI
          }
        },
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("token", response.data.token);
        }
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          localStorage.clear();
        }
        reject(error);
      });
  });
};


export const doDeleteApiCall = async (data) => {
  return new Promise(async (resolve, reject) => {
    let token;
    if (typeof window !== 'undefined') {
      token = localStorage.getItem('token')
    }
    const reqstValues = {
      method: "DELETE",
      body: JSON.stringify(data.bodyData),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token || "",
      },
    };
    fetch(data.url, reqstValues)
      .then((response) => {
        if (response.status === 401) {
          localStorage.clear()
        } else {
          return response.json();
        }
      })
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const doPutApiCall = async (data) => {
  return new Promise(async (resolve, reject) => {
    let token;
    if (typeof window !== 'undefined') {
      token = localStorage.getItem('token')
    }
    const reqstValues = {
      method: "PUT",
      body: JSON.stringify(data.bodyData),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token || "",
      },
    };
    fetch(data.url, reqstValues)
      .then((result) => {
        if (result.status === 401) {
          localStorage.clear()
        }else {
          return result.json();
        }
      })
      .then((result) => {
        if (result.token) {
          localStorage.setItem("token", result.token);
        }
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
